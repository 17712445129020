<template>
    <div 
      :style="{ 
        width: layer.width + 'px', 
        height: layer.height + 'px', 
        position: 'relative', 
        background: layer.config.bg_visual_flow.color_1
      }"
    >
      <!-- Wrapper div for the image container -->
      <div :style="imageContainerStyle">
        <!-- Inner div that holds the image as a background -->
        <div 
          :style="{
            backgroundImage: 'url(' + image + ')',
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: focus ? background_position : 'center'
          }"
        ></div>
      </div>
  
      <div :style="blurDivStyle"></div>
    </div>
  </template>
  
  <script>
  import FontMixin from '../mixins/font';
  
  export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],
    computed: {
      image() {
        return this.layer.config.bg_visual_flow.image;
      },
      focus_points() {
        try {
          let link = new URL(this.image);
          let focus_x = link.searchParams.get('focus_x');
          let focus_y = link.searchParams.get('focus_y');
          return { x: focus_x, y: focus_y };
        } catch (e) {
          return { x: 0, y: 0 };
        }
      },
      has_focus_points() {
        try {
          let link = new URL(this.image);
          let focus_x = link.searchParams.get('focus_x');
          let focus_y = link.searchParams.get('focus_y');
          return !!(focus_x && focus_y);
        } catch (e) {
          return false;
        }
      },
      background_position() {
        let y = Math.abs(this.focus_points.y) / 2 * 100;
        let x = Math.abs(this.focus_points.x) / 2 * 100;
        if (this.focus_points.y < 0) {
          y += 50;
        } else {
          y = 50 - Math.abs(this.focus_points.y) * 50;
        }
        if (this.focus_points.x > 0) {
          x += 50;
        } else {
          x = 50 - Math.abs(this.focus_points.x) * 50;
        }
        return `${x}% ${y}%`;
      },
      focus() {
        return this.has_focus_points;
      },
      gradientDirection() {
        const direction = this.layer?.config?.bg_visual_flow?.direction || 'top';
        switch (direction) {
          case 'top':
            return '0deg';
          case 'left':
            return '270deg';
          case 'bottom':
            return '180deg';
          case 'right':
          default:
            return '90deg';
        }
      },
      blurDivStyle() {
        const color1 = this.layer.config.bg_visual_flow.color_1;
        const imageSize = this.layer.config.bg_visual_flow.image_size;
        const direction = this.layer?.config?.bg_visual_flow?.direction || 'top';
        const gradientDirection = this.gradientDirection;
  
        return {
          width: direction === 'left' || direction === 'right' ? '100px' : '100%',
          height: direction === 'top' || direction === 'bottom' ? '100px' : '100%',
          [direction]: `calc(${imageSize}% - 100px)`,
          position: 'absolute',
          background: `linear-gradient(${gradientDirection}, ${color1} 0%, rgba(${this.hexToRgb(color1)}, 0) 100%)`
        };
      },
      imageContainerStyle() {
        const imageSize = this.layer.config.bg_visual_flow.image_size;
        const direction = this.layer?.config?.bg_visual_flow?.direction || 'top';
        const baseStyle = {
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: this.focus ? this.background_position : 'center'
        };
  
        switch (direction) {
          case 'top':
            return { ...baseStyle, height: imageSize + '%', top: '0px' };
          case 'bottom':
            return { ...baseStyle, height: imageSize + '%', bottom: '0px' };
          case 'left':
            return { ...baseStyle, width: imageSize + '%', left: '0px' };
          case 'right':
            return { ...baseStyle, width: imageSize + '%', right: '0px' };
          default:
            return baseStyle;
        }
      }
    },
    methods: {
      hexToRgb(hex) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
      }
    }
  };
  </script>