<template>
    <div>
        <div v-for="group in groupKeys" style="margin-bottom: 12px;">
            <label>{{ upperFirst(group) }}</label>
            <div style="display: flex; flex-direction: column; gap: 8px; width: 100%;">
                <div v-for="(component, i) in groups[group]"
                    style="display: flex; flex-wrap: wrap; gap: 8px; width: 100%">

                    <div @click="e => onClick(component.render(null, { canvas: canvasContext }).layers)"
                        style="width: 105px; max-height: 105px; border-radius: 6px; padding: 12px; border: 1px solid #eee;"
                        :style="`background: ${backgroundStr(component.render(null, { canvas: sceneContext.canvas }))}; background-size: cover;`">
                        <ComponentPreview :component="component.config()" />
                    </div>

                    <div v-for="variant in component.variants()" style="">
                        <div @click="e => onClick(component.render(variant, { canvas: sceneContext.canvas }).layers)"
                            :style="`background: ${backgroundStr(variant)}; background-size: cover;`"
                            style="width: 105px; max-height: 105px; border-radius: 6px; padding: 12px; border: 1px solid #eee;">
                            <ComponentPreview :component="variant" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentPreview from '../../image-editor/components/preview.vue'
export default {
    props: {
        components: {
            type: Array,
            required: true,
        },
        onClick: {
            type: Function,
            required: true,
            default: () => () => { }
        },
        sceneContext: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },
    components: {
        ComponentPreview
    },
    data() {
        return {
            groups: {},
            canvasContext: {}
        }
    },
    computed: {
        groupKeys() {
            return Object.keys(this.groups)
        }
    },
    methods: {
        upperFirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
        isImage(icon) {
            if (icon) {
                return icon.includes('http')
            } else {
                return false
            }
        },
        backgroundStr(component) {
            if (this.isImage(component.background)) {
                return `url(${component.background})`
            } else {
                return component.background
            }
        }
    },
    watch: {
        sceneContext: {
            handler(context) {
                this.canvasContext = context.canvas
            },
            deep: true,
            immediate: true
        },
        components: {
            handler(components) {
                this.groups = {}
                components.forEach((component, i) => {
                    const label = component.labels()[0]
                    if (label) {
                        if (!this.groups[label]) {
                            this.groups[label] = []
                        }
                        this.groups[label].push(component)
                    }
                })
            },
            immediate: true
        }
    }
}
</script>