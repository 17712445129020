import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ImageWithoutBackground extends Component {
  name(){
    return 'Image without background'
  }

  labels(){
    return ['addon']
  }

  icon(){
    return 'fa-regular fa-picture-o'
  }

  required_addon(){
    return 'image background removal'
  }

  config(){
    return Layer.factory({
      layer_type: 'image',
      text: '{{adflow_image_no_background}}',
      width: 400,
      height: 400,
      config: {
        image_url: '{{adflow_image_no_background}}'
      }
    })
  }
}
