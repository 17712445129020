import actions   from './actions'
import getters   from './getters'
import mutations from './mutations'

const state = () => ({
  id: null,
  audience: [],
  suggestions: [],
  searchResults: [],
  reach: 0,
  reach_status: ''
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
