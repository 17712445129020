<template>
    <VueResizable class="video__timeline-animation" v-if="timeline && animation"
        :class="{ 'video__timeline-animation--active': isActive(animation) }"
        :width="animation.get_html_width(timeline)" :left="animation.get_html_left(timeline)" :height="25"
        :fitParent="true" :active="['l', 'r']" @resize:start="start_resize"
        @resize:end="e => stop_resize(e, animation)">

        <div class="video__timeline-animation__end" v-on:click="select_animation"
            :style="`width: ${in_relative_width}%; min-width: 10%;`">
            <div class="video__timeline-animation__dragger"></div>
            <div class="chip__filter" v-if="animation.composeable()">
                <div class="chip__filter__data custom-select-wrapper">
                    <div class="custom-select-trigger">
                        {{ animation.in_anim().name() }} In
                    </div>
                    <select class="custom-select" v-model="in_animation" v-on:change="on_in_update">
                        <option value="do_nothing">None</option>
                        <option :value="animation.int_name()"
                            v-for="animation in ComposableAnimationFactory.availableInOutComposeableAnimations(animation.layer)">
                            {{ animation.pretty_name() }} in
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="video__timeline-animation-content" v-on:click="select_animation" :style="`width: ${while_relative_width}%; min-width: 15%; display: flex; 
            align-items: center; justify-content: center;`">

            <div class="chip__filter">
                <div class="chip__filter__data custom-select-wrapper" v-if="animation.composeable()">
                    <div class="custom-select-trigger">
                        {{ animation.while_anim().name() }} While
                    </div>
                    <select class="custom-select" v-model="while_animation" v-on:change="on_while_update"
                        v-if="animation.composeable()">
                        <option value="do_nothing">None</option>
                        <option :value="animation.int_name()"
                            v-for="animation in ComposableAnimationFactory.availableWhileComposeableAnimations(animation.layer)">
                            {{ animation.pretty_name() }}
                        </option>
                    </select>
                </div>
                <div class="custom-select-trigger" v-else>
                    {{ animation.name() }}
                </div>
            </div>

            <span class="animation-durxation">
                &nbsp;{{ (animation.duration / 1000).toFixed(2) }}s
            </span>
        </div>


        <div class="video__timeline-animation__end" v-on:click="select_animation"
            :style="`width: ${out_relative_width}%; min-width: 10%; display: flex; justify-content: flex-end;`">
            <div class="chip__filter" v-if="animation.composeable()">
                <div class="chip__filter__data custom-select-wrapper">
                    <div class="custom-select-trigger">
                        {{ animation.out_anim().name() }} Out
                    </div>
                    <select class="custom-select" v-model="out_animation" v-on:change="on_out_update">
                        <option value="do_nothing">None</option>
                        <option :value="animation.int_name()"
                            v-for="animation in ComposableAnimationFactory.availableInOutComposeableAnimations(animation.layer)">
                            {{ animation.pretty_name() }} out
                        </option>
                    </select>
                </div>
            </div>
            <div class="video__timeline-animation__dragger"></div>
        </div>

    </VueResizable>
</template>


<script>
import ComposableAnimationFactory from '../image-editor/lib/animations/composeables/factory'
import LayerModel from './store/modules/layers/model'
import VueResizable from 'vue-resizable'
import Panel from '../studio/components/panel.vue'
import AnimationConfig from './layer_configs/animations.vue'
export default {
    components: { VueResizable, Panel, AnimationConfig },
    props: {
        on_animation_select: {
            type: Function,
            required: true
        },
        animation: {
            type: Object,
            required: true
        },
        active_layer: {
            type: Object,
            required: false
        },
        layers: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            resizing: false,
            out_tmp_layer: null,
            in_tmp_layer: null,
            while_tmp_layer: null,
            ComposableAnimationFactory: ComposableAnimationFactory,
            in_animation: null,
            out_animation: null,
            while_animation: null,
            timeline: null
        }
    },
    computed: {
        layer() {
            return this.animation.layer
        },
        in_relative_width() {
            const duration = this.layer.config.animation.in
                ? this.layer.config.animation.in.duration
                : this.animation.duration * .23;

            return duration / this.animation.duration * 100
        },
        out_relative_width() {
            const duration = this.layer.config.animation.out
                ? this.layer.config.animation.out.duration
                : this.animation.duration * .23;

            return duration / this.animation.duration * 100
        },
        while_relative_width() {
            const duration = this.layer.config.animation.while
                ? this.layer.config.animation.while.duration
                : this.animation.duration * .54;

            return duration / this.animation.duration * 100
        }
    },
    mounted() {
        if (this.animation) {
            this.in_animation = this.animation.in()
            this.out_animation = this.animation.out()
            this.while_animation = this.animation.while()
        }

        this.while_tmp_layer = this.layer_model_factory({
            ...this.animation.layer,
            config: {
                ...this.animation.layer.config,
                animations: [this.animation.layer.config.animation?.while].filter(Boolean)
            }
        })

        this.in_tmp_layer = this.layer_model_factory({
            ...this.animation.layer,
            config: {
                ...this.animation.layer.config,
                animations: [this.animation.layer.config.animation?.in].filter(Boolean)
            }
        })
        this.out_tmp_layer = this.layer_model_factory({
            ...this.animation.layer,
            config: {
                ...this.animation.layer.config,
                animations: [this.animation.layer.config.animation?.out].filter(Boolean)
            }
        })

        this.$nextTick(() => {
            setTimeout(() => {
                this.timeline = this.$parent.$refs.timeline
            }, 25)
        })
    },
    methods: {
        select_animation() {
            this.on_animation_select(this.animation)
        },
        get_layer(layer) {
            return this.layers.find(l => l.id == layer.id)
        },
        show_while_panel(e) {
            this.close_panels()
            this.$refs.whilePanel?.toggleMenu(e)
        },
        layer_model_factory(layer) {
            return new LayerModel(layer)
        },
        on_while_update(event) {
            const updated_layer_conf = {
                ...this.animation.layer,
                config: {
                    ...this.animation.layer.config,
                    animation: {
                        ...this.animation.layer.config.animation,
                        while: { type: this.while_animation }
                    }
                }
            }
            this.animation.update_layer(updated_layer_conf)
        },
        on_in_update(event) {
            const updated_layer_conf = {
                ...this.animation.layer,
                config: {
                    ...this.animation.layer.config,
                    animation: {
                        ...this.animation.layer.config.animation,
                        in: { type: this.in_animation }
                    }
                }
            }
            this.animation.update_layer(updated_layer_conf)
        },
        on_out_update(key, value) {
            const updated_layer_conf = {
                ...this.animation.layer,
                config: {
                    ...this.animation.layer.config,
                    animation: {
                        ...this.animation.layer.config.animation,
                        out: { type: this.out_animation }
                    }
                }
            }
            this.animation.update_layer(updated_layer_conf)
        },
        show_in_panel(e) {
            this.close_panels()
            this.$refs.inPanel?.toggleMenu(e)
        },
        show_out_panel(e) {
            this.close_panels()
            this.$refs.outPanel?.toggleMenu(e)
        },
        close_panels() {
            this.$refs.inPanel[0]?.closeMenu()
            this.$refs.outPanel[0]?.closeMenu()
        },
        stop_resize(e, animation) {
            animation.set_attributes(e, this.timeline, this.get_layer(animation.layer))
            this.resizing = false
        },
        start_resize() {
            this.resizing = true
        },
        isActive(animation) {
            if (this._self['layers/active'] && animation.layer.id == this._self['layers/active'].id) {
                return true;
            }

            if (this.active_layer && animation.layer.id == this.active_layer.id) {
                return true;
            }
        }
    }
}
</script>