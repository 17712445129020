<template>
  <div class="layer-config__essentials">
    <div class="form-group">
      <label>Name</label>
      <input type="text" :value="layer.text" @input="(e) => updateLayer('text', e.target.value)" />
    </div>
    <div class="form-group">
      <label>Scale</label>
      <button type="button" :class="{ 'button__toggle--active': layer.config['object_fit'] == 'contain' }"
        class="button--light button--dark-text" v-on:click="updateLayerConfig({ object_fit: 'contain' })">
        <i class="fa-regular fa-compress" aria-hidden="true"></i>
      </button>
      <button type="button" :class="{ 'button__toggle--active': layer.config['object_fit'] == 'cover' }"
        class="button--light button--dark-text" v-on:click="updateLayerConfig({ object_fit: 'cover' })">
        <i class="fa-regular fa-expand" aria-hidden="true"></i>
      </button>
    </div>

    <div class="form-group">
      <label>Field</label>
      <input type="text" placeholder="additional images field..."
        @input="(e) => updateLayerConfig({ additional_images_field: e.target.value })"
        :value="layer.config['additional_images_field']">
    </div>

    <br />

    <div class="form-group">
      <label>Prefered position</label>
      <p class="small">
        Select the prefered additional image position.
        If this position does not exist the nearest position will be used for image generation.
        If no image is found the layer will not be shown.
      </p>
      <div>
        <div class="image-editor__additional-image-container"
          v-on:click="e => updateLayerConfig({ 'additional_images_index': index })"
          :class="{ 'image-editor__additional-image-container--active': activeIndex() == index }"
          v-for="(image, index) in images" :key="image">
          <Thumb class="thumb--small" :url="image" />
          <br />
          {{ index + 1 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Thumb from '../../assets/thumb.vue'
export default {
  props: ['mediaFolder', 'layer', 'samples', 'onUpdate'],
  components: {
    Thumb
  },
  data() {
    return {

    }
  },
  computed: {
    sample() {
      return this.$props.samples.data[this.$props.samples.indice]
    },
    images() {
      const images = this.sample[this.$props.layer.config['additional_images_field']]
      if (typeof images === 'string') {
        return images.split(',').filter(Boolean)
      } else {
        return (typeof images == 'object') ? images : [images]
      }
    }
  },
  methods: {
    activeIndex() {
      if (this.$props.layer.config.additional_images_index > this.images.length - 1)
        return this.images.length - 1
      return this.$props.layer.config.additional_images_index
    },
    updateLayerConfig(conf) {
      this.updateLayer('config', { ...this.layer.config, ...conf })
    },
    updateLayer(key, value) {
      const layer = { ...this.layer, [key]: value }
      this.$props.onUpdate(layer)
    },
  }
}

</script>
