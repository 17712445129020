import Component from '../../component'

export default class Background extends Component {
  render(variant, { canvas } = { canvas: {} }) {
    if (variant) {
      variant.layers[0].width = canvas.width
      variant.layers[0].height = canvas.height
      return variant
    }
    return this.config({ canvas })
  }

  sizeConfig(size) {
    if (size) {
      const width = size.display_dimensions[0]
      const height = size.display_dimensions[1]
      switch (size.aspect_ratio) {
        case '1:1':
          return {
            width,
            height,
          }
        case '16:9':
          return {
            width,
            height,
          }
        case '9:16':
          return {
            width,
            height,
          }
        default:
          return {}
      }
    }
    return {}
  }
}
