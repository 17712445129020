var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ color: _vm.layer.color, width: _vm.layer.width + 'px', position: 'relative' })},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","z-index":"0","border-radius":"20px","border-bottom-left-radius":"0px","padding":"20px 20px 20px 30px","background":"white","align-items":"center","overflow":"hidden","gap":"10px"}},[(_vm.isImage(_vm.image) && _vm.layer.config.native_tiktok_reply.show_image)?_c('div',{style:({
                backgroundImage: 'url(' + _vm.image + ')',
                width: ((_vm.layer.fontSize * 0.7) + "px"), // Increased width
                height: ((_vm.layer.fontSize * 0.7) + "px"), // Increased height
                minWidth: ((_vm.layer.fontSize * 0.7) + "px"), // Increased width
                minHeight: ((_vm.layer.fontSize * 0.7) + "px"), // Increased height
                borderRadius: '50%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
        })}):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"flex-start","padding":"10px 40px 20px 10px","gap":"10px"}},[_c('span',{staticStyle:{"width":"100%","text-wrap":"wrap","color":"#959595"},style:({ 
                    fontSize: _vm.layer.fontSize * 0.3 + 'px',
                    fontFamily: _vm.font,
                })},[_vm._v("\n                "+_vm._s(_vm.top_text)+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"sticker__repeater__text",staticStyle:{"width":"100%"},style:(("\n                    color: " + (_vm.layer.config.native_tiktok_reply.bg_color) + "; \n                    font-size: " + (_vm.layer.fontSize * 0.5) + "px;\n                    font-family: " + _vm.font + ";\n                    text-wrap: wrap;"))},[_vm._v("\n                "+_vm._s(_vm.layer.text)+"\n            ")])])]),_vm._v(" "),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"45","height":"22","viewBox":"0 0 45 22","fill":"none"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M0 0V16.8135C0 20.6396 4.12305 23.0479 7.45508 21.168L44.9941 0H0Z","fill":"white"}})])])}
var staticRenderFns = []
export { render, staticRenderFns }