import anime from 'animejs/lib/anime'
import Animation from '../animation'

import ComposeableAnimationFactory from './composeables/factory'

export default class InWhileOut extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer, video_duration
  }) {
    super({
      samples, layer, uid, duration, update_layer, video_duration
    })

    this.total_video_duration = video_duration
  }

  name() {
    return this.while_anim() ? this.while_anim().name() : 'Do nothing'
  }

  composeable() {
    return true
  }

  static composeable() {
    return true
  }

  in() {
    if (!this.layer.config.animation.in || !this.layer.config.animation.in.type) return 'do_nothing'
    return this.layer.config.animation.in.type
  }

  out() {
    if (!this.layer.config.animation.out) return 'do_nothing'
    return this.layer.config.animation.out.type
  }

  while() {
    if (!this.layer.config.animation.while) return 'do_nothing'
    return this.layer.config.animation.while.type
  }

  in_anim() {
    return ComposeableAnimationFactory.createComposeableAnimation(this.in(), {
      samples: this.samples,
      layer: this.layer,
      uid: this.uid,
      duration: this.duration,
      update_layer: this.update_layer,
      video_duration: this.total_video_duration
    })
  }

  while_anim() {
    return ComposeableAnimationFactory.createComposeableAnimation(this.while(), {
      samples: this.samples,
      layer: this.layer,
      uid: this.uid,
      duration: this.duration,
      update_layer: this.update_layer,
      video_duration: this.total_video_duration
    })
  }

  out_anim() {
    return ComposeableAnimationFactory.createComposeableAnimation(this.out(), {
      samples: this.samples,
      layer: this.layer,
      uid: this.uid,
      duration: this.duration,
      update_layer: this.update_layer,
      video_duration: this.total_video_duration
    })
  }

  cleanup() {
    const targets = $(`${this.target()}`)
    $(targets).css('opacity', '1');
    this.in_anim().cleanup()
    this.out_anim().cleanup()
    if (this.while_anim()) {
      this.while_anim().cleanup();
    }
  }

  prepare() {
    const targets = $(`${this.target()}`)
    $(targets).css('opacity', '0');
    this.in_anim().prepare('in')
    if (this.while_anim()) {
      this.while_anim().prepare()
    }
  }

  static int_name() {
    return 'inwhileout'
  }

  static pretty_name() {
    return 'In while out'
  }

  static exposed_to() {
    return ['background', 'image', 'text', 'text_area', 'video', 'price', 'rectangle', 'circle', 'logo']
  }

  static properties() {
    return {
      ...super.properties(),
    }
  }

  animation() {
    this.timeline = anime.timeline({
      duration: this.duration,
      autoplay: false
    });

    const { timeline } = this;

    if (this.in()) {
      const initial_opacity = this.layer.config.animation.delay_percentage === 0 ? 1 : 0
      if (this.in() === 'do_nothing') {
        timeline.add({
          targets: `${this.target()}`,
          opacity: {
            value: [initial_opacity, this.opacity()],
            duration: 1,
            easing: 'linear'
          }
        });
      } else {
        let in_duration = this.duration * 0.23
        if (this.layer.config.animation.in && this.layer.config.animation.in.duration) {
          in_duration = this.layer.config.animation.in.duration
          // console.log('setting in duration', in_duration, this.layer.config.animation.in)
        }

        if (in_duration > this.duration) {
          console.error(`In animation duration is longer than total animation duration for layer ${this.layer.id}`);
          in_duration = this.duration * 0.23;
        }

        if (in_duration === 0) {
          in_duration = 1;
        }

        const in_anim = this.in_anim().animation_config(in_duration, 'in')

        timeline.add({
          opacity: {
            value: [initial_opacity, this.opacity()],
            duration: in_duration,
            easing: 'easeOutQuad'
          },
          ...in_anim
        });
      }
    }

    if (this.while_anim()) {
      let dur = this.duration;
      let staggered_delay = 0;
      let while_duration = this.duration;

      if (this.layer.config.animation.while) {
        while_duration = this.layer.config.animation.while.duration;
        // console.log('setting while duration', while_duration)
      }

      if (this.in() !== 'do_nothing' && this.out() !== 'do_nothing') {
        dur *= 0.54;
      } else if (this.in() === 'do_nothing' && this.out() !== 'do_nothing') {
        dur *= 0.77;
      } else if (this.in() !== 'do_nothing' && this.out() === 'do_nothing') {
        dur *= 0.77;
        staggered_delay = this.duration * 0.17;
        if (while_duration) {
          staggered_delay = while_duration * 0.17;
        }
      }

      if (while_duration > this.duration || !while_duration || while_duration === this.duration) {
        while_duration = dur;
      }

      if (while_duration === 0) {
        while_duration = 1;
      }

      const while_anim_conf = this.while_anim().animation_config(while_duration, 'while');
      if (Array.isArray(while_anim_conf)) {
        while_anim_conf.forEach(({ animation, delay }) => {
          timeline.add({
            ...animation,
          }, delay + staggered_delay);
        });
      } else {
        timeline.add({
          ...while_anim_conf,
        });
      }
    }

    if (this.out()) {
      if (this.out() === 'do_nothing') {
        const opa = this.duration === this.total_video_duration ? 1 : 0;
        timeline.add({
          targets: `${this.target()}`,
          opacity: {
            value: [this.opacity(), opa],
            duration: 5,
            easing: 'linear'
          }
        });
      } else {
        let out_duration = this.duration * 0.23
        if (this.layer.config.animation.out && this.layer.config.animation.out.duration) {
          out_duration = this.layer.config.animation.out.duration
          // console.log('setting out duration', out_duration)
        }
        if (out_duration > this.duration) {
          console.error(`Out animation duration is longer than total animation duration for layer ${this.layer.id}`);
          out_duration = this.duration * 0.23;
        }

        if (out_duration === 0) {
          out_duration = 1;
        }

        timeline.add({
          opacity: {
            value: [this.opacity(), 0],
            duration: out_duration,
            easing: 'easeOutQuad'
          },
          ...this.out_anim().animation_config(out_duration, 'out', this.duration === this.total_video_duration),
          complete: () => {
            this.finish();
          }
        });
      }
    }

    return this.timeline;
  }
}
