import anime from 'animejs/lib/anime';
import Animation from '../animation';

export default class Fade extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    });
    this.opacity = this.layer.config.opacity ? this.layer.config.opacity / 100 : 1;
  }

  name() {
    return 'Fade in';
  }

  target() {
    if (!this.container) {
      return `#layer_content_${this.uid}_${this.layer.id}`;
    }
    return `.layer${this.container}_${this.uid}_${this.layer.id}`;
  }

  out() {
    return !!this.conf().out;
  }

  static properties() {
    return {
      ...super.properties(),
      out: {
        type: 'Boolean',
        default: false // Default to false since the fade-out is optional.
      }
    };
  }

  cleanup() {
    const targetElement = $(`${this.target()}`);
    targetElement.css('opacity', this.opacity);
  }

  prepare() {
    const targetElement = $(`${this.target()}`);
    targetElement.css('opacity', '0');
  }

  static exposed_to() {
    return ['image', 'logo', 'text', 'text_area', 'video', 'price', 'rectangle', 'circle', 'background'];
  }

  static int_name() {
    return 'fade';
  }

  animation_config(duration = this.duration) {
    return {
      duration,
      opacity: [0, 1],
      targets: `${this.target()}`
    }
  }

  animation() {
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
    });

    if (this.out()) {
      timeline.add({
        opacity: [0, this.opacity], // Fade from 0 to the original opacity.
        duration: this.duration * 0.25, // Duration of the fade in.
        targets: `${this.target()}`,
        easing: this.easing()
      }, 1);

      timeline.add({
        opacity: [this.opacity, 0], // Fade from the original opacity to 0.
        duration: this.duration * 0.25, // Duration of the fade out.
        targets: `${this.target()}`,
        easing: this.easing(),
      }, `+=${this.duration - this.duration * 0.6}`); // Delay before starting the fade out.
    } else {
      timeline.add({
        opacity: [0, this.opacity], // Fade from 0 to the original opacity.
        duration: this.duration * 0.8, // Duration of the fade in.
        targets: `${this.target()}`,
        easing: this.easing()
      }, 1);
    }
    return timeline;
  }
}
