<template>
  <div class="tweener">
    <div class="expression__page-selector">
      <select v-model="facebookSelectedPage">
        <option :value="null">Selecteer een facebook pagina</option>
        <option v-for="page in facebookPages" v-bind:key="page.id"
                class="facebook-form__side-el"
                v-bind:value="page" :selected="true">
          {{page.name}}
        </option>
      </select>
    </div>
    <div class="expression__page-selector-image">
      <img v-if="facebookSelectedPage" :src="facebookSelectedPage.image"/>
    </div>
  </div>
</template>

<script>

import {CONSTRUCT_EXPRESSION,
        SET_SELECTED_PAGE} from '../../store/mutation-types/facebook_mutations.js'

export default {
  data() {
    return {
      allowNext: false
    }
  },
  computed: {
    namespace(){
      return this.$root.$data.namespace
    },
    facebookPages:{
      get () {
        return this.$store.state[this.namespace].fb_connection.pages
      },
    },
    facebookConnected: {
      get () {
        return this.$store.state[this.namespace].fb_connection.connected
      }
    },
    facebookSelectedPage:{
      get () {
        return this.$store.state[this.namespace].fb_connection.selected
      },
      set (value) {
        if(value){
         this.$store.dispatch(`${this.namespace}/setFBPage`, value);
        }
      }
    }
  },
  mounted(){
    const btn = $('#add_to_cart')
    this.$store.subscribe((mutation, state)=>{
      if(mutation.type === `${this.namespace}/${CONSTRUCT_EXPRESSION}`){
        if(!this.facebookSelectedPage){
          btn.addClass("disabled")
          btn.bind('click',((e)=>{
            e.preventDefault();
            e.stopPropagation();
          }))
        }
      }

      if(mutation.type === `${this.namespace}/${SET_SELECTED_PAGE}`){
        btn.unbind('click')
        btn.removeClass("disabled")
      }
    })
  }
}

</script>
