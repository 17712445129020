import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ProductReview extends Component {
  name() {
    return 'Product review'
  }

  labels() {
    return ['addon']
  }

  required_addon() {
    return 'feedback company product reviews'
  }

  icon() {
    return 'fa-regular fa-star'
  }

  config() {
    return Layer.factory({
      layer_type: 'review',
      text: '{{adflow_review_average_score}}',
      color: '#F8E71C',
      align: 'center',
      fontSize: 20,
      width: 260,
      height: 60,
      config: {
        v_align: 'center',
        product_score_limit: 5,
        product_score_n_reviews: '{{adflow_review_amount}}',
        product_score_base: 10
      }
    })
  }
}
