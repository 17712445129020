<template>
  <transition v-on:after-enter="focusTextInput" name="image-editor__slide-">
    <div v-if="activeLayer" :class="{
    'image-editor__sidebar-layer-config': !headless,
    'image-editor__sidebar-layer-config--no-sizebar': version < 2 || !is_studio
  }" class="studio__headless_menu__holder">

      <div class="studio__headless_menu__header">
        <div class="" v-if="isVideo">
          <div @click="active_mode = 'design'"
            :class="['button__toggle__light', { 'button__toggle--active': active_mode === 'design' }]">
            <i class="fa-regular fa-paintbrush"></i>
            Design
          </div>

          <div v-if="activeLayer.config.show_animations" @click="active_mode = 'animate'"
            :class="['button__toggle__light', { 'button__toggle--active': active_mode === 'animate' }]">
            <i class="fa-regular fa-circle-play"></i>
            Animate
          </div>
        </div>

        <div class="image-editor__sidebar-layer-config-header">
          <label class="tweener ">
            <div class="editor__layer-item__name" style="width: 75%">
              <span v-if="activeLayer.layer_type !== 'group'">
                <i :class="icon(activeLayer)" /> {{ activeLayer.layer_type }}
              </span>
              <span v-else>
                <i :class="icon(activeLayer)" /> {{ activeLayer.layer_type }}
              </span>
            </div>
            <i v-on:click="deleteLayer" class="icon-delete image-editor__sidebar-layer-config-delete">
            </i>
          </label>
        </div>
      </div>

      <div class="studio__headless_menu__body" v-show="tab == 'config' && active_mode === 'design'">

        <div class="layer-config__collabse_group">
          <Collapseable v-if="activeLayer.layer_type !== 'rectangle' && activeLayer.layer_type !== 'circle'"
            :label="activeLayer.name() || 'Text'" :open="true" :toggleable="false" :unlinkable="false">
            <component :version="version" :is_studio="is_studio" :fonts="fonts" :layer="activeLayer"
              :onUpdate="onUpdate" :samples="samples" :show_wrap="!is_studio" :palette="palette"
              :nightswatch="nightswatch" :brandkit="brandkit" :is="getComponent(activeLayer)"
              v-if="getComponent(activeLayer)">
            </component>
          </Collapseable>


          <Collapseable v-if="activeLayer.layer_type === 'component'"
            :label="'Text'" :open="false" :toggleable="false" :unlinkable="false">
            <component :version="version" :is_studio="is_studio" :fonts="fonts" :layer="activeLayer"
              :onUpdate="onUpdate" :samples="samples" :show_wrap="!is_studio" :palette="palette"
              :nightswatch="nightswatch" :brandkit="brandkit" :show_input="false" :is="getComponent({layer_type: 'text_conf'})">
            </component>
          </Collapseable>

          <Collapseable :label="`Position & Dimensions`" :toggleable="false" :unlinkable="version > 1"
            :on_link_toggle="e => toggle_link(LayerModel.LINK_POSITION_DIMENSIONS)"
            :unlinked="activeLayer.is_unlinked(LayerModel.LINK_POSITION_DIMENSIONS)">
            <div class="form-group">
              <label for="translateX">Position X</label>
              <div class="tweener">
                <input type="number" style="width: 75%" :value="getTransformValue('x')"
                  v-on:input="(e) => { updateTransform('x', e.target.value) }">
              </div>
            </div>
            <div class="form-group">
              <label for="translateY">Position Y</label>
              <div class="tweener">
                <input type="number" style="width: 75%" :value="getTransformValue('y')"
                  v-on:input="(e) => { updateTransform('y', e.target.value) }">
              </div>
            </div>
            <div class="form-group">
              <label for="width">Width</label>
              <div class="tweener">
                <input type="number" style="width: 75%" v-model.number="roundedWidth"
                  v-on:input="(e) => { updateLayerConfig({ width: Math.round(e.target.value) }) }">
              </div>
            </div>
            <div class="form-group">
              <label for="height">Height</label>
              <div class="tweener">
                <input type="number" style="width: 75%" v-model.number="roundedHeight"
                  v-on:input="(e) => { updateLayerConfig({ height: Math.round(e.target.value) }) }">
              </div>
            </div>
          </Collapseable>

          <Collapseable :label="`Shadows`" :on_add="add_dropshadow" :unlinkable="version > 1"
            :on_toggle="bool => updateLayerConfig({ ['show_' + LayerModel.LINK_SHADOWS]: bool })"
            :toggled="activeLayer.config['show_' + LayerModel.LINK_SHADOWS]" :open="show_dropshadows"
            :on_link_toggle="e => toggle_link(LayerModel.LINK_SHADOWS)"
            :unlinked="activeLayer.is_unlinked(LayerModel.LINK_SHADOWS)" v-if="activeLayer.layer_type !== 'group'">

            <DropShadowField :activeLayer="activeLayer" :update_layer_config="updateLayerConfig"
              :dropshadow="dropshadow" :palette="palette" :index="index" :key="index"
              :open="index == activeLayer.config.dropshadows.length - 1"
              v-for="(dropshadow, index) in (activeLayer.config.dropshadows || [])" />

          </Collapseable>
          

          <Collapseable :label="`Background`" :toggled="!!activeLayer.config['show_' + LayerModel.LINK_BACKGROUND]"
            :unlinkable="version > 1"
            :on_toggle="bool => updateLayerConfig({ ['show_' + LayerModel.LINK_BACKGROUND]: bool })"
            :on_link_toggle="e => toggle_link(LayerModel.LINK_BACKGROUND)"
            :unlinked="activeLayer.is_unlinked(LayerModel.LINK_BACKGROUND)">
            <BackgroundConfig :layer="activeLayer" :palette="palette" :onUpdate="updateLayer" />
          </Collapseable>

          <!-- <Collapseable :label="`Border`" :toggled="!!activeLayer.config['show_' + LayerModel.LINK_BORDER]"
            :unlinkable="true" :on_toggle="bool => updateLayerConfig({ ['show_' + LayerModel.LINK_BORDER]: bool })"
            :on_link_toggle="e => toggle_link(LayerModel.LINK_BORDER)"
            :unlinked="activeLayer.is_unlinked(LayerModel.LINK_BORDER)">
            <div class="tweener">
              <input type="range" min="0" max="25" style="width: 75%" :value="activeLayer.config['border_width'] || 0"
                v-on:input="(e) => { updateLayerConfig({ border_width: e.target.value }) }">
              <input type="number" style="min-width: 20%; width: 20%" :value="activeLayer.config['border_width'] || 0"
                v-on:input="(e) => { updateLayerConfig({ border_width: e.target.value }) }">
            </div>
          </Collapseable> -->

          <Collapseable :open="!!activeLayer.variable" v-if="activeLayer.can_be_variable() && is_studio && version > 1"
            :label="`Variable`" :toggled="!!activeLayer.variable" :unlinkable="false"
            :on_toggle="bool => updateLayer(LayerModel.LINK_VARIABLE, bool)"
            :on_link_toggle="e => toggle_link(LayerModel.LINK_VARIABLE)"
            :unlinked="activeLayer.is_unlinked(LayerModel.LINK_VARIABLE)">
            <p>This layer will be exposed to our AI systems</p>
          </Collapseable>
          

          <Collapseable :label="`Animated`" v-if="activeLayer.layer_type !== 'group' && isVideo"
            :open="activeLayer.config['show_' + LayerModel.LINK_ANIMATIONS]"
            :on_link_toggle="e => toggle_link(LayerModel.LINK_ANIMATIONS)"
            :unlinked="activeLayer.is_unlinked(LayerModel.LINK_ANIMATIONS)"
            :toggled="!!activeLayer.config['show_' + LayerModel.LINK_ANIMATIONS]" :unlinkable="version > 1"
            :on_toggle="bool => toggleAnimated(bool)">

            <div v-if="activeLayer && version === 1">
              <AnimationConf :activeLayer="activeLayer" :updateLayer="updateLayer" />
            </div>
          </Collapseable>

          <Collapseable :label="`Padding`" v-if="activeLayer.layer_type !== 'group'" :toggleable="true"
            :toggled="!!activeLayer.config['show_' + LayerModel.LINK_PADDING]" :unlinkable="version > 1"
            :on_link_toggle="e => toggle_link(LayerModel.LINK_PADDING)"
            :unlinked="activeLayer.is_unlinked(LayerModel.LINK_PADDING)"
            :on_toggle="bool => updateLayerConfig({ ['show_' + LayerModel.LINK_PADDING]: bool })">
            <div class="editor__padding-input-container editor__padding-input-container--horizontal">
              <input type="number" min="0" max="100" @input="e => updateLayerConfig({ padding_top: e.target.value })"
                :value="activeLayer.config['padding_top'] || 0">
            </div>
            <div style="display: flex;">
              <div class="editor__padding-input-container editor__padding-input-container--vertical">
                <input type="number" min="0" max="100" @input="e => updateLayerConfig({ padding_left: e.target.value })"
                  :value="activeLayer.config['padding_left'] || 0">
              </div>
              <div style="padding: 10px; width: 60%">
                <div class="editor__padding-container">
                  <div class="editor__padding-content" :style="{ ...activeLayer.abs_h_v_align() }"></div>
                  <div class="editor__padding-horizontal-spacer"></div>
                  <div class="editor__padding-vertical-spacer"></div>
                  <div class="editor__padding-top"
                    :style="{ height: activeLayer.px_to_perc('height', activeLayer.config['padding_top']) + '%' }">
                  </div>
                  <div class="editor__padding-left"
                    :style="{ width: activeLayer.px_to_perc('width', activeLayer.config['padding_left']) + '%' }">
                  </div>
                  <div class="editor__padding-right"
                    :style="{ width: activeLayer.px_to_perc('width', activeLayer.config['padding_right']) + '%' }">
                  </div>
                  <div class="editor__padding-bottom"
                    :style="{ height: activeLayer.px_to_perc('height', activeLayer.config['padding_bottom']) + '%' }">
                  </div>
                </div>
              </div>
              <div class="editor__padding-input-container editor__padding-input-container--vertical">
                <input type="number" min="0" max="100"
                  @input="e => updateLayerConfig({ padding_right: e.target.value })"
                  :value="activeLayer.config['padding_right'] || 0">
              </div>
            </div>
            <div class="editor__padding-input-container editor__padding-input-container--horizontal">
              <input type="number" min="0" max="100" @input="e => updateLayerConfig({ padding_bottom: e.target.value })"
                :value="activeLayer.config['padding_bottom'] || 0">
            </div>
          </Collapseable>
        </div>

        <br>

        <div class="layer-config__collabse_group">
          <Collapseable :label="`Adjustments`" :toggleable="false" :unlinkable="version > 1"
            :on_link_toggle="e => toggle_link(LayerModel.LINK_ADJUSTMENTS)"
            :unlinked="activeLayer.is_unlinked(LayerModel.LINK_ADJUSTMENTS)">
            <div class="form-group">
              <label for="points">Opacity</label>
              <div class="tweener">
                <input type="range" min="1" max="100" style="width: 75%" :value="activeLayer.config['opacity'] || 100"
                  v-on:input="(e) => { updateLayerConfig({ opacity: e.target.value }) }">
                <input type="number" style="min-width: 20%; width: 20%" :value="activeLayer.config['opacity'] || 100"
                  v-on:input="(e) => { updateLayerConfig({ opacity: e.target.value }) }">
              </div>
            </div>
            <div class="form-group">
              <label for="points">Rounded corners</label>
              <div class="tweener">
                <input type="range" min="0" max="300" style="width: 75%"
                  :value="activeLayer.config['border_radius'] || 0"
                  v-on:input="(e) => { updateLayerConfig({ border_radius: e.target.value }) }">

                <input type="number" style="min-width: 20%; width: 20%"
                  :value="activeLayer.config['border_radius'] || 0"
                  v-on:input="(e) => { updateLayerConfig({ border_radius: e.target.value }) }">
              </div>
            </div>
          </Collapseable>
        </div>

      </div>

      <div class="studio__headless_menu__body" v-show="active_mode === 'animate'"
        v-if="activeLayer.config.show_animations">
        <AnimationConf :activeLayer="activeLayer" :min_scene_duration="min_scene_duration" :updateLayer="updateLayer"
          :version="version" />
      </div>

    </div>
  </transition>
</template>

<script>
import Collapseable from './components/collapseable.vue'
import text from './layer_configs/text.vue'
import image from './layer_configs/image.vue'
import price from './layer_configs/price.vue'
import review from './layer_configs/review.vue'
import rectangle from './layer_configs/rectangle.vue'
import circle from './layer_configs/circle.vue'
import additionalImages from './layer_configs/additional_images.vue'
import { icon } from './helpers'
import api from './api'
import ColorField from '../feeds/fields/color.vue'
import DropShadowField from './layer_configs/fields/drop_shadow.vue'
import Lock from './layer_configs/lock.vue'
import textArea from './layer_configs/text_area.vue'
import stencil from './layer_configs/stencil.vue'
import video from './layer_configs/video/video.vue'
import videoSwiper from './layer_configs/video/swiper.vue'
import contentSlider from './layer_configs/video/contentslider.vue'
import backgroundLayer from './layer_configs/video/background.vue'
import AnimationConf from './layer_configs/animations.vue'
import Constraints from './layer_configs/constraints.vue'
import BackgroundConfig from './layer_configs/background.vue'
import logo from './layer_configs/logo.vue'
import LayerModel from './store/modules/layers/model'
import componentConfig from './layer_configs/component_config.vue'
import TextConf from './layer_configs/text.vue'

export default {
  props: {
    headless: { type: Boolean, required: false, default: false },
    nightswatch: { type: Boolean, required: false, default: false },
    fonts: { required: false, default: [], type: Array },
    activeLayer: { required: false, type: Object },
    segments: { required: false, type: Array },
    onUpdate: { required: true },
    onClose: { required: false },
    onDelete: { required: false },
    samples: { required: true },
    isVideo: { required: false, type: Boolean, default: false },
    palette: { required: false, type: Array, default: () => [] },
    brandkit: { required: true, type: Object },
    show_segments: { type: Boolean, required: false, default: true },
    is_studio: { type: Boolean, required: false, default: false },
    version: { type: Number, required: false, default: 1 },
    min_scene_duration: { type: Number, required: false, default: 2000 }
  },
  components: {
    ColorField,
    DropShadowField,
    Collapseable,
    Lock,
    AnimationConf,
    Constraints,
    BackgroundConfig
  },
  data() {
    return {
      show_picker: false,
      skipped_first_element: false,
      tab: 'config',
      show_dropshadows: false,
      show_animations: false,
      show_constraints: false,
      active_mode: 'design',
    }
  },
  watch: {
    activeLayer: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.skipped_first_element = false
          if (!newVal.config.show_animations) {
            this.active_mode = 'design'
          }
        }
      }
    },
    active_mode: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal === 'animate') {
          const event = new CustomEvent('animations:timeline:show', { detail: {} })
          this.$store.state.options.event_target.dispatchEvent(event)
        } else if (newVal && this.active_mode !== 'design') {
          const event = new CustomEvent('animations:timeline:hide', { detail: {} })
          this.$store.state.options.event_target.dispatchEvent(event)
        }
      }
    }
  },
  computed: {
    LayerModel() {
      return LayerModel
    },
    roundedWidth: {
      get() {
        return Math.round(this.activeLayer.width);
      },
      set(value) {
        this.updateLayerConfig({ width: Math.round(value) });
      }
    },
    roundedHeight: {
      get() {
        return Math.round(this.activeLayer.height);
      },
      set(value) {
        this.updateLayerConfig({ height: Math.round(value) });
      }
    }
  },
  methods: {
    getTransformValue(axis) {
      const transform = this.activeLayer.config.transform || 'translate(0px, 0px)';
      const match = transform.match(/translate\(([^,]+),\s*([^)]+)\)/);
      if (match) {
        return Math.round(axis === 'x' ? parseFloat(match[1]) : parseFloat(match[2]));
      }
      return 0;
    },
    updateTransform(axis, value) {
      const transform = this.activeLayer.config.transform || 'translate(0px, 0px)';
      const match = transform.match(/translate\(([^,]+),\s*([^)]+)\)/);
      let x = 0, y = 0;
      if (match) {
        x = axis === 'x' ? value : parseFloat(match[1]);
        y = axis === 'y' ? value : parseFloat(match[2]);
      } else {
        x = axis === 'x' ? value : 0;
        y = axis === 'y' ? value : 0;
      }
      this.updateLayerConfig({ transform: `translate(${x}px, ${y}px)` });
    },
    toggle_link(prop) {
      let layer = LayerModel.factory(this.activeLayer)
      if (layer.is_unlinked(prop)) {
        layer.link(prop)
      } else {
        layer.unlink(prop)
      }
      this.updateLayer('unlinked_properties', layer.unlinked_properties)
    },
    add_dropshadow() {
      let shadows = this.activeLayer.config.dropshadows ? this.activeLayer.config.dropshadows : []
      this.show_dropshadows = true
      this.updateLayerConfig({
        show_dropshadows: true,
        dropshadows: [...shadows, {
          dropshadow_h_distance: 0,
          dropshadow_v_distance: 2,
          dropshadow_blur: 4,
          dropshadow_color: 'rgba(0, 0, 0, 0.5)'
        }]
      })
    },
    getComponent(layer) {
      switch (layer.layer_type) {
        case 'text_conf':
          return TextConf
        case 'component':
          return componentConfig
        case 'group':
          return null
        case 'background':
          return backgroundLayer
        case 'swiper':
          return videoSwiper
        case 'video':
          return video
        case 'text':
          return text
        case 'stencil':
          return stencil
        case 'image':
        case 'cube_spinner':
          return image
        case 'logo':
          return logo
        case 'price':
          return price
        case 'review':
          return review
        case 'additional_images':
          return additionalImages
        case 'circle':
          return circle
        case 'rectangle':
          return rectangle
        case 'scaled_text':
        case 'text_area':
          return textArea
        case 'slider':
          return contentSlider
        default:
          return text
      }
    },
    set_tab(tab) {
      this.tab = tab
    },
    focusTextInput() {
      // this.$refs.layerText.focus()
    },
    icon(layer) {
      return icon(layer)
    },
    close() {
      if (this.onClose) {
        this.onClose()
      }
    },
    getSegmentStatus(layer, segment) {
      return !!layer.segments.find(s => s.segment_id == segment.id)
    },
    segmentChange(e, segment) {
      if ($(e.target).is(":checked")) {
        api.createLayerSegment(segment.id, this.activeLayer.id).then(result => {
          this.updateLayer('segments', [...this.activeLayer.segments, result])
        })
      } else {
        let s = this.activeLayer.segments.find(s => s.segment_id == segment.id)
        if (s) {
          api.deleteLayerSegment(s.id).then(result => {
            let filtered_list = this.activeLayer.segments.filter(layer_segment => s.segment_id != layer_segment.segment_id)
            this.updateLayer('segments', [...filtered_list])
          })
        }
      }
    },
    deleteLayer() {
      if (this.onDelete) {
        this.onDelete(this.activeLayer)
      }
    },
    updateLayer(key, value, patch = true) {
      const layer = { ...this.activeLayer, [key]: value }
      this.$props.onUpdate(layer, patch)
    },
    updateLayerConfig(conf) {
      this.updateLayer('config', { ...this.activeLayer.config, ...conf })
    },
    toggleAnimated(bool) {
      if (this.version > 1) {
        let updatedConfig = {
          ...this.activeLayer.config,
          ['show_' + LayerModel.LINK_ANIMATIONS]: bool,
          animation: {
            delay_percentage: 0,
            duration_percentage: 100,
            in: null,
            while: null,
            out: null,
            ...(this.activeLayer.config.animation ? this.activeLayer.config.animation : {})
          },
          animations: [
            bool ? { type: 'inwhileout' } : null,
            ...(this.activeLayer?.config?.animations || [])
          ].filter(Boolean)
            .reduce((acc, current) => {
              const index = acc.findIndex(item => item.type === current.type);
              if (index === -1) {
                acc.push(current);
              }
              return acc;
            }, [])
        }
        this.updateLayerConfig(updatedConfig)
      } else {
        this.updateLayerConfig({ ['show_' + LayerModel.LINK_ANIMATIONS]: bool })
      }
    },
    setLayerImage(url) {
      const layer = { ...this.activeLayer, image_url: url }
      this.$props.onUpdate(layer)
    },
    increaseFontSize() {
      const layer = { ...this.activeLayer }
      layer.fontSize += 1
      layer.width += 1
      layer.height += 1.5
      this.$props.onUpdate(layer)
    },
    decreaseFontSize() {
      const layer = { ...this.activeLayer }
      if (layer.fontSize > 0) {
        layer.fontSize -= 1
        layer.width -= 1
        layer.height -= 1.5
        this.$props.onUpdate(layer)
      }
    }
  }
}

</script>
