<template>
    <Dropdown :active="show" :root="true" :right="right" :top="top" :on_toggle="state => show = state">
        <template slot="btn" slot-scope="{is_active}">
            <button class="briefing_wizard__prompt_button" :class="{
        'briefing_wizard__prompt_button--active': active
    }">
                {{ flavor }} {{ selected_language }}
                <i class="fa-regular fa-chevron-down"></i>
            </button>
        </template>
        <input type="text" ref="search" v-model="search" placeholder="Search..." />
        <div class="plan__select_plan__options" style="height: 150px; margin-top:10px; width:100%; overflow-y: scroll;">
            <div class="plan__select_plan__option" :key="`language_${index}`" v-for="(language, index) in languages"
                v-on:click="e => select_language(language)">
                <div class="plan__select_plan__option_group">
                    {{ language }}
                </div>
            </div>
        </div>
    </Dropdown>
</template>

<script>
import Dropdown from '../components/dropdown.vue'

const languages = [
    "english (uk)",
    "english (us)",
    "german",
    "french",
    "italian",
    "spanish",
    "russian",
    "turkish",
    "polish",
    "dutch",
    "ukrainian",
    "greek",
    "czech",
    "swedish",
    "hungarian",
    "belarusian",
    "serbian",
    "portuguese",
    "bulgarian",
    "danish",
    "finnish",
    "slovak",
    "norwegian",
    "croatian",
    "bosnian",
    "albanian",
    "lithuanian",
    "slovenian",
    "latvian",
    "estonian",
    "macedonian",
    "romanian"
]

export default {
    components: { Dropdown },
    props: {
        autofocus: {
            type: Boolean,
            required: false,
            default: true
        },
        on_change: {
            type: Function,
            required: true
        },
        top: {
            type: Number,
            required: false,
            default: 50
        },
        right: {
            type: Number,
            required: false,
            default: 0
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        },
        default_language: {
            type: String,
            required: false,
            default: null
        },
        flavor: {
            type: String,
            required: false,
            default: 'Translate to'
        },
    },
    data() {
        return {
            search: '',
            selected_language: null,
            show: false,
            languages: languages
        }
    },
    mounted() {
        if (this.default_language) {
            this.selected_language = this.default_language
        }
    },
    watch: {
        search(newVal) {
            if (newVal) {
                this.languages = languages.filter(language => language.toLowerCase().includes(newVal.toLowerCase()))
            } else {
                this.languages = languages
            }
        },
        show(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$refs.search.focus()
                })
            }
        }
    },
    methods: {
        select_language(language) {
            this.selected_language = language
            this.on_change(language)
            this.show = false
        }
    }
}
</script>