import FacebookCardStore from '../fb_carousel_card'
import { registerStoreModule } from '../../../../lib/nimrod/loaders/register_store_module'

import {
  SET_CONTEXT, SET_SELECTED_PAGE, SET_PREVIEW_TYPE, CONSTRUCT_EXPRESSION,
  ADD_CAROUSEL_ITEM, DELETE_CAROUSEL_ITEM, SET_ACTIVE_CARD, SET_ACTIVE_CARD_BY_INDEX,
  UPDATE_CAROUSEL_ITEMS, UPDATE_ADVERT, STRIP_TAGS_FROM_EXPRESSION,
  CONSTRUCTING_EXPRESSION
} from '../../mutation-types/facebook_mutations'

export default {
  [SET_CONTEXT](state, payload){
    state.api_context = payload
  },

  [SET_SELECTED_PAGE](state, payload){
    state.fb_connection.selected = payload
  },

  [SET_PREVIEW_TYPE](state, payload){
    state.type = payload
  },

  [CONSTRUCT_EXPRESSION](state, expression){
    state.constructing = false
    state.advert = expression.advert
    state.type = expression.type
    state.facebookCarouselCards = expression.items.map((card) => card.id)
    state.activeCard = expression.items[0].id
    state.fb_connection = expression.fb_connection
    state.channelId = expression.channelId
    state.advertiseableId = expression.advertiseableId
    state.advertiseableType = expression.advertiseable_type
    state.advertiseablePath = expression.advertiseable_path
    expression.items.forEach((card) => {
      if (registerStoreModule(this, `FacebookCarouselCard_${card.id}`, FacebookCardStore)){
        this.dispatch(`FacebookCarouselCard_${card.id}/firstOrCreate`, card)
      }
    })
  },

  [CONSTRUCTING_EXPRESSION](state){
    state.constructing = true
  },

  [ADD_CAROUSEL_ITEM](state, card){
    state.facebookCarouselCards.push(card.id)
    state.activeCard = state.facebookCarouselCards[state.facebookCarouselCards.length - 1]
    if (registerStoreModule(this, `FacebookCarouselCard_${card.id}`, FacebookCardStore)) this.dispatch(`FacebookCarouselCard_${card.id}/firstOrCreate`, card)
  },

  [DELETE_CAROUSEL_ITEM](state, card){
    state.facebookCarouselCards = state.facebookCarouselCards.filter((item) => item !== card);
    state.activeCard = state.facebookCarouselCards[state.facebookCarouselCards.length - 1]
  },

  [SET_ACTIVE_CARD](state, payload){
    state.activeCard = payload
  },

  [SET_ACTIVE_CARD_BY_INDEX](state, payload){
    state.activeCard = state.facebookCarouselCards[payload]
  },

  [UPDATE_CAROUSEL_ITEMS](state, payload){
    state.facebookCarouselCards = payload;
  },

  [STRIP_TAGS_FROM_EXPRESSION](state){
    // this is temp till the rewrite of personalisation form
    const strip = (string) => string.replace(/<(?:.|\n)*?>/gm, '')
    state.advert = strip(state.advert)
  },
  [UPDATE_ADVERT](state, payload){
    state.advert = payload
  }
}
