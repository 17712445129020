<template>
    <div class="layer-config__essentials" style="padding-bottom: 0px;" v-if="layer.layer_type !== 'group'">
        <div class="form-group" style="width: 47%;">
            <div style="display: flex; gap: 6px;">
                <ColorField :element="element" :element_key="'color'" :palette="palette" :show_dynamic="false" />
            </div>
        </div>
    </div>
</template>

<script>
import ColorField from '../../feeds/fields/color.vue'
export default {
    props: {
        layer: { type: Object, required: true },
        palette: { type: Array, required: true },
        onUpdate: { type: Function, required: true },
    },
    components: { ColorField },
    data() {
        return {
            element: {
                color: {
                    hex8: '#FFFFFFFF'
                },
            },
        }
    },
    watch: {
        layer: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.element.color.hex8 = this.$props.layer.background
                }
            }
        },
        element: {
            deep: true,
            immediate: true,
            handler(newVal) {
                this.setBackground(newVal.color.hex8)
            }
        },
    },
    methods: {
        setBackground(background) {
            if (background)
                this.updateLayer('background', background)
        },
        updateLayer(key, value) {
            this.$props.onUpdate(key, value)
        },
    }
}
</script>