import axios from 'axios';

const api = {
  async getFeeds(){
    const result = await axios.get('/data/feeds.json')
    return result.data
  },

  async getFeedRows(id, segments = []){
    const result = await axios.get(`/data/feeds/${id}.json?limit=25&segments=${JSON.stringify(segments)}`)
    return result.data
  },

  async getSegments(){
    const result = await axios.get('/data/segments.json')
    return result.data
  },

  async createLayerSegment(segmentId, layerId){
    const result = await axios.post('/layer_segments.json', { segment_id: segmentId, layer_id: layerId })
    return result.data
  },

  async deleteLayerSegment(layerSegmentId){
    const result = await axios.delete(`/layer_segments/${layerSegmentId}.json`)
    return result.data
  },
}

export default api
