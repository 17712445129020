<template>
  <div>
    <div v-if="circle">
      <div v-if="render_batches[0]">
        <table>
          <tr>
            <td class="folder__cell folder__cell-small">
              <b>{{render_batches[0].n_completed}} / {{render_batches[0].n_expected_assets}}</b>
            </td>
            <td>
              <div class="progress-circle__container"
                  :style="`border: 3px solid ${status_color(render_batches[0])}`">

                <vue-circle
                  ref="circle"
                  :progress="render_batches[0].completion_percentage"
                  :size="22"
                  :reverse="false"
                  line-cap="butt"
                  :fill="{ color: status_color(render_batches[0]) }"
                  empty-fill="rgba(0, 0, 0, 0)"
                  :animation-start-value="0.0"
                  :start-angle="0"
                  insert-mode="append"
                  :thickness="11"
                  :show-percent="false">

                  <i class="fa-regular fa-exclamation-triangle canvas__error"
                    style="color: white"
                    v-if="error(render_batches[0])"
                    aria-hidden="true"></i>

                  <i class="fa-regular fa-check canvas__complete"
                    style="color: white"
                    v-else-if="success(render_batches[0])"
                    aria-hidden="true"></i>
                </vue-circle>
              </div>
            </td>
          </tr>
        </table>
        <div v-if="result_type" class="tag" style="text-align: center; width: 100%;">
          <a :href="result_path">{{result_type}}</a>
        </div>
      </div>
    </div>
    <table v-else class="canvas__status-table">
      <tbody v-bind:key="batch.folder_id" v-for="batch in render_batches">
        <tr>
          <td v-if="!hide_label" class="folder__cell folder__cell-10">
            <a :href="batch.target_url"> Batch {{batch.id}} </a>
          </td>

          <td class="folder__cell folder__cell-no-pad">
            <div class="progress-bar-holder">
              <div class="progress-bar">
                <div class="progress-bar-text">
                  <span v-if="!hide_status_number">{{batch.n_completed}} / {{batch.n_expected_assets}}</span>
                  {{display_type}}
                </div>
                <div class="progress-bar-pct"
                     :class="{ 'progress-bar-pct--warning': batch.n_errors > 0 }"
                     :style="{
                       width: batch.completion_percentage + '%'}">
                </div>
              </div>
            </div>
          </td>

          <td v-if="batch.killed || error(batch)"
              class="folder__cell folder__cell-small">
            <i class="fa-regular fa-exclamation-triangle canvas__error" aria-hidden="true"></i>
          </td>
          <td v-else-if="success(batch)" class="folder__cell folder__cell-small">
            <i class="fa-regular fa-check canvas__complete" aria-hidden="true"></i>
          </td>
          <td v-else class="folder__cell folder__cell-small">
            <div class="loader"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import consumer from '../channels/consumer'
import VueCircle from 'vue2-circle-progress'

const KILLED = 'render_killed'
const ERROR = 'render_error'
const FINISHED = 'render_finished'

export default {
  components: {
      VueCircle
  },

  data(){
    return {
      render_events: {},
      render_batches: [],
      result_type: null,
      result_path: null,
      socket: null,
      colors: {
        warning: '#FFDA63',
        success: '#1E86FF'
      }
    }
  },

  props: {
    circle: {Type: Boolean, default: false, required: false},
    object_id: {Type: Object, required: false, default: {}},
    channel: {Type: String, required: false, default: 'CanvasChannel'},
    display_type: {Type: String, required: false, default: "assets"},
    batches: {Type: Array, required: false, default: []},
    hide_label: {type: Boolean, required: false, default: false},
    hide_status_number: {type: Boolean, required:false, default: false},
    reload_on_finish: {type: Boolean, required: false, default: false}
  },

  mounted(){
    this.attach_websocket()
    this.set_from_props()
  },

  beforeDestroy(){
    this.socket.unsubscribe()
  },

  methods: {
    set_from_props(){
      this.render_batches = this.$props.batches
    },

    attach_websocket(){
      const context = this
      this.socket = consumer.subscriptions.create({ channel: this.$props.channel, id: this.$props.object_id  }, {
        received(data){
          if(data.message == 'new_batch'){
            context.render_batches = [data.batch]
          } else if(data.message == 'result') {
            context.result_type = data.result_type
            context.result_path = data.result_path
            if(data.run_finished){
              $('#run_automation').css("display", "block")
              location.reload()
            }
          } else {
            context.add_event(data.event, data.canvas_path)
          }
          if(context.circle === true){
            context.$refs.circle.updateProgress(
              context.render_batches[0].completion_percentage)
            context.$refs.circle.updateFill(context.status_color(
              context.render_batches[0]
            ));
          }
          context.$forceUpdate()
        }
      })
    },

    add_event(event, canvas_path){
      const label = event.label

      if(!this.render_events[label])
        this.render_events[label] = []

      this.render_events[label].push(event)

      if(event.type_name == ERROR)
        this.update_error_count(event)

      if(event.type_name == KILLED || event.type_name == FINISHED)
        this.update_completion_count(event)

      if(canvas_path)
        $.get(`${canvas_path}.js`)
    },

    update_error_count(event){
      this.render_batches = this.render_batches.map(b => {
        if(b.id == event.eventable_id)
          b.n_errors += 1
        return b
      })
    },

    error(batch){
      return batch.n_errors > 0 && batch.finished
    },

    success(batch){
      return batch.n_errors == 0 && batch.finished
    },

    status_color(batch){
      if(this.error(batch))
        return this.colors['warning']
      if(this.success(batch))
        return this.colors['success']
      return this.colors['success']
    },

    update_completion_count(event){
      this.render_batches = this.render_batches.map(b => {
        if(b.id == event.eventable_id){
          b.n_completed += 1
          b.completion_percentage = (b.n_completed / b.n_expected_assets) * 100
          if(b.n_completed == b.n_expected_assets)
            b.finished = true
        }
        return b
      })
    }
  }
}

</script>
