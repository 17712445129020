import _ from 'lodash'

class HistoryStore {
  init(store) {
    this.store = store
    window.historyStore = this
    this.history = []
    this.currentIndex = -1
  }

  addState(state) {
    // maybe we have to remove redo steps
    if (this.history) {
      if (this.currentIndex + 1 < this.history.length) {
        this.history.splice(this.currentIndex + 1)
      }
      this.history.push(_.cloneDeep(state))
      this.currentIndex += 1
    }
  }

  can_undo() {
    return this.currentIndex > 0
  }

  can_redo() {
    if (this.history) {
      return this.currentIndex + 1 < this.history.length
    }
    return false
  }

  redo() {
    const nextState = this.history[this.currentIndex + 1]
    if (nextState) {
      this.store.replaceState({
        ...this.store.state,
        ..._.cloneDeep(nextState)
      })
      this.currentIndex += 1
    }
  }

  undo() {
    const prevState = this.history[this.currentIndex - 1]
    if (prevState) {
      const prev_state_cloned = _.cloneDeep(prevState)
      this.store.replaceState({
        ...this.store.state,
        ...prev_state_cloned
      })
      this.currentIndex -= 1
    }
  }
}

export const historyStore = new HistoryStore()
window.historystore = historyStore
