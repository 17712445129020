import Layer from '../../store/modules/layers/model'
import TextArea from './text_area'

export default class CTA extends TextArea {
  constructor({ variable_tag, display_name, default_value } =
  { variable_tag: null, display_name: null, default_value: null }) {
    super({ variable_tag, display_name, default_value })
  }

  name() {
    return this.display_name || 'CTA'
  }

  labels() {
    return ['layer']
  }

  icon() {
    return 'fa-regular fa-text'
  }

  config({ canvas }) {
    return Layer.factory({
      layer_type: 'text_area',
      text: this.default_value || '',
      width: 300,
      height: 140,
      color: '#000000',
      align: 'center',
      config: {
        v_align: 'center',
      },
      fontSize: 30,
      x: canvas.width / 2 - 150,
      y: canvas.height / 3 - 70,
      variable_tag: 'cta',
      variable: true,
    })
  }
}
