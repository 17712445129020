import lib from './lib'

const PRICE = 'price'
const SALE_PRICE = 'sale_price'
const TAG = 'sale_pct'
const MATCHER = /\[\[\d*_?(?:item_)?sale_pct\]\]/
const GLOB_MATCHER = /.*\[\[\d*_?(?:item_)?sale_pct\]\].*/

export const sale_pct = {
  tag: TAG,
  MATCHER,
  GLOB_MATCHER,
  parse(text, sample, config) {
    const MATCH = text.match(MATCHER)[0]
    let prefix = ''
    if (MATCH.includes('item_')) {
      prefix = MATCH.replace('[[', '').replace(']]', '').split(TAG)[0]
    }

    if (!sample[`${prefix}${SALE_PRICE}`]) return text.replace(MATCH, 0)

    const price = lib.get_currency(sample[`${prefix}${PRICE}`], config)
    const sale_price = lib.get_currency(sample[`${prefix}${SALE_PRICE}`], config)

    const pct = Math.round(
      ((price.amount.intValue - sale_price.amount.intValue) / price.amount.intValue) * 100
    )

    return text.replace(MATCH, pct)
  }
}
