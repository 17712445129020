import Vue               from 'vue/dist/vue.esm';
import TurbolinksAdapter from 'vue-turbolinks';
import ConsumeStatus from './consume/status.vue';

Vue.use(TurbolinksAdapter)

function loadConsumeStatus(){
  $('consume-status').each((index, el) => {
    const app = new Vue({ // eslint-disable-line
      el,
      components: { 'consume-status': ConsumeStatus }
    });
  })
}

export default loadConsumeStatus
