import {
  UPDATE_CARD_TARGET_URL, UPDATE_CARD_HEADER,
  UPDATE_CARD_DESCRIPTION, SET_MEDIA_RESOURCE,
  SET_MEDIA_RESOURCE_POST_PROCESSING, UPDATE_CARD,
  UPDATE_CARD_LAYERS
} from '../../mutation-types/facebook_mutations'

export default {

  CONSTRUCT(state, card){
    state.id                = card.id
    state.facebookChannelId = card.facebookChannelId
    state.header            = card.header
    state.target_url        = card.target_url
    state.description       = card.description
    state.media             = card.media
    state.position          = card.position
    state.link              = card.link
    state.namespace         = `FacebookCarouselCard_${card.id}`
    state.canvas            = card.canvas
  },

  DESTRUCT(state){
    this.unregisterModule(state.namespace)
  },

  STRIP_HTML(state){
    const strip = (string) => (string ? string.replace(/<(?:.|\n)*?>/gm, '') : '')
    state.header      = strip(state.header)
    state.target_url  = strip(state.target_url)
    state.description = strip(state.description)
  },

  [UPDATE_CARD_LAYERS](state, layers){
    if (state.canvas){
      state.canvas.layers = layers
    }
  },

  [UPDATE_CARD](state, card){
    state.target_url  = card.target_url
    state.description = card.description
    state.header      = card.header
  },

  [UPDATE_CARD_TARGET_URL](state, payload){
    state.target_url = payload
  },

  [UPDATE_CARD_HEADER](state, payload){
    state.header = payload
  },

  [UPDATE_CARD_DESCRIPTION](state, payload){
    state.description = payload
  },

  [SET_MEDIA_RESOURCE](state, payload){
    // keep preview but also upload and use returned id as absolute asset location
    state.media = payload
  },

  [SET_MEDIA_RESOURCE_POST_PROCESSING](state, payload){
    // Hacky tacky but it works
    const payloadCopy = { ...payload.media };
    delete payloadCopy.targetCard;
    state.media = payloadCopy;
  },
}
