import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class VideoPlayer extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })
  }

  name() {
    return 'Videoplayer'
  }

  static stand_alone() {
    return true
  }

  getVideoElement() {
    return $(`#video_layer_${this.uid}_${this.layer.id}`)[0];
  }

  seek(dur) {
    const video = this.getVideoElement();
    if (video) {
      const time = dur / 1000;
      if (!Number.isNaN(time) && Number.isFinite(time)) {
        video.pause();
        video.currentTime = time;
      } else {
        console.error('Invalid time value:', time);
      }
    }
  }

  animation() {
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
    })
    return timeline
  }

  async tick(t) {
    const video = this.getVideoElement();
    if (video) {
      const currentTime = t / 1000; // Convert to seconds
      const videoDuration = video.duration;
      const seekTime = currentTime % videoDuration;
      console.log('seeking video to', seekTime);

      // Only update if the time difference is significant
      if (Math.abs(video.currentTime - seekTime) > 0.01) {
        video.currentTime = seekTime;

        // Wait for the frame to be ready or timeout
        await new Promise((resolve) => {
          const timeout = setTimeout(() => {
            video.removeEventListener('timeupdate', onTimeUpdate);
            resolve();
          }, 1000); // Timeout after 1 second

          const onTimeUpdate = () => {
            if (Math.abs(video.currentTime - seekTime) < 0.01) {
              clearTimeout(timeout);
              video.removeEventListener('timeupdate', onTimeUpdate);
              resolve();
            }
          };

          video.addEventListener('timeupdate', onTimeUpdate);
        });
      }

      this.lastUpdateTime = t;
    }
  }

  play() {
    setTimeout(() => {
      const video = this.getVideoElement();
      if (video && video.paused) {
        video.play();
      }
    }, 1)
  }

  pause() {
    setTimeout(() => {
      const video = this.getVideoElement();
      if (video) video.pause();
    }, 1)
  }

  cleanup() {
    setTimeout(() => {
      const video = this.getVideoElement();
      if (video) {
        video.pause();
        video.currentTime = 0;
        video.load();
      }
    }, 1)
  }

  restart() {
    this.cleanup();
  }

  prepare() {
    setTimeout(() => {
      const video = this.getVideoElement();
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    })
  }
}
