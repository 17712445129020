import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class LogoV2 extends Component {
  name() {
    return 'Logo'
  }

  labels() {
    return ['brandkit']
  }

  icon() {
    return 'fa-regular fa-picture-o'
  }

  config(opts = {}) {
    return Layer.factory({
      layer_type: 'background',
      text: '',
      variable: true,
      variable_tag: 'logo',
      width: 200,
      height: 200,
      config: {
        object_fit: 'contain',
        media_url: '',
      },
      ...opts
    })
  }
}
