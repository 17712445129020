import api from '../../api/feed.api'
import { INIT } from '../mutation_types'
import {
  SAMPLING, SAMPLE, PREVIOUS_SAMPLE, NEXT_SAMPLE, ACTIVATE, INIT_SEGMENTS
} from './mutation_types'

export default {
  async init({ commit, rootState, dispatch }, feed){ // eslint-disable-line
    let feeds = []
    if (feed){
      feeds = [feed]
      commit(SAMPLE, feed.data)
      commit(ACTIVATE, feed.complex_identifier)
      await dispatch('init_segments')
    }
    // else {
    //   feeds = await api.getFeeds()
    //   const activated_feed = localStorage.getItem(`image_editor_${rootState.canvas.id}_feed`)
    //   if (activated_feed && !feed) dispatch('sample', { feed_id: activated_feed })
    // }
    commit(INIT, { feeds: feeds || [] })
  },

  async init_segments({ commit }){
    const segments = await api.getSegments()
    commit(INIT_SEGMENTS, segments)
  },

  async sample({
    dispatch, rootState, state, commit
  }, { feed_id, segments = [] }){
    if (state.active_feed !== feed_id && feed_id){
      commit(ACTIVATE, feed_id)
      localStorage.setItem(`image_editor_${rootState.canvas.id}_feed`, feed_id)
    }
    if (feed_id){
      dispatch('sampling', true)
      const result = await api.getFeedRows(feed_id, [
        rootState.options.top_segment ? rootState.options.top_segment.id : null,
        ...segments
      ].filter(Boolean))
      commit(SAMPLE, result)
      dispatch('sampling', false)
    } else {
      dispatch('sampling', true)
      commit(SAMPLE, rootState.canvas.feed.data)
      dispatch('sampling', false)
    }
  },

  sampling({ commit }, busy){
    commit(SAMPLING, busy)
  },

  segment({ dispatch, state, commit }, segment_id){
    if (state.active_feed) {
      commit(SAMPLE, [])
      dispatch('sample', { feed_id: state.active_feed, segments: segment_id ? [segment_id] : [] })
    }
  },

  previous_sample({ commit }){
    commit(PREVIOUS_SAMPLE)
  },

  next_sample({ commit }){
    commit(NEXT_SAMPLE)
  }
}
