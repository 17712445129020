import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class InstaText extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Insta text'
  }

  labels() {
    return ['text']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'textArea',
          name: 'Callout',
          hide_label: true,
          key: 'root.text',
        },
        [
          {
            type: 'color',
            name: 'Text',
            key: 'root.color',
          },
          {
            type: 'color',
            name: 'Bg',
            key: 'bg_color',
          },
        ],
        {
          type: 'slider',
          name: 'Text size',
          key: 'root.fontSize',
          min: 0,
          max: 300,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          color: 'black',
          config: {
            ...baseConfig.config,
            text_insta: {
              ...baseConfig.config.text_insta,
              bg_color: 'white',
              text_color: 'black',
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'white',
      align: 'center',
      text: 'Sample title text',
      width: 350,
      height: 200,
      fontSize: 120,
      lineHeight: 1.1,
      ...this.opts,
      config: {
        v_align: 'center',
        show_background: false,
        component: {
          type: 'text_insta',
          name: 'Text insta',
        },
        text_insta: {
          text_color: 'white',
          bg_color: 'black',
        }
      }
    }
  }

  config() {
    return {
      background: 'white',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
