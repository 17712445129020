import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Title extends Component {
  name(){
    return 'Title'
  }

  labels(){
    return ['product']
  }

  config(){
    return Layer.factory({
      layer_type: 'text',
      text: '{{title}}'
    })
  }
}
