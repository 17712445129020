<template>
    <div v-if="size">
        <div ref="parentContainer" class="canvas_scale_container">
            <div :style="{ zoom: computedScale }">
                <Canvas :id="null" :style="{ width: `${size.width}px`, height: `${size.height}px` }"
                    :samples="{ data: [], indice: 0 }" :layers="component.layers" :disabled="true"
                    :activeVariant="variant" :background_color="'transparent'" :size="size" ref="canvas"></Canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Canvas from '../canvas.vue'
export default {
    components: { Canvas },
    props: {
        component: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            parentWidth: 0,
            parentHeight: 0
        }
    },
    methods: {
        updateParentDimensions() {
            const parent = this.$refs.parentContainer;
            this.parentWidth = parent.clientWidth;
            this.parentHeight = parent.clientHeight;
        },
    },
    mounted() {
        this.updateParentDimensions();
        window.addEventListener('resize', this.updateParentDimensions);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateParentDimensions);
    },
    computed: {
        computedScale() {
            const scaleX = this.parentWidth / this.size.w;
            const scaleY = this.parentHeight / this.size.h;
            return Math.min(scaleX, scaleY);
        },
        variant() {
            return {
                layers: this.$props.component,
                default: true,
                deleted: false,
                enabled: true,
                id: null,
                is_default: true,
                name: "default",
                size: this.variantSize,
                static: false,
            }
        },
        variantSize() {
            return {
                "display_dimensions": [this.size.w, this.size.h],
                "render_dimensions": [this.size.w, this.size.h],
                "name": "facebook_square",
                "icon": "fa-facebook-square",
                "enabled": true,
                "short_name": "square",
                "aspect_icon": "1-1",
                "aspect_ratio": "1:1",
                "order": 20
            }
        },
        size() {
            return {
                w: this.component.layers[0].width,
                h: this.component.layers[0].height
            }
        }
    }
}

</script>