<template>
  <div class="tree__carousel">
    <transition-group name="list">
      <div v-for="row in l_feed.data" :key="row.id" class="catalog__canvas--small list-item">
        <div class="catalog__canvas--preview-small">
          <Canvas v-bind:layers="_self['layers/list']"
                  v-bind:background="state_canvas.background_image"
                  v-bind:background_color="state_canvas.background_color"
                  v-bind:size="{w: state_canvas.width, h: state_canvas.height}"
                  v-bind:activeVariant="_self['variants/active']"
                  v-bind:disabled="true"
                  v-bind:brandkit="brandkit"
                  v-bind:samples="{indice: 0, data: [row]}"/>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Canvas from './canvas.vue'

import { mapState, mapGetters } from 'vuex'
import  EditorStoreFactory from './store'

export default {
  data(){
    return {
      _self: this,
      l_feed: {data: []}
    }
  },
  components: {Canvas},
  props: {
    canvas: {Type: Object, required: false, default: ()=>{}},
    feed: {Type: Object, required: false, default: ()=>{}},
    variant_name: {Type: String, required: false, default: "default"},
    brandkit: {type: Object, required: false, default: ()=>{}}
  },
  watch: {
    feed: {
      immediate: true,
      deep: true,
      handler(newVal){
        this.l_feed = {data: newVal.data.slice(0, 5).map(d=>d.data).filter(Boolean)}
      }
    }
  },
  created(){
    let store = EditorStoreFactory.create()
    this.$root.$options.store = store
    this.$root.$store = store
    this.$store = store
    this.$store.dispatch('init', {
      canvas: this.$props.canvas,
      feed: this.$props.feed,
      variant_name: this.$props.variant_name
    })
  },
  computed: {
    ...mapState({
      layers: state => state.layers,
      feeds: state => state.feeds.list,
      segments: state => state.feeds.segments,
      sampling: state => state.feeds.sampling,
      state_canvas: state => state.canvas
    }),
    ...mapGetters(['variants/active', 'feeds/keys', 'feeds/active_feed', 'feeds/samples', 'layers/active', 'layers/list'])
  },
  methods: {
    sample_feed(event){
      this.$store.dispatch('feeds/sample', {feed_id: event.target.value})
    },
    next_sample(){
      this.$store.dispatch('feeds/next_sample')
    },

    previous_sample(){
      this.$store.dispatch('feeds/previous_sample')
    },
    makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
 charactersLength));
   }
   return result;
}
  }
}

</script>
