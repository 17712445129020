import anime from 'animejs/lib/anime';
import Animation from '../animation';

export default class Marquee extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }){
    super({
      samples, layer, uid, duration, update_layer
    });
  }

  name(){
    return 'Marquee';
  }

  cleanup(){
    // Cleanup code if needed
  }

  prepare(){
    // Set initial state for animation
    const targetElement = $(`${this.target()}`);
    const initialTransform = targetElement.css('transform');

    // Move the element to its initial position (no translation)
    targetElement.css('transform', initialTransform);
  }

  static int_name(){
    return 'marquee';
  }

  target(){
    return `#layer_content_${this.uid}_${this.layer.id}`;
  }

  static exposed_to(){
    return ['text']; // You can customize the supported layer types
  }

  animation(){
    const targetElement = $(`${this.target()}`);
    const targetWidth = targetElement.width();
    const totalDuration = this.duration * (targetWidth / this.layer.width)

    return anime.timeline({
      duration: totalDuration,
      autoplay: false,
      loop: true, // Enable looping
    }).add({
      targets: `${this.target()}`,
      translateX: [0, -targetWidth], // Move the element from left to left (no initial translation)
      easing: 'linear',
      duration: totalDuration * 2, // Slower sliding effect (adjust this value for desired speed)
    });
  }
}
