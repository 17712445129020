<template>
    <span>
        <button type="button" class="button__toggle__backplated" @click="toggle">
            <i v-if="unlinked" class="fa-regular fa-unlink" aria-hidden="true"></i>
            <i v-else class="fa-regular fa-link" aria-hidden="true"></i>
            <span v-if="unlinked" class="tooltip tooltip--top">Attach style</span>
            <span v-else class="tooltip tooltip--top">Detach style</span>
        </button>
    </span>
</template>

<script>

export default {
    props: {
        property: {
            type: String,
            required: true
        },
        linked: {
            type: Boolean,
            required: true
        },
        on_link_toggle: {
            type: Function,
            required: true,
        }
    },
    data() {
        return {

        }
    },
    computed: {
        unlinked() {
            return !this.linked
        }
    },
    methods: {
        toggle() {
            this.$props.on_link_toggle(this.property)
        }
    }
}
</script>