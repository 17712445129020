import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Image extends Component {
  name(){
    return 'Image'
  }

  labels(){
    return ['product']
  }

  icon(){
    return 'fa-regular fa-picture-o'
  }

  config(){
    return Layer.factory({
      layer_type: 'image',
      text: null,
      width: 400,
      height: 400,
      config: {
        image_url: '{{image_link}}'
      }
    })
  }
}
