import anime from 'animejs/lib/anime';
import Animation from '../animation';

export default class TextPop extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    });
    this.prepared = false
    const text_container = document.querySelector(`#layer_text_${this.uid}_${this.layer.id}`);
    this.initial_transform = text_container.style.transform || 'none'
  }

  name() {
    return 'Word Smash';
  }

  cleanup() {
    // Cleanup code if needed
    const textContainer = document.querySelector(`#layer_text_${this.uid}_${this.layer.id}`);
    textContainer.style.transform = this.initial_transform;
    textContainer.innerHTML = this.layer.text;
    $(`#multine-ellipsis_${this.uid}_${this.layer.id}`).css('overflow', 'hidden');
    $(`.layer_container_${this.uid}_${this.layer.id}`).css('overflow', 'hidden');
    $(`.layer_${this.uid}_${this.layer.id}`).css('overflow', 'hidden');
    this.prepared = false;
  }

  prepare() {
    // Store the initial state for animation
    this.prepared = true;
    $(`#multine-ellipsis_${this.uid}_${this.layer.id}`).css('overflow', 'visible');
    $(`.layer_container_${this.uid}_${this.layer.id}`).css('overflow', 'visible');
    $(`.layer_${this.uid}_${this.layer.id}`).css('overflow', 'visible');
  }

  static int_name() {
    return 'text_pop';
  }

  static exposed_to() {
    return ['text'];
  }

  static properties() {
    const props = {
      ...super.properties(),
    };
    delete props.easing;
    return props;
  }

  animation() {
    // Select the element containing the text to be animated
    const textContainer = document.querySelector(`#layer_text_${this.uid}_${this.layer.id}`);

    // Split the text content into individual words
    const words = this.layer.text.split(/\s+/);

    // Replace the text content with the individual words wrapped in <span> elements
    textContainer.innerHTML = '';
    words.forEach((word, i) => {
      // Create a <span> element for the word
      const wordSpan = document.createElement('span');
      wordSpan.style.display = 'inline-block';
      wordSpan.textContent = word;
      textContainer.appendChild(wordSpan);

      if (i < words.length - 1) {
        // Create a <span> element for the space after the word (if any)
        const spaceSpan = document.createElement('span');
        spaceSpan.style.display = 'inline-block';
        spaceSpan.innerHTML = '&nbsp;';
        textContainer.appendChild(spaceSpan);
      }
    });

    // Select all the <span> elements in the container
    const letters = textContainer.querySelectorAll('span');

    // Get the total duration for the animation based on the number of letters
    const totalDuration = this.duration;
    const self = this
    let animationCount = 0;
    // Animate the text
    return anime.timeline({
      duration: totalDuration,
      autoplay: false,
    }).add({
      targets: letters,
      begin(){
        if (animationCount === 0) {
          self.prepare();
        }
        animationCount += 1;
      },
      complete(){
        animationCount -= 1;
        if (animationCount === 0) {
          self.cleanup();
        }
      },
      translateY: [-1500, 0], // Increase the initial falling distance
      scale: [4, 1], // Increase the initial scale
      // opacity: [0, 1],
      easing: 'easeOutExpo',
      duration: this.duration,
      delay: (el, i) => (this.duration / ((letters.length * 4))) * i,
    });
  }
}
