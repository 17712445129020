import Vuex from 'vuex'
import BriefingWizardComponent from './briefing_wizard.vue'
import StudioStore from '../studio/store/index'

export const BriefingWizard = {
  load(data = {}){
    $('briefing-wizard').each((index, el) => {
      const renderComponent = { tag: 'briefing-wizard', component: BriefingWizardComponent }
      const app = new window.vue({ // eslint-disable-line
        el,
        components: { [renderComponent.tag]: renderComponent.component },
        data,
        store: new Vuex.Store(StudioStore)
      })
    })
  }
}
