<template>
  <div style="padding-top: 12px;">
    <draggable v-model="localLayers" animation="110" group="layers" easing="cubic-bezier(1, 0, 0, 1)"
      @change="() => onOrderChange(localLayers)">
      <transition-group name="image-editor__layers">
        <div v-for="layer in localLayers" :key="layer.id">
          <!--layer heeft 3 states: editor__layer-item, editor__layer-item--visible_this en editor__layer-item__hidden_this -->
          <div @click.stop="setActive(layer)" class="editor__layer-item" :class="{
      'editor__layer-item--active': activeLayer && activeLayer.id == layer.id,
      'editor__layer-item--hidden_this': layer.visible == false
    }" v-if="layer.layer_type !== 'group'" :style="layer.config.group_id ? { 'margin-left': '20px' } : {}">
            <div class="editor__layer-item__icon editor__layer-item__icon--active" :class="icon(layer)"></div>
            <div class="editor__layer-item__name">{{ is_text_layer(layer) ? layer.text : layer.name() }}</div>

            <Panel 
              :position="'right'" 
              :margin="32" 
              :panelTitle="'Visability'" 
              :active="active_layer_dropdown === layer.id" 
              :key="`layer_dropdown_${layer.id}`"
              :on_toggle="state => state ? active_layer_dropdown = layer.id : active_layer_dropdown = null"
              >
              <template v-slot:button>
                <div class="editor__layer-item__visability_icon">
                  <i class="fa-regular fa-eye" v-if="layer.visible"></i>
                  <i class="fa-regular fa-eye-slash" v-else></i>
                </div>
              </template>
              <template v-slot>
                <div style="position:relative;">
                  <div class="v-dropdown__option" style="display: flex"
                    @click="on_layer_visibility_change(layer, layer_model.VISIBILITY_MODES.ALL_VISIBLE)">
                    <div class="editor__layer-item__visability_icon">
                      <i style="opacity:.5" class="fa-regular fa-eye"></i>
                    </div>
                    Show in all sizes
                  </div>
                  <div class="v-dropdown__option" style="display: flex"
                    @click="on_layer_visibility_change(layer, layer_model.VISIBILITY_MODES.ONLY_THIS_HIDE)">
                    <div class="editor__layer-item__visability_icon">
                      <i class="fa-regular fa-eye-slash"></i>
                    </div>
                    Hide in this size
                  </div>
                  <div class="v-dropdown__option" style="display: flex"
                    @click="on_layer_visibility_change(layer, layer_model.VISIBILITY_MODES.ONLY_THIS_VISIBLE)">
                    <div class="editor__layer-item__visability_icon">
                      <i class="fa-regular fa-eye"></i>
                    </div>
                    Show only in this size
                  </div>
                </div>

              </template>
            </Panel>
          </div>
          <LayerGroup :group="layer" :activeLayer="activeLayer" :on_order_change="onOrderChange"
            :on_layer_update="on_layer_update" :layers="layers_for_group(layer.id)" v-if="layer.layer_type === 'group'"
            :on_select="setActive"></LayerGroup>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>


<script>
import draggable from 'vuedraggable'
import { icon } from './helpers'
import LayerGroup from './layer_group.vue'
import Dropdown from '../components/dropdown.vue'
import { mixin as clickaway } from 'vue-clickaway2';
import LayerModel from './store/modules/layers/model'
import Panel from '../studio/components/panel.vue'

export default {
  data: () => {
    return {
      localLayers: [],
      active_layer_dropdown: null,
      layer_model: LayerModel
    }
  },
  mixins: [clickaway],
  components: { draggable, LayerGroup, Dropdown, Panel },
  props: {
    onOrderChange: { required: true },
    onSetActive: { required: true },
    onAddLayer: { required: true },
    activeLayer: { required: false, type: Object },
    layers: { type: Array, required: true },
    on_layer_update: { type: Function, required: true },
    on_layer_visibility_change: { type: Function, required: true }
  },
  watch: {
    layers: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.localLayers = [...newVal].map(l => LayerModel.factory(l)).sort((a, b) => {
          return (a.position > b.position) ? 1 : -1
        }).filter(l => !l.config.group_id || l.layer_type === 'group')
      }
    }
  },
  methods: {
    is_text_layer(layer){
      let types = ['text', 'text_area', 'scaled_text']
      return types.includes(layer.layer_type)
    },
    layers_for_group(group_id) {
      if (!group_id) return []
      return this.layers.filter(l => l.config.group_id == group_id && l.layer_type !== 'group') //eslint-disable-line
    },
    add_layer(config) {
      this.onAddLayer(config)
      this.show_dropdown = false
    },
    icon(layer) {
      return icon(layer)
    },
    setActive(layer) {
      if (layer && this.activeLayer && layer.id === this.activeLayer.id) return
      this.onSetActive(layer)
    }
  }
}
</script>
