<template>
  <video v-if="show" :poster="l_poster" :ref="`video`" :preload="preload" muted style="width: 100%;"
    @mouseover="hover = true" @mouseleave="hover = false">
    <source :src="`${url}#t=${l_start},${l_end}`" type="video/mp4">
  </video>
</template>

<script>
export default {
  props: {
    start: {
      type: Number,
      required: false
    },
    end: {
      type: Number,
      required: false
    },
    poster: {
      type: String,
      required: false
    },
    url: {
      type: String,
      required: true
    },
    preload: {
      type: String,
      required: false,
      default: 'metadata'
    }
  },
  data() {
    return {
      hover: false,
      playing: false,
      show: false,
      l_start: null,
      l_end: null,
      l_poster: null
    }
  },
  watch: {
    hover(newVal) {
      if (newVal === true) {
        this.play()
      } else {
        this.pause()
      }
    }
  },
  mounted() {
    this.l_poster = this.poster || null
    this.l_end = this.end
    this.l_start = this.start
    if (this.url && this.url.includes('#')) {
      let times = this.url.split('#').pop()
      times = times.replace('t=', '').split(',')
      this.l_start = times[0]
      this.l_end = times[1]
    } else if (this.l_end === null && this.l_start === null) {
      this.l_start = 0
      this.l_end = 99
    }
    this.show = true
    this.$nextTick(() => {
      this.ref.addEventListener('pause', this.reload)
      this.ref.addEventListener('timeupdate', this.progress)
    })
  },
  computed: {
    ref() {
      return this.$refs[`video`]
    }
  },
  methods: {
    pause() {
      if (this.playing) {
        this.ref.pause()
        this.playing = false
      }
    },
    play() {
      if (!this.playing) {
        this.ref.play()
        this.playing = true
      }
    },
    progress(e) {
      if (e.target.currentTime >= this.l_end) {
        this.pause()
        this.reload()
      }
    },
    reload(e) {
      if (!Number.isNaN(this.l_start) && Number.isFinite(this.l_start)) {
        this.ref.currentTime = this.l_start
      }
    }
  }
}
</script>
