<template>
    <div :style="{
        ...additionalStyles,
        width: layer.width + 'px',
        height: layer.height + 'px',
        'backdrop-filter': `${filter}`,
        '-webkit-backdrop-filter': `${filter}`,
    }">
    </div>
</template>

<script>
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],
    data() {
        return {

        }
    },
    computed: {
        additionalStyles() {
            const styles = {}
            if (this.filterType === 'sepia') {
                return {
                    opacity: this.opacity
                }
            } else {
                return {
                    backgroundColor: this.color,
                }
            }
        },
        color() {
            const color = this.layer?.config?.bg_backdrop.color_1
            if (color === 'transparent') {
                return 'transparent'
            }
            let rgb = this.hexToRGB(color || '#000000')
            return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${this.opacity})`
        },
        filterValue() {
            switch (this.filterType) {
                case 'blur':
                    return (this.layer?.config?.bg_backdrop.filter_value || 0) * .25;
                case 'sepia':
                case 'grayscale':
                case 'invert':
                case 'saturate':
                case 'contrast':
                case 'brightness':
                    return (this.layer?.config?.bg_backdrop.filter_value || 0)
            }
        },
        opacity() {
            return (this.layer?.config?.bg_backdrop.opacity || 100) / 100;
        },
        filterType() {
            return this.layer?.config?.bg_backdrop.type || 'blur'
        },
        filter() {
            switch (this.filterType) {
                case 'blur':
                    return `blur(${this.filterValue}px)`
                case 'brightness':
                    return `brightness(${this.filterValue})`
                case 'contrast':
                    return `contrast(${this.filterValue})`
                case 'grayscale':
                    return `grayscale(${this.filterValue})`
                case 'hue-rotate':
                    return `hue-rotate(${this.filterValue}deg)`
                case 'invert':
                    return `invert(${this.filterValue})`
                case 'opacity':
                    return `opacity(${this.filterValue})`
                case 'saturate':
                    return `saturate(${this.filterValue})`
                case 'sepia':
                    return `sepia(${this.filterValue}%)`
                default:
                    return `blur(${this.filterValue}px)`
            }
        }
    },
    methods: {
        hexToRGB(hex) {
            const r = parseInt(hex.slice(1, 3), 16);
            const g = parseInt(hex.slice(3, 5), 16);
            const b = parseInt(hex.slice(5, 7), 16);
            return { r, g, b };
        },
    }

}
</script>