import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Text extends Component {
  constructor({ variable_tag, display_name, default_value } =
  { variable_tag: null, display_name: null, default_value: null }) {
    super({ variable_tag, display_name, default_value })
  }

  name() {
    return this.display_name || 'Text area'
  }

  labels() {
    return ['layer']
  }

  icon() {
    return 'fa-regular fa-text'
  }

  config() {
    return Layer.factory({
      layer_type: 'text_area',
      text: this.default_value || '',
      width: 300,
      height: 140,
      variable: !!this.variable_tag,
      variable_tag: this.variable_tag,
    })
  }
}
