export default class {
  constructor(card){
    this.asset_url    = card.asset_url
    this.description  = card.description || ''
    this.header       = card.header || ''
    this.id           = card.id
    this.link         = card.link
    this.position     = card.position
    this.target_url   = card.target_url
    this.canvas       = card.canvas || null
  }

  toState(){
    return {
      id: this.id,
      media: {
        type: 'image',
        source: this.asset_url
      },
      target_url: this.target_url,
      link: this.link,
      description: this.description,
      header: this.header,
      canvas: this.canvas
    }
  }
}
