<template>
  <div class="layer-config__essentials">
    <div class="form-group">
      <label>Media</label>
      <UrlUpload 
        name="image_url" 
        :media_folder="mediaFolder" 
        element_key="data"
        :element="{ data: layer.config.media_url }"
        :on_change="(url) => setLayerMedia(url)"
        />
    </div>

    <div class="button__tab_group" v-if="is_studio && version > 1">
      <div class="button__toggle__light button--small" :class="{ 'button__toggle--active': layer.variable_tag === 'product' }"
        @click="updateLayer('variable_tag', 'product')">
        Product
      </div>
      <div class="button__toggle__light button--small" :class="{ 'button__toggle--active': layer.variable_tag === 'lifestyle' }"
        @click="updateLayer('variable_tag', 'lifestyle')">
        Lifestyle
      </div>
      <div class="button__toggle__light button--small" :class="{ 'button__toggle--active': layer.variable_tag === 'generic' }"
        @click="updateLayer('variable_tag', 'generic')">
        Generic
      </div>
    </div>

    <div class="form-group">
      <label>Scale</label>
      <div class="button__tab_group">
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_fit'] == 'contain' }"
          class="button__toggle__light" v-on:click="updateLayerConfig({ object_fit: 'contain' })">
          <i class="fa-regular fa-compress" aria-hidden="true"></i>
          <span class="tooltip tooltip--top">Fit</span>
        </button>
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_fit'] == 'cover' }"
          class="button__toggle__light" v-on:click="updateLayerConfig({ object_fit: 'cover' })">
          <i class="fa-regular fa-expand" aria-hidden="true"></i>
          <span class="tooltip tooltip--top">Cover</span>
        </button>
      </div>
    </div>

    <div class="form-group">
      <label>Snap</label>
      <div class="button__tab_grid">
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_position'] == 'left top' }"
          class="button__toggle__light button__tab_grid__button" v-on:click="updateLayerConfig({ object_position: 'left top' })">
          <i class="fa-regular fa-arrow-up-left"></i>
          <span class="tooltip tooltip--top">Top left</span>
        </button>
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_position'] == 'top' }"
          class="button__toggle__light button__tab_grid__button" v-on:click="updateLayerConfig({ object_position: 'top' })">
          <i class="fa-regular fa-arrow-up"></i>
          <span class="tooltip tooltip--top">Top center</span>
        </button>
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_position'] == 'right top' }"
          class="button__toggle__light button__tab_grid__button" v-on:click="updateLayerConfig({ object_position: 'right top' })">
          <i class="fa-regular fa-arrow-up-right"></i>
          <span class="tooltip tooltip--top">Top right</span>
        </button>
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_position'] == 'left' }"
          class="button__toggle__light button__tab_grid__button" v-on:click="updateLayerConfig({ object_position: 'left' })">
          <i class="fa-regular fa-arrow-left"></i>
          <span class="tooltip tooltip--top">Center left</span>
        </button>
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_position'] == 'center' }"
          class="button__toggle__light button__tab_grid__button" v-on:click="updateLayerConfig({ object_position: 'center' })">
          <i class="fa-regular fa-arrows-to-circle"></i>
          <span class="tooltip tooltip--top">Center</span>
        </button>
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_position'] == 'right' }"
          class="button__toggle__light button__tab_grid__button" v-on:click="updateLayerConfig({ object_position: 'right' })">
          <i class="fa-regular fa-arrow-right"></i>
          <span class="tooltip tooltip--top">Center right</span>
        </button>
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_position'] == 'left bottom' }"
          class="button__toggle__light button__tab_grid__button" v-on:click="updateLayerConfig({ object_position: 'left bottom' })">
          <i class="fa-regular fa-arrow-down-left"></i>
          <span class="tooltip tooltip--top">Bottom left</span>
        </button>
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_position'] == 'bottom' }"
          class="button__toggle__light button__tab_grid__button" v-on:click="updateLayerConfig({ object_position: 'bottom' })">
          <i class="fa-regular fa-arrow-down"></i>
          <span class="tooltip tooltip--top">Bottom center</span>
        </button>
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_position'] == 'right bottom' }"
          class="button__toggle__light button__tab_grid__button" v-on:click="updateLayerConfig({ object_position: 'right bottom' })">
          <i class="fa-regular fa-arrow-down-right"></i>
          <span class="tooltip tooltip--top">Bottom right</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UrlUpload from '../../../feeds/fields/audio-video.vue'

export default {
  props: ['mediaFolder', 'layer', 'onUpdate', 'version', 'is_studio'],
  components: {
    UrlUpload
  },
  methods: {
    setLayerMedia(url) {
      this.updateLayerConfig({ 'media_url': url })
    },
    updateLayerConfig(conf) {
      this.updateLayer('config', { ...this.layer.config, ...conf })
    },
    updateLayer(key, value) {
      const layer = { ...this.layer, [key]: value }
      this.$props.onUpdate(layer)
    },
  }
}
</script>

