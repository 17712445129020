import { mustache } from '../../../lib/parsers'

export default {
  computed: {
    font() {
      if (this.layer.dynamic_font_id) {
        const font_id = mustache.fill_in(this.layer.dynamic_font_id, { brandkit: this.brand })
        if (this.$root.$store && this.$root.$store.state.fonts) {
          const font = this.$root.$store.state.fonts.find((f) => f.id == font_id) // eslint-disable-line
          if (font) return font.face
        }
        return this.layer.font
      }
      return this.layer.font
    },
  }
}
