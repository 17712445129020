<template>
  <div v-on-clickaway="away" style="position: relative">
    <div class="tweener editor__select-selector" v-on:click="toggle()">
      <slot :selected="selected" name="selected_option">
        <div class="editor__select-selector-value"
            :style="active_option ? active_option.data.style : {}">
          {{active_option && active_option.elm ? active_option.elm.innerText : placeholder}}
        </div>
      </slot>
      <div class="editor__select-selector-caret">
        <i v-if="!active" class="fa fa-chevron-down" aria-hidden="true"></i>
        <i v-if="active" class="fa fa-chevron-up" aria-hidden="true"></i>
      </div>
    </div>
    <div v-show="active" class="editor__select-container">
      <div class="form-group" style="padding: 8px; margin-top: 0px;" v-if="searchable">
        <input ref="search" type="text" placeholder="search..."  v-on:input="(e)=>filter(e.target.value)">
      </div>
      <div class="editor__select-content">
        <slot>
          <div class="editor__select-option">

          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2';
// swap fuzzysort for fuse.js
import fuzzysort from 'fuzzysort'

export default {
  props: {
    placeholder: {type: String, required: false, default: "Nothing"},
    selected: {required: false, default: null},
    searchable: {required: false, default: false, type: Boolean}
  },
  data(){
    return {
      active: false,
      sel: this.selected,
      active_option: null,
      registered_listeners: false,
    }
  },
  mixins: [ clickaway ],
  created(){
    document.addEventListener('keydown', e => {
      if(e.key == 'Escape' || e.code == 'Escape')
        this.away()
    })
  },
  mounted(){
    this.attach_listeners()
  },
  updated(){
    this.attach_listeners()
    this.active_option = this.active_opt()
    this.options.forEach(opt => opt.elm.classList ? opt.elm.classList.remove('editor__select-option--active') : null)

    if(this.active_option && this.active_option.elm)
      this.active_option.elm.classList.add('editor__select-option--active')
  },
  watch: {
    selected: {
      immediate: true,
      handler(newVal){
        setTimeout(()=>{
          this.sel = newVal
          this.$forceUpdate()
        }, 1)
      }
    },
    active(newVal){
      if(newVal && this.searchable)
        setTimeout(()=>{this.$refs.search.focus()}, 1)
      if(newVal == false && this.searchable){
        this.$refs.search.value = ""
        this.filter("")
      }
    }
  },
  computed: {
    options(){
      if(this.$slots.default)
        return this.$slots['default'].map(slot => {
          if(slot.elm)
            return {
              elm: slot.elm,
              searchable: slot.elm.innerText
            }
        }).filter(Boolean)
      return []
    },
  },
  methods: {
    active_opt(){
      let active = null
      if(this.$slots.default){
        this.$slots['default'].forEach(slot => {
          if(slot.data && slot.data.attrs && slot.data.attrs.value && slot.data.attrs.value == this.sel){
            active = slot
          }
        })
      }
      return active
    },
    attach_listeners(){
      if(this.$slots.default){
        this.$slots['default'].forEach(slot => {
          $(slot.elm).off('click')
          $(slot.elm).on('click', ()=>{
            this.sel = slot.data.attrs.value
            this.$emit('change', this.sel)
            this.away()
          })
        })
      }
    },
    filter(val){
      const r = fuzzysort.go(val, this.options, {key:'searchable', allowTypo: true, threshold: -1000})
      this.options.forEach((opt)=>{
        if(opt.elm && opt.elm.style)
          if(val) {
            opt.elm.style.display = 'none'
          } else {
            opt.elm.style.display = 'block'
          }
      })
      r.forEach((opt)=>{
        if(opt.obj.elm && opt.obj.elm.style)
          opt.obj.elm.style.display = 'block'
      })
    },
    // handle_input (e) {
    //   this.$emit('input', this.content)
    // },
    away() {
      this.active = false
    },
    toggle(){
      this.active = !this.active
    },
  },
}
</script>
