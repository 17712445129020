export const registerStoreModule = (store, namespace, storeModule) => {
  if (!store.state[namespace]) {
    store.registerModule(
      `${namespace}`,
      { ...storeModule },
      { preserveState: false }
    );
    return true
  }
  return false
}
