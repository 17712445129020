import actions   from './actions'
import getters   from './getters'
import mutations from './mutations'

const state = () => ({
  id: null,
  facebookChannelId: null,
  header: '',
  target_url: '',
  description: '',
  media: '',
  position: '',
  link: '',
  namespace: '',
  canvas: null
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
