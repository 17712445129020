<template>
  <transition name="slide">
    <div class="studio__template-picker" v-if="show_template_picker">
      <span v-if="!this.$slots.default" class="studio__template-picker__holder">

        <div class="studio__nav" :style="`${active_template ? 'filter: blur(2px);' : null}`">
          <div class="studio__nav-wrapper">
            <div class="studio__nav-tab" :class="{ 'studio__nav-tab--active': template_filter === 'self' }"
              v-on:click="template_filter = 'self'">
              My templates
            </div>
            <div class="studio__nav-tab" v-if="is_video" v-on:click="template_filter = 'adflow'"
              :class="{ 'studio__nav-tab--active': template_filter === 'adflow' }">
              Adflow templates
            </div>
          </div>

          <div style="display: flex;">
            <div v-if="processing.busy" style="margin-right: 15px;">
              <div class="loader"></div>
            </div>
            <a class="button button--light" style="margin-right: 6px;"
              :href="`/designs/new?t=studio&filter=${is_video ? 'video' : 'image'}&source=${briefing.preview_feed.complex_identifier}&redirect=/briefings/${briefing.id}?tp=true`">
              Create new template
            </a>
            <button class="button button--light" style="margin-right: 6px;" v-on:click="show_template_picker = false">
              Close
            </button>
          </div>

        </div>

        <div class="studio__template-picker__body">
          <template v-if="template_filter === 'self'">
            <div class="campaign-body__virgin-holder" v-if="templates.canvasses.length === 0">
              <!-- <div class="loader"></div> &nbsp; <p> Fetching templates... </p> -->
              <a class="button" style="margin-right: 6px;"
                :href="`/designs/new?t=studio&filter=${is_video ? 'video' : 'image'}&source=${briefing.preview_feed.complex_identifier}&redirect=/briefings/${briefing.id}?tp=true`">
                Create new template
              </a>
            </div>

            <Template v-for="canvas in templates.canvasses.filter(c => is_video ? (c && c.video) : true)"
              :key="`video_template_${canvas.id}`" :canvas="canvas" :briefing="briefing" :brandkit="brandkit"
              :use_default_data="true" :on_toggle="c => activate_canvas(c)" :on_delete="c => delete_canvas(c)"
              :on_duplicate="c => duplicate_canvas(c)"
              :active="active_template ? active_template.id == canvas.id : false" :briefing_meta="briefing_meta" />
          </template>

          <template v-else>
            <span v-for="adflow_template in adflow_templates">
              <Template :key="`video_template_${adflow_template.name}`" :canvas="adflow_template.template"
                :editable="false" :use_default_data="true" :briefing="briefing" :brandkit="brandkit"
                :anim_uid="adflow_template.name" :on_toggle="c => activate_template(c)" :on_delete="c => null"
                :on_duplicate="c => null" :active="active_template ? active_template.id == adflow_template.name : false"
                :briefing_meta="briefing_meta" />
            </span>
          </template>
        </div>
      </span>
      <slot></slot>

      <transition name="studio__previews-template-picker">
        <div class="studio__previews-template-picker" v-if="active_template"
          style="top: 70px; box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.1);">
          <div class="studio__template-picker__header">

            <h1 class="bigger" v-if="is_video">
              Add scene to video
            </h1>
            <h1 class="bigger" v-else>
              Add variant
            </h1>
            <div style="display: flex;">
              <button class="button--light" v-on:click="active_template = null">
                Back
              </button>
            </div>
            <!-- <button v-on:click="create_scene" :disabled="variants.length == 0" style="float: right; margin-right: 15px;">
                Create scene
              </button> -->
          </div>
          <span>
            <transition-group class="studio__variant-picker__body" name="studio-templates-list">
              <TemplatePickerVariant :feed="template_picker.canvas.feed.data" :indice="1337" :force_placeholders="true"
                :key="`bc_${active_template.id}_feed_${1337}`" />

              <TemplatePickerVariant :feed="feed" :indice="index" v-for="(feed, index) in template_picker.preview_feeds"
                :key="`bc_${active_template.id}_feed_${index}`" />
            </transition-group>
          </span>
          <div v-if="template_picker.preview_feeds.length == 0" class="studio__main-panel studio__main-panel--templates"
            style="display: flex; padding: 20px; padding-top: 0; ">
            <div class="loader"></div> &nbsp; <p>Prefilling scenes...</p>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2';
import { mapState } from 'vuex'
import CanvasPreview from '../../catalogs/canvas_preview.vue'
import TemplatePickerVariant from './template_picker_variant.vue'
import Template from './template.vue'
import Api from '../../image-editor/store/api/editor.api'

export default {
  components: { CanvasPreview, TemplatePickerVariant, Template },
  mixins: [clickaway],
  props: {

  },
  data() {
    return {
      template_filter: "self",
      adflow_templates: []
    }
  },
  async mounted() {
    const url_params = new URLSearchParams(window.location.search)
    const tp = url_params.get('tp')
    const templates = await Api.get_templates({ video: true })
    this.adflow_templates = templates.map(canvas => {
      canvas.variants = canvas.variants.map(v => {
        return {
          ...v,
          layers: v.layers.map(l => {
            return {
              ...l,
              id: (Math.random() + 1).toString(36).substring(7)
            }
          })
        }
      })
      return { template: { ...canvas, video: true } }
    })

    if (tp)
      setTimeout(() => {
        this.show_template_picker = true
      }, 20)
  },
  computed: {
    ...mapState({
      templates: state => state.templates,
      template_picker: state => state.template_picker,
      brandkit: state => state.brandkit,
      briefing: state => state.briefing,
      briefing_canvasses: state => state.briefing_canvasses,
      variants: state => state.template_picker.variants,
      processing: state => state.processing,
    }),
    is_video() {
      return this.briefing.type !== 'ImageBriefing'
    },
    briefing_meta() {
      return this.$store.getters.briefing_meta
    },
    footage_assets() {
      return this.$store.getters.footage_assets
    },
    product_assets() {
      return this.$store.getters.product_assets
    },
    briefing_canvas() {
      return this.$store.getters.template_picker_briefing_canvas
    },
    show_template_picker: {
      get() {
        return this.$store.state.template_picker.show
      },
      set(value) {
        this.$store.dispatch('SET_SHOW_TEMPLATE_PICKER', value)
      }
    },
    template_picker_variants: {
      get() {
        return this.$store.state.template_picker.variants
      },
      set(value) {
        this.$store.commit('SET_VARIANTS_TEMPLATE_PICKER', value)
      }
    },
    active_template: {
      get() {
        return this.$store.state.template_picker.canvas
      },
      set(value) {
        this.$store.dispatch('SET_TEMPLATE_TEMPLATE_PICKER', value)
      }
    },
  },
  watch: {
    templates: {
      deep: true,
      immediate: false,
      handler(newval) {
        if (newval && newval.canvasses.length === 0)
          this.template_filter = 'adflow'
      }
    }
  },
  methods: {
    away(e) {
      if (!e.target.classList.contains('editor__size-variant') && !e.target.classList.contains('icon-ar'))
        this.show_template_picker = false
    },
    duplicate_canvas(canvassable) {
      this.$store.dispatch('DUPLICATE_CANVAS', { canvassable })
    },
    delete_canvas(canvassable) {
      this.$store.dispatch('DELETE_CANVAS', { canvassable })
    },
    close() {
      this.show_template_picker = false
    },
    activate_canvas(canvas) {
      this.active_template = canvas
      this.template_picker_variants = []
      if (canvas && canvas.n_footage_slots === 0 && canvas.n_item_slots === 0) {
        this.$store.dispatch('CREATE_SCENE', { canvas })
      }
    },
    activate_template(canvas) {
      let t = this.adflow_templates.find(t => t.template.id === canvas.id)
      this.active_template = { ...canvas, adflow_template: true, name: t.name }
      this.template_picker_variants = []
      if (canvas && canvas.n_footage_slots === 0 && canvas.n_item_slots === 0) {
        this.$store.dispatch('CREATE_SCENE', { canvas: { ...canvas, adflow_template: true, name: t.name } })
      }
    },
    create_scene() {
      this.$store.dispatch('CREATE_SCENE')
    },
    capfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
}
</script>
