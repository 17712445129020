import Background from './types/background'
import Layer from '../../store/modules/layers/model'

export default class BgBackdrop extends Background {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Backdrop'
  }

  labels() {
    return ['overlays']
  }

  icon() {
    return 'fa-solid fa-heart'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'color',
          name: 'Background',
          key: 'color_1',
        },

        {
          type: 'slider',
          name: 'Opacity',
          key: 'opacity',
          min: 0,
          max: 100,
          step: 1,
        },

        {
          type: 'slider',
          name: 'Filter',
          key: 'filter_value',
          min: 0,
          max: 100,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()
    const background = 'https://staging-assets.adflow.io/public/6e9163c9-1581-4532-a4bf-13bead2be8ce.jpeg?focus_x=-0.6513801987486199&focus_y=0.19381670960618325'
    return [
      {
        background,
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            bg_backdrop: {
              ...baseConfig.config.bg_backdrop,
              color_1: 'transparent',
              type: 'sepia',
              filter_value: 100,
              opacity: 100
            }
          },
        }]
      },
      {
        background,
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            bg_backdrop: {
              ...baseConfig.config.bg_backdrop,
              color_1: 'transparent',
              type: 'grayscale',
              filter_value: 100,
              opacity: 100
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      width: 560,
      height: 245,
      ...this.opts,
      config: {
        component: {
          type: 'bg_backdrop',
          name: 'Backdrop',
        },
        bg_backdrop: {
          color_1: '#FFFFFF',
          type: 'blur',
          filter_value: 20,
          opacity: 50,
        }
      }
    }
  }

  config() {
    return {
      background: 'https://staging-assets.adflow.io/public/6e9163c9-1581-4532-a4bf-13bead2be8ce.jpeg?focus_x=-0.6513801987486199&focus_y=0.19381670960618325',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
