import axios from 'axios';

const api = {
  async createLayer(layer) {
    const result = await axios.post('/layers.json', { layer })
    return result.data
  },

  async deleteLayer(layer) {
    const result = await axios.delete(`/layers/${layer.id}.json`)
    return result.data
  },

  async updateLayer(layer, options = {}) {
    if (options.trigger_callbacks) {
      const result = await axios.patch(`/layers/${layer.id}.json?trigger_callbacks=true`, { layer })
      return result.data
    }
    const result = await axios.patch(`/layers/${layer.id}.json?`, { layer })
    return result.data
  },

  async updateLayers(layers) {
    const result = await axios.patch('/layers/batch.json?trigger_callbacks=true', { layers })
    return result.data
  }
}

export default api
