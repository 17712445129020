<template>
    <span>
        <modal name="asset_bucket" :focusTrap="true" :clickToClose="false" :classes="['modal__flex']" :width="'80%'"
            :height="'85%'">


            <div class="modal__header modal__header--underlined">
                <a href="/briefings" class="button button--light">
                    <i class="fa-solid fa-chevron-left"></i>
                </a>
                <div class="modal__header_container">
                    <h3>Create Video Ads</h3>
                    <!--<p>Add your images and videos and turn them into captivating ads that highlight your products value and drive sales.</p>-->
                </div>
            </div>

            <div class="modal__flex_body" style="flex-direction: column; gap: 24px;">

                <div class="brieding_wizard__upload">
                    <Medialib ref="medialib" :show="true" :fixed="false"
                        :feed_id="feed ? feed.complex_identifier : null" :select_on_upload="true"
                        :on_asset="element => select_asset(element)" :default_tab="default_tab" :show_filters="false">

                        <template v-slot:virgin>
                            <div class="medialibrary__virgin_container">
                                <div class="medialibrary__virgin_container_holder">
                                    <i class="fa-regular fa-cloud-arrow-up"></i>
                                    <p class="strong">Drag & Drop your files (jpg, png, mp4)</p>
                                </div>
                            </div>
                        </template>

                        <template v-slot:footer>
                            <div style="padding: 12px; background: #EEE; border-top: 1px solid #CCC;"
                                v-if="selected_assets.length > 0">
                                <label>Selected assets</label>
                                <div style="display: flex; margin-top: 5px;">
                                    <div v-for="(asset, index) in selected_assets" :key="`briefing_assets_${index}`"
                                        class="medialibrary__asset-container"
                                        style="background: transparent; padding: 0px;">
                                        <div class="medialibrary__asset medialibrary__asset--small" :style="!asset.asset_url.toLowerCase().includes('mp4') ? {
            backgroundSize: 'cover',
            backgroundImage: `url(${asset.asset_url})`
        } : {}">
                                            <span v-if="asset.asset_url.toLowerCase().includes('mp4')">
                                                <Video :url="asset.asset_url"></Video>
                                                <div class="medialibrary__vid-icon">
                                                    <i class="fa-regular fa-video"></i>
                                                </div>
                                            </span>
                                            <!-- <img :src="asset.data.image_link" :id="`briefing_asset_${asset.data.id}`"> -->
                                            <div class="medialibrary__asset-action">
                                                <button class="button button--dark"
                                                    v-on:click="delete_feed_row({ data: asset })">
                                                    <i class="fa-regular fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Medialib>
                </div>

                <span v-if="this.feed.data.length > 0 || step == 2">
                    <div class="briefing_wizard__form" style="margin-top: 12px;">
                        <div class="form-group">
                            <label>Campaign name</label>
                            <input type="text" v-model="campaign_briefing.name" placeholder="Name" />
                        </div>

                        <div class="form-group">
                            <label>Goal</label>
                            <div class="briefing_wizard__goal_container">
                                <div class="briefing_wizard__goal" v-on:click="goal = 'product promotion'"
                                    :class="{ 'briefing_wizard__goal--active': goal == 'product promotion' }">
                                    Product promotion
                                </div>
                                <div class="briefing_wizard__goal" v-on:click="goal = 'new product'"
                                    :class="{ 'briefing_wizard__goal--active': goal == 'new product' }">
                                    New product
                                </div>
                                <div class="briefing_wizard__goal" v-on:click="goal = 'branding'"
                                    :class="{ 'briefing_wizard__goal--active': goal == 'branding' }">
                                    Branding
                                </div>
                                <div class="briefing_wizard__goal" v-on:click="goal = 'social proof'"
                                    :class="{ 'briefing_wizard__goal--active': goal == 'social proof' }">
                                    Social proof
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="languageSelect">Language</label>
                            <select id="languageSelect" v-model="language" class="form-control">
                                <option :value="lang" v-for="lang in supported_languages">{{ lang }}</option>
                            </select>
                        </div>

                        <div class="form-group" style="display: flex; flex-direction: column; flex: 1;">
                            <label>Describe your ad</label>
                            <textarea style="flex: 1;" rows="6" v-model="campaign_briefing.purpose" placeholder="Tell us about your brand & product, think benefits, features, customer problems, etc. The more detail you provide the better our AI can craft your video ads. 
EXAMPLE:

Theragun PRO Plus 6TH GENERATION theragun-proplus
The ultimate multi-therapy device for athletic performance
€ 599

Benefits
• Relieve pain and reduce tension + soreness
• Recover faster
• Repair tissue to boost healing
• Enhance performance
• Improve circulation, mobility + flexibility
• Maximize warm-up + reduce injury risk
• Prevent soreness from exercise

Description
Theragun PRO Plus is the unrivaled percussive massage device designed to help you pursue the upper limits of your performance. It combines our strongest deep muscle percussive massage with 5 more cutting-edge therapies that include near infrared LED light therapy to help treat joints and tissue, vibration therapy to support circulation, heat therapy to help relieve soreness and support quick warm-ups and breathwork to help increase focus and relaxation. Trusted by professionals, Theragun PRO Plus helps relieve pain, optimize recovery and improve mobility.
                            "></textarea>
                        </div>
                    </div>
                </span>

                <span v-else style="display: flex; flex-direction: column; position: relative;">
                    <div class="briefing_wizard__upload_instructions__container">
                        <h5>Upload instructions (for best results)</h5>
                        <div class="briefing_wizard__upload_instructions">

                            <div class="briefing_wizard__upload_instruction">
                                <img src="/icon-do.svg" class="briefing_wizard__upload_instruction__icon"></img>
                                <div class="briefing_wizard__upload_instruction__visual">
                                    <img src="/upload-instruction-image.png"></img>
                                </div>
                                <div class="briefing_wizard__upload_instruction__text">
                                    <h4>Upload clear images with background</h4>
                                    <p>Upload JPEG or PNG files.</p>
                                </div>
                            </div>
                            <div class="briefing_wizard__upload_instruction">
                                <img src="/icon-do.svg" class="briefing_wizard__upload_instruction__icon"></img>
                                <div class="briefing_wizard__upload_instruction__visual">
                                    <img src="/upload-instruction-image-6.gif"></img>
                                </div>
                                <div class="briefing_wizard__upload_instruction__text">
                                    <h4>Upload short videos</h4>
                                    <p>Upload MP4 or MOV files. Max 250MB or 10 minutes.</p>
                                </div>
                            </div>

                            <div class="briefing_wizard__upload_instruction">
                                <img src="/icon-do.svg" class="briefing_wizard__upload_instruction__icon"></img>
                                <div class="briefing_wizard__upload_instruction__visual">
                                    <img src="/upload-instruction-image-3.png"></img>
                                </div>
                                <div class="briefing_wizard__upload_instruction__text">
                                    <h4>Image without background</h4>
                                    <p>Upload images with light or transparent background.</p>
                                </div>
                            </div>

                            <div class="briefing_wizard__upload_instruction">
                                <img src="/icon-dont.svg" class="briefing_wizard__upload_instruction__icon"></img>
                                <div class="briefing_wizard__upload_instruction__visual">
                                    <img src="/upload-instruction-image-2.png"></img>
                                </div>
                                <div class="briefing_wizard__upload_instruction__text">
                                    <h4>Text/graphics/subtitles overlays</h4>
                                    <p>Don’t upload assets with text/graphics/subtitle overlays.</p>
                                </div>
                            </div>

                            <div class="briefing_wizard__upload_instruction">
                                <img src="/icon-dont.svg" class="briefing_wizard__upload_instruction__icon"></img>
                                <div class="briefing_wizard__upload_instruction__visual">
                                    <img src="/upload-instruction-image-5.gif"></img>
                                </div>
                                <div class="briefing_wizard__upload_instruction__text">
                                    <h4>Don’t use video with voice-over</h4>
                                    <p>The audio of your video will not be used.</p>
                                </div>
                            </div>

                            <div class="briefing_wizard__upload_instruction">
                                <img src="/icon-dont.svg" class="briefing_wizard__upload_instruction__icon"></img>
                                <div class="briefing_wizard__upload_instruction__visual">
                                    <img src="/upload-instruction-image-4.png"></img>
                                </div>
                                <div class="briefing_wizard__upload_instruction__text">
                                    <h4>Don’t upload brand logos or graphics</h4>
                                    <p>Use brandkit under settings, to add brand colours and logos.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </span>

            </div>
            <div class="modal__footer tweener">
                <Transition name="fade">
                    <span v-if="assets.length < 5">
                        Select <span class="strong">{{ 5 - assets.length }}</span> more asset{{ (5 - assets.length) > 1
            ? 's' : '' }} for the best result
                    </span>
                    <span v-else>
                        Great! You have selected <span class="strong">{{ assets.length }}</span> assets
                    </span>
                </Transition>
                <button @click="next" :disabled="assets.length < 5" class="button button--big button--gradient">{{ step
            == 2 ?
                    "Generate" : "Next"}}</button>

            </div>
        </modal>
    </span>
</template>

<script>
import Medialib from '../studio/components/medialib.vue'
import StudioApi from '../studio/store/api.js'
import Video from '../studio/components/video.vue'
import api from '../studio/api.js'
import Dropzone from '../studio/dropzone.vue'
import studioStoreApi from '../studio/store/api.js'

export default {
    components: { Medialib, Video, Dropzone },
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        },

        campaign_briefing: {
            type: Object,
            required: true
        },
        on_finish: {
            type: Function,
            required: true,
            default: () => { }
        }
    },
    data() {
        return {
            active_uploads: [],
            cb: null,
            media_lib_active: false,
            goal: this.campaign_briefing.goal || 'product promotion',
            language: this.campaign_briefing.language || 'english (uk)',
            feed: this.campaign_briefing.feed,
            selected_assets: [],
            extracting: false,
            extraction_url: this.brand_homepage,
            extracting_state: null,
            step: (this.$props.campaign_briefing.feed.data.length > 0 ? 2 : 1),
            supported_languages: [
                "english (uk)",
                "english (us)",
                "german",
                "french",
                "italian",
                "spanish",
                "russian",
                "turkish",
                "polish",
                "dutch",
                "ukrainian",
                "greek",
                "czech",
                "swedish",
                "hungarian",
                "belarusian",
                "serbian",
                "portuguese",
                "bulgarian",
                "danish",
                "finnish",
                "slovak",
                "norwegian",
                "croatian",
                "bosnian",
                "albanian",
                "lithuanian",
                "slovenian",
                "latvian",
                "estonian",
                "macedonian",
                "romanian"
            ]
        }
    },
    computed: {
        assets() {
            return this.selected_assets
        },
        default_tab() {
            if (this.step == 2) {
                return null
            }
            return this.campaign_briefing.feed.data.length > 0 ? null : 'assets'
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.show)
                this.$modal.show('asset_bucket')
        }, 1)
        this.selected_assets = this.campaign_briefing.feed.data.map(r => r.data)
    },
    watch: {
        media_lib_active(new_val) {
            if (new_val === true) {
                this.$modal.show('medialib_modal')
            } else {
                this.$modal.hide('medialib_modal')
            }
        },
        show: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal) {
                    this.$modal.show('asset_bucket')
                } else {
                    this.$modal.hide('asset_bucket')
                }
            }
        }
    },
    methods: {
        async next() {
            if (this.step === 1) {
                this.step = 2
            } else {
                await this.save_and_finish()
            }
        },
        async save_and_finish() {
            const result = await StudioApi.update_campaign_briefing({
                goal: this.goal,
                language: this.language,
                purpose: this.campaign_briefing.purpose,
                name: this.campaign_briefing.name,
                id: this.campaign_briefing.id
            })
            this.on_finish(result)
        },
        show_media_lib() {
            this.media_lib_active = true
        },
        async create_assets(files) {
            await Promise.all(files.map(async (file, index) => {
                this.active_uploads.push({
                    progress: 0,
                    url: URL.createObjectURL(file),
                    type: file.type.split('/')[0]
                })
                const asset = await studioStoreApi.upload_file(file, {
                    onUploadProgress: progressEvent => {
                        const { loaded, total } = progressEvent;
                        const percentage = Math.floor((loaded * 100) / total)
                        this.active_uploads[index].progress = percentage
                    }
                })
            }))
            this.active_uploads = []
        },
        async add_asset(row) {
            const result = await StudioApi.create_feed_row(this.feed.complex_identifier, { data: row })
            return result
        },
        async finish_asset_pick() {
            this.media_lib_active = false
            await Promise.all(this.selected_assets.map(async asset => await this.add_asset(asset)))
            this.selected_assets = []
        },
        async select_asset(asset) {
            const result = await this.add_asset(asset)
            this.selected_assets = [...this.selected_assets, result.data]
        },
        remove_selected_asset(asset, indice) {
            this.selected_assets.splice(indice, 1)
        },
        async delete_feed_row(row) {
            const result = await StudioApi.delete_feed_row(this.feed.complex_identifier, row.data['adflow_row_id'])
            this.selected_assets = this.selected_assets.filter(r => r['adflow_row_id'] != row.data['adflow_row_id'])
            if (this.selected_assets.length === 0)
                this.step = 1
        },
        async extract_images() {
            const result = await api.extract_images({ campaign_briefing: this.campaign_briefing, url: this.extraction_url })
            if (result.status === true)
                this.extracting = true
        },
    }
}
</script>