<template>
  <modal :name="`focuspickermodal`"
         @closed="on_close"
         height="auto" width="70%" style="position: absolute;">
    <div style="display: flex;">
      <div style="width: 20%; padding: 20px;">
        <b>Focus</b><br><br>
        <div>
          <img :id="`focuspicker`" :src="url" style="width: 100%;"/>
        </div>
      </div>
      <div style="width: 20%; height: 400px; background-size: cover;"
            :style="focus_picker_styles">
      </div>
      <div style="width: 20%; height: 250px; background-size: cover;"
            :style="focus_picker_styles">
      </div>
      <div style="width: 30%; height: 150px; background-size: cover;"
            :style="focus_picker_styles">
      </div>
    </div>
  </modal>
</template>

<script>
import {FocusPicker} from "image-focus"
import { debounce } from "debounce"
export default  {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    on_pick:{
      type: Function,
      required: true
    },
    on_close: {
      type: Function,
      required: true
    }
  },
  data(){
    return {
      local_url: null,
    }
  },
  mounted(){
    this.local_url = this.url
  },
  watch: {
    show:{
      immediate: true,
      handler(){
        if(this.show){
          setTimeout(()=>{
            this.$modal.show(`focuspickermodal`)
            this.open_img_focus()
          }, 20)
        } else {
          this.$modal.hide(`focuspickermodal`)
        }
      }
    }
  },
  computed: {
    focus_picker_styles(){
      return {
        'background-image': `url(${this.url})`,
        'background-position': this.background_position
      }
    },
    background_position(){
      let y = Math.abs(this.focus_points.y) / 2 * 100
      let x = Math.abs(this.focus_points.x) / 2 * 100
      if(this.focus_points.y < 0){
        y += 50
      } else {
        y = 50 - Math.abs(this.focus_points.y) * 50
      }
      if(this.focus_points.x > 0){
        x += 50
      } else {
        x = 50 - Math.abs(this.focus_points.x) * 50
      }
      return `${x}% ${y}%`
    },
    focus_points(){
      try {
        let link = new URL(this.local_url)
        let focus_x = link.searchParams.get('focus_x')
        let focus_y = link.searchParams.get('focus_y')
        return {x: focus_x, y: focus_y}
      } catch(e){
        return {
          x: 0,
          y: 0
        }
      }
    }
  },
  methods: {
    open_img_focus(){
      if(this.url){
        let url = new URL(this.url)
        setTimeout(()=>{
          const focusPickerEl = document.getElementById(`focuspicker`)
          const focusPicker = new FocusPicker(focusPickerEl, {
            onChange: focus => {
              url.searchParams.set('focus_y', focus.y)
              url.searchParams.set('focus_x', focus.x)
              this.update(url.toString())
            },
            focus: this.get_focus_points(),
          })
        }, 50)
      }
    },
    update(data){
      this.local_url = data
      this.onpck(data)
    },
    onpck: debounce(function(data){
      this.on_pick(data)
    }, 500),
    get_focus_points(){
      let url = new URL(this.url)
      let obj = {x: url.searchParams.get('focus_x') || 0, y: url.searchParams.get('focus_y') || 0}
      return obj
    },
  }
}
</script>
