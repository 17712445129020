import _ from 'lodash'
import {
  IMPORT, UPDATE, DELETE, CREATE, INIT, PALETTE
} from '../../modules/mutation_types'
import { historyStore } from './history'

export default {
  factory(){
    return (store) => {
      // called when the store is initialized
      const init_check = {
        variants: false,
        layers: false,
        feeds: false
      }

      const debouncer = {}

      let initial_state_set = false
      store.subscribe((mutation, state) => {
        if (mutation.type.includes(INIT)) {
          const t = mutation.type.split('/')[0]
          if (t) init_check[t] = true
        }

        if (initial_state_set === false && Object.values(init_check).every(Boolean)){
          historyStore.init(store)
          const first_state = _.cloneDeep(store.state)
          historyStore.addState({
            // feeds: {
            //   ...first_state.feeds,
            //   sampling: false
            // },
            variants: first_state.variants,
            layers: {
              ...first_state.layers,
              active: null
            },
            mutation: mutation.type
          })
          initial_state_set = true
        }

        const allowed_types = [UPDATE, DELETE, CREATE, PALETTE]

        if (mutation.type === IMPORT
           || (allowed_types.includes(mutation.type.split('/')[1])
           && (debouncer[mutation.type]
             ? (this.timestamp() - debouncer[mutation.type] >= 0.5) : true))){
          debouncer[mutation.type] = this.timestamp()
          historyStore.addState({
            // feeds: state.feeds,
            variants: state.variants,
            layers: {
              ...state.layers,
              active: null
            },
            mutation: mutation.type
          })
        }
      })
    }
  },
  timestamp(){
    return Date.now() / 1000
  },
  get_object(mutation_type, payload, state){
    if (mutation_type.includes('layers')){
      return state.layers.list.find((l) => l.id === payload.id)
    }
    if (mutation_type.includes('variants')){
      return state.variants.list.find((l) => l.id === payload.id)
    }
    return {}
  }
}
