import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class Texter extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })
  }

  name() {
    return 'Texter'
  }

  cleanup() {
    setTimeout(() => {
      $(`.texter_element_${this.uid}_${this.layer.id}`).css('opacity', 1)
    }, 15)
  }

  static stand_alone() {
    return true
  }

  animation() {
    let n_texters = 10
    if (this.layer.config.texter) n_texters = this.layer.config.texter.amount
    const texter_delay = this.duration / n_texters
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
      loop: true,
      direction: 'alternate',
    })
    timeline.add({
      targets: `.texter_element_${this.uid}_${this.layer.id}`,
      opacity: [0, 1],
      duration: texter_delay / 3,
      delay(el, i, l) { // eslint-disable-line no-unused-vars
        return (i * texter_delay) / 3;
      },
    })
    return timeline
  }
}
