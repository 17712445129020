<template>
  <div>
    <TextConfig :layer="layer"
                :fonts="fonts"
                :mediaFolder="mediaFolder"
                :onUpdate="onUpdate"
                :palette="palette"
                :inputName="'Product score'"/>

    <!-- MAG WEG                
    <div  class="layer-config__essentials">
      <div class="form-group">
        <label>Product score scale</label>
        <div class="tweener">
          <input type="text" disabled value="X" style="min-width: 30%; width: 35%">
          <div style="padding: 8px">Out of </div>
          <input type="number"
                style="min-width: 30%; width: 35%"
                :value="layer.config['product_score_limit']"
                v-on:input="e => updateLayerConfig({product_score_limit: e.target.value})">
        </div>
      </div>

      
      <div class="form-group">
        <label>Show number of reviews</label>
        <div class="button-group--select">
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              product_score_show_n_reviews: true,
            })
          }">
            <input type="radio" name="product_score_show_n_reviews"
                   :checked="layer.config['product_score_show_n_reviews'] == true">
            <label><span>Show</span></label>
          </div>
          <div class="button--select button--select-half-width" v-on:click="(e)=>{
            updateLayerConfig({
              product_score_show_n_reviews: false,
            })
          }">
            <input type="radio" name="product_score_show_n_reviews"
                   :checked="layer.config['product_score_show_n_reviews'] == false || layer.config['product_score_show_n_reviews'] == 'false'">
            <label><span>Hide</span></label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Custom score symbol</label>
        <UrlUpload
          name="product_score_symbol"
          :media_folder="mediaFolder"
          element_key="data"
          :element="{data: layer.config.product_score_symbol}"
          :on_change="url => updateLayerConfig({product_score_symbol: url})"
        />
      </div>
      
    </div>
    -->

  </div>
</template>

<script>
import UrlUpload from '../../feeds/fields/audio-video.vue'
import TextConfig from './text.vue'
export default  {
  props: ['layer', 'fonts', 'mediaFolder', 'onUpdate', 'palette'],
  components: {TextConfig, UrlUpload},
  data(){
    return {

    }
  },
  methods: {
    log(abc){
      console.log(abc)
    },
    updateLayerConfig(conf){
      this.updateLayer('config', {...this.layer.config, ...conf})
    },
    updateLayer(key, value){
      const layer = {...this.layer, [key]: value}
      this.$props.onUpdate(layer)
    },
  }
}

</script>
