import api from '../../api/expression_api'
import {
  SET_SUGGESTIONS,
  SET_SEARCH_RESULTS,
  SET_AUDIENCE_REACH,
  SET_AUDIENCE_ID,
  SET_AUDIENCE
} from '../../mutation-types/facebook_mutations'

export default {

  async construct({ commit }, { audienceId }){
    const result = await api.getAudience(audienceId)
    commit(SET_AUDIENCE_ID, audienceId)
    commit(SET_AUDIENCE, result.targets)
    commit(SET_AUDIENCE_REACH, result.reach)
  },

  async update({ commit, state }){
    const result = await api.updateAudience(state)
    commit(SET_AUDIENCE, result.targets)
    commit(SET_AUDIENCE_REACH, result.reach)
  },

  async search({ commit, state }, query){
    const result = await api.searchFacebookInterests({ id: state.id, query })
    commit(SET_SEARCH_RESULTS, result.targets)
  },

  async getReach({ state, commit }){
    const result = await api.facebookAudienceReach({ id: state.id })
    commit(SET_AUDIENCE_REACH, result)
  },

  async getSuggestions({ state, commit }){
    const result = await api.getFacebookInterestSuggestions(state)
    commit(SET_SUGGESTIONS, result.targets)
  }

}
