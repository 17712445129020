<template>
  <div>

  </div>
</template>

<script>
export default  {
  props: ['layer', 'samples', 'disabled'],
  mounted(){

  },
  computed: {

  },
  methods: {

  }
}
</script>
