<template>
  <div class="layer-config__collabse">
    <div class="layer-config__collabse-label" @click="handle_label_click">
      <label :style="{ color: `${disabled ? '#CCC' : ''}` }">{{ label }}</label>
      <div class="layer-config__collabse-actions">
        <i @click.stop="on_remove" v-if="on_remove" class="fa-regular fa-trash"></i>
        <i v-if="on_add" @click.stop="on_add" class="fa-regular fa-plus"></i>
        <button type="button" class="button__toggle__backplated" v-if="unlinkable" @click.stop="on_link_toggle">
          <i v-if="unlinked" class="fa-regular fa-unlink" aria-hidden="true"></i>
          <i v-else class="fa-regular fa-link" aria-hidden="true"></i>
          <span v-if="unlinked" class="tooltip tooltip--top">Attach style</span>
          <span v-else class="tooltip tooltip--top">Detach style</span>
        </button>
        <label class="switch" v-if="toggleable">
          <input type="checkbox" v-model="checked" @click.stop="toggle_switch">
          <span class="slider round"></span>
        </label>
        <div style="min-width: 16px; max-width: 16px;">
          <i class="fa-regular" :class="{ 'fa-chevron-right': !show, 'fa-chevron-down': !!show }"></i>
        </div>
      </div>
    </div>
    <transition name="image-editor__slide-td">
      <div v-if="show">
        <div class="layer-config__collabse-body">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      checked: false
    };
  },
  props: {
    on_remove: {
      type: Function,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    on_toggle: {
      type: Function,
      default: null
    },
    toggled: {
      type: Boolean,
      default: false
    },
    on_add: {
      type: Function,
      default: null
    },
    open: {
      type: Boolean,
      default: false
    },
    onchange: {
      type: Function,
      default: null
    },
    toggleable: {
      type: Boolean,
      default: true
    },
    unlinkable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    on_link_toggle: {
      type: Function,
      default: () => {}
    },
    unlinked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle_switch() {
      this.checked = !this.checked;
      if (this.on_toggle) {
        this.on_toggle(this.checked);
      }
      this.show = this.checked;
      if (this.onchange) {
        this.onchange(this.show);
      }
    },
    handle_label_click(event) {
      if (event.target.closest('.switch')) {
        return;
      }
      if (this.toggleable && !this.checked) {
        this.toggle_switch();
      } else {
        this.toggle_collapse();
      }
    },
    toggle_collapse() {
      if (!this.disabled) {
        this.show = !this.show;
        if (this.onchange) {
          this.onchange(this.show);
        }
      }
    }
  },
  watch: {
    toggled: {
      immediate: true,
      handler(newVal) {
        this.checked = newVal;
      }
    },
    open: {
      immediate: true,
      handler(newVal) {
        this.show = newVal;
      }
    }
  }
};
</script>