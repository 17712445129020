<template>
    <div v-if="componentConfig">

        <div class="form-group" v-if="option.type === 'number'">
            <label v-if="!option.hide_label">{{ option.name }}</label>
            <input type="number" v-on:input="e => updateLayerComponentConfig({ [option.key]: e.target.value })"
                :value="value">

            <LinkButton :property="option.key" :linked="isLinked(option.key)"
                :on_link_toggle="(property) => toggleLink(property)" />
        </div>

        <div v-if="option.type === 'text'">
            <div class="form-group">
                <label v-if="!option.hide_label">{{ option.name }}</label>
                <input type="text" v-on:input="e => updateLayerComponentConfig({ [option.key]: e.target.value })"
                    :value="value">
            </div>
        </div>

        <div v-if="option.type === 'textArea'">
            <div class="form-group">
                <label v-if="!option.hide_label">{{ option.name }}</label>

                <textarea rows="4" v-on:input="e => updateLayerComponentConfig({ [option.key]: e.target.value })"
                    :value="value"></textarea>
            </div>
        </div>

        <div v-if="option.type === 'draggableListWithTextAndIcon'">
            <draggable v-model="listItems" style="max-height: 200px; overflow-y:scroll;" animation="110"
                easing="cubic-bezier(1, 0, 0, 1)">
                <div v-for="(item, index) in listItems" :key="`${index}_list_item`" class="editor__layer-item"
                    style="display: flex; justify-content: center; align-items: center; ">
                    <input v-model="item.text" type="text">

                    <div style="margin-left: 6px;">
                        <i class="icon-delete image-editor__sidebar-layer-config-delete"
                            @click="e => listItems.splice(index, 1)"></i>
                    </div>
                </div>
            </draggable>

            <div class="form-group">
                <div class="tweener" style="gap: 8px;">
                    <input v-model="new_item.text" type="text" @keyup.enter="addListItem">
                    <button class="button__toggle" @click="addListItem">+</button>
                </div>
            </div>

        </div>

        <div class="form-group" v-if="option.type === 'assetPicker'">
            <label v-if="!option.hide_label">{{ option.name }}</label>
            <UrlUpload :name="option.name" :media_folder="mediaFolder" :element_key="option.key"
                style="border-radius: 4px; overflow: hidden;"
                :display="option.display === 'simple' ? 'simple' : 'block'" :element="{ ...componentConfig }"
                :on_change="(url) => updateLayerComponentConfig({ [option.key]: url })" />
        </div>

        <div class="form-group" v-if="option.type === 'buttonGroup'">
            <label v-if="!option.hide_label">{{ option.name }}</label>
            <div style="display: flex;">
                <div class="form_group__detach">
                    <div class="button__tab_group">
                        <button class="button__toggle__light" v-for="button in option.options"
                            :class="{ 'button__toggle--active': componentConfig[option.key] == button.value }"
                            @click="e => updateLayerComponentConfig({ [option.key]: button.value })">
                            <span v-if="button.type === 'text'">{{ button.name }}</span>
                            <div v-if="button.type === 'image'"
                                :style="{ width: '25px', height: '25px', backgroundImage: `url(${button.icon})` }"
                                class="button__image"></div>
                            <i v-if="button.type === 'icon'" :class="button.icon"></i>
                        </button>
                    </div>
                </div>

                <LinkButton :property="option.key" :linked="isLinked(option.key)"
                    :on_link_toggle="(property) => toggleLink(property)" />
            </div>

        </div>

        <div class="form-group" v-if="option.type === 'toggle'">
            <label>{{ option.name }}</label>
            <label class="switch">
                <input type="checkbox" :checked="componentConfig[option.key]" @click="e => {
                    updateLayerComponentConfig({ [option.key]: !componentConfig[option.key] })
                }">
                <span class="slider round"></span>
            </label>
        </div>

        <div class="form-group" v-if="option.type === 'color'">
            <label v-if="!option.hide_label">{{ option.name }}</label>
            <ColorField :show_dynamic="true" :element="color" :element_key="'color'" :palette="palette" />
            <LinkButton :property="option.key" :linked="isLinked(option.key)"
                :on_link_toggle="(property) => toggleLink(property)" />
        </div>

        <div class="form-group" v-if="option.type === 'slider'">
            <label v-if="!option.hide_label">{{ option.name }}</label>
            <div class="tweener">
                <input type="range" :step="option.step" :min="option.min" :max="option.max" style="width: 65%"
                    :value="value"
                    v-on:input="(e) => { updateLayerComponentConfig({ [option.key]: parseInt(e.target.value) }) }">
                <input type="number" style="min-width: 17%; width: 17%" :value="value"
                    v-on:input="(e) => { updateLayerComponentConfig({ [option.key]: parseInt(e.target.value, 10) }) }">
                <LinkButton :property="option.key" :linked="isLinked(option.key)"
                    :on_link_toggle="(property) => toggleLink(property)" />
            </div>

        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import UrlUpload from '../../../feeds/fields/audio-video.vue'
import ColorField from '../../../feeds/fields/color.vue'
import LinkButton from './link_button.vue'
import LayerModel from '../../store/modules/layers/model'
export default {
    components: {
        UrlUpload,
        draggable,
        ColorField,
        LinkButton
    },
    props: {
        layer: {
            type: Object,
            required: true,
            default: () => ({})
        },
        fonts: {
            type: Array,
            required: true,
            default: () => ([])
        },
        mediaFolder: {
            type: String,
            required: true,
            default: () => ''
        },
        onUpdate: {
            type: Function,
            required: true,
            default: () => () => { }
        },
        palette: {
            type: Array,
            required: true,
            default: () => []
        },
        option: {
            type: Object,
            required: true,
            default: () => ({})
        },
    },
    data() {
        return {
            listItems: [],
            new_item: { text: '', icon: '' },
            color: {
                color: {
                    hex: this.value
                }
            }
        }
    },
    mounted() {
        if (this.option.type === 'draggableListWithTextAndIcon')
            this.listItems = [...this.layer.config[this.componentName].items.map(i => ({ ...i }))]

        if (this.option.type === 'color')
            this.color = { color: { hex: this.value } }
    },
    computed: {
        componentConfig() {
            return this.layer.config[this.componentName]
        },
        componentName() {
            return this.layer.config.component.type
        },
        value() {
            if (this.option.key.includes('root.')) {
                return this.layer[this.option.key.split('root.')[1]]
            }
            if (this.option.key.includes('config.')) {
                const key = this.option.key.split('config.')[1]
                return this.layer.config[key]
            }
            return this.layer.config[this.componentName][this.option.key]
        }
    },
    watch: {
        color: {
            deep: true,
            handler(newVal) {
                this.updateLayerComponentConfig({ [this.option.key]: newVal.color.hex })
            }
        },
        listItems: {
            deep: true,
            handler(newval) {
                this.updateLayerComponentConfig({ items: newval })
            }
        }
    },
    methods: {
        toggleLink(property) {
            property = `component.${property}`
            let lCopy = LayerModel.factory(this.layer)
            if (lCopy.is_linked(property)) {
                lCopy.unlink(property)
            } else {
                lCopy.link(property)
            }
            this.onUpdate(lCopy)
        },
        isLinked(key) {
            key = `component.${key}`
            if (this.layer) {
                return this.layer.is_linked(key)
            } else {
                return false
            }
        },
        addListItem() {
            this.listItems.push({ ...this.new_item, icon: this.listItems[0].icon })
            this.new_item = { text: '', icon: '' }
        },
        updateLayerComponentConfig(conf) {
            let layer = { ...this.layer }
            let config = { ...this.layer.config }
            Object.keys(conf).forEach(key => {
                if (key.includes('root.')) {
                    layer[key.split('root.')[1]] = conf[key]
                    delete conf[key]
                    return
                }
                if (key.includes('config.')) {
                    const k = key.split('config.')[1]
                    config[k] = conf[key]
                    delete conf[key]
                    return
                }
            })

            let layerConfig = { ...config }
            layerConfig[this.componentName] = { ...layerConfig[this.componentName], ...conf }
            layer = { ...layer, config: { ...layerConfig } }

            this.onUpdate(layer)
        }
    }
}
</script>