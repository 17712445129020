<template>
  <VueResizable class="studio__scene-card-holder" :width="disabled ? 100 : scene_width_based_on_duration"
    :active="is_video ? ['r'] : []" :minWidth="55" :maxWidth="800" :fitParent="false" @resize:move="on_resize"
    @resize:end="on_resize_end">
    <div class="studio__scene-card-holder" :style="`width: ${disabled ? '100px' : resizeable_width}px`" ref="scene">

      <Dropdown v-if="!disabled" :active="show_dropdown" :root="true" :y_offset="briefing.version === 1 ? -150 : -60"
        :x_offset="-190" :pos="'top_right_abs'" :on_toggle="state => show_dropdown = state">
        <template slot="btn" slot-scope="{is_active}">
          <div class="canvas__button" style="top: 15px; right: 15px; visibility: visible;">
            <i class="fa-regular fa-ellipsis-h"></i>
          </div>
        </template>
        <div class="dropdown__container_vue" style="width: 230px;">
          <ul style="padding: 0px; margin: 0px;">

            <li v-if="briefing.version == 1" class="dropdown__option" v-on:click="e => toggle_template_save(true)">
              <a class="dropdown__anchor">
                <div class="dropdown__container_vue__icon">
                  <i class="fa-regular fa-save"></i>
                </div>
                <span>
                  Save to my templates
                </span>
              </a>
            </li>

            <li class="dropdown__option" v-on:click="edit" v-if="briefing.version == 1">
              <a class="dropdown__anchor">
                <div class="dropdown__container_vue__icon">
                  <i class="fa-regular fa-pen"></i>
                </div>
                <span>
                  Edit
                </span>
              </a>
            </li>
            <li class="dropdown__option" v-on:click="destroy">
              <a class="dropdown__anchor">
                <div class="dropdown__container_vue__icon">
                  <i class="fa-regular fa-trash"></i>
                </div>
                <span>
                  Delete
                </span>
              </a>
            </li>
          </ul>
        </div>
      </Dropdown>

      <modal :name="`save_scene_as_template_${scene.id}`" :focusTrap="true" :shiftY="0.3"
        :clickToClose="!creating_template" height="auto" width="30%">
        <div>
          <div class="modal__body" style="padding: 20px;">
            <h3>Enter a name for your new template</h3>
            <input type="text" :disabled="creating_template" v-model="scene_template_create_name">
          </div>
          <div class="modal__footer">
            <hr>
            <button :disabled="creating_template" class="button--light"
              v-on:click="e => toggle_template_save(false)">Close</button> &nbsp;
            <button :disabled="creating_template" class="button" v-on:click="e => save_as_template()">
              <span v-if="!creating_template">Create</span>
              <span v-else>
                <div class="loader"></div>
              </span>
            </button>
          </div>
        </div>
      </modal>

      <div class="studio__scene-card" @click="e => disabled === false ? activate() : null"
        :class="{ 'studio__scene-card--active': active_scene ? active_scene.id == scene.id && !disabled : false }"
        :style="`${missing_size ? 'border: 2px solid red' : ''}; ${disabled ? 'height: auto' : ''}; width: 100%; `">

        <div ref="parentContainer" class="studio__scene__preview" style="margin: 0">
          <CanvasPreview v-if="show" :canvas="scene.canvas" :selected="false" :brandkit="brandkit" :fonts="[]"
            :variant="scene.canvas.variants.find(v => v.size.name === active_size)" :on_hover_play="false"
            :ref="`canvas_preview_${scene.canvas.id}`" :animation_complete="() => anim_complete()"
            :min_scene_duration="scene.min_scene_duration" :samples="{ data: scene_data, indice: 0 }" />
        </div>
      </div>
      <div class="studio_scene-card-dragger" v-if="is_video && !disabled">
        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
      </div>
      <div class="studio__scene-card-indicator" v-if="!disabled && is_video">
        {{ readable_duration }}s
      </div>
    </div>
  </VueResizable>
</template>
<script>
import VueResizable from 'vue-resizable'
import CanvasPreview from '../../catalogs/canvas_preview.vue'
import SceneVariant from './scene_variant.vue'
import { mapState } from 'vuex'
import { debounce } from 'debounce'
import Dropdown from '../../components/dropdown.vue'

export default {
  components: { CanvasPreview, SceneVariant, VueResizable, Dropdown },
  props: {
    scene: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: true,
      resizeable_width: 110,
      show_dropdown: false,
      scene_template_create_name: "",
      creating_template: false
    }
  },
  mounted() {
    this.resizeable_width = this.scene_width_based_on_duration
  },
  watch: {
    active_scene: {
      deep: true,
      handler(newval, oldval) {
        if (newval && newval.id == this.scene.id) {
          this.resizeable_width = newval.min_scene_duration / 2000 * 110
        }
      }
    }
  },
  computed: {
    ...mapState({
      size_hover: state => state.size_hover,
      active_size: state => state.size,
      brandkit: state => state.brandkit,
      briefing: state => state.briefing,
      active_scene: state => state.active_scene,
      scene_context: state => state.scene_context,
      size: state => state.size,
      animation_controller_map: state => state.animation_controller_map
    }),
    is_video() {
      return this.briefing.type !== 'ImageBriefing'
    },
    readable_duration() {
      return (this.scene.min_scene_duration / 1000).toFixed(2)
    },
    briefing_meta() {
      return this.$store.getters.briefing_meta
    },
    variant_scenes() {
      return this.$store.getters.variant_scenes.filter(s => s.parent_id == this.scene.id)
    },
    scenes_missing_sizes() {
      return this.$store.getters.scenes_missing_sizes
    },
    scene_width_based_on_duration() {
      return (this.scene.min_scene_duration / 2000) * 110
    },
    scene_data() {
      if (this.scene.canvas && this.scene.canvas.feed && this.scene.canvas.feed.data.length === 0) {
        return [{ brandkit: this.brandkit }]
      } else if (this.scene.canvas && this.scene.canvas.feed) {
        let brandkit = { ...this.brandkit }
        let default_data = this.scene.canvas.feed.data[0].data
        if (default_data.brandkit && default_data.brandkit.logos && default_data.brandkit.logos.length > 0 && default_data.brandkit.logos.every(l => !!l)) {
          brandkit.logos = default_data.brandkit.logos
        }
        return this.scene.canvas.feed.data.map(d => ({ ...d['data'], brandkit }))
      } else {
        return []
      }
    },
    missing_size() {
      if (this.size_hover && this.scenes_missing_sizes[this.size_hover])
        return this.scenes_missing_sizes[this.size_hover].includes(this.scene.id)
      return !this.scene.canvas.variants.find(v => v.size.name === this.active_size)
    }
  },
  methods: {
    toggle_template_save(show = false) {
      if (show) {
        this.show_dropdown = false
        this.$modal.show(`save_scene_as_template_${this.scene.id}`)
      } else {
        this.$modal.hide(`save_scene_as_template_${this.scene.id}`)
        this.creating_template = false
        this.scene_template_create_name = ""
      }
    },
    save_as_template() {
      this.creating_template = true
      this.$store.dispatch('create_template_from_scene', {
        scene: this.scene,
        name: this.scene_template_create_name,
        callback: () => {
          this.toggle_template_save(false)
          this.$store.dispatch('SET_SHOW_TEMPLATE_PICKER', true)
        }
      })
    },
    on_resize(e) {
      this.resizeable_width = e.width
      this.$store.commit('UPDATE_SCENE_DURATION', { ...this.scene, min_scene_duration: parseInt((e.width / 110) * 2000, 10) })
    },
    on_resize_end(e) {
      let new_scene_duration = (e.width / 110) * 2000
      let duration = parseInt(new_scene_duration, 10)
      this.update_briefing_canvas('min_scene_duration', duration)
      this.animation_controller_map[this.scene.canvas.id].reset(duration)
    },
    update_briefing_canvas: debounce(function (key, value) {
      this.$store.dispatch('update_briefing_canvas', { ...this.scene, [key]: value })
    }, 500),
    edit() {
      this.show_dropdown = false
      window.location.href = `/designs/${this.scene.canvas.id}/edit?c_size=${this.missing_size ? [this.active_size] : ''}&redirect=/briefings/${this.briefing.id}&duration=${this.scene.min_scene_duration}&size=${this.size}`
    },
    duration() {
      return this.$refs[`canvas_preview_${this.scene.canvas.id}`].duration()
    },
    anim_complete() {
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 1)
    },
    activate() {
      // if(this.active_scene && this.active_scene.id == this.scene.id) {
      //   this.$store.commit('ACTIVATE_SCENE', null)
      // } else {
      if (this.scene_context.id !== this.scene.id)
        this.$store.commit('SET_SCENE_CONTEXT', { ...this.scene })

      this.$store.dispatch('ACTIVATE_SCENE', { ...this.scene, ref: this.$refs.scene })
      this.$store.commit('SET_SHOW_SIZE_SELECTOR', false)
      this.$store.dispatch('SET_SHOW_TEMPLATE_PICKER', false)
      // }
    },
    destroy() {
      this.show_dropdown = false
      this.$store.dispatch('delete_design', this.scene)
    }
  }
}
</script>
