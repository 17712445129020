var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ color: _vm.layer.color, 'font-size': _vm.layer.fontSize + 'px', display: 'flex', gap: '10px' })},_vm._l((_vm.range),function(i,index){return _c('div',{key:i,class:("review-star-" + _vm.animation_uid),staticStyle:{"position":"relative"},style:({ width: _vm.layer.fontSize + 'px', height: _vm.layer.fontSize + 'px' })},[_c('div',{staticStyle:{"position":"absolute","filter":"grayscale(100%)","display":"flex"}},[(!_vm.score_symbol)?_c('i',{staticClass:"fa-sharp fa-solid fa-star",attrs:{"aria-hidden":"true"}}):_c('div',{style:({
  width: _vm.size + 'px',
  height: _vm.size + 'px',
  'filter': 'grayscale(100%)',
  'background-size': 'contain',
  'background-repeat': 'no-repeat',
  'background-image': ("url(" + _vm.score_symbol + ")")
})})]),_vm._v(" "),(_vm.ends_with_half && _vm.score && (index == parseInt(_vm.score)))?_c('div',{staticStyle:{"position":"absolute","display":"flex"}},[(!_vm.score_symbol)?_c('i',{staticClass:"fa-sharp fa-solid fa-star-half",attrs:{"aria-hidden":"true"}}):_c('div',{style:({
  width: _vm.size / 2 + 'px',
  height: _vm.size + 'px',
  'background-size': 'contain',
  'background-repeat': 'no-repeat',
  'background-size': ((_vm.layer.fontSize) + "px " + (_vm.layer.fontSize) + "px"),
  'background-image': ("url(" + _vm.score_symbol + ")")
})})]):(i < _vm.score)?_c('div',{staticStyle:{"position":"absolute","display":"flex"}},[(!_vm.score_symbol)?_c('i',{staticClass:"fa-sharp fa-solid fa-star",attrs:{"aria-hidden":"true"}}):_c('div',{style:({
  width: _vm.size + 'px',
  height: _vm.size + 'px',
  'background-size': 'contain',
  'background-repeat': 'no-repeat',
  'background-image': ("url(" + _vm.score_symbol + ")")
})})]):_vm._e()])}),0)}
var staticRenderFns = []
export { render, staticRenderFns }