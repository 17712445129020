<template>
    <div>
        <div @click="on_select(group)"
            class="editor__layer-item"
            :class="getActiveClass(group)">
            <div class="editor__layer-item__icon editor__layer-item__icon--active" :class="icon(group)"></div>
            <div class="editor__layer-item__name">
                {{ group.config.name }} 
            </div>
            <div class="editor__layer-item__visability_icon">
                <i class="fa-regular fa-eye"></i>
            </div>
        </div>
        <draggable v-model="l_layers"
              animation="110"
              group="layers"
              easing="cubic-bezier(1, 0, 0, 1)"
              style="width: 100%; min-height: 10px; background: #CCC;"
              @change="on_change">
            <div @click="on_select(layer)"
                class="editor__layer-item"
                v-for="layer in layers"
                :key="layer.id"
                :class="getActiveClass(layer)" 
                :style="layer.config.group_id ? { 'margin-left': '20px' } : {}">
                <div class="editor__layer-item__icon editor__layer-item__icon--active" :class="icon(layer)"></div>
                <div class="editor__layer-item__name">{{layer.text || `Layer ${layer.id}`}}</div>
            </div>
        </draggable>
    </div>
</template>

<script>
import {icon} from './helpers'
import draggable from 'vuedraggable'
export default {
    props: {
        group: {type: Object, required: true},
        layers: { type: Array, required: true },
        on_select: {type: Function, required: true},
        on_layer_update:{type: Function, required: true},
        activeLayer: {required: false, type: Object},
        on_order_change: {type: Function, required: true}
    },
    components: {draggable},
    data(){
        return {
            l_layers: []
        }
    },
    watch: {
        layers: {
            deep: true,
            immediate: true,
            handler(newval){
                this.l_layers = newval.sort((a, b)=>{
                    return (a.position > b.position) ? 1 : -1
                })
            }
        }
    },
    methods: {
        on_change(e){
            if(e.added){
                if(e.added.element){
                    this.on_layer_update({...e.added.element, position: e.added.newIndex, config: {
                        ...e.added.element.config,
                        group_id: this.group.id,
                    }})
                }
            }else if(e.removed){
                if(e.removed.element){
                    this.on_layer_update({...e.removed.element, config: {
                        ...e.removed.element.config,
                        group_id: null
                    }})
                }
            } else {
                this.l_layers.forEach((layer, index)=>{
                    this.on_layer_update({...layer, position: index})
                })
            }
        },
        icon(layer){
          return icon(layer)
        },
        getActiveClass(layer){
            if(this.activeLayer){
                return this.activeLayer.id == layer.id ? 'editor__layer-item--active' : ''
            } else {
                return ''
            }
        },
    }
}
</script>