import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class StickerPricetagCircle extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Price tag'
  }

  labels() {
    return ['sticker']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        [
          {
            type: 'text',
            name: 'Top text',
            key: 'top_text',
          },
          {
            type: 'toggle',
            name: 'strike',
            key: 'line_through',
          },
          {
            type: 'color',
            name: 'color',
            key: 'top_text_color',
          },
        ],
        [
          {
            type: 'text',
            name: 'Text',
            hide_label: false,
            key: 'root.text',
          },
          {
            type: 'color',
            name: 'color',
            key: 'root.color',
          },
        ],
        [
          {
            type: 'text',
            name: 'Text label',
            key: 'label',
          },
          {
            type: 'color',
            name: 'color',
            key: 'label_color',
          },
        ],
        {
          type: 'slider',
          name: 'Text size',
          key: 'root.fontSize',
          min: 0,
          max: 300,
          step: 1,
        },
        {
          type: 'buttonGroup',
          name: 'Shape',
          hide_label: false,
          key: 'shape',
          options: [
            {
              type: 'text',
              name: 'Circle',
              value: 'circle',
            },
            {
              type: 'text',
              name: 'Wavy',
              value: 'wavy',
            },
            {
              type: 'text',
              name: 'Spikey',
              value: 'spikey',
            }
          ]
        },
        [
          {
            type: 'color',
            name: 'Bg',
            key: 'bg_color',
          },
          {
            type: 'toggle',
            name: 'Show',
            key: 'show_bg',
          },
        ],
        [
          {
            type: 'color',
            name: 'Border',
            key: 'bg_border_color',
          },
          {
            type: 'toggle',
            name: 'Show',
            key: 'show_border',
          },
        ],
        {
          type: 'slider',
          name: 'Border width',
          key: 'border_width',
          min: 1,
          max: 50,
          step: 1,
        },
        [
          {
            type: 'buttonGroup',
            name: 'Label align',
            hide_label: false,
            key: 'h_align',
            options: [
              {
                type: 'icon',
                name: 'Left',
                icon: 'fa-regular fa-arrow-left',
                value: 'row',
              },
              {
                type: 'icon',
                name: 'Right',
                icon: 'fa-regular fa-arrow-right',
                value: 'row-reverse',
              },
            ]
          },
          {
            type: 'buttonGroup',
            name: 'vert',
            hide_label: false,
            key: 'v_align',
            options: [
              {
                type: 'icon',
                name: 'Top',
                icon: 'fa-regular fa-arrow-up',
                value: 'flex-start',
              },
              {
                type: 'icon',
                name: 'Down',
                icon: 'fa-regular fa-arrow-down',
                value: 'flex-end',
              }
            ]
          },
        ],
        {
          type: 'slider',
          name: 'Distance',
          key: 'gap',
          min: 0,
          max: 100,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          fontSize: 165,
          text: '19',
          config: {
            ...baseConfig.config,
            sticker_pricetag_circle: {
              ...baseConfig.config.sticker_pricetag_circle,
              top_text: '39,98', // Standaardwaarde voor 'from' tekst
              top_text_color: '#000000', // Standaardwaarde voor 'from' kleur
              line_through: true, // Default voor doorhalen
              label: '99', // Label voor prijstag
              label_color: '#000000', // Kleur van label
              bg_color: '#ECFF79', // Achtergrondkleur van de shape
              show_bg: true, // Toggle voor het weergeven van achtergrond
              bg_border_color: '#000000', // Randkleur van de shape
              show_border: true, // Toggle voor het weergeven van rand
              border_width: 13, // Standaard breedte van de rand
              shape: 'spikey', // Standaardvorm van de shape
              gap: 0 // Afstand/gap tussen elementen
            }
          },
        }]
      },
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          fontSize: 165,
          color: '#FFFFFF',
          text: '30',
          config: {
            ...baseConfig.config,
            sticker_pricetag_circle: {
              ...baseConfig.config.sticker_pricetag_circle,
              top_text: 'SALE', // Standaardwaarde voor 'from' tekst
              top_text_color: '#FFFFFF', // Standaardwaarde voor 'from' kleur
              line_through: false, // Default voor doorhalen
              label: '%', // Label voor prijstag
              label_color: '#FFFFFF', // Kleur van label\
              bg_color: '#000000', // Achtergrondkleur van de shape
              show_bg: false, // Toggle voor het weergeven van achtergrond
              bg_border_color: '#FFFFFF', // Randkleur van de shape
              show_border: true, // Toggle voor het weergeven van rand
              border_width: 8, // Standaard breedte van de rand
              shape: 'circle', // Standaardvorm van de shape
              gap: 0 // Afstand/gap tussen elementen
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'black',
      align: 'center',
      text: 'SALE',
      width: 200,
      height: 200,
      fontSize: 110,
      background: '#FF4B4B',
      ...this.opts,
      config: {
        v_align: 'center',
        line_height: 1,
        component: {
          type: 'sticker_pricetag_circle',
          name: 'Sticker pricetag circle',
        },
        sticker_pricetag_circle: {
          top_text: '', // Standaardwaarde voor 'from' tekst
          top_text_color: '#000000', // Standaardwaarde voor 'from' kleur
          line_through: false, // Default voor doorhalen
          label: '', // Label voor prijstag
          label_color: '#000000', // Kleur van label
          bg_color: '#FFFFFF', // Achtergrondkleur van de shape
          show_bg: true, // Toggle voor het weergeven van achtergrond
          bg_border_color: '#000000', // Randkleur van de shape
          show_border: true, // Toggle voor het weergeven van rand
          border_width: 10, // Standaard breedte van de rand
          h_align: 'row', // Horizontale uitlijning
          v_align: 'flex-start', // Verticale uitlijning
          shape: 'circle', // Standaardvorm van de shape
          gap: 0 // Afstand/gap tussen elementen
        }
      }
    }
  }

  config() {
    return {
      background: 'white',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
