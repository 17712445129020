<template>
    <component
      :is="component"
      :layer="layer"
      :brandkit="brandkit"
      :samples="samples"
      :on_error="on_error"
      :active="active"
      :animation_uid="animation_uid"
      :disabled="disabled"></component>
  </template>
  
  <script>
  import ImageLayer from './image.vue'
  export default {
    props: ['layer', 'samples', 'disabled', 'active', 'on_error', 'brandkit', 'animation_uid'],
    data(){
      return {
  
      }
    },
    computed: {
      component(){
        return ImageLayer
      }
    }
  }
  </script>
  