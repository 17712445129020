<template>
  <div class="editor__palette">
    <label>Brandkit</label>
    <hr>
    <div class="editor__palette_swatch" v-if="brandkit.palette.length > 0" @click="()=>select_palette(brandkit_palette_complete)">
      <div v-for="(color, color_indice) in brandkit_palette_complete" :key="color_indice">
        <div class="editor__palette_swatchcolor" :style="{background: color}"></div>
      </div>
    </div>
    <div v-else-if="project_id">
      <a target="_blank" :href="`/projects/${project_id}/edit`">
        <button style="width: 100%; color: white;">
          Configure brandkit
        </button>
      </a>
    </div>

    <br/><br/>

    <div>
      Logos
      <hr>
      <div class="thumbs_container">
        <div :key="key"
              style="cursor: pointer;"
              v-on:click="add_layer(image_layer_config(asset, key))"
              v-for="(asset, key) in brandkit.logos">
          <Thumb class="thumb thumb--small" style="background-size: none;" :url="asset"/>
        </div>
      </div>
    </div>

    <br/><br/>

    <div class="tweener">
      <label>Random swatches</label>
      <div style="cursor: pointer;"><i @click="()=>randomize_palettes(5)" class="fa-regular fa-arrow-rotate-right"></i></div>
    </div>

    <hr>
    <div v-for="(palette, index) in palettes" :key="index" class="editor__palette_swatch" @click="()=>select_palette(palette)">
      <div v-for="(color, color_indice) in palette" :key="color_indice">
        <div class="editor__palette_swatchcolor" :style="{background: color}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import chroma from "chroma-js"
import Dropdown from '../components/dropdown.vue'
import Thumb from '../assets/thumb.vue'
import BrandkitLogo from './components/brandkit/logo'

export default {
  props: {
    on_select: {type: Function, required: true, default: ()=>{}},
    project_id: {type: Number, required: true},
    add_layer: {type: Function, required: false, default: ()=>{}},
    selected: {type: Array, required: false, default: ()=>[]},
    brandkit: {type: Object, required: false, default:()=>{
      return {
        colors: []
      }
    }}
  },
  data(){
    return {
      palettes: [],
      active: [...this.brandkit_palette()],
      n_colors: 7
    }
  },
  components: {Dropdown, Thumb},
  mounted(){
    this.randomize_palettes(5)
  },
  computed: {
    brandkit_palette_complete(){
      return this.brandkit_palette(this.$props.brandkit.palette)
    }
  },
  methods: {
    image_layer_config(asset, indice){
      return new BrandkitLogo().config({config: {image_url: `{{brandkit.logos|index:${indice}}}`}})
    },
    filter_assets(filter){
      return this.brandkit.assets.filter(asset => {
        if(asset.feed_element['labels']){
          return asset.feed_element['labels'].includes(filter)
        }
        return false
      })
    },
    select_palette(palette){
      this.active = [...palette]
      this.$props.on_select(palette)
    },
    brandkit_palette(p=this.$props.selected){
      try {
        return chroma.scale(p).mode('lch').colors(this.n_colors)
      } catch (e){
        return []
      }
    },
    randomize_palettes(n=1){
      this.palettes = this.generate_palettes(n)
    },
    generate_palettes(n=1){
      let palettes = []
      for(let i = 0; i<n; i++){
        palettes.push(chroma.scale([chroma.random(),chroma.random()]).mode('lch').colors(this.n_colors))
      }
      return palettes
    }
  }
}
</script>
