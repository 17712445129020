<template>
    <div class="layer-config__essentials">
        <div class="form-group">
            <label>Original price</label>
            <input :value="layer.config.sticker.original_price"
                v-on:input="(e) => { tmp.original_price = e.target.value }" type="number" min="0.00" max="10000.00"
                step="0.01" />
        </div>

        <div class="form-group">
            <label>Sale price</label>
            <input :value="layer.config.sticker.sale_price" v-on:input="(e) => { tmp.sale_price = e.target.value }"
                type="number" min="0.00" max="10000.00" step="0.01" />
        </div>


        <LayerPriceConfig :layer="layer" :fonts="fonts" :onUpdate="onUpdate" :hideText="true" />
    </div>
</template>

<script>
import TextConf from '../text.vue'
import UrlUpload from '../../../feeds/fields/audio-video.vue'
import draggable from 'vuedraggable'
import LayerPriceConfig from '../price.vue'

export default {
    props: ['layer', 'fonts', 'mediaFolder', 'onUpdate', 'inputName', 'palette', 'nightswatch', 'is_studio', 'version'],
    components: { TextConf, draggable, UrlUpload, LayerPriceConfig },
    data() {
        return {
            tmp: {}
        }
    },
    mounted() {
        this.tmp = { ...this.layer.config.sticker }
    },
    watch: {
        tmp: {
            deep: true,
            handler(newVal) {
                this.updateLayerListConfig(newVal)
            }
        }
    },
    methods: {
        updateLayerListConfig(conf) {
            let layerConfig = { ...this.layer.config }
            layerConfig.sticker = { ...layerConfig.sticker, ...conf }
            const layer = { ...this.layer, config: { ...layerConfig } }
            this.onUpdate(layer)
        }
    }
}
</script>