<template>
  <Moveable v-if="!disabled" class="moveable" v-bind="moveable_conf" ref="moveable" :zoom="moveable_conf.zoom"
    @drag="handleDrag" @dragEnd="handleDragEnd" @resizeStart="handleResizeStart" @resize="handleResize"
    @resizeEnd="handleResizeEnd" @rotate="handleRotate" @rotateEnd="handleRotateEnd" @warp="handleWarp"
    @warpEnd="handleWarpEnd" @scale="handleScale" @scaleEnd="handleScaleEnd" @dragGroup="onDragGroup"
    @dragGroupEnd="onDragGroupEnd" @resizeGroupStart="onResizeGroupStart" @resizeGroup="onResizeGroup"
    @resizeGroupEnd="onResizeGroupEnd" @rotateGroup="onRotateGroup" @rotateGroupEnd="onRotateGroupEnd"
    :props="({ contextable: true })" />
</template>

<script>
import Moveable from 'vue-moveable';
import { debounce } from 'lodash';
import { Contextable } from './contextable'
import LayerContextMenu from '../layer_context_menu.vue'
import Vue from 'vue/dist/vue.esm.js' // eslint-disable-line

export default {
  components: { Moveable },
  props: {
    layer: { type: Object, required: true },
    active: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    zoom: { type: Number, required: true },
    on_update: { type: Function, required: true },
    editor_uid: { type: String, required: true },
    on_action_update: { type: Function, required: true },
    is_studio: { type: Boolean, required: false, default: false },
    on_layer_update: { type: Function, required: false, default: (layer) => { } }
  },
  data() {
    return {
      contextable: Contextable,
      vueInstance: null,
      moveable_conf: {
        ables: [this.is_studio ? Contextable : null].filter(Boolean),
        draggable: this.$props.active,
        edgeDraggable: true,
        throttleDrag: 0,
        resizable: this.$props.active && this.$props.layer.layer_type !== 'stencil',
        throttleResize: 0,
        keepRatio: false,
        warpable: false,
        throttleScale: 0,
        rotatable: this.$props.active,
        scalable: this.$props.active && this.$props.layer.layer_type === 'stencil',
        throttleRotate: 0,
        pinchable: false, // ["draggable", "resizable", "scalable", "rotatable"]
        origin: false,
        snappable: true,
        snapCenter: true,
        rotationPosition: 'bottom',
        renderDirections: (
          this.$props.layer.layer_type === 'text_area' ? ["w", "e", "se"] : ["nw", "n", "ne", "w", "e", "sw", "s", "se"]
        ),
        elementGuidelines: [
          `.canvas__layer-text-container--${this.$props.editor_uid}`,
          `.canvas__body-workspace--${this.$props.editor_uid}`
        ],
        snapThreshold: 5,
        isDisplaySnapDigit: true,
        snapGap: true,
        snapDirections: { "top": true, "right": true, "bottom": true, "left": true, center: true, middle: true },
        elementSnapDirections: { "top": true, "right": true, "bottom": true, "left": true, center: true, middle: true },
        snapDigit: 0,
        target: [`.layer_${this.$props.layer.id}`],
        debouncedUpdateLayer: null
      }
    }
  },
  mounted() {
    this.$store = this.$props.store
    this.$nextTick(() => {
      if (this.moveable)
        this.moveable.updateRect()
    })
    this.debouncedUpdateLayer = debounce(this.update_layer, 7);
    if (this.layer.config['show']) {
      this.visible = this.layer.config['show']
    }
    this.original_text = this.layer.text
    this.x = this.layer.x
    this.y = this.layer.y
    this.width = this.layer.width
    this.height = this.layer.height
  },
  computed: {
    is_disabled() {
      return this.disabled
    },
    moveable() {
      if (this.$refs.moveable) {
        return this.$refs.moveable.$_moveable
      }
    },
    autoScale() {
      return this.layer.layer_type === 'scaled_text' ||
        this.layer.layer_type === 'component' ||
        this.layer.layer_type === 'review' ||
        this.layer.layer_type === 'text_area'
    },
  },
  watch: {
    layer: {
      immediate: true,
      deep: true,
      handler(new_layer, old_layer) {
        if (this.vueInstance) {
          this.vueInstance.activeLayer = new_layer;
          this.vueInstance.zoom = this.zoom;
          this.vueInstance.animation_uid = this.editor_uid;
        }
        if (new_layer && old_layer && new_layer.id !== old_layer.id) {
          this.moveable_conf.target = [`.layer_${new_layer.id}`];
          this.moveable_conf.renderDirections = (
            new_layer.layer_type === 'text_area' ? ["w", "e", "se"] : ["nw", "n", "ne", "w", "e", "sw", "s", "se"]
          );
          this.vueInstance = null;
          // this.$nextTick(() => {
          //   setTimeout(() => {
          //     try {
          //       const layerContextMenu = document.getElementsByTagName('LayerContextMenu');
          //       if (layerContextMenu) {
          //         if (!this.vueInstance) {
          //           this.vueInstance = new Vue({
          //             el: 'LayerContextMenu',
          //             data: {
          //               activeLayer: new_layer,
          //               zoom: this.zoom,
          //               animation_uid: this.editor_uid
          //             },
          //             render: h => h(LayerContextMenu, {
          //               props: {
          //                 activeLayer: this.layer,
          //                 zoom: this.zoom,
          //                 animation_uid: this.animation_uid,
          //                 onUpdate: (layer) => {
          //                   this.on_layer_update(layer);
          //                 }
          //               }
          //             })
          //           });
          //         }
          //       }
          //     } catch (e) {
          //       console.log(e);
          //     }
          //   }, 1)
          // });
        } else {
          if (this.$refs.layerContextMenu) {
            this.$refs.layerContextMenu.$destroy();
          }
        }

        this.transform_str = this.transform(new_layer)
        this.width = new_layer.width
        this.height = new_layer.height
        this.moveable_conf = {
          ...this.moveable_conf,
          elementGuidelines: [
            `.canvas__layer-text-container--${this.$props.editor_uid}`,
            `.canvas__body-workspace--${this.$props.editor_uid}`
          ],
          target: [
            `.layer_${new_layer.id}`
          ]
        }
        setTimeout(() => {
          if (this.moveable)
            this.moveable.updateRect()
          this.$forceUpdate()
        }, 10)
      }
    },
    constraint(newConstraint) {
      this.moveable_conf = {
        ...this.moveable_conf,
        horizontalGuidelines: [0, newConstraint.height / 2, newConstraint.height],
        verticalGuidelines: [0, newConstraint.width / 2, newConstraint.width]
      }
    },
    zoom: {
      immediate: true,
      handler(new_zoom) {
        if (new_zoom < 0.3) {
          this.moveable_conf.zoom = 4
        } else if (new_zoom < 0.6) {
          this.moveable_conf.zoom = 2
        } else if (new_zoom < 0.75) {
          this.moveable_conf.zoom = 1.5
        } else {
          this.moveable_conf.zoom = 1
        }
      }
    },
    active: {
      immediate: true,
      handler(isActive) {
        this.moveable_conf.draggable = isActive
        this.moveable_conf.resizable = isActive && this.layer.layer_type !== 'stencil'
        this.moveable_conf.scalable = isActive && this.layer.layer_type === 'stencil'
        this.moveable_conf.rotatable = isActive
        this.moveable_conf.warpable = false

        if (isActive) {
          $(document).on('keydown', this.key_down_listeners)
          $(document).on('keyup', this.key_up_listeners)
        } else {
          $(document).off('keydown', this.key_down_listeners)
          $(document).off('keyup', this.key_up_listeners)
        }
      },
    }
  },
  methods: {
    update_layer(action, opts) {
      this.on_update(action, opts)
    },

    debouncedUpdateLayer(action, opts) {
      this.debouncedUpdateLayer(action, opts);
    },

    set_moveable_opts(opts) {
      this.moveable_conf = { ...this.moveable_conf, ...opts }
    },

    key_up_listeners(e) {
      if (!e.shiftKey) {
        this.moveable_conf.keepRatio = false
      }
    },

    key_down_listeners(e) {
      try {
        let nudge = 1
        if (e.shiftKey) {
          nudge = 10
          this.moveable_conf.keepRatio = true
        }
        if (e.target.localName != 'input' && e.target.localName != 'textarea') {
          switch (e.keyCode) {
            case 37:
              e.preventDefault();
              this.moveable.request("draggable", { deltaX: -nudge, deltaY: 0 }, true)
              break;
            case 38:
              e.preventDefault();
              this.moveable.request("draggable", { deltaX: 0, deltaY: -nudge }, true)
              break;
            case 39:
              e.preventDefault();
              this.moveable.request("draggable", { deltaX: nudge, deltaY: 0 }, true)
              break;
            case 40:
              e.preventDefault();
              this.moveable.request("draggable", { deltaX: 0, deltaY: nudge }, true)
              break;
          }
        }
      } catch (e) {
        // Catch moveable errors due to race conditions
      }
    },

    transform(layer) {
      if (layer.config.transform)
        return layer.config.transform

      let transform = ''
      if (layer.rotation) {
        transform += `rotate(${layer.rotation}deg)`
      }
      if (layer.translation) {
        transform += `translate(${layer.translation[0]}px, ${layer.translation[1]}px)`
      }
      return transform
    },

    handleDrag(e) {
      e.target.style.transform = e.transform;
    },

    handleDragEnd({ lastEvent }) {
      if (lastEvent) {
        this.debouncedUpdateLayer('layers/translate', {
          transform: lastEvent.transform,
          layer_id: this.layer.id
        })
      }
    },

    handleResizeStart(e) {
      this.on_action_update('resizing', true)
      e.target.style.minHeight = 'unset';
    },

    handleResize(e) {
      // e.direction
      // Use corner drag to resize text size?
      // [1, 1] => right, bottom
      // [-1, -1] => left, top
      // [0, -1] => center, top

      this.width = e.width
      this.height = e.height

      //remove min-height when resizing
      e.target.style.minHeight = 'unset';

      e.target.style.width = `${this.width}px`;
      e.target.style.height = `${this.height}px`;
      e.target.style.transform = e.drag.transform

      this.debouncedUpdateLayer('layers/resize', {
        width: this.width,
        height: this.height,
        transform: e.drag.transform,
        layer_id: this.layer.id,
        auto_scale: e.direction[0] === 1 && e.direction[1] === 1 && this.autoScale
      })
    },
    handleResizeEnd({ lastEvent }) {
      if (lastEvent) {
        this.width = lastEvent.width
        this.height = lastEvent.height
        this.debouncedUpdateLayer('layers/resize', {
          width: lastEvent.width,
          height: lastEvent.height,
          transform: lastEvent.drag.transform,
          layer_id: this.layer.id,
          auto_scale: lastEvent.direction[0] === 1 && lastEvent.direction[1] === 1 && this.autoScale
        })
        this.on_action_update('resizing', false)
        setTimeout(() => {
          lastEvent.target.style.minHeight = lastEvent.height + 'px';
          lastEvent.target.style.height = 'unset';
        }, 1000)
      }
    },
    handleRotate(e) {
      e.target.style.transform = e.drag.transform
    },
    handleRotateEnd({ lastEvent }) {
      if (lastEvent) {
        this.debouncedUpdateLayer('layers/rotate', {
          transform: lastEvent.drag.transform,
          layer_id: this.layer.id
        })
      }
    },
    handleWarp({ target, transform }) {
      target.style.transform = transform;
    },
    handleWarpEnd({ lastEvent }) {
      if (lastEvent) {
        this.debouncedUpdateLayer('layers/warp', {
          transform: lastEvent.transform,
          layer_id: this.layer.id
        })
      }
    },
    handleScale({ target, transform }) {
      target.style.transform = transform
    },
    handleScaleEnd({ lastEvent }) {
      if (lastEvent) {

      }
    },
    onDragGroup({ events }) {
      events.forEach(ev => {
        ev.target.style.transform = ev.transform;
      });
    },
    onDragGroupEnd({ events }) {
      events.forEach(ev => {
        let layerId = ev.target.getAttribute('data-layer-id');
        if (ev.lastEvent) {
          this.debouncedUpdateLayer('layers/translate', {
            transform: ev.lastEvent.transform,
            layer_id: layerId
          })
        }
      })
    },
    onResizeGroupStart({ setMin, setMax }) {
      setMin([this.minWidth, this.minHeight]);
      setMax([this.maxWidth, this.maxHeight]);
    },
    onResizeGroup({ events }) {
      events.forEach(ev => {
        ev.target.style.width = `${ev.width}px`;
        ev.target.style.height = `${ev.height}px`;
        ev.target.style.transform = ev.drag.transform;
      });
    },
    onResizeGroupEnd({ events }) {
      events.forEach(ev => {
        let layerId = ev.target.getAttribute('data-layer-id');
        if (ev.lastEvent) {
          this.debouncedUpdateLayer('layers/resize', {
            width: ev.lastEvent.width,
            height: ev.lastEvent.height,
            transform: ev.lastEvent.drag.transform,
            layer_id: layerId
          })
        }
      });
    },
    onRotateGroup({ events }) {
      events.forEach(ev => {
        ev.target.style.transform = ev.drag.transform;
      });
    },
    onRotateGroupEnd({ events }) {
      events.forEach(ev => {
        let layerId = ev.target.getAttribute('data-layer-id');
        if (ev.lastEvent) {
          this.debouncedUpdateLayer('layers/rotate', {
            transform: ev.lastEvent.drag.transform,
            layer_id: layerId
          })
        }
      });
    }
  }
}
</script>
