import api from '../../api/variants.api'
import Variant from './model'
import { STATE_PICK_VARIANT_SIZE, STATE_DEFAULT } from '../editor/index'

import {
  CREATE, UPDATE, DELETE, INIT, ACTIVATE, CREATE_REMOTE, UPDATE_REMOTE, DELETE_REMOTE, UNDO
} from '../mutation_types'

import Layer from '../layers/model'

export default {
  init({ commit, dispatch }, {
    variants, variant_name = 'default', canvas, active_variant_id
  }) {
    commit(INIT, variants)
    let variant = variants.find((v) => v.name === variant_name)
    const urlParams = new URLSearchParams(window.location.search);
    const size = urlParams.get('size')
    if (size) {
      variant = variants.find((v) => v.size.name === size)
    }
    if (active_variant_id) {
      variant = variants.find((v) => v.id === active_variant_id)
    }
    if (variant) {
      if (variant.size.name === 'sizeless' && canvas.size === 'sizeless') {
        dispatch('set_state', STATE_PICK_VARIANT_SIZE, { root: true })
      }
      dispatch('activate', variant)
    } else {
      dispatch('activate', variants[0])
    }
  },

  async create({
    commit, state, rootGetters, dispatch, rootState
  }, { segment, size, layers = rootGetters['layers/list'] }) {
    const v = Variant.factory({
      segment_id: segment ? segment.id : null,
      id: null,
      // eslint-disable-next-line
      size: size ? (size.key ? size.key : size.name) : rootState.canvas.size,
      name: `${segment ? segment.name : null}`,
      layers: layers.map((l) => Layer.factory({ ...l, id: undefined })),
      persisted: false
    })
    commit(CREATE, v)
    dispatch('layers/add', v.layers, { root: true })
    dispatch(ACTIVATE, state.list[state.list.length - 1])
    dispatch('set_state', STATE_DEFAULT, { root: true })
    return v
  },

  update({
    commit, getters, dispatch, rootState
  }, changes) {
    const updated_variant = { ...getters.active, ...changes, dirty: true }
    if (changes.segment_id && getters.active.segment_id !== changes.segment_id) {
      dispatch('feeds/segment', updated_variant.segment_id, { root: true })
    }

    if (rootState.ui_state === STATE_PICK_VARIANT_SIZE && updated_variant.persisted) {
      dispatch('set_state', STATE_DEFAULT, { root: true })
      dispatch('update_remote', updated_variant)
    } else {
      commit(UPDATE, updated_variant)
      dispatch('set_state', STATE_DEFAULT, { root: true })
    }
  },

  undo({ commit, state }, history) {
    commit(UNDO, history)
    switch (history.type.split('/')[1]) {
      case CREATE:
        commit(ACTIVATE, state.list[state.list.length - 1])
        break;
      default:
        break
    }
  },

  async create_remote({ rootState, commit }, variant) {
    const result = await api.createVariant(rootState.canvas.id, variant)
    commit(CREATE_REMOTE, { old_val: variant, new_val: result })
    return result
  },

  async update_remote({ rootState, commit }, variant) {
    const result = await api.updateVariant(rootState.canvas.id, variant)
    commit(UPDATE_REMOTE, { old_val: variant, new_val: result })
  },

  async delete_remote({ commit, rootState }, variant) {
    const result = await api.deleteVariant(rootState.canvas.id, variant)
    commit(DELETE_REMOTE, { old_val: variant, new_val: result })
  },

  async delete({
    state, commit, dispatch
  }, variant_id) {
    const variant = state.list.find((v) => v.id === variant_id)
    dispatch('layers/remove', variant.layers, { root: true })
    commit(DELETE, state.list.find((v) => v.id === variant_id))
    dispatch(ACTIVATE, state.list[0])
  },

  activate({
    commit, dispatch, rootState
  }, variant) {
    commit(ACTIVATE, variant)
    if (!variant.default && variant.segment_id) {
      dispatch('feeds/segment', variant.segment_id, { root: true })
    } else {
      dispatch('feeds/sample', { feed_id: rootState.feeds.active_feed }, { root: true })
    }
  },
}
