import Model from '../model'
import Layer from '../layers/model'

class Variant extends Model{
  constructor({
    id, name, layers = [], segment_id, segment_name,
    is_static, enabled, is_default, derivative, dirty,
    deleted, image_canvas_id, size
  }){
    super()
    this.id = id || this.guid()
    this.persisted = !isNaN(this.id) // eslint-disable-line
    this.layers = layers.map((l) => Layer.factory({ ...l, image_canvas_variant_id: this.id }))
    this.name = name
    this.segment_id = segment_id
    this.size = size
    this.static = is_static
    this.default = is_default
    this.is_default = is_default
    this.enabled = enabled
    this.derivative = derivative
    this.dirty = dirty || false
    this.deleted = deleted || false
    this.image_canvas_id = image_canvas_id
    this.segment_name = segment_name
  }

  static factory(conf){
    return new Variant({
      name: Model.guid(),
      persisted: false,
      dirty: false,
      deleted: false,
      is_default: false,
      ...conf
    })
  }
}

export default Variant
