<template>
  <div class="editor__headless_menu" :class="{
    'editor__headless_menu--small': !activeLayer && version > 1,
    'editor__headless_menu--old': version === 1,
  }">
    <div class="studio__headless_menu" v-if="activeLayer">
      <LayerConf v-bind:activeLayer="activeLayer" :headless="true" v-bind:fonts="fonts" v-bind:palette="palette"
        :samples="{ data: [], indice: 0 }" v-bind:onUpdate="updateLayer" v-bind:onDelete="deleteLayer"
        v-bind:isVideo="canvas.video" :min_scene_duration="min_scene_duration" :brandkit="brandkit" :version="version"
        :is_studio="true" />
    </div>
    <SizeBar v-if="version > 1" :canvas="canvas" :sizes="sizes" :layers="layers"
      :on_size_select="variant => on_variant_change(variant)" :active_size="active_size" />
  </div>
</template>
<script>
import Collapseable from '../components/collapseable.vue'
import DropShadowConfigurator from '../layer_configs/headless/dropshadows.vue'
import LayerConf from '../layer_config.vue'
import SizeBar from '../sizebar.vue'
export default {
  components: { SizeBar, LayerConf, Collapseable, DropShadowConfigurator },
  props: {
    version: {
      type: Number,
      required: true,
    },
    min_scene_duration: {
      type: Number,
      required: false,
    },
    active_size: {
      type: Object,
      required: false,
    },
    activeLayer: {
      type: Object,
      required: false,
    },
    on_variant_change: {
      type: Function,
      required: true,
    },
    sizes: {
      type: Array,
      required: true,
    },
    canvas: {
      type: Object,
      required: true,
    },
    layers: {
      type: Array,
      required: true,
    },
    brandkit: {
      type: Object,
      required: true,
    },
    updateLayer: {
      type: Function,
      required: true,
    },
    deleteLayer: {
      type: Function,
      required: true,
    },
    palette: {
      type: Array,
      required: true
    },
    fonts: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  data() {
    return {
      comps: [],
      show_dropshadows: false
    }
  },
  mounted() {
  },
  watch: {

  },
  methods: {
    textable() {
      return this.activeLayer.layer_type === 'price' ||
        this.activeLayer.layer_type === 'text' ||
        this.activeLayer.layer_type === 'text_area' ||
        this.activeLayer.layer_type === 'texter'
    },
    close_all_comps() {
      this.comps = this.comps.map(c => {
        c.open = false
        return c
      })
    },
    manage_drop_shadows(state) {
      this.close_all_comps()
      this.show_dropshadows = state
    },
    manage_shown_comps(comp, state) {
      if (state === true)
        this.show_dropshadows = false

      this.comps = this.comps.map(c => {
        if (c.name === comp) {
          c.open = state
        } else {
          c.open = false
        }
        return c
      })
    },
    add_dropshadow() {
      let shadows = this.activeLayer.config.dropshadows ? this.activeLayer.config.dropshadows : []
      this.show_dropshadows = true
      this.updateLayerConfig({
        show_dropshadows: true,
        dropshadows: [...shadows, {
          dropshadow_h_distance: 0,
          dropshadow_v_distance: 2,
          dropshadow_blur: 4,
          dropshadow_color: 'rgba(79, 79, 79, 1)'
        }]
      })
    },
    updateLayerConfig(conf) {
      this.update_conf('config', { ...this.activeLayer.config, ...conf })
    },
    update_conf(key, value) {
      let layer = this.activeLayer
      layer[key] = value
      this.updateLayer(layer)
    },
    transform(layer) {
      const matched = layer.config.transform.match(/translate\((.*)px, (.*)px\)/)
      let expr = matched[0]
      let x = matched[1]
      let y = matched[2]
      if (layer.config.transform) {
        return layer.config.transform.replace(expr, `translate(${parseInt(x, 10) - 200}px, ${parseInt(y, 10) - (40 * (this.comps.length + 1))}px)`)
      }
    },
  }
}
</script>
