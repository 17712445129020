var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.layer.config.list)?_c('div',{staticStyle:{"padding":"12px"}},[_c('div',{style:({ color: _vm.layer.color, display: 'flex', flexDirection: 'column', alignItems: _vm.layer.config.list.align_list, gap: _vm.layer.config.list.list_spacing + 'px' })},_vm._l((_vm.listItems),function(item,index){return _c('div',{key:index,style:({
      fontSize: _vm.layer.fontSize * .9 + 'px',
      display: 'flex',
      background: _vm.listBgColor,
      padding: '10px',
      borderRadius: '10px',
      flexDirection: _vm.iconPosition === 'l' ? 'row' : 'row-reverse',
      gap: _vm.layer.config.list.icon_spacing + 'px',
      alignItems: 'center',
      '--icon-size': (_vm.layer.fontSize * 0.9 * 1.2 * _vm.iconSizeFactor) + 'px' // 120% of font size
    })},[(_vm.iconType !== 'none')?[(_vm.isImage(_vm.icon))?_c('div',{style:({
          minWidth: 'var(--icon-size)',
          minHeight: 'var(--icon-size)',
          backgroundImage: 'url(' + _vm.icon + ')',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        })}):_c('i',{class:_vm.iconType,style:({ fontSize: 'var(--icon-size)', color: _vm.layer.config.list.icon_color })})]:_vm._e(),_vm._v(" "),_c('span',{style:({ fontSize: (_vm.layer.fontSize * .7) + 'px', textWrap: 'wrap', fontFamily: _vm.font }),domProps:{"innerHTML":_vm._s(_vm.parse(item.text))}})],2)}),0)]):_vm._e()}
var staticRenderFns = []
export { render, staticRenderFns }