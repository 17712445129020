import { INIT } from '../mutation_types'
import {
  SAMPLING, NEXT_SAMPLE, SAMPLE, PREVIOUS_SAMPLE, ACTIVATE, INIT_SEGMENTS
} from './mutation_types'

export default {
  [INIT](state, { feeds }){
    state.list = feeds
  },
  [SAMPLING](state, busy){
    state.sampling = busy
  },
  [INIT_SEGMENTS](state, segments){
    state.segments = segments
  },
  [SAMPLE](state, sample){
    state.sample = sample.map((d) => d.data)
    if (state.sample.length > 0){
      state.indice = 0
    } else {
      state.indice = -1
    }
  },
  [ACTIVATE](state, feed_id){
    state.active_feed = feed_id
  },
  [NEXT_SAMPLE](state){
    if (state.indice < state.sample.length - 1) state.indice += 1
  },
  [PREVIOUS_SAMPLE](state){
    if (state.indice > 0) state.indice -= 1
  },
}
