import FadeAnimation from './fade';
import SlideAnimation from './slide';
import PopAnimation from './pop';
import DropAnimation from './drop';
import DoNothing from '../do_nothing'
import Zoom from './zoom';
import Typewriter from '../typewriter';
import ZoomSnap from './zoom_snap';

export default class ComposeableAnimationFactory {
  static getAnimationMap() {
    return {
      fade: FadeAnimation,
      slide: SlideAnimation,
      pop: PopAnimation,
      drop: DropAnimation,
      do_nothing: DoNothing,
      zoom: Zoom,
      typewriter: Typewriter,
      zoom_snap: ZoomSnap
    };
  }

  static availableComposeableAnimations(layer) {
    return Object.values(ComposeableAnimationFactory.getAnimationMap())
      .filter((Animation) => Animation.exposed_to().includes(layer.layer_type));
  }

  static availableWhileComposeableAnimations(layer) {
    const whiles = ['zoom', 'typewriter', 'zoom_snap'];
    return Object.values(ComposeableAnimationFactory.getAnimationMap())
      .filter((Animation) => Animation.exposed_to().includes(layer.layer_type))
      .filter((Animation) => whiles.some((name) => Animation.int_name().includes(name)));
  }

  static availableInOutComposeableAnimations(layer) {
    const in_out = ['fade', 'slide', 'pop', 'drop'];
    return Object.values(ComposeableAnimationFactory.getAnimationMap())
      .filter((Animation) => Animation.exposed_to().includes(layer.layer_type))
      .filter((Animation) => in_out.some((name) => Animation.int_name().includes(name)));
  }

  static byType(animationType) {
    return Object.values(ComposeableAnimationFactory.getAnimationMap())
      .find((Animation) => Animation.int_name() === animationType);
  }

  static createComposeableAnimation(animationType, options) {
    // Find the correct Animation class
    const AnimationClass = Object.values(ComposeableAnimationFactory.getAnimationMap())
      .find((Animation) => Animation.int_name() === animationType);

    // Instantiate the found Animation class or DoNothing if not found
    const animationObject = AnimationClass ? new AnimationClass(options) : new DoNothing(options);

    return animationObject;
  }
}
