var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'editor__layer--inactive': !_vm.active },on:{"click":function (e) { return _vm.selectLayer(e, true); }}},[_c('div',{ref:"layer",staticClass:"canvas__layer-text-container",class:((_vm.disabled ? '' : 'layer_' + _vm.layer.id) + " layer_container_" + _vm.animation_uid + "_" + (_vm.layer.id) + " canvas__layer-text-container--" + _vm.editor_uid),style:(Object.assign({}, {opacity: _vm.layer.config['opacity'] ? _vm.layer.config['opacity'] / 100 : 1,
      'align-items': _vm.layer.config['align-items']},
      _vm.layer_constraints,
      {zIndex: _vm.zIndex,
      transform: _vm.transform_str,
      position: 'absolute',
      display: _vm.layer.visible ? 'block' : 'none'})),attrs:{"data-layer-id":_vm.layer.id}},[_c('div',{class:("layer_" + _vm.animation_uid + "_" + (_vm.layer.id)),style:(Object.assign({}, {display: 'flex',
      overflow: _vm.overflow,
      'border-radius': _vm.layer.config['border_radius'] + 'px',
      backgroundColor: _vm.background},
      _vm.layer_constraints,
      _vm.layer.styles()))},[_c('div',{staticStyle:{"display":"flex"},attrs:{"id":("layer_content_" + _vm.animation_uid + "_" + (_vm.layer.id))}},[_c(_vm.determine_component(_vm.layer),{tag:"component",attrs:{"layer":_vm.layer,"brandkit":_vm.brandkit,"samples":_vm.samples,"grouped_layers":_vm.grouped_layers,"on_layer_select":_vm.selectLayer,"activeLayer":_vm.activeLayer,"on_error":_vm.on_error,"active":_vm.active,"animation_uid":_vm.animation_uid,"disabled":_vm.disabled}})],1)])])])}
var staticRenderFns = []
export { render, staticRenderFns }