<template>
    <div :style="{ color: layer.color, width: layer.width + 'px', position: 'relative' }">
    
        <div
            style="
                display: flex;
                flex-direction: column;
                z-index: 0;
                overflow: hidden;"
            :style="` 
                gap: ${layer.config.native_reply.gap}px; 
                background: ${layer.config.native_reply.bg_color}; 
                border-radius: ${layer.fontSize * 0.25}px;`"
        >
            <span
                style="
                    width: 100%;
                    text-wrap: wrap;
                    color: white"
                :style="{ 
                    padding: layer.fontSize * 0.25 + 'px',
                    fontSize: layer.fontSize * 0.25 + 'px',
                    textDecoration: layer.config.native_reply.line_through ? 'line-through' : 'none',
                    fontFamily: font,
                }">
                {{ top_text }}
            </span>

            <div
                :style="`
                    display: flex;
                    flex-direction: ${layer.config.native_reply.h_align};
                    align-items: ${layer.config.native_reply.v_align};
                    justify-content: center;`"
            >   
                <span
                    class="sticker__repeater__text"
                    style="
                        background: white;
                        width: 100%;"
                    :style="`
                        color: ${layer.config.native_reply.bg_color}; 
                        font-size: ${layer.fontSize * 0.30}px;
                        font-family: ${font};
                        text-wrap: wrap;
                        padding: ${layer.fontSize * 0.25}px;`"
                >
                    {{ layer.text }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    components: {},
    mixins: [FontMixin],
    computed: {
        component_name() {
            return this.layer.config.component.type
        },
        bgColor() {
            return this.layer.config[this.component_name].bg_color || 'white'
        },
        top_text() {
            return this.layer.config.native_reply.top_text
        },
    },
    methods: {
        parse(text) {
            return highlight.highlight(text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color)
        },
    }
}
</script>