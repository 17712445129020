import axios from 'axios';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

const api = {
  async generate_ads(campaign_briefing, commands, amount) {
    const result = await axios.post(`/campaign_briefings/${campaign_briefing.id}/generate.json`, {
      commands,
      amount
    })
    return result.data
  },
  async feeds() {
    const result = await axios.get('/sources.json')
    return result.data
  },
  async build_briefing(briefing) {
    const result = await axios.post(`/briefings/${briefing.id}/build.json`, {})
    return result.data
  },
  async get_canvasses(page = 1, channel = null) {
    const result = await axios.get(`/image_canvasses.json?page=${page}&channel=${channel}`)
    return result.data
  },
  async update_briefing(briefing) {
    const result = await axios.patch(`/briefings/${briefing.id}.json`, {
      briefing
    })
    return result
  },
  async delete_briefing_canvas(briefing_canvas_id) {
    const result = await axios.delete(`/briefing_canvasses/${briefing_canvas_id}.json`)
    return result
  },
  async create_briefing_canvas(briefing, canvas) {
    const result = await axios.post('/briefing_canvasses.json', {
      briefing_canvas: {
        canvas_id: canvas.canvas_id,
        briefing_id: briefing.id
      }
    })
    return result.data
  },
  async update_campaign_briefing(campaign_briefing) {
    const result = await axios.patch(`/campaign_briefings/${campaign_briefing.id}.json`, {
      campaign_briefing
    })
    return result
  },
  async extract_images({ url, campaign_briefing }) {
    const result = await axios.post(`/campaign_briefings/${campaign_briefing.id}/extract_images.json`, {
      url
    })
    return result.data
  },
  async save_selected_suggestions({ campaign_briefing, suggestions }) {
    const result = await axios.post(`/campaign_briefings/${campaign_briefing.id}/save_suggestions.json`, {
      suggestions
    })
    return result.data
  }
}

export default api
