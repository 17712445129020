<template>
  <div class="studio__template" :class="{ 'studio__template--active': active }">
    <Dropdown :active="show_dropdown" v-if="editable" :root="true" :top="55" :right="-165"
      :on_toggle="state => show_dropdown = state">
      <template slot="btn" slot-scope="{is_active}">
        <span class="canvas__button">
          <i class="far fa-ellipsis-h"></i>
        </span>
      </template>
      <div class="dropdown__container_vue">
        <ul>
          <li class="dropdown__option">
            <a class="dropdown__anchor" v-on:click="e => edit_canvas(canvas)">
              Edit template
            </a>
          </li>
          <li class="dropdown__option">
            <a class="dropdown__anchor" v-on:click="e => duplicate(canvas)">
              Duplicate
            </a>
          </li>
          <li class="dropdown__option">
            <a class="dropdown__anchor" v-on:click="e => remove(canvas)">
              Delete
            </a>
          </li>
        </ul>
      </div>
    </Dropdown>

    <div class="studio__template-image" v-on:click="() => l_variant ? on_toggle(canvas) : null"
      :class="{ [`studio__template-image--${active_size}`]: true }">
      <CanvasPreview v-if="l_variant" :canvas="canvas" :variant="l_variant" :selected="false" :brandkit="brandkit"
        :on_hover_play="canvas.video" :fonts="[]" :anim_uid="anim_uid" :element="element" />
      <div v-else class="studio__template__missing">
        <div>
          <div class="rounder">
            <i class="fa-solid fa-triangle-exclamation"></i>
          </div>
          <div class="tweener">
            <p>Size missing</p>
          </div>
        </div>
        <button v-on:click.self="add_size_to_template" class="button button--light">Add size</button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '../../components/dropdown.vue'
import CanvasPreview from '../../catalogs/canvas_preview.vue'
import { mapState } from 'vuex'

export default {
  components: { CanvasPreview, Dropdown },
  props: {
    use_default_data: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    on_toggle: { type: Function, default: () => { } },
    on_delete: { type: Function, default: () => { } },
    on_duplicate: { type: Function, default: () => { } },
    canvas: { type: Object, default: () => { } },
    brandkit: { type: Object, default: () => { } },
    briefing: { type: Object, default: () => { } },
    briefing_meta: { type: Object, default: () => { } },
    variant: { type: Object, default: null },
    editable: { type: Boolean, default: true },
    anim_uid: { type: String, default: null }
  },
  data() {
    return {
      show_dropdown: false
    }
  },
  computed: {
    ...mapState({
      active_size: state => state.size,
    }),
    element() {
      let obj = {}
      if (this.use_default_data === true) {
        //create a fallback to preview data when no default data is present
        if (Object.entries(this.canvas_data)
          .filter(([key]) => key !== 'adflow_row_id')
          .every(([, val]) => val === null || val === '')) {
          obj = {
            ...obj,
            ...this.briefing.preview_feed.data[0].data,
          }
        } else {
          obj = {
            ...this.canvas_data,
            ...this.briefing_meta
          }
        }
      }
      if (this.use_default_data === false) {
        obj = {
          ...obj,
          ...this.briefing.preview_feed.data[0].data,
        }
      }
      return obj
    },
    canvas_data() {
      if (this.canvas && this.canvas.feed && this.canvas.feed.data && this.canvas.feed.data.length > 0)
        return this.canvas.feed.data[0].data
      return {}
    },
    active_size_object() {
      return this.$store.getters.active_size_object
    },
    l_variant() {
      if (this.$props.variant)
        return this.$props.variant
      return this.canvas.variants.find(v => v.size.name == this.active_size)
    }
  },
  methods: {
    add_size_to_template(e) {
      e.stopPropagation()
      this.edit_canvas(this.canvas, true)
    },
    duplicate(canvas) {
      this.show_dropdown = false
      this.on_duplicate(canvas)
    },
    remove(canvas) {
      this.show_dropdown = false
      this.on_delete(canvas)
    },
    toggle_canvas(canvas) {
      this.on_toggle(canvas)
    },
    edit_canvas(canvas, add_size = false) {
      this.show_dropdown = false
      window.location.href = `/designs/${canvas.id}/edit?filter=image&source=${this.briefing.preview_feed.complex_identifier}&${add_size ? 'c_size=' + this.active_size : ''}&redirect=/briefings/${this.briefing.id}?tp=true`
    }
  }
}
</script>