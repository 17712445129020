<template>
    <div :style="{ color: layer.color, width: layer.width + 'px' }">
        <div class="component__tooltip__wrapper" :class="calloutClass">
            <span 
                class="component__tooltip" 
                :style="`color: ${layer.color}; font-size: ${layer.fontSize * .45}px; background: ${bgColor}; font-family: ${font}`">
                {{ layer.text }}
            </span>
            <!-- Horizontal SVG -->
            <svg 
                class="component__tooltip__pointer component__tooltip__pointer--horizontal" 
                :width="svgHorizontalWidth" 
                :height="svgHorizontalHeight" 
                viewBox="0 0 16 32" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <rect :width="svgHorizontalWidth" :height="svgHorizontalHeight" fill="none"/>
                <path id="Polygon 2" d="M0 15.5L15.75 0.344555L15.75 30.6554L0 15.5Z" :fill="bgColor"/>
            </svg>
            <!-- Vertical SVG -->
            <svg 
                class="component__tooltip__pointer component__tooltip__pointer--vertical" 
                :width="svgVerticalWidth" 
                :height="svgVerticalHeight" 
                viewBox="0 0 32 16" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <rect :width="svgVerticalWidth" :height="svgVerticalHeight" fill="none"/>
                <g id="Frame 250">
                    <path id="Polygon 3" d="M15.5 0L30.6554 15.75H0.344556L15.5 0Z" :fill="bgColor"/>
                </g>
            </svg>
        </div>        
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    components: {},
    mixins: [FontMixin],
    data() {
        return {}
    },
    computed: {
        component_name() {
            return this.layer.config.component.type
        },
        line_length() {
            return this.layer.config[this.component_name].line_length || 60
        },
        alignment() {
            return this.layer.config[this.component_name].align || 'left'
        },
        v_align() {
            return this.layer.config[this.component_name].v_align || 'bottom'
        },
        h_align() {
            return this.layer.config[this.component_name].h_align || 'left'
        },
        bgColor() {
            // Dynamically fetch bg_bolor, default to 'white' if not set
            return this.layer.config[this.component_name].bg_color || 'white'
        },
        calloutClass() {
            return `component__tooltip__wrapper--${this.alignment}`
        },
        // Compute the scaling factor for SVG based on the font size
        svgScaleFactor() {
            return this.layer.fontSize * 0.01 // Adjust the 0.4 as needed for proper scaling
        },
        // Compute width and height for horizontal SVG
        svgHorizontalWidth() {
            return 16 * this.svgScaleFactor // Ensure it is not smaller than 16
        },
        svgHorizontalHeight() {
            return 32 * this.svgScaleFactor // Ensure it is not smaller than 31
        },
        // Compute width and height for vertical SVG
        svgVerticalWidth() {
            return 32 * this.svgScaleFactor // Ensure it is not smaller than 31
        },
        svgVerticalHeight() {
            return 16 * this.svgScaleFactor // Ensure it is not smaller than 16
        }
    },
    methods: {
        parse(text) {
            return highlight.highlight(text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color)
        },
    }
}
</script>