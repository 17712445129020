<template>
    <div :style="{ width: layer.width + 'px', height: layer.height + 'px', background: bgGradient }">
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],
    data() {
        return {

        }
    },
    computed: {
        // Determine the gradient angle or position based on direction
        gradientPosition() {
            const direction = this.layer?.config?.bg_gradient?.direction || 'center';
            
            // Map the direction to a valid radial position
            switch (direction) {
                case 'top':
                    return 'center bottom';
                case 'left':
                    return 'right center';
                case 'bottom':
                    return 'center top';
                case 'right':
                    return 'left center';
                default:
                    return 'center'; // Default to center if no direction is set
            }
        },
        gradientDirection() {
            const direction = this.layer?.config?.bg_gradient?.direction || 'right';
            
            switch (direction) {
                case 'top':
                    return '0deg';
                case 'left':
                    return '270deg';
                case 'bottom':
                    return '180deg';
                case 'right':
                default:
                    return '90deg';
            }
        },
        bgGradient() {
            // Safely access color_1, color_2, and type with fallbacks
            const color1 = this.layer?.config?.bg_gradient?.color_1 || '#ffffff'; // Default to white if not set
            const color2 = this.layer?.config?.bg_gradient?.color_2 || '#000000'; // Default to black if not set
            const gradientType = this.layer?.config?.bg_gradient?.type || 'linear'; // Default to 'linear'

            // Check if the gradient is linear or radial
            if (gradientType === 'radial') {
                // Construct a radial gradient with dynamic position
                return `radial-gradient(circle at ${this.gradientPosition}, ${color1} 0%, ${color2} 100%)`;
            } else {
                // Construct a linear gradient with dynamic direction
                return `linear-gradient(${this.gradientDirection}, ${color1} 0%, ${color2} 100%)`;
            }
        },
    },
    methods: {
        
    }

}
</script>