import axios from 'axios'

const errorNotification = function (header, msg){
  $(document).trigger('error_notification', [header, msg]);
}

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 406){
    const responseData = error.response.data
    errorNotification(responseData.header, responseData.message)
  } else {
    console.log(`We encountered an unexpected http error: ${error.response.status}`)
  }
  return Promise.reject(error);
});

let context = 'channels'

const api = {
  async getAudience(id){
    const response = await axios.get(`/facebook_audiences/${id}.json`)
    return response.data
  },
  async updateAudience({ id, audience }){
    const response = await axios.patch(
      `/facebook_audiences/${id}.json`,
      { facebook_audience: { targets: audience } }
    )
    return response.data
  },
  async searchFacebookInterests({ id, query }){
    const response = await axios.get(`/facebook_audiences/${id}/search.json?query=${query}`)
    return response.data
  },
  async getFacebookInterestSuggestions({ id }){
    const response = await axios.get(`/facebook_audiences/${id}/suggestions.json`)
    return response.data
  },
  async facebookAudienceReach({ id }){
    const response = await axios.get(`/facebook_audiences/${id}/reach.json`)
    return response.data
  },
  async getFacebookPages(){
    const response = await axios.get('/facebook_pages.json')
    return response.data
  },
  async getFacebookPage(fbPageId){
    const response = await axios.get(`/facebook_pages/${fbPageId}.json`)
    return response.data
  },
  async getProposition(propositionId){
    const response = await axios.get(`/adstore/propositions/${propositionId}.json`)
    return response.data
  },
  async adoptProposition(params){
    const response = await axios.post(`/${context}/${params.id}.json`, params)
    return { data: response.data, location: response.headers.location }
  },
  async updateChannel(fbChannel){
    try {
      const params = {
        channel: {
          advert: fbChannel.advert,
        }
      }
      if (fbChannel.facebook_page) params.channel.facebook_page_id = fbChannel.facebook_page
      const result = await axios.patch(`/${fbChannel.advertiseablePath}/${fbChannel.advertiseableId}.json`, params)
      return result.data;
    } catch (err){
      console.error('Something went wrong updating the channel', err)
      return null
    }
  },
  async deleteCard(card){
    try {
      const result = await axios.delete(card.link)
      return result;
    } catch (err) {
      console.error('Something went wrong deleting the card', err);
      return null
    }
  },

  async createCard(card, expressionId){
    try {
      const result = await axios.post(`/${context}/${expressionId}/facebook_caroussel_cards.json`, { card })
      return result.data;
    } catch (err){
      console.error('Something went wrong creating a card', err)
      return null
    }
  },

  async updateCard(card){
    const result = await axios.patch(`${card.link}.json`, { card })
    return result.data;
  },

  async getExpression(id){
    const response = await axios.get(`/${context}/${id}/expression.json`)
    return response.data;
  },

  async getAsset(url){
    await axios.get(url).data
  },

  async uploadAsset(file, card){
    const data = new FormData()
    data.append('card[image]', file)
    const headers = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      const response = await axios.patch(`${card.link}.json`, data, headers);
      return response.data
    } catch (e){
      console.error('error uploading asset', e);
      return 0;
    }
  },
  baseUrl(){
    return window.location.href.split('#')[0];
  },
  setContext(ctx){
    context = ctx
  },
  getContext(){
    return context
  }
}

export default api
