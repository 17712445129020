function removePluralization(string){
  const lastChar = string.slice(-1)
  if (lastChar === 's' || lastChar === 'S'){
    return string.substring(0, string.length - 1);
  }
  return string
}

function mapCarouselItemToCard(item){
  return {
    id: item.id,
    link: item.link,
    target_url: item.target_url,
    header: item.header,
    description: item.description,
  }
}

export default {
  mapCarouselItemToCard,
  removePluralization
}
