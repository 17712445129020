import { debounce } from 'debounce';
// import * as Sentry from '@sentry/vue';
import _ from 'lodash'
import {
  UPDATE, INIT, IMPORT, UNDO, START_SAVE, FINISH_SAVE, REDO
} from '../mutation_types'
import {
  TOGGLE_SETTINGS, UPDATE_CANVAS, DIRTY, SET_TOP_SEGMENT, PALETTE, SET_STATE
} from './mutation_types'
import api from '../../api/editor.api'
import Variant from '../variants/model'

export default {
  init({ commit, dispatch }, {
    canvas, feed, brandkit, variant_name = 'default', autosave = false, type = 'load', options, fonts = []
  }) {
    commit(INIT, {
      canvas,
      brandkit,
      autosave,
      options,
      fonts,
      addons: feed ? feed.addons : [],
      project_id: feed ? feed.project_id : null
    })
    if (type === 'load') {
      dispatch('feeds/init', feed)
    }
    const vars = canvas.variants.map((v) => Variant.factory(v))
    let active_variant_id = null
    if (options) active_variant_id = options.active_variant_id
    dispatch('variants/init', {
      canvas, variants: vars, variant_name, active_variant_id
    })
    dispatch('layers/init', vars.flatMap((v) => v.layers))
    dispatch('toggle_settings', true)

    if (options && options.auto_create_sizes) {
      setTimeout(() => {
        options.auto_create_sizes.forEach((size) => {
          dispatch('variants/create', { layers: canvas.layers, size: { key: size } })
        })
      }, 500)
    }
  },

  emit({ state }, { type, detail }) {
    state.options.event_target.dispatchEvent(new CustomEvent(type, { detail }))
  },

  update({ commit }) {
    commit(UPDATE)
  },

  set_state({ commit }, state) {
    commit(SET_STATE, state)
  },

  set_top_segment({ commit }, segment) {
    commit(SET_TOP_SEGMENT, segment)
  },

  async redo({ commit }) {
    commit(REDO)
  },

  async undo({ commit }) {
    commit(UNDO)
  },

  async save({
    state, commit, dispatch, getters
  }, { redirect = true } = {}) {
    commit(START_SAVE)
    if (redirect) await dispatch('layers/activate', null)
    await dispatch('feeds/init_segments')

    const imp = state.history.find((h) => h.mutation.includes(IMPORT))
    if (imp) await api.reset_canvas(state.canvas)

    const id_map = {}
    const layer_id_map = {}
    const layer_group_id_map = {}
    const to_be_persisted_variants = state.variants
      .list
      .filter((v) => !v.persisted && !v.deleted)
      .map((v) => {
        if (v.segment_name) {
          const segment = getters['feeds/public_segments'].find((s) => s.name === v.segment_name)
          return {
            ...v,
            segment_id: segment ? segment.id : null
          }
        }
        return v
      })

    const to_be_persisted_layers = state.layers.list.filter((l) => !l.persisted && !l.deleted)
    const dirty_variants = state.variants.list.filter((v) => v.dirty && v.persisted && !v.deleted)
    const dirty_layers = state.layers.list.filter((l) => l.dirty && l.persisted && !l.deleted)
    const deleted_layers = state.layers.list.filter((l) => l.deleted && l.persisted)
    const deleted_variants = state.variants.list.filter((v) => v.deleted && v.persisted)

    function handle_async(operation, ...args) {
      return operation(...args).catch((e) => {
        // Handle the error or just log it
        console.error('An error occurred:', e.message);
        // Optionally, return a value indicating that the operation failed or was skipped
        return { error: true, message: e.message };
      });
    }

    try {
      if (!state.canvas.template && !state.canvas.video) {
        dirty_layers.map((l) => l.resolve_static(state.brandkit))
        to_be_persisted_layers.map((l) => l.resolve_static(state.brandkit))
      }

      await Promise.all(deleted_layers.map(async (l) => handle_async(dispatch, 'layers/delete_remote', l)))
      await Promise.all(deleted_variants.map(async (v) => handle_async(dispatch, 'variants/delete_remote', v)))
      await Promise.all(dirty_variants.map(async (v) => handle_async(dispatch, 'variants/update_remote', v)))
      await Promise.all(to_be_persisted_variants.map((v) => dispatch('variants/create_remote', v)
        .then((result) => {
          id_map[`${v.id}`] = result.id;
        })))

      let to_be_persisted_layers_without_groups = to_be_persisted_layers.filter((l) => l.layer_type !== 'group')
      to_be_persisted_layers_without_groups = to_be_persisted_layers_without_groups.map((l) => {
        if (l.config.group_id && layer_group_id_map[l.config.group_id]) {
          l.config.group_id = layer_group_id_map[l.config.group_id]
        }
        return l
      }).sort((a, b) => {
        if (a.parent_id == null && b.parent_id != null) {
          return -1;
        }
        if (a.parent_id != null && b.parent_id == null) {
          return 1;
        }
        return 0;
      });

      for (const l of to_be_persisted_layers_without_groups) { //eslint-disable-line
        const result = await handle_async(dispatch, 'layers/create_remote', { //eslint-disable-line
          ...l,
          parent_id: l.parent_id ? layer_id_map[l.parent_id] : null,
          image_canvas_variant_id: id_map[l.image_canvas_variant_id] || l.image_canvas_variant_id
        })
        layer_id_map[l.id] = result.id
      }

      await Promise.all(dirty_layers.map(async (l) => handle_async(dispatch, 'layers/update_remote', l)))
      await api.update_canvassable(state.canvas)
      if (state.options.on_save) state.options.on_save(state.canvas)
    } catch (e) {
      console.error('A critical error occurred:', e);
      // Sentry.captureException(e, {
      //   tags: {
      //     actions: 'commit save design',
      //   },
      // });
      throw e
    }

    commit(FINISH_SAVE, {
      variant_id_map: id_map,
      layer_id_map
    })

    commit(DIRTY, false)
    if (state.options.save_redirect_url && redirect && state.options.save_redirect_url.startsWith('/')) {
      window.location = state.options.save_redirect_url
    }
  },

  activate({ dispatch }, variant) {
    dispatch('variants/activate', variant)
  },

  toggle_settings({ commit, dispatch }, bool) {
    dispatch('layers/activate', null)
    commit(TOGGLE_SETTINGS, bool)
  },

  update_canvas({ dispatch }, canvas) {
    dispatch('update_canvas_remote', canvas)
  },

  palette({ dispatch, commit }, palette) {
    commit(PALETTE, palette)
    dispatch('layers/palette', palette)
  },

  update_canvas_remote: debounce(async ({
    commit, state
  }, canvas) => {
    canvas = await api.update_canvassable({ ...state.canvas, ...canvas })
    commit(UPDATE_CANVAS, canvas)
  }, 350),

  export({ rootState }) {
    const canvas = JSON.parse(JSON.stringify(rootState.canvas))
    canvas.template = false
    canvas.variants = canvas.variants.map((variant) => {
      variant.id = null
      variant.persisted = false
      variant.layers.map((l) => {
        l.id = null
        l.persisted = false
        l.image_canvas_variant_id = null

        return l
      })
      return variant
    })
    return Buffer.from(JSON.stringify(canvas), 'utf-8').toString('base64')
  },

  undo_import({ dispatch }, history) {
    dispatch('init', { canvas: history.old_value, type: 'import' })
  },

  import({
    state, dispatch, rootGetters, commit
  }, { template, base64 }) {
    const cloned_state = _.cloneDeep(state)
    let data = {}
    if (base64 === true) {
      data = JSON.parse(Buffer.from(template, 'base64').toString())
    } else {
      data = template
    }

    data.id = cloned_state.canvas.id
    data.canvas_id = cloned_state.canvas.canvas_id
    data.url = cloned_state.canvas.url.replace(cloned_state.canvas.id, data.id)
    data.name = cloned_state.canvas.name
    data.size = 'facebook_catalog'
    data.template = false
    data.variants = [...data.variants.map((variant, index) => {
      if (!variant.size) variant.size = 'facebook_catalog'
      variant.persisted = false
      variant.id = null
      if (index === 0) {
        variant.is_default = true
        variant.name = 'default'
        variant.enabled = true
      }
      if (typeof variant.size === 'string') {
        // variant.size = variant.size
      } else {
        variant.size = variant.size.name
      }
      variant.layers.forEach((l) => {
        const b = state.fonts.find((font) => font.face.includes(l.font))
        const font = { ...b }
        l.font_id = font ? font.id : null
      })
      return variant
    })]

    data.variants = [...data.variants,
      ...cloned_state.variants.list.filter((v) => v.persisted).map((variant) => {
        variant.deleted = true
        return variant
      }).filter(Boolean)]

    data.available_sizes = cloned_state.canvas.available_sizes
    commit(IMPORT, cloned_state)
    dispatch('init', {
      canvas: data, brandkit: cloned_state.brandkit, type: 'import', fonts: cloned_state.fonts, feed: rootGetters['feeds/active_feed']
    })
  },

}
