import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class SaleSticker extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Sticker'
  }

  labels() {
    return ['stickers']
  }

  static enabled() {
    return false;
  }

  icon() {
    return 'fa-regular fa-note-sticky'
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'white',
      align: 'center',
      background: 'red',
      width: 300,
      height: 300,
      fontSize: 40,
      ...this.opts,
      config: {
        v_align: 'center',
        border_radius: 99999,
        show_background: true,
        component: {
          type: 'sticker',
          name: 'Sticker',
        },
        sticker: {
          currency: 'EUR',
          original_price: '100.00',
          sale_price: '50.00',
        }
      }
    }
  }

  config() {
    return {
      background: 'white',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
