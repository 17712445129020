import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Price extends Component {
  name() {
    return 'Price'
  }

  labels() {
    return ['product']
  }

  icon() {
    return 'fa-regular fa-eur'
  }

  config() {
    return Layer.factory({
      layer_type: 'price',
      text: '{{price}}'
    })
  }
}
