import api from '../store/api/expression_api'
import FacebookCarousselCard from './facebook_caroussel_card';

export default class {
  constructor(context, expression){
    this.link                     = expression.link || null
    this.id                       = expression.id || null
    this.selected_fb_page         = expression.facebook_page || null
    this.type                     = expression.type || null
    this.advert                   = expression.advert || ''
    this.facebook_caroussel_cards = this.createCards(expression.facebook_caroussel_cards) || []
    this.advertiseable_id         = expression.channelable.id || null
    this.advertiseable_type       = expression.channelable.type || null
    this.context                  = context || {}
    this.fb_connection            = { selected: null, connected: false, pages: [] }
  }

  async initialize() {
    if (!['templates', 'public_propositions'].includes(this.context.api_context)){
      await this.getPagesAndSetSelected()
    }
  }

  async getPagesAndSetSelected(){
    this.fb_connection = await api.getFacebookPages();
    this.setSelectedFacebookPage()
  }

  createCards(cards){
    return cards.map((card) => new FacebookCarousselCard(card))
  }

  setSelectedFacebookPage(){
    this.fb_connection.selected = this.fb_connection.pages
                                      .filter(page => page.id === this.selected_fb_page)[0] // eslint-disable-line
  }

  getAdvertiseablePath(){
    switch (this.advertiseable_type){
      case 'FacebookPost':
        return 'facebook_posts'
      case 'FacebookChannel':
        return 'facebook_channels'
      default:
        return 'facebook_channels'
    }
  }

  toState(){
    let activeCard =  this.facebook_caroussel_cards[0] || null
    if (activeCard) activeCard = activeCard.toState()
    return {
      advert: this.advert,
      type: this.type.split('/')[1],
      advertiseable_type: this.advertiseable_type,
      advertiseable_path: this.getAdvertiseablePath(),
      items: this.facebook_caroussel_cards.map((card) => card.toState()),
      active: activeCard,
      fb_connection: this.fb_connection,
      channelId: this.id,
      advertiseableId: this.advertiseable_id
    }
  }

  // constructor wrapper, this is done so async methods can be used on init
  static async create(context, expression) {
    const  o = new this(context, expression);
    await  o.initialize();
    return o;
  }
}
