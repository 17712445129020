<template>
  <div class="form-group">
    <div v-if="!isSwiperEmpty" class="tweener">
      <div class="product_selector__button-assets">
        <div v-for="asset in assets" class="product_selector__button-asset" @click.stop="delete_swiper_asset(asset)">
          <img :src="asset.asset_url">
          <div class="product_selector__button-assets__overlay">
            <i class="fa-regular fa-trash-can"></i>
          </div>
        </div>
      </div>
      <div class="button__tab_group">
        <button class="button__toggle__light" @click="delete_all_swiper_assets">
          <i class="fa-regular fa-trash-can"></i>
          <span class="tooltip tooltip--top">Delete all</span>
        </button>
        <button class="button__toggle__light button__toggle--active" @click.stop="open_media_lib">
          <i aria-hidden="true" class="fa-regular fa-plus"></i>
          <span class="tooltip tooltip--top">Add media</span>
        </button>
      </div>
    </div>

    <div v-else @click="open_media_lib" class="empty-swiper__button">
      <button class="button button--light">
        <i aria-hidden="true" class="fa-regular fa-plus"></i>
        Choose files
      </button>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../lib/media/eventbus'

export default {
  props: ['mediaFolder', 'layer', 'onUpdate'],
  data() {
    return {
      field_id: Math.random().toString(36).substring(7)
    }
  },
  computed: {
    assets() {
      return this.layer.config.swiper.assets;
    },
    isSwiperEmpty() {
      return this.assets.length === 0;
    }
  },
  mounted() {
    EventBus.$on('on_asset_' + this.field_id, this.handle_asset_pick);
    EventBus.$on('closed', () => {
      EventBus.$off('on_asset_' + this.field_id, this.handle_asset_pick);
    });
  },
  methods: {
    open_media_lib() {
      EventBus.$emit('show', { field_id: this.field_id });
    },
    handle_asset_pick(asset) {
      this.add_asset(asset);
    },
    delete_swiper_asset(asset) {
      let assets = this.assets.filter(a => a.asset_url !== asset.asset_url);
      this.updateLayerConfig({ swiper: { ...this.layer.config.swiper, assets } });
    },
    delete_all_swiper_assets() {
      this.updateLayerConfig({ swiper: { ...this.layer.config.swiper, assets: [] } });
    },
    add_asset(asset) {
      let newAssets = [...this.assets, asset]; // Voeg de nieuwe asset toe aan de bestaande lijst van assets
      this.updateLayerConfig({ swiper: { ...this.layer.config.swiper, assets: newAssets } });
    },
    updateLayerConfig(conf) {
      this.updateLayer('config', { ...this.layer.config, ...conf });
    },
    updateLayer(key, value) {
      const layer = { ...this.layer, [key]: value };
      this.$props.onUpdate(layer);
    }
  }
}
</script>
