export default {
  activeCard: (state, getters) => getters.card(state.activeCard),

  activeCardIndex: (state) => {
    for (let i = 0; i < state.facebookCarouselCards.length; i += 1){
      if (state.facebookCarouselCards[i] === state.activeCard) return i;
    }
    return 0;
  },

  advert: (state) => (state.advert ? state.advert.replace(/<(?:.|\n)*?>/gm, '') : ''),

  previewType: (state) => (state.facebookCarouselCards.length > 1 ? 'carousel' : 'singleimage'),

  card: (state, getters, rootState) => (id) => rootState[`FacebookCarouselCard_${id}`],

  cards: (state, getters) => state.facebookCarouselCards.map((cardId) => getters.card(cardId))
}
