<template>
	<div style="position:relative; width: 100%;">
    <div v-on:click.self="e => select_layer(e, true, glayer)" 
        style="display: flex;" 
        :style="`${activeLayer.id === glayer.id ? 'border: 1px solid black;' : null }`"
        :id="`layer_content_${animation_uid}_${layer.id}`" v-for="glayer in grouped_layers">
      <component v-bind:is="determine_component(glayer)" :layer="glayer" :brandkit="brandkit" :samples="samples"
        :grouped_layers="[]" :animation_uid="animation_uid" :disabled="disabled"
        v-on:click.stop></component>
    </div>
	</div>
</template>
  
<script> 
import { getComponent } from '../helpers'
import Layer from '../layer.vue'
  export default  {
    components: { Layer },
    props: ['layer', 'on_layer_select', 'grouped_layers', 'activeLayer',
    'active', 'samples', 'disabled', 'brandkit', 'animation_uid'],
    data(){
      return {
       
      }
    },
    mounted() {

    },
    watch: {
    
    },
    computed: {

    },
    methods: {
      select_layer(e, dbclick, layer){
        this.on_layer_select(e, dbclick, layer)
      },
      determine_component(layer){
        return getComponent(layer)
      },
    }
  }
  </script>
  