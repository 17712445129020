import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class FocusPoint extends Animation {
  constructor({
    layer, duration, update_layer
  }) {
    super({
      layer, duration, update_layer
    })
  }

  name() {
    return 'Focuspoint'
  }

  static exposed_to() {
    return ['image']
  }

  static int_name() {
    return 'focuspoint'
  }

  animation() {
    return anime.timeline({
      duration: this.duration,
      autoplay: false,
    }).add({
      targets: `#image_layer_${this.layer.id}`,
      backgroundPosition: ['0% 0%', this.calc_bg_pos(this.focus_points(this.layer.config.image_url))],
      duration: this.duration,
      easing: this.easing(),
      loop: this.loop(),
      direction: 'alternate'
    })
  }

  focus_points(source) {
    try {
      const link = new URL(source)
      const focus_x = link.searchParams.get('focus_x')
      const focus_y = link.searchParams.get('focus_y')
      return { focusx: focus_x, focusy: focus_y }
    } catch (e) {
      return { focusx: 0, focusy: 0 }
    }
  }

  calc_bg_pos({ focusx, focusy }) {
    let y = (Math.abs(focusy) / 2) * 100
    let x = (Math.abs(focusx) / 2) * 100
    if (this.focus_points.y < 0) {
      y += 50
    } else {
      y = 50 - (Math.abs(focusy) * 50)
    }
    if (this.focus_points.x > 0) {
      x += 50
    } else {
      x = 50 - (Math.abs(focusx) * 50)
    }
    return `${x}% ${y}%`
  }
}
