<template>
  <component
    :is="component"
    :layer="transformed_layer"
    :brandkit="brandkit"
    :samples="samples"
    :on_error="on_error"
    :active="active"
    :animation_uid="animation_uid"
    :disabled="disabled"></component>
</template>

<script>
import ImageLayer from './image.vue'
import VideoLayer from './video/video.vue'
import {mustache} from '../../lib/parsers'
export default {
  props: ['layer', 'samples', 'disabled', 'active', 'on_error', 'brandkit', 'animation_uid'],
  data(){
    return {

    }
  },
  computed: {
    transformed_layer(){
      let layer = {...this.layer}
      if(this.is_video){
        layer.config.video_url = this.layer.config.media_url
      } else {
        layer.config.image_url = this.layer.config.media_url
      }
      return layer
    },
    is_video(){
      return mustache.fill_in(this.layer.config.media_url, this.samples.data[this.samples.indice]).includes('.mp4')
    },
    component(){
      if(this.is_video){
        return VideoLayer
      } else {
        return ImageLayer
      }
    }
  }
}
</script>
