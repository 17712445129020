<template>
  <div class="animations__wrapper">

    <span class="animations__wrapper" v-if="version === 2">
      <span class="animation__block" v-if="component_anim">
        <p class="animation__block__header">{{ component_anim.int_name() }}</p>

        <div v-for="[key, property] in Object.entries(component_anim.properties())">
          <span class="form-group" v-if="property.type === 'List'">
            <label>{{ property.pretty_name }}</label>
            <div class="button__tab_group">
              <div class="button__toggle__light"
                :class="{ 'button__toggle--active': component_anim_config[key] === value.value }"
                @click="e => update_component_animation(key, value.value)" v-for="value in property.values">
                <span>{{ value.display }}</span>
              </div>
            </div>
          </span>
        </div>

      </span>

      <div class="animation__block">
        <p class="animation__block__header">In</p>
        <select v-on:change="e => update_composeable_anim('in', e)">
          <option value="do_nothing">None</option>
          <option :value="animation.int_name()" :selected="in_anim.int_name() === animation.int_name()"
            v-for="animation in ComposableAnimationFactory.availableInOutComposeableAnimations(activeLayer)">
            {{ animation.pretty_name() }} in
          </option>
        </select>

        <div v-for="[key, property] in Object.entries(in_anim.properties())">
          <span class="form-group" v-if="property.type === 'List'">
            <label>{{ key }}</label>
            <div class="button__tab_group">
              <div class="button__toggle__light"
                :class="{ 'button__toggle--active': activeLayer.config.animation.in[key] === value.value }"
                @click="e => update_composeable_anim_properties('in', key, value.value)"
                v-for="value in property.values">
                <span>{{ value.display }}</span>
              </div>
            </div>
          </span>
        </div>

        <div class="form-group" v-if="in_anim.int_name() !== 'do_nothing'">
          <label>Duration (seconds)</label>
          <div class="tweener">
            <input type="range" :value="in_duration" style="width: 60%" min="0" step="1" :max="animation_duration"
              @input="(e) => update_composeable_anim_properties('in', 'duration', e.target.value)">
            <input disabled type="number" :value="(in_duration / 1000).toFixed(2)"
              style="min-width: 25%; margin-left: 10px; width: 25%"
              @input="(e) => update_composeable_anim_properties('in', 'duration', e.target.value)">
          </div>
        </div>
      </div>


      <div class="animation__block">
        <p class="animation__block__header">While</p>
        <select v-on:change="e => update_composeable_anim('while', e)">
          <option value="do_nothing">None</option>
          <option :value="animation.int_name()" :selected="while_anim.int_name() === animation.int_name()"
            v-for="animation in ComposableAnimationFactory.availableWhileComposeableAnimations(activeLayer)">
            {{ animation.pretty_name() }}
          </option>
        </select>

        <div v-for="[key, property] in Object.entries(while_anim.properties())">
          <span class="form-group" v-if="property.type === 'List'">
            <label>{{ key }}</label>
            <div class="button__tab_group">
              <div class="button__toggle__light"
                :class="{ 'button__toggle--active': activeLayer.config.animation.while[key] === value.value }"
                @click="e => update_composeable_anim_properties('while', key, value.value)"
                v-for="value in property.values">
                <span>{{ value.display }}</span>
              </div>
            </div>
          </span>
        </div>

        <div class="form-group" v-if="while_anim.int_name() !== 'do_nothing'">
          <label>Duration (seconds)</label>
          <div class="tweener">
            <input type="range" :value="while_duration" style="width: 60%" min="0" step="1" :max="animation_duration"
              @input="(e) => update_composeable_anim_properties('while', 'duration', e.target.value)">
            <input type="number" disabled :value="(while_duration / 1000).toFixed(2)"
              style="min-width: 25%; margin-left: 10px; width: 25%"
              @input="(e) => update_composeable_anim_properties('while', 'duration', e.target.value)">
          </div>
        </div>
      </div>


      <div class="animation__block">
        <p class="animation__block__header">Out</p>
        <select v-on:change="e => update_composeable_anim('out', e)">
          <option value="do_nothing">None</option>
          <option :value="animation.int_name()" :selected="out_anim.int_name() === animation.int_name()"
            v-for="animation in ComposableAnimationFactory.availableInOutComposeableAnimations(activeLayer)">
            {{ animation.pretty_name() }} out
          </option>
        </select>

        <div v-for="[key, property] in Object.entries(out_anim.properties())">
          <span class="form-group" v-if="property.type === 'List'">
            <label>{{ key }}</label>
            <div class="button__tab_group">
              <div class="button__toggle__light"
                :class="{ 'button__toggle--active': activeLayer.config.animation.out[key] === value.value }"
                @click="e => update_composeable_anim_properties('out', key, value.value)"
                v-for="value in property.values">
                <span>{{ value.display }}</span>
              </div>
            </div>
          </span>
        </div>

        <div class="form-group" v-if="out_anim.int_name() !== 'do_nothing'">
          <label>Duration (seconds)</label>
          <div class="tweener">
            <input type="range" :value="out_duration" style="width: 60%" min="0" step="1" :max="animation_duration"
              @input="(e) => update_composeable_anim_properties('out', 'duration', e.target.value)">
            <input type="number" disabled :value="(out_duration / 1000).toFixed(2)"
              style="min-width: 25%; margin-left: 10px; width: 25%"
              @input="(e) => update_composeable_anim_properties('out', 'duration', e.target.value)">
          </div>
        </div>
      </div>
    </span>

    <span v-else>
      <select v-on:change="e => updateAnimationConfig({ type: e.target.value })">
        <option :value="null" :selected="activeLayer.animation()">No animation</option>
        <option v-for="animation in activeLayer.available_animations()" :value="animation.int_name()"
          :key="animation.int_name()"
          :selected="activeLayer.animation() ? activeLayer.animation().int_name() == animation.int_name() : false">
          {{ animation.int_name() }}
        </option>
      </select>

      <div class="layer-config__spacer" v-if="activeLayer.animation()">
        <div class="form-group" :key="key" v-for="key in Object.keys(activeLayer.animation().properties())">
          <label>{{ key }}</label>
          <select v-if="activeLayer.animation().properties()[key].type == 'Select'"
            v-on:change="e => updateAnimationConfig({ [key]: e.target.value })">
            <option v-for="option in activeLayer.animation().properties()[key].options" :key="option" :value="option"
              :selected="activeLayer.config.animations[0][key] == option">
              {{ option }}
            </option>
          </select>

          <div style="font-size:14px;" v-if="activeLayer.animation().properties()[key].type == 'Boolean'">
            <div class="button-group--select">
              <div class="button--select button--select-half-width" v-on:click="(e) => {
      updateAnimationConfig({ [key]: true })
    }">
                <input type="radio" v-model="activeLayer.animation_config()[key]" value="true" name="show_symbol">
                <label>
                  <span v-if="activeLayer.animation().properties()[key].display">
                    {{ activeLayer.animation().properties()[key].display['true'] }}
                  </span>
                  <span v-else>
                    Yes
                  </span>
                </label>
              </div>
              <div class="button--select button--select-half-width" v-on:click="(e) => {
      updateAnimationConfig({ [key]: false })
    }">
                <input type="radio" v-model="activeLayer.animation_config()[key]" value="false" name="show_symbol">
                <label>
                  <span v-if="activeLayer.animation().properties()[key].display">
                    {{ activeLayer.animation().properties()[key].display['false'] }}
                  </span>
                  <span v-else>
                    No
                  </span>
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </span>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import ComposableAnimationFactory from '../lib/animations/composeables/factory'
import AnimationFactory from '../lib/animations'
export default {
  props: {
    activeLayer: { type: Object, required: true },
    updateLayer: { type: Function, required: true },
    version: { type: Number, required: false, default: 1 },
    min_scene_duration: { type: Number, required: false, default: 0 },
  },
  components: {},
  data() {
    return {
      ComposableAnimationFactory: ComposableAnimationFactory,
      AnimationFactory: AnimationFactory
    }
  },
  mounted() {

  },
  computed: {
    component_anim() {
      let comp_anim = this.activeLayer.config.animations.find(a => a.type !== 'inwhileout')
      if (!comp_anim) return null
      return AnimationFactory.by_type(comp_anim.type)
    },
    component_anim_config() {
      return this.activeLayer.config.animations.find(a => a.type !== 'inwhileout')
    },
    animation_duration() {
      return this.activeLayer.config.animation.duration_percentage / 100 * this.min_scene_duration
    },
    while_duration() {
      let duration = this.activeLayer.config.animation.while ? this.activeLayer.config.animation.while.duration : 0
      return duration === undefined ? this.animation_duration * .6 : duration
    },
    in_duration() {
      let duration = this.activeLayer.config.animation.in ? this.activeLayer.config.animation.in.duration : 0
      return duration === undefined ? this.animation_duration * .2 : duration
    },
    out_duration() {
      let duration = this.activeLayer.config.animation.out ? this.activeLayer.config.animation.out.duration : 0
      return duration === undefined ? this.animation_duration * .2 : duration
    },
    while_anim() {
      if (!this.activeLayer.config.animation.while) return { int_name: () => 'do_nothing', properties: () => ({}) }
      let anim = ComposableAnimationFactory.byType(this.activeLayer.config.animation.while.type)
      if (!anim) return { int_name: () => 'do_nothing', properties: () => ({}) }
      return anim
    },
    in_anim() {
      if (!this.activeLayer.config.animation.in) return { int_name: () => 'do_nothing', properties: () => ({}) }
      let anim = ComposableAnimationFactory.byType(this.activeLayer.config.animation.in.type)
      if (!anim) return { int_name: () => 'do_nothing', properties: () => ({}) }
      return anim
    },
    out_anim() {
      if (!this.activeLayer.config.animation.out) return { int_name: () => 'do_nothing', properties: () => ({}) }
      let anim = ComposableAnimationFactory.byType(this.activeLayer.config.animation.out.type)
      if (!anim) return { int_name: () => 'do_nothing', properties: () => ({}) }
      return anim
    }
  },
  methods: {
    update_composeable_anim_properties: debounce(function (target, property, value) {
      const total_time = this.animation_duration;

      let current_in_duration = this.in_duration;
      let current_out_duration = this.out_duration;
      let current_while_duration = this.while_duration;

      if ((target === 'in' || target === 'out') && property === 'duration') {

        const new_in_duration = target === 'in' ? value : current_in_duration;
        const new_out_duration = target === 'out' ? value : current_out_duration;

        let new_while_duration = total_time - parseFloat(new_in_duration) - parseFloat(new_out_duration);

        if (new_while_duration < 0) {
          new_while_duration = 0;
        }

        this.updateLayer('config', {
          ...this.activeLayer.config,
          animation: {
            ...this.activeLayer.config.animation,
            [target]: {
              ...this.activeLayer.config.animation[target],
              [property]: value
            },
            while: {
              ...this.activeLayer.config.animation.while,
              duration: parseFloat(new_while_duration)
            }
          }
        });

      } else if (target === 'while' && property === 'duration') {
        const difference = parseFloat(value) - parseFloat(current_while_duration);

        const adjustment = difference / 2;
        const new_in_duration = current_in_duration - adjustment;
        const new_out_duration = current_out_duration - adjustment;

        if (new_in_duration < 0 || new_out_duration < 0) {
          console.warn('Adjustment leads to negative duration, aborting update.');
          return;
        }

        this.updateLayer('config', {
          ...this.activeLayer.config,
          animation: {
            ...this.activeLayer.config.animation,
            in: {
              ...this.activeLayer.config.animation.in,
              duration: parseFloat(new_in_duration)
            },
            out: {
              ...this.activeLayer.config.animation.out,
              duration: parseFloat(new_out_duration)
            },
            while: {
              ...this.activeLayer.config.animation.while,
              duration: parseFloat(value)
            }
          }
        });
      } else {
        let config = {
          ...this.activeLayer.config,
          animation: {
            ...this.activeLayer.config.animation,
            [target]: {
              ...this.activeLayer.config.animation[target],
              [property]: value
            },
          }
        }

        if (config.animation.while && !config.animation.while.duration) {
          config.animation.while.duration = total_time - this.in_duration - this.out_duration
        }

        this.updateLayer('config', config);
      }

    }, 0),

    update_component_animation(property, value) {
      this.updateLayer('config', {
        ...this.activeLayer.config,
        animations: this.activeLayer.config.animations.map(a => {
          if (a.type === 'inwhileout') return a
          return {
            ...a,
            [property]: value
          }
        })
      })
    },


    update_composeable_anim(target, event) {
      const config = {
        ...this.activeLayer.config,
        animation: {
          ...this.activeLayer.config.animation,
          [target]: {
            ...this.activeLayer.config.animation[target],
            type: event.target.value,
          }
        }
      }
      this.updateLayer('config', config)
    },
    updateAnimationConfig(conf) {
      let current_conf = this.activeLayer.config.animations ? this.activeLayer.config.animations[0] : {}
      this.updateLayer('config', {
        ...this.activeLayer.config,
        animations: [{
          ...current_conf,
          ...conf
        }]
      })
    },
  }
}
</script>
