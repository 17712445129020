import currency from 'currency.js'

const matchers = {
  EUR: /\d{1,6}(?:[.,]\d{3})*(?:[.,]\d{1,5})[ \t]+(EUR)(?!O)/g,
  USD: /\d{1,6}(?:[.,]\d{3})*(?:[.,]\d{1,5})[ \t]+(USD)(?!O)/g,
  GBP: /\d{1,6}(?:[.,]\d{3})*(?:[.,]\d{1,5})[ \t]+(GBP)(?!O)/g,
  DKK: /\d{1,6}(?:[.,]\d{3})*(?:[.,]\d{1,5})[ \t]+(DKK)(?!O)/g,
  SKK: /\d{1,6}(?:[.,]\d{3})*(?:[.,]\d{1,5})[ \t]+(SKK)(?!O)/g,
  PLN: /\d{1,6}(?:[.,]\d{3})*(?:[.,]\d{1,5})[ \t]+(PLN)(?!O)/g,
  MXN: /\d{1,6}(?:[.,]\d{3})*(?:[.,]\d{1,5})[ \t]+(MXN)(?!O)/g,
  MXP: /\d{1,6}(?:[.,]\d{3})*(?:[.,]\d{1,5})[ \t]+(MXP)(?!O)/g,
  MXV: /\d{1,6}(?:[.,]\d{3})*(?:[.,]\d{1,5})[ \t]+(MXV)(?!O)/g,
  PHP: /\d{1,6}(?:[.,]\d{3})*(?:[.,]\d{1,5})[ \t]+(PHP)(?!O)/g
}

const symbols = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  DKK: 'kr',
  SKK: 'Sk',
  PLN: 'zł',
  MXN: '$',
  MXP: '$',
  MXV: 'V',
  PHP: '₱'
}

export default {
  default_currency_format: {
    decimal: ',',
    separator: '.'
  },
  is_currency(string){
    return !!this.match_symbol(string).matched
  },
  get_currency(text){
    const cur = this.match_symbol(text)
    return {
      matched_string: cur.matched,
      amount: this.convert_to_currency(
        cur.matched,
        cur.symbol
      ),
      symbol: cur.symbol,
      sign: symbols[cur.symbol]
    }
  },
  convert_to_currency(amount, symbol){
    amount = amount.replace(symbol, '')
    amount = this.to_common_decimal_notation(amount)
    return currency(amount, {
      symbol: symbols[symbol],
    })
  },
  match_symbol(string){
    let matched = null
    let matched_symbol = null
    Object.keys(matchers).forEach((symbol) => {
      if (string.match(matchers[symbol])){
        matched = string.match(matchers[symbol])[0]
        matched_symbol = symbol
      }
    })
    return {
      matched,
      symbol: matched_symbol
    }
  },
  to_common_decimal_notation(val) {
    val = val.trim();
    let result = val.replace(/[^0-9]/g, '')
    if (/[,.]\d{1,2}$/.test(val)) {
      const decimals = val.match(/[,.]\d{1,2}$/)[0].replace(',', '').replace('.', '')
      if (decimals.length < 2){
        result = `${result}0`
      }
      result = result.replace(/(\d{1,2})$/, '.$1');
    }
    return result
  }
}
