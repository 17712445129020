<template>
    <vue-infinite-viewer :zoom="zoom" :displayVerticalScroll="false" :displayHorizontalScroll="false"
        @pinch="e => onPinch(e)" ref="viewer" class="headless-viewer" :usePinch="true">
        <slot></slot>
    </vue-infinite-viewer>
</template>

<script>
import VueInfiniteViewer from 'vue-infinite-viewer'
const LAYER_CONFIG_MENU_WIDTH = 120

export default {
    props: {
        zoom: { type: Number, default: 1 },
        onPinch: { type: Function, default: () => { } },
        objectDimensions: { type: Object, default: () => ({ width: 0, height: 0 }) },
        xOffsetFactor: { type: Number, default: null },
    },
    components: { VueInfiniteViewer },
    data() {
        return {

        }
    },
    mounted() {
        this.$nextTick(() => {
            this.scrollCenter()
        })
    },
    methods: {
        scrollCenter() {
            // this.$refs.viewer.scrollCenter({ absolute: true })

            let RANGE_TO_CENTER_WITHIN = $('.studio__player__preview').width() - LAYER_CONFIG_MENU_WIDTH
            let RANGE_TO_CENTER_WITHIN_HEIGHT = $('.studio__player__preview').height()

            if (RANGE_TO_CENTER_WITHIN < 0) {
                RANGE_TO_CENTER_WITHIN = $('.editor__main-panel').width()
                RANGE_TO_CENTER_WITHIN_HEIGHT = $('.editor__main-panel').height()
            }


            let WIDTH_OFFSET_PERCENTAGE = .15;
            let HEIGHT_OFFSET_PERCENTAGE = .1;
            if (this.objectDimensions.height > this.objectDimensions.width) {
                WIDTH_OFFSET_PERCENTAGE = .2;
                HEIGHT_OFFSET_PERCENTAGE = .075;
            } else if (this.objectDimensions.height < this.objectDimensions.width) {
                WIDTH_OFFSET_PERCENTAGE = .075;
                HEIGHT_OFFSET_PERCENTAGE = .25;
            }
            let X_OFFSET = RANGE_TO_CENTER_WITHIN * WIDTH_OFFSET_PERCENTAGE
            if (this.xOffsetFactor) {
                X_OFFSET = this.xOffsetFactor
            }
            setTimeout(() => {
                this.$refs.viewer.scrollTo(-(X_OFFSET), -(RANGE_TO_CENTER_WITHIN_HEIGHT * HEIGHT_OFFSET_PERCENTAGE), {
                    easing: 0,
                    absolute: true,
                    duration: 0
                })
            }, 1)
        }
    }
}
</script>