<template>
  <div style="padding: 12px;" v-if="layer.config.list">
    <div
      :style="{ color: layer.color, display: 'flex', flexDirection: 'column', alignItems: layer.config.list.align_list, gap: layer.config.list.list_spacing + 'px' }">
      <div v-for="(item, index) in listItems" :style="{
        fontSize: layer.fontSize * .9 + 'px',
        display: 'flex',
        background: listBgColor,
        padding: '10px',
        borderRadius: '10px',
        flexDirection: iconPosition === 'l' ? 'row' : 'row-reverse',
        gap: layer.config.list.icon_spacing + 'px',
        alignItems: 'center',
        '--icon-size': (layer.fontSize * 0.9 * 1.2 * iconSizeFactor) + 'px' // 120% of font size
      }" :key="index">
        <template v-if="iconType !== 'none'">
          <div v-if="isImage(icon)" :style="{
            minWidth: 'var(--icon-size)',
            minHeight: 'var(--icon-size)',
            backgroundImage: 'url(' + icon + ')',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }"></div>
          <i v-else :class="iconType"
            :style="{ fontSize: 'var(--icon-size)', color: layer.config.list.icon_color }"></i>
        </template>
        <span :style="{ fontSize: (layer.fontSize * .7) + 'px', textWrap: 'wrap', fontFamily: font }"
          v-html="parse(item.text)"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
  props: ['layer', 'animation_uid'],
  mixins: [FontMixin],
  data() {
    return {

    }
  },
  computed: {
    icon() {
      return this.layer.config.list.icon || ''
  },
  iconType() {
    return this.layer.config.list.icon_type || ''
    },
    listBgColor() {
      return this.layer.config.list.show_bg ? (this.layer.config.list.bg_color || 'transparent') : 'none';
    },
    listItems() {
      if (this.layer.config && this.layer.config.list) {
        return this.layer.config.list.items || []
      }
      return []
    },
    iconPosition() {
      if (!this.layer.config || !this.layer.config.list) return 'l';
      return this.layer.config.list.icon_position || 'l';
    },
    iconSizeFactor() {
      const iconSize = this.layer.config.list.icon_size || 0; // default to 0 if not set
      // Transform icon_size to a factor between 0.5 and 2
      return 0.5 + ((iconSize + 100) / 300) * 1.5;
    }
  },
  methods: {
    parse(text) {
      return highlight.highlight(text,
        this.layer.config?.highlights?.background_color,
        this.layer.config?.highlights?.text_color)
    },
    isImage(icon) {
      return /\.(jpeg|jpg|gif|png|svg|webp)$/.test(icon);
    }
  }

}
</script>