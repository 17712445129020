var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":("layer_textarea_" + _vm.animation_uid + "_" + (_vm.layer.id))}},[(_vm.type_writer)?_c('div',{ref:"toFit",style:({
  fontFamily: _vm.font,
  margin: 0,
  overflow: 'hidden',
  color: _vm.layer.color,
})},_vm._l((_vm.type_writer_characters),function(char,index){return _c('span',{key:index,class:("typewriter-char-" + _vm.animation_uid)},[(char === '\n')?_c('span',[_vm._v(" ")]):_c('span',[_vm._v(_vm._s(char))])])}),0):_c('pre',{ref:"toFit",style:({
  fontFamily: _vm.font,
  margin: 0,
  overflow: 'hidden',
  color: _vm.layer.color,
})},[_vm._v("    "),_c('span',{attrs:{"id":("layer_text_" + _vm.animation_uid + "_" + (_vm.layer.id))},domProps:{"innerHTML":_vm._s(_vm.text_value)}}),_vm._v("\n  ")])])}
var staticRenderFns = []
export { render, staticRenderFns }