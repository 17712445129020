<template>
  <div>
    <span :style="{
      'white-space': layer.config['word_wrap'] == 'initial' ? 'initial' : '',
      fontFamily: font,
      fontSize: `${layer.fontSize}px`,
      color: color,
      'word-break': 'break-word',
      'hyphens': 'auto',
      WebkitLineClamp: layer.config['word_wrap_line_clamp'] || 4
    }" class="multine-ellipsis"
      :id="`multine-ellipsis_${animation_uid}_${layer.id}`">
      <span :id="`layer_text_${animation_uid}_${layer.id}`" v-if="disabled" v-html="parse(text_value)"></span>
      <span style="display: block;" :id="`layer_text_${animation_uid}_${layer.id}`" v-else
        v-html="parse(text_value) || `Layer ${layer.id}`"></span>
    </span>
  </div>
</template>

<script>
import { mustache, highlight } from '../../lib/parsers'
import { apply } from './tags'

export default {
  props: ['layer', 'samples', 'disabled', 'brandkit', 'animation_uid'],
  data() {
    return {
      text: '',
      config: {}
    }
  },
  watch: {
    layer: {
      deep: true,
      immediate: true,
      handler(newval, oldval) {
        this.text = newval.text
        this.config = newval.config
      }
    }
  },
  computed: {
    font() {
      if (this.layer.dynamic_font_id) {
        let font_id = mustache.fill_in(this.layer.dynamic_font_id, { brandkit: this.brand })
        if (this.$root.$store && this.$root.$store.state.fonts) {
          let font = this.$root.$store.state.fonts.find(f => f.id == font_id)
          if (font)
            return font.face
        }
        return this.layer.font
      } else {
        return this.layer.font
      }
    },
    color() {
      return mustache.fill_in(this.layer.color, this.sample)
    },
    sample: function () {
      if (this.samples.data)
        return { ...this.samples.data[this.samples.indice], brandkit: { ...this.brand } } || {}
      return {}
    },
    brand: function () {
      let brand = Object.keys(this.$props.brandkit).length > 0 ? this.$props.brandkit : (this.$root.$store ? this.$root.$store.state.brandkit : {})
      let placeholder_brand = {}
      if (this.samples.data[this.samples.indice] && this.samples.data[this.samples.indice].brandkit) {
        placeholder_brand = this.samples.data[this.samples.indice].brandkit
      }

      let result = { ...brand }
      for (let key in brand) {
        if (!brand[key] && placeholder_brand[key]) {
          result[key] = placeholder_brand[key]
        }
      }

      return result;
    },
    text_value: function () {
      let string = this.text
      if (!string) return null
      try {
        if (this.sample) {
          return mustache.fill_in(string, this.sample)
        } else {
          return string
        }
      } catch (e) {
        return ' '
      }
    }
  },
  methods: {
    parse(str) {
      let result = apply(str || "", this.sample, this.config) || ""
      if (result === 'undefined')
        result = this.text.replace('{{', '').replace('}}', '')
      // Remove all HTML tags
      result = result.replace(/<[^>]*>?/gm, '')

      if (this.layer.config.highlights) {
        return highlight.highlight(result,
          this.layer.config.highlights.background_color,
          this.layer.config.highlights.text_color)
      } else {
        return highlight.highlight(result)
      }
    },
  }
}
</script>
