import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class ReviewStarsFlyIn extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })
  }

  name() {
    return 'Review Stars Fly-in'
  }

  target() {
    return `.review-star-${this.uid}`
  }

  cleanup() {
    $(this.target()).css({
      transform: 'translateX(0px) scale(1)',
      opacity: 1
    })
  }

  prepare() {
    // Position stars off-screen to the left and set opacity to 0
    $(this.target()).css({
      transform: 'translateX(-100%) scale(0)',
      opacity: 0
    })
  }

  static int_name() {
    return 'review_stars_fly_in'
  }

  static exposed_to() {
    return []
  }

  static properties() {
    const props = {
      ...super.properties(),
      direction: {
        type: 'Boolean',
        default: true,
        display: {
          true: 'in',
          false: 'out'
        }
      }
    }
    delete props.easing
    return props
  }

  animation_config(duration = this.duration) {
    const anim_conf = {
      targets: `.review-star-${this.uid}`,
      translateX: [anime.stagger(100, { start: -1000 }), 0],
      scale: [0, 1],
      opacity: [0, 1],
      easing: 'easeOutBack',
      duration,
      delay: anime.stagger(10, { start: 0 }),
      loop: false,
    }
    return anim_conf;
  }

  animation() {
    const duration = this.duration * 0.5;
    const timeline = anime.timeline({
      duration,
      autoplay: false,
      complete: () => {
        this.finish()
      }
    })

    timeline.add(this.animation_config(duration))

    return timeline
  }
}
