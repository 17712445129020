import facebookExpressionStore from '../../../expression/store/modules/fb_expression'
import { loadComponent } from './load_component'

const EXPRESSION_NAMESPACE = 'FacebookExpression'

export const loadExpressionComponent = ({
  component, tag, element, callback
}) => {
  const renderComponent = { tag, component }
  const rootNamespace   = EXPRESSION_NAMESPACE
  const storeModule     = facebookExpressionStore
  const execBeforeInit = ({
    store, namespace, expressionId, expressionType, options
  }) => {
    store.dispatch(`${namespace}/setContext`, expressionType);
    store.dispatch(`${namespace}/firstOrCreate`, { channelId: expressionId, ...options });
    if (callback) callback({ store, expressionId, namespace })
  }

  loadComponent({
    rootNamespace, renderComponent, storeModule, element, execBeforeInit
  })
}
