<template>
  <div :class="{ 'layer-config__essentials': essentials }">
    <div class="form-group" v-if="show_input">
      <input ref="layerText" type="text" :value="layer.text" @input="(e) => updateLayer('text', e.target.value)">
    </div>
    <div class="button__tab_group"
      v-if="(layer.layer_type === 'text' || layer.layer_type === 'text_area') && is_studio && version > 1">
      <div class="button__toggle__light button--small"
        :class="{ 'button__toggle--active': layer.variable_tag === 'title' }"
        @click="updateLayer('variable_tag', 'title')">
        Title
      </div>
      <div class="button__toggle__light button--small"
        :class="{ 'button__toggle--active': layer.variable_tag === 'subtitle' }"
        @click="updateLayer('variable_tag', 'subtitle')">
        Subtitle
      </div>
      <div class="button__toggle__light button--small"
        :class="{ 'button__toggle--active': layer.variable_tag === 'cta' }" @click="updateLayer('variable_tag', 'cta')">
        CTA
      </div>
      <div class="button__toggle__light button--small"
        :class="{ 'button__toggle--active': layer.variable_tag === 'info' }"
        @click="updateLayer('variable_tag', 'info')">
        Info
      </div>
    </div>
    <div v-if="show_fonts" class="form-group">
      <div class="form_group__detach">
        <div class="form-group">
          <ColorField :show_dynamic="true" :element="element" :element_key="'color'" :palette="palette" />
        </div>
        <div class="button__tab_group">
          <button type="button" class="button__toggle__light"
            @click.stop="e => toggle_link(LayerModel.LINK_TEXT_COLOR)">
            <i v-if="layer.is_unlinked(LayerModel.LINK_TEXT_COLOR)" class="fa-regular fa-unlink" aria-hidden="true"></i>
            <i v-else class="fa-regular fa-link" aria-hidden="true"></i>
            <span v-if="layer.is_unlinked(LayerModel.LINK_TEXT_COLOR)" class="tooltip tooltip--top">Attach color</span>
            <span v-else class="tooltip tooltip--top">Detach color</span>

          </button>
        </div>
      </div>
      <Select placeholder="Select font..." :searchable="true" :selected="selected_font_id"
        v-on:change="val => (typeof val === 'object') ? setDynamicFont(val) : setFont(val)">
        <label class="editor__select-label">Brandkit</label>
        <hr>
        <div class="editor__select-option" :value="bf" :key="`brandkit_font_${bf.font.id}`"
          v-for="(bf) in brandkit_font_families" :style="{ 'font-family': bf.font.face }">

          {{ bf.prefix }} {{ bf.font.family }}
        </div>
        <br>
        <label class="editor__select-label">My fonts</label>
        <hr>
        <div class="editor__select-option" :value="font.id" :key="`org_font_${font.id}`"
          v-for="font in organization_font_families" :style="{ 'font-family': font.face }">
          {{ font.family }}
        </div>
        <br>
        <label class="editor__select-label">Default fonts</label>
        <hr>
        <div class="editor__select-option" :value="font.id" :key="font.id" v-for="font in font_families"
          :style="{ 'font-family': font.face }">
          {{ font.family }}
        </div>
      </Select>
    </div>

    <div class="form-group">
      <div class="tweener" v-if="show_fonts">
        <div style="width: 100%">
          <Select :selected="selected_font_id" v-on:change="val => setFont(val)" placeholder="Select format...">
            <div class="editor__select-option" :value="font.id" :key="font.id" v-for="font in font_formats(font)"
              :style="{ 'font-family': font.face }">
              {{ font.format }}
            </div>
          </Select>
        </div>
      </div>
      <br />
      <!-- <label for="points">Font size</label> -->
      <div class="tweener">
        <input type="range" style="width: 60%" min="6" step="1" :value="layer.fontSize" max="1000"
          @input="(e) => updateLayer('fontSize', e.target.value)">
        <input type="number" :value="layer.fontSize" style="min-width: 25%; margin-left: 10px; width: 25%"
          @input="(e) => updateLayer('fontSize', e.target.value)">

        <div class="button__tab_group">
          <button type="button" class="button__toggle__light" @click.stop="e => toggle_link(LayerModel.LINK_FONT_SIZE)">
            <i v-if="layer.is_unlinked(LayerModel.LINK_FONT_SIZE)" class="fa-regular fa-unlink" aria-hidden="true"></i>
            <i v-else class="fa-regular fa-link" aria-hidden="true"></i>
            <span v-if="layer.is_unlinked(LayerModel.LINK_FONT_SIZE)" class="tooltip tooltip--top">Attach size</span>
            <span v-else class="tooltip tooltip--top">Detach size</span>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Alignment</label>

      <div class="form_group__detach">

        <!--Dit mag 1 button worden waar je op door kunt klikken die wisselt tussen left, center en right-->
        <div class="button__tab_group">
          <button type="button" :class="{ 'button__toggle--active': layer.align == 'left' }"
            class="button__toggle__light" @click="align('left')">
            <i class="fa-regular fa-align-left" aria-hidden="true"></i>
            <span class="tooltip tooltip--top">Left</span>
          </button>
          <button type="button" :class="{ 'button__toggle--active': layer.align == 'center' }"
            class="button__toggle__light" @click="align('center')">
            <i class="fa-regular fa-align-center" aria-hidden="true"></i>
            <span class="tooltip tooltip--top">Center</span>
          </button>
          <button type="button" :class="{ 'button__toggle--active': layer.align == 'right' }"
            class="button__toggle__light" @click="align('right')">
            <i class="fa-regular fa-align-right" aria-hidden="true"></i>
            <span class="tooltip tooltip--top">Right</span>
          </button>
        </div>

        <!--Dit mag 1 button worden waar je op door kunt klikken die wisselt tussen top, center en bottom-->
        <div class="button__tab_group">
          <button type="button" :class="{ 'button__toggle--active': layer.config['v_align'] == 'top' }"
            class="button__toggle__light" @click="updateLayerConfig({ 'v_align': 'top' })">
            <i class="fa-regular fa-arrow-up" aria-hidden="true"></i>
            <span class="tooltip tooltip--top">Top</span>
          </button>
          <button type="button" :class="{ 'button__toggle--active': layer.config['v_align'] == 'center' }"
            class="button__toggle__light" @click="updateLayerConfig({ 'v_align': 'center' })">
            <i class="fa-regular fa-arrows-v" aria-hidden="true"></i>
            <span class="tooltip tooltip--top">Center</span>
          </button>
          <button type="button" :class="{ 'button__toggle--active': layer.config['v_align'] == 'bottom' }"
            class="button__toggle__light" @click="updateLayerConfig({ 'v_align': 'bottom' })">
            <i class="fa-regular fa-arrow-down" aria-hidden="true"></i>
            <span class="tooltip tooltip--top">Bottom</span>
          </button>
        </div>
        <div class="button__tab_group">
          <!--Als je op deze button klikt worden alle alignment opties ont-linkt-->
          <button type="button" class="button__toggle__light" @click.stop="e => toggle_link(LayerModel.LINK_ALIGNMENT)">
            <i v-if="layer.is_unlinked(LayerModel.LINK_ALIGNMENT)" class="fa-regular fa-unlink" aria-hidden="true"></i>
            <i v-else class="fa-regular fa-link" aria-hidden="true"></i>
            <span v-if="layer.is_unlinked(LayerModel.LINK_ALIGNMENT)" class="tooltip tooltip--top">Attach
              alignment</span>
            <span v-else class="tooltip tooltip--top">Detach alignment</span>
          </button>
        </div>
      </div>
    </div>


    <!--Als je op deze button klikt onderstaande menu in dropdown openen-->
    <!-- <div class="button__tab_group">
      <button type="button" class="button__toggle__light">
        <i class="fa-regular fa-line-height" aria-hidden="true"></i>
      </button>
    </div> -->

    <!--Dit mag allemaal in een dropdown komen-->
    <div class="tweener">
      <div class="form-group" style="width: 20%;">
        <label><i class="fa-regular fa-text-width" aria-hidden="true"></i></label>
        <input type="number" step="0.1" :value="layer.config['letter_spacing'] || 0"
          v-on:input="e => updateLayerConfig({ 'letter_spacing': e.target.value })" class="form-control--small">
      </div>
      <div class="form-group" style="width: 20%;">
        <label>
          <i class="fa-regular fa-paragraph" aria-hidden="true"></i>
          <i class="fa-regular fa-arrows-h" aria-hidden="true"></i>
        </label>
        <input type="number" step="0.1" :value="layer.config['word_spacing'] || 0"
          v-on:input="e => updateLayerConfig({ 'word_spacing': e.target.value })" class="form-control--small">
      </div>
      <div class="form-group" style="width: 20%;">
        <label>
          <i class="fa-solid fa-line-height"></i>
        </label>
        <input type="number" step="0.1" :value="layer.config['line_height'] || 1.1"
          v-on:input="e => updateLayerConfig({ 'line_height': e.target.value })" class="form-control--small">
      </div>
    </div>

    <div class="layer-config__collabse_group">
      <Collapseable :label="`Highlights`" :open="show_highlights" v-if="layer.layer_type !== 'group'" :unlinkable="true"
        :toggleable="false" :on_link_toggle="e => toggle_link(LayerModel.LINK_HIGHLIGHTS)"
        :unlinked="layer.is_unlinked(LayerModel.LINK_HIGHLIGHTS)">

        <div v-if="layer">
          <Highlights :layer="layer" :palette="palette" :onUpdate="l => onUpdate(l, true)" />
        </div>

      </Collapseable>
    </div>


    <div class="form-group" v-if="show_wrap">
      <label>Word wrap</label>
      <div style="display: flex;">
        <button type="button" style="font-weight: normal;"
          :class="{ 'button__toggle--active': layer.config['word_wrap'] == 'initial' }"
          class="button--light button--dark-text" @click="updateLayerConfig({ 'word_wrap': 'initial' })">
          wrap
        </button>

        <button type="button" style="margin-left: 10px; font-weight: normal;"
          :class="{ 'button__toggle--active': layer.config['word_wrap'] == '' || !layer.config['word_wrap'] }"
          class="button--light button--dark-text" @click="updateLayerConfig({ 'word_wrap': '' })">
          no wrap
        </button>
      </div>


      <div style="padding-top:10px;" v-if="layer.config['word_wrap'] == 'initial'">
        <label>Max lines before cut-off</label>
        <div style="width: 20%;">
          <input type="number" step="1" min="1" :value="layer.config['word_wrap_line_clamp'] || 4"
            v-on:input="e => updateLayerConfig({ 'word_wrap_line_clamp': e.target.value })" class="form-control--small">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ColorField from '../../feeds/fields/color.vue'
import Select from '../../components/select.vue'
import { mustache } from '../../lib/parsers'
import Lock from './lock.vue'
import Collapseable from '../components/collapseable.vue'
import Highlights from '../layer_configs/highlights.vue'
import LayerModel from '../store/modules/layers/model'

export default {
  props: {
    layer: { type: Object, required: true },
    fonts: { type: Array, required: true },
    essentials: { type: Boolean, required: false, default: true },
    onUpdate: { type: Function, required: true },
    inputName: { type: String, required: false, default: 'text' },
    palette: { type: Array, required: true },
    nightswatch: { type: Boolean, required: false, default: false },
    show_input: { type: Boolean, required: false, default: true },
    show_fonts: { type: Boolean, required: false, default: true },
    show_wrap: { type: Boolean, required: false, default: true },
    version: { type: Number, required: false, default: 1 },
    is_studio: { type: Boolean, required: false, default: false }
  },
  components: { ColorField, Select, Lock, Collapseable, Highlights },
  data() {
    return {
      show_highlights: false,
      element: {
        color: { hex: this.layer.color },
      },
      highlights: {
        text: {
          color: {
            hex8: '#FFFFFFFF'
          }
        },
        background: {
          color: {
            hex8: '#FF0000FF'
          }
        }
      }
    }
  },
  computed: {
    LayerModel() {
      return LayerModel
    },
    selected_font_id() {
      if (this.layer.dynamic_font_id) {
        return mustache.fill_in(this.layer.dynamic_font_id, { brandkit: this.$root.$store.state.brandkit })
      }
      return this.layer.font_id
    },
    font() {
      return this.fonts.find(f => f.id == this.selected_font_id) || { id: null, family: null, name: null, format: null }
    },
    brandkit_font_families() {
      let brandkit = this.$root.$store.state.brandkit

      if (brandkit) {
        const brandkit_fonts = []

        if (brandkit.primary_font_id) {
          brandkit_fonts.push({
            matcher: '{{brandkit.primary_font_id}}',
            prefix: '(Primary)',
            font: null
          })
        }

        if (brandkit.secondary_font_id) {
          brandkit_fonts.push({
            matcher: '{{brandkit.secondary_font_id}}',
            prefix: '(Secondary)',
            font: null
          })
        }

        return brandkit_fonts.map(bf => {
          bf.font = this.fonts.find(f => f.id == mustache.fill_in(bf.matcher, { brandkit }))
          return bf
        }).filter(bf => !!bf.font)

      } else {
        return null
      }
    },
    organization_font_families() {
      let selected = [this.fonts.find(f => f.id == this.layer.font_id && !!f.organization_id)]
      return this.fonts.reduce((memo, font) => {
        if (!memo.find(f => (f && font) ? (f.family == font.family) : false)) {
          if (!!font.organization_id === true) {
            memo.push(font)
          }
        }
        return memo
      }, selected.filter(Boolean)).sort((a, b) => a.family.localeCompare(b.family))
    },
    font_families() {
      let selected = [this.fonts.find(f => f.id == this.layer.font_id)]
      return this.fonts.reduce((memo, font) => {
        if (!memo.find(f => (f && font) ? (f.family == font.family) : false)) {
          if (!font.organization_id)
            memo.push(font)
        }
        return memo
      }, selected.filter(Boolean)).sort((a, b) => a.family.localeCompare(b.family))
    },
  },
  watch: {
    element: {
      deep: true,
      handler(newVal) {
        this.updateLayer('color', newVal.color.hex8)
      }
    },
    layer: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (newVal.config.highlights) {
            this.highlights.text.color.hex8 = newVal.config?.highlights?.text_color ?? '#FFFFFFFF'
            this.highlights.background.color.hex8 = newVal.config?.highlights?.background_color ?? '#FFFFFFFF'
          }
        }
      }
    }
  },
  methods: {
    toggle_link(prop) {
      let layer = LayerModel.factory(this.layer)
      if (layer.is_unlinked(prop)) {
        layer.link(prop)
      } else {
        layer.unlink(prop)
      }
      this.updateLayer('unlinked_properties', layer.unlinked_properties)
    },
    font_formats(font) {
      return this.fonts.filter(f => f.family.includes(font.family))
        .sort((a, b) => a.format.localeCompare(b.format))
    },
    updateLayerConfig(conf) {
      this.updateLayer('config', { ...this.layer.config, ...conf })
    },
    updateLayer(key, value) {
      const layer = { ...this.layer, [key]: value }
      this.$props.onUpdate(layer)
    },
    setFont(fontId) {
      const font = this.fonts.find(f => f.id === parseInt(fontId))
      this.$props.onUpdate({
        ...this.layer,
        font: font ? font.filename : '',
        dynamic_font_id: null,
        font_id: parseInt(fontId)
      })
      this.$forceUpdate()
    },
    setDynamicFont(brandkit_font) {
      const font = brandkit_font.font
      this.$props.onUpdate({
        ...this.layer,
        font: font ? font.filename : '',
        font_id: brandkit_font.font.id,
        dynamic_font_id: brandkit_font.matcher
      })
      this.$forceUpdate()
    },
    align(align) {
      this.$props.onUpdate({ ...this.layer, align })
    },
  }
}
</script>
