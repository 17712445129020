const SCANNER = /\{{2}[^}]+\}{2}/
const zip = (a, b) => a.map((k, i) => [k, b[i]]);

class Token {
  constructor(content){
    this.content = content
  }

  action(){
    return this.content.split('|')[1]
  }

  get_content(){
    return this.content.split('|')[0]
  }

  perform_action(data){
    if (data){
      if (this.action().includes('index:')){
        const indice = this.action().split(':')[1]
        if (data.length < indice){
          return data[data.length - 1]
        }
        return data[indice]
      }
      if (this.action() === 'random'){
        return data[Math.floor(Math.random() * data.length)]
      }
    }
    return data
  }
}

export default {
  scan(string){
    const matches = (string || '').match(SCANNER)
    if (matches){
      return matches.map((str) => str.replace('{{', '').replace('}}', ''))
    }
    return []
  },

  detect(string){
    return this.scan(string).map((token) => token.split('|')[0]).length > 0;
  },

  to_token_string(text){
    const strings = (text || '').split(SCANNER)
    const tokens  = this.scan(text).map((token) => new Token(token))
    if (!strings) return tokens
    return zip(strings, tokens).flat().filter(Boolean)
  },

  fill_in(text, variables){
    return this.to_token_string(text).reduce((output, token) => {
      if (token instanceof Token){
        let variable = this.resolve(token.get_content(), variables)
        if (token.action()){
          variable = token.perform_action(variable)
        }
        if (variable === null) variable = ''
        // if (!variable) throw `Excepted ${token.content}
        // to be included in ${JSON.stringify(variables)}` // eslint-disable-line
        return output + variable
      }
      return output + token
    }, '')
  },

  resolve(token_content, variables, output = ''){
    const content  = token_content.split('.')
    const token    = content[0]
    content.shift()
    if (content.length > 0){
      output = this.resolve(content.join('.'), variables[token], output)
    } else {
      // TODO TRY CATCH SHOULD NOT BE NEEDED
      try {
        output = variables[token]
      } catch (e){
        output = ''
      }
    }
    return output
  }
}
