<template>
    <div class="briefing_wizard__prompt_output">
        <span class="profile_initial">A</span>
        <div class="briefing_wizard__prompt">
            <div class="briefing_wizard__prompt_header">
                <span class="strong">Adflow</span>
                <span class="" v-if="created_at">{{ created_at }}</span>
            </div>
            <transition-group tag="div" name="fade"
                style="display: flex; align-items: flex-start; align-content: flex-start; gap: 12px; flex-shrink: 0; flex-wrap: wrap;">
                <div style="position: relative;" :key="`sug_it_${ad_index}_${ad.render_key}`"
                    v-for="(ad, ad_index) in l_ads">
                    <div @click="e => ad.id ? set_active_ad(ad) : null"
                        class="template_adopt adflow__briefing_small adflow__briefing_small--story">
                        <CompactPlayer v-if="active_size"
                            :style="`text-size-adjust: none; zoom: 0.225; margin-right: 40px; width: ${active_size.display_dimensions[0] + 20}px; height: ${active_size.display_dimensions[0] + 20}px;`"
                            :briefing_id="ad.id" :key="`sug_${ad.id}`" :brandkit="brandkit" :show="show_previews"
                            :size="active_size" />
                        <div class="loader" v-else></div>
                    </div>
                </div>
            </transition-group>
            <div class="briefing_wizard__prompt_actions">
                <div class="briefing_wizard__prompt_actions__row">
                    <button @click="e => set_version({
                    type: 'version',
                    pretty_name: `V${index + 1}`,
                    id: ad.id,
                    mode: null,
                    iteration_id: iteration.uuid
                })" class="briefing_wizard__prompt_button" :class="{
                    'briefing_wizard__prompt_button--active': find_version({
                        type: 'version',
                        mode: null,
                        id: ad.id,
                        iteration_id: iteration.uuid
                    })
                }" v-for="(ad, index) in iteration.ads">
                        V{{ index + 1 }}
                    </button>
                </div>
                <div class="briefing_wizard__prompt_actions__row" v-if="selected_version">
                    <button class="briefing_wizard__prompt_button" :class="{
                    'briefing_wizard__prompt_button--active': selected_command ? selected_command.type === 'assets' : false
                }" @click="e => set_command({
                    type: 'assets',
                    mode: 'shuffle',
                    pretty_name: 'Shuffle assets',
                    iteration_id: iteration.uuid
                })">Shuffle assets</button>
                    <Dropdown :active="show_rephrase_dropdown" :root="true" :right="-75" :top="last ? -170 : 50"
                        :on_toggle="state => show_rephrase_dropdown = state">
                        <template slot="btn" slot-scope="{is_active}">
                            <button class="briefing_wizard__prompt_button" :class="{
                    'briefing_wizard__prompt_button--active': selected_command ? selected_command.type === 'script' : false
                }">Rephrase text
                                <i class="fa-regular fa-chevron-down"></i>
                            </button>
                        </template>
                        <div class="plan__select_plan__options" style="height: 150px; overflow-y: scroll;">
                            <div class="plan__select_plan__option" :key="`rephrase_opt_${index}`"
                                v-for="(option, index) in rephrase_options" v-on:click="e => set_command({
                    type: 'script',
                    mode: option.mode,
                    pretty_name: option.name,
                    iteration_id: iteration.uuid
                })">
                                <div class="plan__select_plan__option_group">
                                    {{ option.name }}
                                </div>
                            </div>
                        </div>
                    </Dropdown>

                    <LanguagePicker :right="-75" :top="last ? -220 : 50" :default_language="campaign_briefing.language"
                        :active="selected_command ? selected_command.type === 'language' : false" :on_change="language => set_command({
                    type: 'language',
                    mode: language,
                    pretty_name: 'translate to ' + language,
                    iteration_id: iteration.uuid
                })" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompactPlayer from '../studio/components/compact_player.vue'
import Dropdown from '../components/dropdown.vue'
import LanguagePicker from './language_picker.vue'
export default {
    components: {
        CompactPlayer,
        Dropdown,
        LanguagePicker
    },
    props: {
        show_previews: {
            type: Boolean,
            required: true
        },
        active_size: {
            type: Object,
            required: true
        },
        active_ad: {
            type: Object,
            required: false
        },
        brandkit: {
            type: Object,
            required: true
        },
        iteration: {
            type: Object,
            required: true
        },
        on_commands_change: {
            type: Function,
            required: true
        },
        on_active_ad_change: {
            type: Function,
            required: true
        },
        campaign_briefing: {
            type: Object,
            required: true
        },
        last: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            l_ads: [this.iteration.ads],
            show_rephrase_dropdown: false,
            show_language_dropdown: false,
            languages: [
                "english (uk)",
                "english (us)",
                "german",
                "french",
                "italian",
                "spanish",
                "russian",
                "turkish",
                "polish",
                "dutch",
                "ukrainian",
                "greek",
                "czech",
                "swedish",
                "hungarian",
                "belarusian",
                "serbian",
                "portuguese",
                "bulgarian",
                "danish",
                "finnish",
                "slovak",
                "norwegian",
                "croatian",
                "bosnian",
                "albanian",
                "lithuanian",
                "slovenian",
                "latvian",
                "estonian",
                "macedonian",
                "romanian"
            ],
            rephrase_options: [
                {
                    name: "Rephrase",
                    mode: "rephrase"
                },
                {
                    name: "Highlight keywords",
                    mode: "highlight_keywords"
                },
                {
                    name: "Simplify the message",
                    mode: "simplify"
                },
                {
                    name: "Add more emoji's 😎",
                    mode: "add_emojis"
                },
                {
                    name: "Add more call to actions",
                    mode: "add_cta"
                },
                {
                    name: "Add more urgency",
                    mode: "add_urgency"
                },
                {
                    name: "Add more humor",
                    mode: "add_humor"
                },
                {
                    name: "Add more benefits",
                    mode: "add_benefits"
                },
                {
                    name: "Add more social proof",
                    mode: "add_social_proof"
                },
                {
                    name: "Add more scarcity",
                    mode: "add_scarcity"
                },
                {
                    name: "Add more storytelling",
                    mode: "add_storytelling"
                },
                {
                    name: "Add more authority",
                    mode: "add_authority"
                },
                {
                    name: "Add more curiosity",
                    mode: "add_curiosity"
                },
                {
                    name: "Add more FOMO",
                    mode: "add_fomo"
                },
            ],
            selected_version: null,
            selected_command: null,
            command_composition: {
                'version': null,
                'assets': null,
                'script': null,
                'language': null
            }
        }
    },
    watch: {
        iteration: {
            deep: true,
            immediate: true,
            handler(newval, oldval) {
                this.l_ads = newval.ads
            }
        }
    },
    computed: {
        created_at() {
            try {
                const date = new Date(this.iteration.ads[0].created_at * 1000)
                let str = date.toLocaleString()
                if (str === 'Invalid Date') {
                    return 'Now'
                } else {
                    return str
                }
            } catch (e) {
                return 'Now'
            }
        },
    },
    methods: {
        set_active_ad(ad) {
            this.on_active_ad_change(ad)
            this.set_version({
                type: 'version',
                pretty_name: `V${this.iteration.ads.indexOf(ad) + 1}`,
                id: ad.id,
                mode: null,
                iteration_id: this.iteration.uuid
            })
        },
        force_update(briefing) {
            this.l_ads = this.l_ads.map(ad => {
                if (ad.id === briefing.id) {
                    return { ...ad, render_key: Math.random() }
                }
                return ad
            })
        },
        clear_selection() {
            this.selected_version = null
            this.selected_command = null
            this.command_composition = {
                'version': null,
                'assets': null,
                'script': null,
                'language': null
            }
        },
        find_version(command) {
            return this.selected_version?.id == command.id
        },
        find_command(command) {
            return this.command_composition[command.type]?.id == command?.id
        },
        set_command(command) {
            this.show_rephrase_dropdown = false
            this.show_language_dropdown = false
            this.selected_command = command
            this.on_commands_change({
                iteration_id: this.iteration.uuid,
                commands: [
                    this.selected_version,
                    this.selected_command
                ].filter(Boolean)
            })
        },
        set_version(command) {
            this.selected_version = command
            this.on_commands_change({
                iteration_id: this.iteration.uuid,
                commands: [
                    this.selected_version,
                    this.selected_command
                ].filter(Boolean)
            })
        },
        toggle_command(command) {
            this.show_rephrase_dropdown = false
            let command_type_isset = !!this.command_composition[command.type]
            let command_mode_is_eql = this.command_composition[command.type]?.mode == command.mode
            let command_target_is_eql = this.command_composition[command.type]?.id == command.id
            if (command.type !== 'version')
                command_target_is_eql = false

            if (command_type_isset && (command_mode_is_eql || command_target_is_eql)) {
                this.command_composition[command.type] = null
            } else {
                this.command_composition[command.type] = command
            }

            this.on_commands_change({
                iteration_id: this.iteration.uuid,
                commands: Object.keys(this.command_composition).map(key => this.command_composition[key]).filter(Boolean)
            })
        }
    }
}
</script>