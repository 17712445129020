<template>
  <div class="feed-builder__text-input"> 
    <input type="text"
           :disabled="disabled"
           v-model="element[element_key]"
           v-on:change="(e)=>emit_change(e)">
  </div>
</template>


<script>

export default {
  data(){
    return { }
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    element: {
      type: Object,
      required: true
    },
    element_key: {
      type: String,
      required: true
    },
    on_change: {
      type: Function,
      required: false,
      defaullt: ()=>{}
    }
  },

  methods: {
    emit_change(e){
      if(this.$props.on_change){
        this.on_change(this.element[this.element_key], this.element_key)
      }
      if(this.$props.submit_on_change === true){
        setTimeout(()=>{
          this.$el.closest('form').submit()
        }, 1)
      }
    }
  }
}

</script>
