<template>
    <div :style="{ color: layer.color, width: layer.width + 'px' }" class="callout__insta-align-container">
        <div 
            class="callout__insta-content" 
            :class="{
                'callout__insta-flex-row': isHorizontal && !isRight,
                'callout__insta-flex-column': isVertical && !isBottom,
                'callout__insta-flex-column-bottom': isBottom,
                'callout__insta-flex-row-right': isRight
            }"
        >
            <!-- SVG for Left/Right Alignment -->
            <svg
                v-if="isLeft || isRight"
                :class="['callout__insta-arrow-svg', arrowPositionClass]"
                width="72"
                height="91"
                viewBox="0 0 72 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M68.4963 3C29.5 10.5 10.9963 29.1 18.9963 87.5M18.9963 87.5L28 69M18.9963 87.5L3 75"
                    :stroke="bgColor"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>

            <!-- SVG for Top/Bottom Alignment -->
            <svg
                v-if="isTop || isBottom"
                :class="['callout__insta-arrow-svg', arrowPositionClass]"
                width="41"
                height="62"
                viewBox="0 0 41 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M37.4177 59.0026C30.918 34.5029 23.4353 23.9094 3.13478 3.75762M3.13478 3.75762L7.28475 23.9094M3.13478 3.75762L23.4353 3.90539"
                    :stroke="bgColor"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>

            <!-- Text Content -->
            <div
                class="callout__insta-works_title"
                :style="`color: ${layer.color}; font-size: ${layer.fontSize * 0.45}px; background: ${bgColor}; font-family: ${font}`"
            >
                {{ layer.text }}
            </div>

        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'

export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],
    computed: {
        component_name() {
            return this.layer.config.component.type
        },
        bgColor() {
            return this.layer.config[this.component_name].bg_color || 'white'
        },
        align() {
            return this.layer.config[this.component_name].align || 'left'
        },
        lineAlign() {
            return this.layer.config[this.component_name].line_align || 'normal'
        },
        isLeft() {
            return this.align === 'left'
        },
        isRight() {
            return this.align === 'right'
        },
        isTop() {
            return this.align === 'top'
        },
        isBottom() {
            return this.align === 'bottom'
        },
        isHorizontal() {
            return this.isLeft || this.isRight
        },
        isVertical() {
            return this.isTop || this.isBottom
        },
        isMirrored() {
            return this.lineAlign === 'invert'
        },
        arrowPositionClass() {
            if (this.isLeft && this.lineAlign === 'normal') return 'callout__insta-arrow-left-down'
            if (this.isLeft && this.lineAlign === 'invert') return 'callout__insta-arrow-left-up'
            if (this.isRight && this.lineAlign === 'normal') return 'callout__insta-arrow-right-down'
            if (this.isRight && this.lineAlign === 'invert') return 'callout__insta-arrow-right-up'
            if (this.isTop && this.lineAlign === 'normal') return 'callout__insta-arrow-top-left'
            if (this.isTop && this.lineAlign === 'invert') return 'callout__insta-arrow-top-right'
            if (this.isBottom && this.lineAlign === 'normal') return 'callout__insta-arrow-bottom-left'
            if (this.isBottom && this.lineAlign === 'invert') return 'callout__insta-arrow-bottom-right'
            return ''
        }
    },
    methods: {
        parse(text) {
            return highlight.highlight(
                text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color
            )
        },
    }
}
</script>