<template>
  <span v-if="show">
    <Scene :scene="scene" :key="scene.id" :indice="index" :playing="playing" :size="{ key: size }" :brandkit="brandkit"
      :active="active_scene ? active_scene.id == scene.id : false" :ref="'scene_' + scene.id" :anim_uid="size"
      :active_playing="playing && current_scene_indice == index" :complete="e => complete(scene, index, e)"
      :on_progress="(scene, anim) => on_progress(scene, index, anim)" v-for="(scene, index) in to_be_rendered_scenes" />
  </span>
</template>

<script>
import { mapState } from 'vuex'
import CanvasPreview from '../../catalogs/canvas_preview.vue'
import Scene from './scene_player_scene.vue'
import { EventBus } from '../../lib/media/eventbus.js'
export default {
  components: { CanvasPreview, Scene },
  data() {
    return {
      zindices: {},
      completion_counter: 0,
      show: true,
      audio: false,
      total_progress: 0,
      current_scene_indice: 0,
      playing: false,
      duration_interval: null,
      scene_index_map: {},
    }
  },
  watch: {
    briefing_canvasses: {
      handler(newVal) {
        this.scene_index_map = newVal.reduce((acc, scene, index) => {
          acc[scene.id] = index
          return acc
        }, {})
      },
      immediate: true,
      deep: true
    },
    player_paused: {
      handler: function (paused) {
        if (paused) {
          this.pause()
        } else {
          this.play()
        }
      }
    },
    active_scene(new_scene, old_scene) {
      this.briefing_canvasses.forEach((s, i) => {
        this.pause(i)
      })
      this.$store.commit('SCENE_PLAYER_PAUSED', true)
      setTimeout(() => {
        this.briefing_canvasses
          .filter(s => s.id !== new_scene.id)
          .forEach(s => {
            if (this.$refs['scene_' + s.id]) {
              this.$refs['scene_' + s.id][0]?.reset()
            }
          })
        if (old_scene && new_scene.id === old_scene.id) {
          if (this.$refs['scene_' + new_scene.id])
            this.$refs['scene_' + new_scene.id][0]?.reset()
        }
      }, 60)

      if (new_scene) {
        let indice = this.briefing_canvasses.findIndex(s => s.id == new_scene.id)
        this.current_scene_indice = indice
        this.$store.commit('SCENE_PLAYER_PROGRESS', this.calculate_scene_progress_percentage(new_scene, indice, { progress: 0 }))
      } else {
        this.$store.commit('SCENE_PLAYER_PROGRESS', 0)
      }
    }
  },
  mounted() {
    this.duration_interval = setInterval(() => {
      this.$store.commit('SCENE_PLAYER_DURATION', this.briefing_canvasses.reduce((acc, scene) => {
        if (this.$refs['scene_' + scene.id] && this.$refs['scene_' + scene.id][0])
          acc += this.$refs['scene_' + scene.id][0].duration()
        return acc
      }, 0))
    }, 300)
    document.addEventListener("visibilitychange", (event) => {
      if (document.visibilityState == "visible") {
        if (this.playing)
          this.audio.play()
      } else {
        if (this.playing)
          this.stop_audio(false)
      }
    })
  },
  destroyed() {
    clearInterval(this.duration_interval)
    this.stop_audio()
  },
  computed: {
    ...mapState({
      brandkit: state => state.brandkit,
      briefing: state => state.briefing,
      active_scene: state => state.active_scene,
      progress: state => state.player_progress,
      player_paused: state => state.player_paused,
      sounds: state => state.sounds,
      scene_context: state => state.scene_context,
      size: state => state.size,
      ui_edit_state: state => state.ui_edit_state,
    }),
    selected_sound() {
      return this.$store.getters.selected_sound
    },
    briefing_canvasses() {
      return this.$store.getters.root_scenes
    },
    briefing_meta() {
      return this.$store.getters.briefing_meta
    },
    to_be_rendered_scenes() {
      // return this.briefing_canvasses.slice(this.current_scene_indice, this.current_scene_indice + 2)
      return this.briefing_canvasses
    },
  },
  methods: {
    get_active_scene_index() {
      // if(this.active_scene && this.current_scene_indice < this.briefing_canvasses.findIndex(scene => scene.id == this.active_scene.id))
      //   return this.briefing_canvasses.findIndex(scene => scene.id == this.active_scene.id)
      // else {

      // }
      // return this.current_scene_indice
      return this.briefing_canvasses.findIndex(scene => scene.id == this.active_scene.id)
    },
    calculate_scene_progress_percentage(scene, index, anim) {
      // Calculate the total duration of all scenes
      let total_duration_in_ms = this.briefing_canvasses.reduce((total, scene) => {
        try {
          return total + this.$refs['scene_' + scene.id][0].duration();
        } catch (e) {
          return total + scene.min_scene_duration
        }
      }, 0);

      // Sum the durations of all scenes up to the current one
      let duration_up_to_current_scene_in_ms = this.briefing_canvasses.slice(0, index).reduce((total, scene) => {
        try {
          return total + this.$refs['scene_' + scene.id][0].duration();
        } catch (e) {
          return total + scene.min_scene_duration
        }
      }, 0);

      // Calculate the duration of the current scene up to the current animation progress
      let current_scene_duration_in_ms = 0
      try {
        current_scene_duration_in_ms = this.$refs['scene_' + scene.id][0].duration();
      } catch (e) {
        current_scene_duration_in_ms = scene.min_scene_duration
      }
      let current_scene_progress_in_ms = current_scene_duration_in_ms * (anim.progress / 100);

      // Calculate the progress up to and including the current scene
      let progress_in_ms = duration_up_to_current_scene_in_ms + current_scene_progress_in_ms;

      // Convert progress to a percentage of the total duration
      let progress_percentage = (progress_in_ms / total_duration_in_ms) * 100;

      return progress_percentage;
    },
    pause(indice = this.current_scene_indice, callback = () => { }) {
      if (this.$refs['scene_' + this.briefing_canvasses[indice].id] && this.$refs['scene_' + this.briefing_canvasses[indice].id][0])
        this.$refs['scene_' + this.briefing_canvasses[indice].id][0].pause()
      this.stop_audio(false)
      this.playing = false
      callback()
    },
    play() {
      EventBus.$emit('hide')
      this.playing = true
      let indice = this.current_scene_indice
      this.start_audio(() => {
        if (indice == -1)
          indice = 0
        this.$refs['scene_' + this.briefing_canvasses[indice].id][0].play()
      }, indice)
    },
    start_audio(callback, indice = 0) {
      if (this.audio)
        this.audio.pause()
      if (this.selected_sound && this.selected_sound.url) {
        this.audio = new Audio(this.selected_sound.url)
        let current_time_set = false
        this.audio.addEventListener('canplaythrough', (e) => {
          this.audio.volume = 0.5
          if (!current_time_set) {
            current_time_set = true
            let dur = this.briefing_canvasses.map(bc => bc.min_scene_duration).reduce((a, b) => a + b, 0) * (this.progress / 100)
            this.audio.currentTime = dur / 1000
          }
          this.audio.play()
          callback()
        })
      } else {
        callback()
      }
    },
    stop_audio(fadeout = false) {
      if (this.audio && fadeout) {
        setTimeout(() => {
          for (let i = 0; i < 50; i++) {
            setTimeout(() => {
              this.audio.volume -= 0.0053
            }, i * 10)
          }
        }, this.briefing_canvasses[this.get_active_scene_index()].min_scene_duration - 600)
      } else if (this.audio) {
        this.audio.pause()
      }
    },
    on_progress(scene, index, anim) {
      index = this.scene_index_map[scene.id]
      let progress = this.calculate_scene_progress_percentage(scene, index, anim)
      this.$store.commit('SCENE_PLAYER_PROGRESS', progress)
    },
    reset(indice = 0) {
      if (this.audio)
        this.audio.pause()
      this.audio = null
      this.playing = false
      this.current_scene_indice = this.get_active_scene_index()
      let progress = this.calculate_scene_progress_percentage(this.active_scene, this.get_active_scene_index(), { progress: 0 })
      this.$store.commit('SCENE_PLAYER_PROGRESS', progress)
      this.$store.commit('SCENE_PLAYER_PAUSED', true)
      if (this.active_scene && this.scene_context.id == this.active_scene.id)
        this.$store.commit('SET_SCENE_CONTEXT', { ...this.active_scene })
    },
    complete(scene, index, anim) {
      index = this.scene_index_map[scene.id]
      if (index == this.briefing_canvasses.length - 1 || this.ui_edit_state === 'ANIMATING') {
        this.reset()
        this.briefing_canvasses.forEach((scene, index) => {
          if (this.$refs['scene_' + scene.id][0])
            this.$refs['scene_' + scene.id][0]?.reset()
        })
      } else {
        this.current_scene_indice = index + 1
        this.$refs['scene_' + this.briefing_canvasses[index + 1].id][0].play()
      }

      if (index == this.briefing_canvasses.length - 2) {
        this.stop_audio(true)
      }
    },
  }
}
</script>
