<template>
	<transition name="fade">
		<div v-if="l_show" v-on-clickaway="away" class="studio__exports-container">

			<span v-if="is_video">
				<p class="studio__nav__credit_text" v-if="credits > -1">Videos credits remaining: {{
					credits }}</p>
			</span>

			<span>			
				<form class="editor__size-selector-options">
					<label class="editor__size-selector-option">
						<input type="checkbox" name="option1" v-model="available_sizes['1:1']">
						<span class="editor__size-selector-option__name">Square</span>
						<span>(1:1)</span>
					</label>
					<label class="editor__size-selector-option">
						<input type="checkbox" name="option2" v-model="available_sizes['9:16']">
						<span class="editor__size-selector-option__name">Story</span>
						<span>(9:16)</span>
					</label>
					<label class="editor__size-selector-option">
						<input type="checkbox" name="option3" v-model="available_sizes['16:9']">
						<span class="editor__size-selector-option__name">Wide</span>
						<span>(16:9)</span>
					</label>
				</form>
			</span>			

			<a class="button button--full-width modal__open" data-modal-id="payment_plans"
				v-if="credits === 0">
				<span><i class="fa-solid fa-bolt"></i> Upgrade to export</span>
			</a>

			<span v-if="credits !== 0">
				<span v-if="exportable">
					<button class="button button--full-width" v-if="!exporting" :disabled="credits === 0"
						@click="export_assets">Export</button>
					<button class="button button--full-width" v-else disabled>
						<div class="loader"></div>
					</button>
				</span>
				<span v-else>
					<button class="button button--full-width" disabled>
						No sizes selected
					</button>
				</span>
			</span>


			<div class="studio__export-batch" v-for="folder in folders.slice(0, 5)" :key="folder.sid">
				<div class="studio__export-batch__header">
					<div class="studio__export-batch__title">
					</div>
					<a class="button button--light" :href="`/folders/${folder.sid}.zip`"
						v-if="finished || folder.batch ? folder.batch.finished : false">
						Download
					</a>
				</div>
				<div class="studio__export-batch__body">
					<div class="studio__export-preview" v-for="asset in folder.assets" :key="asset.sid">
						<Video v-if="is_video" :preload="'metadata'" :url="asset.file_url" />
						<Thumb v-else :src="asset.file_url"></Thumb>
					</div>
				</div>
			</div>

		</div>
	</transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2';
import Thumb from '../thumb.vue'
import Video from './video.vue'

export default {
	components: {
		Thumb,
		Video
	},
	mixins: [clickaway],
	props: {
		n_expected_exports: {
			type: Number,
			required: true
		},
		on_export: {
			type: Function,
			required: true
		},
		credits: {
			type: Number,
			required: true
		},
		batch: {
			type: Object,
			default: () => ({}),
			required: false
		},
		show: {
			type: Boolean,
			default: false
		},
		folders: {
			type: Array,
			default: () => []
		},
		on_close: {
			type: Function,
			default: () => { }
		},
		is_video: {
			type: Boolean,
			default: false
		},
		plans: { type: Array, required: true },
    active_plan_id: { type: String, required: true },
	},
	data() {
		return {
			l_show: this.show,
			finished: false,
			exporting: false,
			available_sizes: {
				'1:1': true,
				'9:16': true,
				'16:9': true
			},
			sizes: []
		}
	},
	computed: {
		exportable() {
			return this.sizes.length > 0
		},
		active() {
				return !!this.plans.find(plan => plan.id === this.active_plan_id)
		},
	},
	watch: {
		available_sizes: {
			immediate: true,
			deep: true,
			handler(newVal) {
				this.sizes = Object.keys(newVal).filter(k => newVal[k])
			}
		},
		show(newVal) {
			this.l_show = newVal
		},
		batch: {
			deep: true,
			handler(new_val) {
				this.finished = new_val?.finished
				if (new_val === null) {
					this.exporting = false
				}
			}
		},
		l_show: {
			immediate: true,
			handler(newVal, oldVal) {
				if (newVal === true) {
					setTimeout(() => {
						$('body').trigger('load-modal')
					}, 200)
				}
			}
		},
	},
	methods: {
		export_assets() {
			this.exporting = true
			this.on_export(this.sizes)
		},
		away() {
			this.l_show = false
			this.on_close()
		}
	}
}
</script>
