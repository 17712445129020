import anime from 'animejs/lib/anime';
import Animation from '../animation';

export default class Typewriter extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    });
  }

  name() {
    return 'Typewriter';
  }

  cleanup() {
    $(`#layer_text_${this.uid}_${this.layer.id}`).html(this.layer.text)
    $(`.typewriter-char-${this.uid}`).css('opacity', 1)
  }

  prepare() {
    $(`.typewriter-char-${this.uid}`).css('opacity', 0)
  }

  static int_name() {
    return 'typewriter';
  }

  target() {
    return `#layer_text_${this.uid}_${this.layer.id}`;
  }

  static exposed_to() {
    return ['text', 'text_area']; // You can customize the supported layer types
  }

  animation_config(duration = this.duration) {
    const characterLength = this.layer.text.length;
    const animationDuration = duration / characterLength;

    const animations = [];
    $(`.typewriter-char-${this.uid}`).each((index, elem) => {
      animations.push({
        animation: {
          targets: elem,
          opacity: [0, this.opacity()],
          easing: this.easing(),
          duration: animationDuration * 0.5,
        },
        delay: ((index * this.duration) / characterLength) * 0.85
      });
    });
    return animations;
  }

  static pretty_name() {
    return 'Typewriter';
  }

  animation() {
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
    });

    this.animation_config().forEach(({ animation, delay }) => {
      timeline.add({
        ...animation,
      }, delay);
    })

    return timeline;
  }
}
