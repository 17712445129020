import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Swiper extends Component {
  name() {
    return 'Swiper'
  }

  labels() {
    return ['video']
  }

  icon() {
    return 'fa-regular fa-arrows-h'
  }

  config(opts = {}) {
    return Layer.factory({
      layer_type: 'swiper',
      text: '',
      width: 300,
      height: 300,
      config: {
        show_animations: true,
        animations: [{ type: 'swiper', in_place: false, easing: 'easeInOutExpo' }],
        object_fit: 'contain',
        swiper: {
          assets: [],
          use_items: false,
          version: 2
        }
      },
      ...opts
    })
  }
}
