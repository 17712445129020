import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class CubeSpinner extends Component {
  name() {
    return 'CubeSpinner'
  }

  labels() {
    return ['video']
  }

  icon() {
    return 'fa-regular fa-cube'
  }

  config(opts = {}) {
    return Layer.factory({
      layer_type: 'cube_spinner',
      text: '',
      width: 300,
      height: 300,
      config: {
        show_animations: true,
        animation: {
          delay_percentage: 0,
          duration_percentage: 100,
          in: null,
          while: null,
          out: null,
        },
        animations: [
          {
            type: 'inwhileout'
          }],
      },
      ...opts
    })
  }
}
