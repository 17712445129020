<template>
  <div>
    <Collapseable :open="show" :toggleable="false" :unlinkable="false" :label="`shadow ${index + 1}`"
      :on_remove="() => remove()" class="layer-config__sub-collabse">
      <div class="form-group">
        <label>Color</label>
        <ColorField :element="element" :element_key="'color'" :palette="palette" />
      </div>
      <div class="form-group">
        <label>Horizontal distance</label>
        <div class="tweener">
          <input type="range" min="-250" max="250" style="width: 75%" :value="dropshadow['dropshadow_h_distance'] || 0"
            v-on:input="(e) => { update({ dropshadow_h_distance: e.target.value }) }">
          <input type="number" style="min-width: 20%; width: 20%" :value="dropshadow['dropshadow_h_distance'] || 0"
            v-on:input="(e) => { update({ dropshadow_h_distance: e.target.value }) }">
        </div>
      </div>
      <div class="form-group">
        <label>Vertical distance</label>
        <div class="tweener">
          <input type="range" min="-250" max="250" style="width: 75%" :value="dropshadow['dropshadow_v_distance'] || 0"
            v-on:input="(e) => { update({ dropshadow_v_distance: e.target.value }) }">
          <input type="number" style="min-width: 20%; width: 20%" :value="dropshadow['dropshadow_v_distance'] || 0"
            v-on:input="(e) => { update({ dropshadow_v_distance: e.target.value }) }">
        </div>
      </div>
      <div class="form-group">
        <label>Blur</label>
        <div class="tweener">
          <input type="range" min="0" max="250" style="width: 75%" :value="dropshadow['dropshadow_blur'] || 0"
            v-on:input="(e) => { update({ dropshadow_blur: e.target.value }) }">
          <input type="number" min="0" max="250" style="min-width: 20%; width: 20%"
            :value="dropshadow['dropshadow_blur'] || 0"
            v-on:input="(e) => { update({ dropshadow_blur: e.target.value }) }">
        </div>
      </div>
      <div class="form-group">
        <label>Opacity</label>
        <div class="tweener">
          <input type="range" min="0" max="100" style="width: 75%" :value="get_alpha() * 100"
            v-on:input="(e) => { set_alpha(e.target.value) }">
          <input type="number" min="0" max="100" style="min-width: 20%; width: 20%" :value="get_alpha() * 100"
            v-on:input="(e) => { set_alpha(e.target.value) }">
        </div>
      </div>
    </Collapseable>
  </div>
</template>

<script>
import ColorField from '../../../feeds/fields/color.vue'
import Collapseable from '../../components/collapseable.vue'
export default {
  data() {
    return {
      show: false,
      element: {
        color: { rgba: this.$props.activeLayer.dropshadow_color },
      },
    }
  },
  components: { ColorField, Collapseable },
  props: ['update_layer_config', 'activeLayer', 'dropshadow', 'index', 'open', 'palette'],
  watch: {
    element: {
      deep: true,
      immediate: false,
      handler(newVal) {
        if (newVal.color.rgba)
          this.update({
            dropshadow_color: `rgba(${newVal.color.rgba.r}, ${newVal.color.rgba.g}, ${newVal.color.rgba.b}, ${newVal.color.rgba.a})`,
          })
      }
    },
  },
  mounted() {
    this.show = this.$props.open
    let keys = ['r', 'g', 'b', 'a']
    if (this.dropshadow.dropshadow_color) {
      let rgba = this.dropshadow
        .dropshadow_color
        .replace('rgba(', '')
        .replace(')', '')
        .split(',')
        .reduce((memo, value, index) => {
          memo[keys[index]] = parseFloat(value)
          return memo
        }, {})
      this.element.color.rgba = rgba
    }
  },
  methods: {
    remove() {
      let shadows = [...(this.activeLayer.config.dropshadows || [])]
      shadows.splice(this.index, 1)
      this.update_layer_config({ dropshadows: shadows })
    },
    update(conf) {
      let shadows = [...(this.activeLayer.config.dropshadows || [])]
      shadows[this.index] = { ...shadows[this.index], ...conf }
      this.update_layer_config({ dropshadows: shadows })
    },
    get_alpha() {
      if (this.dropshadow.dropshadow_color.startsWith('rgba(')) {
        let colors = this.dropshadow
          .dropshadow_color
          .replace('rgba(', '')
          .replace(')', '')
        return colors.split(',')[3]
      }
      return 1
    },
    set_alpha(opacity) {
      if (this.dropshadow.dropshadow_color.startsWith('rgba(')) {
        let colors = this.dropshadow
          .dropshadow_color
          .replace('rgba(', '')
          .replace(')', '')
          .split(',')
        colors[3] = opacity / 100
        this.update({ dropshadow_color: `rgba(${colors.join(',')})` })
      }
    },
  }
}

</script>
