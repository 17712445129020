import FacebookExpression from '../../../models/facebook_expression'
import FacebookCarousselCard from '../../../models/facebook_caroussel_card'
import api from '../../api/expression_api'
import helpers from '../../helpers/helpers'
import {
  SET_SELECTED_PAGE, CONSTRUCT_EXPRESSION,
  ADD_CAROUSEL_ITEM, DELETE_CAROUSEL_ITEM, SET_ACTIVE_CARD,
  STRIP_TAGS_FROM_EXPRESSION, SET_CONTEXT, CONSTRUCTING_EXPRESSION
} from '../../mutation-types/facebook_mutations'

export default {
  async saveExpression({ dispatch, getters }){
    dispatch('updateChannel')
    const { cards } = getters
    cards.forEach((unmappedCard) => {
      const card = helpers.mapCarouselItemToCard(unmappedCard);
      card.position = cards.indexOf(unmappedCard)
      api.updateCard(card)
    })
  },

  setContext({ commit }, context){
    api.setContext(context)
    commit(SET_CONTEXT, context)
  },

  async deleteCarouselCard({ commit, getters }, card){
    if (getters.cards.length > 1){
      commit(DELETE_CAROUSEL_ITEM, card.id)
      this.dispatch(`${card.namespace}/destroy`)
    }
  },

  async addCarouselCard({ state, commit, getters }){
    if (getters.cards.length < 10) {
      const newCard = await api.createCard({ header: '', description: '', target_url: '' }, state.channelId);
      if (newCard) commit(ADD_CAROUSEL_ITEM, new FacebookCarousselCard(newCard).toState())
    }
  },

  async updateChannel({ state, dispatch }){
    let selectedPage = null
    if (state.fb_connection.selected) selectedPage = state.fb_connection.selected.id
    await api.updateChannel({
      advert: state.advert,
      facebook_page: selectedPage,
      channelId: state.channelId,
      advertiseableId: state.advertiseableId,
      advertiseablePath: state.advertiseablePath
    })

    dispatch('doneUpdatingChannel')
  },

  async setFBPage({ state, dispatch }, payload){
    const selectedPage = payload.id
    await api.updateChannel({
      facebook_page: selectedPage,
      channelId: state.channelId,
      advertiseableId: state.advertiseableId,
      advertiseablePath: state.advertiseablePath
    })
    dispatch('doneSettingFBPage', payload)
  },

  StripHTML({ commit, getters }){
    commit(STRIP_TAGS_FROM_EXPRESSION)
    getters.cards.forEach((card) => {
      this.commit(`${card.namespace}/STRIP_HTML`)
    })
  },

  async setActiveCard({ state, commit, dispatch }, cardId){
    if (state.facebookCarouselCards.indexOf(cardId) > -1){
      commit(SET_ACTIVE_CARD, cardId)
      dispatch('saveExpression');
    }
  },

  async firstOrCreate({ commit, state }, options) {
    if (!state.constructing){
      commit(CONSTRUCTING_EXPRESSION)
      const expressionData = await api.getExpression(options.channelId)
      const facebookExpression = await FacebookExpression.create({
        channelId: options.channelId,
        namespace: options.namespace,
        numberOfCardsToShow: options.numberOfCardsToShow,
        api_context: state.api_context
      }, expressionData)

      commit(CONSTRUCT_EXPRESSION, facebookExpression.toState())
    }
  },

  async doneSettingFBPage({ commit }, payload){
    // this action is only called as event trigger for letting subscribers know updating
    // the channel has finished
    commit(SET_SELECTED_PAGE, payload)
  },

  async doneUpdatingChannel(){
    // this action is only called as event trigger for letting subscribers know updating
    // the channel has finished
  },
}
