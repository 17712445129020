/** Facebook expression mutations */
export const SET_CONTEXT                        = 'SET_CONTEXT'
export const SET_SELECTED_PAGE                  = 'SET_SELECTED_FB_PAGE'
export const SET_PREVIEW_TYPE                   = 'SET_PREVIEW_TYPE'
export const CONSTRUCT_EXPRESSION               = 'CONSTRUCT'
export const ADD_CAROUSEL_ITEM                  = 'ADD_CAROUSEL_CARD'
export const DELETE_CAROUSEL_ITEM               = 'DELETE_CAROUSEL_CARD'
export const SET_ACTIVE_CARD                    = 'SET_ACTIVE_CARD'
export const SET_ACTIVE_CARD_BY_INDEX           = 'SET_ACTIVE_CARD_BY_INDEX'
export const SET_MEDIA_RESOURCE                 = 'SET_MEDIA_RESOURCE'
export const SET_MEDIA_RESOURCE_POST_PROCESSING = 'SET_MEDIA_RESOURCE_POST_PROCESSING'
export const UPDATE_CAROUSEL_ITEMS              = 'UPDATE_CAROUSEL_ITEMS'
export const UPDATE_CAROUSEL_ITEM               = 'UPDATE_CAROUSEL_ITEM'
export const UPDATE_ADVERT                      = 'UPDATE_ADVERT'
export const STRIP_TAGS_FROM_EXPRESSION         = 'STRIP_TAGS_FROM_EXPRESSION'
export const CONSTRUCTING_EXPRESSION            = 'CONSTRUCTING'

// Card mutations
export const UPDATE_CARD_LAYERS                 = 'UPDATE_CARD_LAYERS'
export const UPDATE_CARD_TARGET_URL             = 'UPDATE_TARGET_URL'
export const UPDATE_CARD_HEADER                 = 'UPDATE_HEADER'
export const UPDATE_CARD_DESCRIPTION            = 'UPDATE_DESCRIPTION'
export const UPDATE_CARD                        = 'UPDATE_CARD'

// Interest mutations
export const SET_SUGGESTIONS                    = 'SET_SUGGESTIONS'
export const SET_INTERESTS                      = 'SET_INTERESTS'
export const CLEAR_SUGGESTIONS                  = 'CLEAR_SUGGESTIONS'
export const SET_SEARCH_STRING                  = 'SET_SEARCH_STRING'
export const SET_SEARCH_RESULTS                 = 'SET_SEARCH_RESULTS'
export const CLEAR_SEARCH_RESULTS               = 'CLEAR_SEARCH_RESULTS'
export const REMOVE_INTEREST                    = 'REMOVE_INTEREST'
export const ADD_INTEREST                       = 'ADD_INTEREST'
export const SET_VALIDATION_RESULTS             = 'SET_VALIDATION_RESULTS'
export const SET_AUDIENCE_REACH                 = 'SET_AUDIENCE_REACH'
export const SET_AUDIENCE_ID                    = 'SET_AUDIENCE_ID'
export const SET_AUDIENCE                       = 'SET_AUDIENCE'
export const SET_AUDIENCE_REACH_STATUS          = 'SET_AUDIENCE_REACH_STATUS'
