import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class RevieCard2 extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Review card'
  }

  labels() {
    return ['review']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'text',
          name: 'Title',
          key: 'top_text',
        },
        {
          type: 'textArea',
          name: 'Review',
          hide_label: false,
          key: 'root.text',
        },
        {
          type: 'slider',
          name: 'Text size',
          key: 'root.fontSize',
          min: 0,
          max: 300,
          step: 1,
        },
        {
          type: 'color',
          name: 'Stars',
          key: 'bg_color',
        },
      ],
    }
  }

  variants() {
    return [
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      align: 'left',
      text: '“Here comes your review text. This can be a longer message sharing all about your customer’s awesome experience.”',
      width: 600,
      height: 240,
      fontSize: 34,
      ...this.opts,
      config: {
        v_align: 'center',
        line_height: 1.1,
        component: {
          type: 'review_card_2',
          name: 'Review card',
        },
        review_card_2: {
          top_text: 'Review title here',
          h_align: 'row', // Horizontale uitlijning
          v_align: 'flex-start', // Verticale uitlijning
          bg_color: '#FF5900',
          show_image: true,
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
