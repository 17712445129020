<template>
  <div>
    <div style="width: 99999px; display: flex;" :id="`swiper_${animation_uid}_${layer.id}`">
      <div v-for="(asset, index) in assets" :class="`swiper__slide_${animation_uid}_${layer.id}`"
        :id="`swiper__slide_${animation_uid}_${layer.id}_${index}`"
        style="display: flex; align-items: center; justify-content: center;" :style="container_styles(asset)"
        :key="`swiper_${animation_uid}_${layer.id}_${index}`">
        <img :src="asset" :class="`swiper__slide__image_${animation_uid}_${layer.id}`" v-show="!has_focus_points(asset)"
          :style="styles">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['layer', 'samples', 'disabled', 'on_error', 'brandkit', 'animation_uid'],
  data() {
    return {
      assets: []
    }
  },
  computed: {
    styles() {
      return {
        'object-fit': this.layer.config['object_fit'] || 'contain',
        'object-position': `${this.layer.config.object_position || 'center'}`,
        width: '100%',
        height: '100%'
      }
    },
    sample: function () {
      return { ...this.samples.data[this.samples.indice], brandkit: { ...this.brand } } || {}
    },
    brand: function () {
      return Object.keys(this.$props.brandkit).length > 0 ? this.$props.brandkit : (this.$root.$store ? this.$root.$store.state.brandkit : {})
    },
  },
  watch: {
    layer: {
      immediate: true,
      deep: true,
      handler(newval, oldval) {
        if (this.samples && this.samples.data && this.samples.data.length > 0) {
          this.assets = this.samples.data.map(item => item['1_item_image_link'] ? item['1_item_image_link'] : item['image_link'])
        } else if (this.layer.config.swiper && this.layer.config.swiper.assets && this.layer.config.swiper.assets.filter(Boolean).length > 0) {
          this.assets = this.layer.config.swiper.assets.map(item => item.asset_url)
        }
      }
    },
  },
  methods: {
    container_styles(src) {
      if (this.has_focus_points(src)) {
        return {
          width: `${this.layer.width}px`,
          height: `${this.layer.height}px`,
          'background-image': `url(${src})`,
          'background-size': 'cover',
          'background-position': this.background_position(src),
          '-webkit-backface-visibility': 'hidden',
          '-moz-backface-visibility': 'hidden',
          '-ms-backface-visibility': 'hidden',
          '-webkit-transform': 'translate3d(0, 0, 0)'
        }
      } else {
        return {
          width: `${this.layer.width}px`,
          height: `${this.layer.height}px`,
          'background-size': 'cover',
          'background-position': this.background_position(src),
          '-webkit-backface-visibility': 'hidden',
          '-moz-backface-visibility': 'hidden',
          '-ms-backface-visibility': 'hidden',
          '-webkit-transform': 'translate3d(0, 0, 0)'
        }
      }
    },
    isValidURL(string) {
      var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      return (res !== null)
    },
    background_position(src) {
      let y = Math.abs(this.focus_points(src).y) / 2 * 100
      let x = Math.abs(this.focus_points(src).x) / 2 * 100
      if (this.focus_points(src).y < 0) {
        y += 50
      } else {
        y = 50 - Math.abs(this.focus_points().y) * 50
      }
      if (this.focus_points(src).x > 0) {
        x += 50
      } else {
        x = 50 - Math.abs(this.focus_points(src).x) * 50
      }
      return `${x}% ${y}%`
    },
    focus_points(src) {
      try {
        let link = new URL(src)
        let focus_x = link.searchParams.get('focus_x')
        let focus_y = link.searchParams.get('focus_y')
        return { x: focus_x, y: focus_y }
      } catch (e) {
        return { x: 0, y: 0 }
      }
    },
    has_focus_points(src) {
      try {
        let link = new URL(src)
        let focus_x = link.searchParams.get('focus_x')
        let focus_y = link.searchParams.get('focus_y')
        return !!(focus_x && focus_y)
      } catch (e) {
        return false
      }
    },
  }
}
</script>
