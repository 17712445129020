<template>
  <div class="modal__fullscreen">
    <!-- @contextmenu.prevent -->

    <Exports :is_video="is_video" :show="exports_active" :batch="batch" :credits="credits" :folders="folders"
      :n_expected_exports="n_expected_exports" :on_export="export_assets" :on_close="e => exports_active = false"
      :plans="plans" :active_plan_id="active_plan_id" v-if="s_briefing" />
    <div class="studio__body-wrapper">
      <div class="studio__nav-bar">
        <div class="studio__nav">
          <a @click="() => back()" class="button__toggle__backplated">
            <i class="fa-regular fa-house"></i>
          </a>

          <span class="studio__nav_item">
            <input v-model="briefing_name">
          </span>
        </div>
        <div class="studio__nav">

          <div class="editor__size-variant" v-for="size in s_briefing.sizes" v-on:click="set_size(size)"
            v-on:mouseover="set_size_hover(true, size)" v-on:mouseout="set_size_hover(false, null)" :class="{
              'editor__size-variant--error': Object.keys(scenes_missing_sizes).includes(size),
              'editor__size-variant--active': size === active_size
            }">
            <div class="icon-ar" :class="`icon-ar--${get_size_object(size).aspect_icon}`" aria-hidden="true"></div>
            <div class="editor__size-variant--tag">
              <span>{{ get_size_object(size).render_dimensions[0] }}x{{
                get_size_object(size).render_dimensions[1] }}</span>
              <i v-if="briefing.version === 1" v-on:click.stop="() => toggle_briefing_size(size)"
                class="fa-regular fa-trash-can"></i>
            </div>
          </div>
        </div>
        <div class="studio__nav-wrapper">

          <Transition name="fade">
            <div
              style="display: flex; gap: 4px; align-items: center; justify-content: flex-end; padding-right: 4px; width: 100px;"
              v-if="saving">
              <p class="body-default-regular">Saving..</p>
              <div class="loader loader--small"></div>
            </div>
            <div
              style="display: flex; gap: 4px; align-items: center; justify-content: flex-end; padding-right: 4px; width: 100px;"
              v-else>
              <p class="body-default-regular">
                Saved
                <span v-if="nightswatch">(V{{ briefing.version }})</span>
              </p>
              <i class="fa-regular fa-circle-check"></i>
            </div>
          </Transition>

          <div class="studio__nav">
            <a class="button modal__open" data-modal-id="payment_plans" v-if="credits === 0">
              <i class="fa-solid fa-bolt"></i> Upgrade
            </a>
          </div>

          <div class="studio__nav">
            <a :class="['button__toggle__backplated', { 'button__toggle__backplated': credits === 0 }]"
              v-on:click="exports_active = !exports_active">
              <i class="fa-solid fa-arrow-down-from-bracket"></i> Download
            </a>
          </div>
        </div>
      </div>

      <div class="studio__body" id="teleportTo">
        <div class="studio__sidebar">
          <div class="studio__sidebar__navs">
            <div class="editor__sidebar__nav" v-on:click="tab = 'data'"
              v-if="override_sidebar_content === false && show_back_btn === false"
              :class="{ 'editor__sidebar__nav--active': this.tab == 'data' }">
              <i class="fa-regular fa-paintbrush" aria-hidden="true"></i>
              <p>Edit</p>
            </div>
            <span v-if="scene_context">
              <div class="editor__sidebar__nav" v-on:click="tab = 'layers'"
                v-if="override_sidebar_content === false && show_back_btn === false"
                :class="{ 'editor__sidebar__nav--active': this.tab == 'layers' }">
                <i class="fa-regular fa-layer-group" aria-hidden="true"></i>
                <p>Layers</p>
              </div>
              <div class="editor__sidebar__nav" v-on:click="tab = 'insert'"
                v-if="version > 1 && override_sidebar_content === false && show_back_btn === false"
                :class="{ 'editor__sidebar__nav--active': this.tab == 'insert' }">
                <i class="fa-solid fa-plus"></i>
                <p>Insert</p>
              </div>
              <div class="editor__sidebar__nav" v-on:click="tab = 'components'"
                v-if="version > 1 && override_sidebar_content === false && show_back_btn === false"
                :class="{ 'editor__sidebar__nav--active': this.tab == 'components' }">
                <i class="fa-regular fa-star"></i>
                <p>Components</p>
              </div>
            </span>
            <div class="editor__sidebar__nav" v-on:click="tab = 'music';"
              :class="{ 'editor__sidebar__nav--active': this.tab == 'music' }"
              v-if="is_video && nightswatch && override_sidebar_content === false && show_back_btn === false">
              <i class="fa-regular fa-music"></i>
              <p>Music</p>
            </div>
            <div class="editor__sidebar__nav" v-on:click="tab = 'labels';"
              :class="{ 'editor__sidebar__nav--active': this.tab == 'labels' }"
              v-if="nightswatch && override_sidebar_content === false && show_back_btn === false">
              <i class="fa-regular fa-tag"></i>
              <p>Labels</p>
            </div>
            <div class="editor__sidebar__nav"
              v-on:click="override_sidebar_content = false; show_back_btn = false; show_template_picker = false;"
              v-if="override_sidebar_content === true || show_back_btn === true">
              <i class="fa-regular fa-chevron-left" aria-hidden="true"></i>
              <p>Back</p>
            </div>
          </div>

          <div :class="{
            'studio__sidebar__body': true,
            'studio__sidebar__body__wide-holder': override_sidebar_content
          }">

            <div :class="{ 'studio__sidebar__body__small-holder': true }">
              <div v-if="tab == 'labels'">
                <h3>Labels</h3>
                <label>Labels split by a comma</label>
                <textarea v-model="briefing_labels" rows="10"></textarea>
              </div>

              <LayerData :onUpdate="editor_ref.updateLayer" :layers="all_active_scene_variable_layers"
                v-if="tab == 'data' && editor_ref && scene_context && scene_context.canvas && version > 1" />

              <div v-if="tab == 'layers'">
                <h3>Layers</h3>
                <span v-if="editor_ref">
                  <LayerList :layers="all_active_scene_size_layers" :onSetActive="editor_ref.setActiveLayer"
                    :onOrderChange="editor_ref.updateLayerOrder" :onAddLayer="add_layer"
                    :on_layer_visibility_change="editor_ref.on_layer_visibility_change"
                    :activeLayer="editor_ref.getActiveLayer()" :on_layer_update="editor_ref.updateLayer" />
                </span>
              </div>

              <div style="display: flex; flex-direction: column; gap: 8px;" v-if="tab == 'insert' && version > 1">

                <h3>Insert</h3>

                <div class="editor__insert__group" v-if="brandkit ? brandkit.logos.length > 0 : false">
                  <h5>Logos</h5>
                  <div class="thumbs_container">
                    <div :key="`logo_asset_${key}`" class="thumb thumb--small"
                      v-on:click="add_layer(image_layer_config(asset))" v-for="(asset, key) in brandkit.logos">
                      <Thumb :url="asset" />
                    </div>
                  </div>
                </div>

                <div class="editor__insert__group">
                  <h5>Media</h5>
                  <div class="editor__insert" v-for="(component, i) in media_components"
                    @click="add_layer(component.config({ canvas: scene_context.canvas }))">
                    <i :class="component.icon()"></i>
                    <div class="editor__layer-item__name">{{ component.name() }}</div>
                  </div>
                </div>

                <div class="editor__insert__group">
                  <h5>Text</h5>
                  <div class="editor__insert" v-for="(component, i) in text_components"
                    @click="add_layer(component.config({ canvas: scene_context.canvas }))">
                    <i :class="component.icon()"></i>
                    <div class="editor__layer-item__name">{{ component.name() }}</div>
                  </div>
                </div>

                <div class="editor__insert__group">
                  <h5>Shapes</h5>
                  <div class="editor__insert" v-for="(component, i) in shape_components"
                    @click="add_layer(component.config({ canvas: scene_context.canvas }))">
                    <i :class="component.icon()"></i>
                    <div class="editor__layer-item__name">{{ component.name() }}</div>
                  </div>
                </div>
              </div>

              <div v-if="tab === 'components' && scene_context">
                <div class="editor__insert__group">
                  <ComponentLibrary :sceneContext="scene_context" v-if="version > 1 && scene_context.canvas"
                    :onClick="comp => add_layers(comp)" :components="predefined_components" />
                </div>
              </div>


              <div v-if="tab == 'music'">
                <h3>Music</h3>
                <div class="tweener">
                  <h5>Select a song</h5>
                  <div class="studio__scene-powered-by">
                    <img src="/pixabay.svg">
                  </div>
                </div>

                <div class="studio__tracks">
                  <div v-for="(sound, index) in sounds" :key="sound.name" v-if="index !== 0"
                    @click="play_sound(sound.name)" class="studio__track">
                    <div v-if="activeSound === sound.name">
                      <div class="studio__track__action studio__track__action--active">
                        <i class="fa-regular fa-pause"></i>
                      </div>
                    </div>
                    <div v-else>
                      <div class="studio__track__action">
                        <i class="fa-regular fa-play"></i>
                      </div>
                    </div>
                    <div class="studio__track__name">
                      <p>{{ sound.name }}</p>
                    </div>
                    <button @click.stop="update_sound(sound.name)" class="button button--light">+</button>
                  </div>
                </div>

              </div>

              <transition name="slide">
                <FeedEditor :schema="scene_context.canvas.feed_schema" :feed_id="scene_context.canvas.feed_id"
                  :feed="scene_context.canvas.feed" :brandkit="brandkit" :slurp="scene_context.canvas.slurp"
                  :show_spreadsheet="override_sidebar_content" :hidden_fields="[]"
                  :on_override="override => override_sidebar_content = override"
                  :on_row_update="(feed, row) => update_feed(feed, row, scene_context)"
                  v-if="scene_context && scene_context.canvas && version === 1 && tab == 'data' && !show_template_picker" />
              </transition>

              <span :label="`Briefing footage`" v-if="show_template_picker"
                :open="show_footage || !active_scene || show_template_picker">
                <button v-on:click="media_lib_active = true" class="button button--light button--full-width">
                  <i class="fa-solid fa-photo-film-music"></i>
                  Add media
                </button>
                <Assets :rembg="rembg" />
              </span>
            </div>
          </div>
        </div>

        <!-- <div class="editor__main-panel-overlay" 
              v-on-clickaway="(e) => away(e)"
              style="margin-top: 0px;" v-if="show_size_selector"> -->
        <modal :name="`size_selector`" height="350px" width="30%" style="z-index: 2000; padding: 20px;" :shiftY="0.2"
          @closed="show_size_selector = false">

          <div class="modal__flex">
            <div class="modal__header">
              <h3>Sizes</h3>
            </div>
            <div class="modal__body">
              <div class="modal__flex_body">
                <div class="editor__size-selector-option" style="width: 100%" :key="size.name"
                  v-for="size in available_sizes" v-on:click="toggle_briefing_size(size.name)">
                  <div style="display: flex; width: 50%">
                    <input type="checkbox" :checked="s_briefing.sizes.includes(size.name)"> &nbsp; &nbsp;
                    <div>{{ size.aspect_ratio }}</div>
                  </div>
                  <div style="display: flex">
                    {{ size.short_name }}
                    <div style="margin-left: 6px">
                      <i :class="size.icon" class="fab"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal__footer">
              <button v-on:click="save_size_selection" class="button button--primary">Save</button>
            </div>
          </div>
        </modal>
        <!-- </div> -->


        <div class="studio__player-holder">
          <transition name='fade'>
            <ScenePlayer v-if="ui_state === 'DEFAULT' &&
              !initializing &&
              active_scene &&
              active_scene.canvas ? active_scene.canvas.variants.find(v => v.size.name === active_size) : false" />

            <div class="studio__main-panel-overlay" v-else-if="ui_state === 'DEFAULT' && briefing_canvasses.length > 0">
              <i class="fa-solid fa-triangle-exclamation"></i>
              <h3>Missing sizes</h3>
              <p>One or more sizes are missing, edit scenes or templates to add the desired size</p>
            </div>

            <div class="studio__main-panel-overlay" v-else-if="initializing === true">
              <h3>Fetching {{ is_video ? 'scenes' : 'images' }} <div class="ellipsis-loader">
                  <span>.</span><span>.</span><span>.</span>
                </div>
              </h3>
              <p>This should not take long</p>
            </div>
          </transition>
        </div>
      </div>

      <div class="editor__main-panel-zoom" style="right: 20px; bottom: 230px;">
        <div class="editor__main-panel-zoom__slider">
          <input type="range" min="0.1" step="0.01" :value="global_zoom" max="1"
            @input="e => global_zoom = e.target.value">
        </div>
        <div style="display: inline-block;">
          {{ parseInt(global_zoom * 100) }}%
        </div>
      </div>

      <SceneCreator />

      <span v-if="scene_context && version == 1">
        <transition name="fade">
          <div class="studio__template-picker" v-show="show_asset_picker"
            v-on-clickaway="e => show_asset_picker = false">
            <Medialib :show="show_asset_picker" :feed_id="project_feed_id" :fixed="false"
              :on_close="e => show_asset_picker = false">
              <template v-slot:extra_actions>
                <button class="button--light" v-on:click="show_asset_picker = false"
                  style="margin-right: 6px;">Ready</button>
              </template>
              <template v-slot:custom_tab_selector>
                <i class="fa-regular fa-image"></i> Brand logos
              </template>
              <template v-slot:custom_tab_content="slot_props">
                <div class="media_lib__body">
                  <div class="media_lib__body__navbar">
                    <a :href="`/brands/${brandkit.brand_id}/edit`" target="_blank">Manage brandkit</a>
                  </div>
                  <div class="medialibrary__category-container">
                    <div style="display: flex; overflow-x: scroll; padding-bottom: 8px">
                      <div :key="key" style="cursor: pointer;"
                        v-on:click="e => slot_props.pick_asset({ image_link: asset, asset_url: asset })"
                        v-for="(asset, key) in brandkit.logos">
                        <Thumb class="thumb" style="background-size: none; margin-right: 8px;" :url="asset" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Medialib>
          </div>
        </transition>

        <TemplatePicker />

        <transition name="studio__template-picker-slide">
          <div class="studio__template-picker" v-show="media_lib_active">
            <Medialib :show="media_lib_active" :feed_id="project_feed_id" :custom_feed="briefing_feed" :fixed="false"
              :custom_feed_name="'briefing'" :on_asset="element => add_asset(element)"
              :on_close="e => media_lib_active = false">
              <template v-slot:extra_actions>
                <button class="button--light" v-on:click="media_lib_active = false"
                  style="margin-right: 6px;">Ready</button>
              </template>
              <template v-slot:virgin>
                <div class="medialibrary__virgin_container">
                  <div class="medialibrary__virgin_container_holder">
                    <i class="fa-regular fa-cloud-arrow-up"></i>
                    <p class="strong">Drag & Drop your files (jpg, png, mp4)</p>
                  </div>
                </div>
              </template>
            </Medialib>
          </div>
        </transition>

        <modal :name="`briefing_start`" :focusTrap="true" :shiftY="0.2" :shiftX="briefing_start_modal.x"
          :clickToClose="false" height="auto" width="55%">
          <div>
            <div class="modal__body">
              <div class="tweener">
                <h3>Start by adding some backgrounds and products</h3>
                <div style="margin-top: 6px;" v-if="!medialib_loaded" class="loader"></div>
              </div>
              <hr>
              <br>
              <div style="width: 100%;">
                <p v-if="!medialib_loaded">Loading assets...</p>
                <!-- <button class="button" :disabled="!medialib_loaded" @click="open_media_lib">Add footage</button> -->
              </div>
              <br>
              <div v-for="(asset, index) in assets.map(a => a.data)" :key="`briefing_assets_${index}`"
                class="medialibrary__asset-container">
                <div class="medialibrary__asset">
                  <span v-if="asset.asset_url ? !asset.asset_url.toLowerCase().includes('mp4') : false">
                    <img :src="asset.asset_url" :id="`asset_${asset.asset_sid}`">
                  </span>

                  <span v-else>
                    <Video :url="asset.image_link"></Video>
                    <div class="medialibrary__vid-icon">
                      <i class="fa-regular fa-video"></i>
                    </div>

                  </span>
                  <!-- <img :src="asset.data.image_link" :id="`briefing_asset_${asset.data.id}`"> -->
                  <div class="medialibrary__asset-buttons">
                    <button v-on:click.self="delete_feed_row({ data: asset })">D</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal__footer">
              <hr>
              <button v-if="assets.length > 0" v-on:click="close_start_brief">Begin</button>
            </div>
          </div>
        </modal>
      </span>
      <Medialib v-if="scene_context && version > 1" :show="media_lib_active" :feed_id="project_feed_id"
        :custom_feed="briefing_feed" :fixed="true" :custom_feed_name="'briefing'"
        v-on-clickaway="e => toggle_media_lib(false)" :on_asset="element => (element)"
        :on_open="e => toggle_media_lib(true)" :on_close="e => toggle_media_lib(false)">
        <template v-slot:extra_actions>
          <button class="button--light" v-on:click="e => toggle_media_lib(false)"
            style="margin-right: 6px;">Ready</button>
        </template>
        <template v-slot:virgin>
          <div class="medialibrary__virgin_container">
            <div class="medialibrary__virgin_container_holder">
              <i class="fa-regular fa-cloud-arrow-up"></i>
              <p class="strong">Drag & Drop your files (jpg, png, mp4)</p>
            </div>
          </div>
        </template>
        <template v-slot:custom_tab_selector>
          <i class="fa-regular fa-image"></i> Brand logos
        </template>
        <template v-slot:custom_tab_content="slot_props">
          <div class="media_lib__body">
            <div class="media_lib__body__navbar">
              <a :href="`/brands/${brandkit.brand_id}/edit`" target="_blank">Manage brandkit</a>
            </div>
            <div class="medialibrary__category-container">
              <div style="display: flex; overflow-x: scroll; padding-bottom: 8px">
                <div :key="key" style="cursor: pointer;"
                  v-on:click="e => slot_props.pick_asset({ image_link: asset, asset_url: asset })"
                  v-for="(asset, key) in brandkit.logos">
                  <Thumb class="thumb" style="background-size: none; margin-right: 8px;" :url="asset" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Medialib>
    </div>
  </div>
</template>

<script>
import LayerGenerator from '../image-editor/components/index.js'
import { mapState, mapGetters } from 'vuex'
import Medialib from './components/medialib.vue'
import Collapseable from '../image-editor/components/collapseable.vue'
import TemplatePicker from './components/template_picker.vue'
import SceneCreator from './components/scene_creator.vue'
import Assets from './assets.vue'
import FeedEditor from '../image-editor/components/feed/editor.vue'
import ScenePlayer from './components/scene_player.vue'
import { EventBus } from '../lib/media/eventbus.js'
import { debounce } from 'debounce'
import Video from './components/video.vue'
import Dropdown from '../components/dropdown.vue'
import Exports from './components/exports.vue'
import SizeSelector from '../image-editor/components/sizes.vue'
import { mixin as clickaway } from 'vue-clickaway2';
import Thumb from '../assets/thumb.vue'
import LayerList from '../image-editor/layer_list.vue'
import LayerData from '../image-editor/layer_data.vue'
import LogoLayerFactory from '../image-editor/components/brandkit/logov2'
import Teleport from 'vue2-teleport';
import Panel from './components/panel.vue'
import LayerModel from '../image-editor/store/modules/layers/model'
import ComponentLibrary from './components/component_library.vue'
import { transform } from 'lodash'

export default {
  mixins: [clickaway],
  components: {
    Teleport,
    LayerData,
    Medialib,
    Collapseable,
    ScenePlayer,
    Assets,
    LayerList,
    TemplatePicker,
    SceneCreator,
    FeedEditor,
    Video,
    Dropdown,
    Exports,
    SizeSelector,
    Thumb,
    Panel,
    ComponentLibrary
  },
  props: {
    on_back: {
      type: Function, required: false, default: null
    },
    initial_credits: {
      type: Number,
      required: false,
      default: 0
    },
    nightswatch: {
      type: Boolean,
      required: false,
      default: false,
    },
    briefing: {
      type: Object,
      required: false
    },
    project_feed_id: {
      type: String,
      required: false
    },
    version: {
      type: Number,
      required: true,
      default: 1
    },
    fonts: { Type: Array, required: false, default: [] },
    brandkit: { type: Object, required: true, default: () => { } },
  },
  data() {
    return {
      back_path: '/briefings',
      exports_active: false,
      media_lib_active: false,
      show_asset_picker: false,
      template_picker_active: false,
      show_footage: false,
      medialib_loaded: false,
      show_export_menu: false,
      override_sidebar_content: false,
      show_back_btn: false,
      show_labels: false,
      share_link: window.location.protocol + '//' + window.location.host + '/shared_briefings/' + this.briefing.id,
      l_briefing_canvasses: [],
      activeSound: null,
      audioInstance: null,
      saving: false,
      active_animation_controller: null,
      briefing_start_modal: {
        x: 0.5
      },
      slider_zoom: 1,
      media_components: [],
      text_components: LayerGenerator.text_components(),
      shape_components: LayerGenerator.shape_components(),
      predefined_components: LayerGenerator.predefined_components(),
      plans: [],  // Standaard een lege array
      active_plan_id: '',  // Standaard een lege string
    }
  },
  mounted() {
    this.event_target.addEventListener('saving', this.saving_handler)
    this.event_target.addEventListener('saved', this.saved_handler)
    this.event_target.addEventListener('animations:timeline:show', this.show_animation_timeline_handler)
    this.event_target.addEventListener('animations:timeline:hide', this.hide_animation_timeline_handler)

    this.media_components = LayerGenerator.media_components(this.is_video, this.nightswatch),

      this.event_target.addEventListener('zoom_changed', (e) => {
        this.global_zoom = e.detail.zoom
      })

    this.event_target.addEventListener('animation_controller_init', (e) => {
      this.$store.commit('SET_ANIMATION_CONTROLLER_MAP', e.detail)
    })

    this.$store.dispatch('init', {
      briefing: this.briefing,
      brandkit: this.brandkit,
      fonts: this.$props.fonts,
      options: {
        initial_credits: this.initial_credits,
        nightswatch: this.nightswatch,
      }
    })

    document.addEventListener('click', this.handleOutsideClick);

    if (this.briefing.sizes.length == 0) {
      this.show_size_selector = true
    }

    EventBus.$on('ready', () => {
      this.medialib_loaded = true
    })

    EventBus.$on('show', (data) => {
      this.show_asset_picker = true
    })

    EventBus.$on('asset_picked', (data) => {
      this.show_asset_picker = false
    })

  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
    this.event_target.removeEventListener('saving', this.saving_handler);
    this.event_target.removeEventListener('saved', this.saved_handler);
    this.event_target.removeEventListener('zoom_changed')
    this.event_target.removeEventListener('animations:timeline:show', this.show_animation_timeline_handler)
    this.event_target.removeEventListener('animations:timeline:hide', this.hide_animation_timeline_handler)
  },
  watch: {
    all_active_scene_size_layers: {
      handler(newVal, oldVal) {
        if (newVal.length === 0) {
          this.tab = 'insert'
        }
      }
    },
    show_size_selector: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.$modal.show('size_selector')
        } else {
          this.$modal.hide('size_selector')
        }
      },
    },
    show_template_picker: {
      handler(newVal, oldVal) {
        if (newVal === true) {
          this.show_back_btn = true
        } else {
          this.show_back_btn = false
        }
      }
    },
    credits: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === 0) {
          this.exports_active = false
          setTimeout(() => {
            $('body').trigger('load-modal')
          }, 200)
        }
      }
    },
    playing: {
      handler(newVal, oldVal) {
        if (newVal === true)
          this.show_back_btn = false
      },
      deep: true,
    },
    assets: {
      deep: true,
      handler() {
        if (this.assets.length === 0 && this.version === 1) {
          this.media_lib_active = true
          this.show_template_picker = true
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      all_active_scene_layers: 'all_active_scene_layers',
      all_active_scene_size_layers: 'all_active_scene_size_layers',
      all_active_scene_variable_layers: 'all_active_scene_variable_layers',
    }),
    ...mapState({
      event_target: state => state.event_target,
      rembg: state => state.rembg,
      editor_ref: state => state.editor_ref,
      processing: state => state.processing,
      briefing_canvasses: state => state.briefing_canvasses,
      active_scene: state => state.active_scene,
      active_size: state => state.size,
      active_feed: state => state.active_feed,
      briefing_feed: state => state.briefing.feed,
      batch: state => state.batch,
      available_sizes: state => state.available_sizes,
      folders: state => state.folders,
      s_briefing: state => state.briefing,
      ui_state: state => state.ui_state,
      playing: state => !state.player_paused,
      credits: state => state.credits,
      initializing: state => state.initializing,
      scene_context: state => state.scene_context,
      sounds: state => state.sounds,
    }),
    active_layer() {
      return this.editor_ref.getActiveLayer()
    },
    n_expected_exports() {
      let n_sizes = 0
      n_sizes = this.s_briefing && this.s_briefing.sizes ? this.s_briefing.sizes.length : 0

      if (this.is_video)
        return n_sizes

      return n_sizes * this.briefing_canvasses.length
    },
    is_video() {
      return this.briefing.type !== 'ImageBriefing'
    },
    scenes_missing_sizes() {
      return this.$store.getters.scenes_missing_sizes
    },
    footage_assets() {
      return this.$store.getters.footage_assets
    },
    product_assets() {
      return this.$store.getters.product_assets
    },
    unclassified_assets() {
      return this.$store.getters.unclassified_assets
    },
    assets() {
      return this.$store.getters.assets
    },
    show_size_selector: {
      get() {
        return this.$store.state.show_size_selector
      },
      set(value) {
        this.$store.commit('SET_SHOW_SIZE_SELECTOR', value)
      }
    },
    global_zoom: {
      get() {
        return this.$store.state.global_zoom
      },
      set(value) {
        this.$store.commit('SET_GLOBAL_ZOOM', parseFloat(value, 10))
      }
    },
    show_template_picker: {
      get() {
        return this.$store.state.template_picker.show
      },
      set(value) {
        this.$store.dispatch('SET_SHOW_TEMPLATE_PICKER', value)
      }
    },
    tab: {
      get() {
        return this.$store.state.tab
      },
      set(value) {
        this.$store.commit('SET_TAB', value)
      }
    },
    briefing_labels: {
      get() {
        return this.$store.state.briefing.labels.join(', ')
      },
      set(value) {
        this.$store.dispatch('update_briefing', { labels: value.split(',').map(label => label.trim()) })
      }
    },
    briefing_name: {
      get() {
        return this.$store.state.briefing.name
      },
      set(value) {
        this.$store.dispatch('update_briefing', { name: value })
      }
    },
  },
  methods: {
    saving_handler() {
      this.saving = true
    },
    saved_handler() {
      this.saving = false
    },
    show_animation_timeline_handler() {
      this.$store.commit('SET_UI_EDIT_STATE', 'ANIMATING')
    },
    hide_animation_timeline_handler() {
      this.$store.commit('SET_UI_EDIT_STATE', 'DEFAULT')
    },
    add_layer(layer) {
      this.editor_ref.addLayer(layer)
      this.tab = 'layers'
    },
    add_layers(layers) {
      let activeLayer = this.active_layer
      if (activeLayer && activeLayer.layer_type === 'component') {

        let fontSizeDiffPercentage = layers[0].fontSize / activeLayer.fontSize
        let useFontsize = activeLayer.fontSize
        console.log(fontSizeDiffPercentage, layers[0].fontSize, activeLayer.fontSize)
        if (fontSizeDiffPercentage > .35) {
          useFontsize = layers[0].fontSize
        }

        let additionalOpts = {}
        if (layers[0].config.component.type === 'list') {
          additionalOpts = layers[0].config.list
          if (activeLayer.config.component.type === 'list')
            additionalOpts.items = activeLayer.config.list.items
        }

        this.editor_ref.updateLayer({
          ...activeLayer,
          background: layers[0].background,
          color: layers[0].color,
          fontSize: useFontsize,
          align: layers[0].align,
          width: activeLayer.width,
          height: activeLayer.height,
          config: {
            ...activeLayer.config,
            ...layers[0].config,
            transform: activeLayer.config.transform,
            ...additionalOpts
          }
        })
      } else {
        layers.forEach(layer => {
          this.add_layer(layer)
        })
      }
    },
    toggle_media_lib(bool) {
      this.media_lib_active = bool
    },
    back() {
      if (this.on_back) {
        this.on_back({ briefing_canvasses: this.$store.getters.root_scenes })
      } else {
        let url = new URL(window.location.href)
        let ref = url.searchParams.get('referer')
        ref = ref ? ref : '/briefings'
        window.location.href = window.location.origin + '/' + ref
      }
    },
    update_feed(feed, row, scene = this.active_scene) {
      this.$store.dispatch('update_feed', {
        scene,
        feed_id: scene.canvas.feed_id,
        rows: [...feed]
      })
    },
    away(e) {
      this.show_size_selector = false
    },
    get_size_object(size) {
      return this.available_sizes.find((s) => {
        return s.name == size
      })
    },
    toggle_briefing_size(size) {
      this.$store.dispatch('toggle_briefing_size', size)
    },
    set_size(size) {
      this.away()
      this.$store.commit('SET_SIZE', size)
    },
    set_size_hover(hover, size) {
      this.$store.commit('SET_SIZE_HOVER', size)
    },
    export_assets(sizes) {
      if (this.is_video) {
        this.export_video(sizes)
      } else {
        this.export_images(sizes)
      }
    },
    export_images(sizes) {
      this.$store.dispatch('build_images', sizes)
    },
    export_video(sizes) {
      this.$store.dispatch('build_video', sizes)
    },
    update_briefing_canvas: debounce(function (key, value) {
      let scene = { ...this.active_scene, [key]: value }
      delete scene.ref
      this.$store.dispatch('update_briefing_canvas', scene)
    }, 500),
    edit() {
      window.location.href = `/designs/${this.active_scene.canvas.id}/edit?source=${this.active_scene.feed.complex_identifier}&redirect=/briefings/${this.briefing.id}`
    },
    close_start_brief() {
      this.$modal.hide('briefing_start')
      // EventBus.$emit('hide')
      this.show_footage = true
      if (this.briefing.sizes.length === 0) {
        this.show_size_selector = true
      } else {
        this.show_template_picker = true
      }
    },
    save_size_selection() {
      if (this.briefing_canvasses.length == 0)
        this.show_template_picker = true
      this.show_size_selector = false
    },
    async delete_feed_row(row) {
      this.$store.dispatch('delete_asset', row.data)
    },
    open_media_lib() {
      // EventBus.$emit('show', {tab: 'assets'})
    },
    add_asset(row) {
      this.$store.commit('ACTIVATE_SCENE', null)
      this.$store.dispatch('add_assets', [{ data: row }])
      if (this.$store.state.briefing_canvasses[0])
        this.$store.commit('ACTIVATE_SCENE', this.$store.state.briefing_canvasses[0])
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.studio__track')) {
        this.stopAllSounds();
      }
    },
    stopAllSounds() {
      if (this.audioInstance) {
        this.audioInstance.pause();
        this.audioInstance.currentTime = 0;
        this.activeSound = null;
      }
    },
    play_sound(soundName) {
      if (this.activeSound === soundName) {
        if (this.audioInstance) {
          this.audioInstance.pause();
          this.audioInstance.currentTime = 0;
        }
        this.activeSound = null;
        return;
      }
      if (this.audioInstance) {
        this.audioInstance.pause();
        this.audioInstance.currentTime = 0;
      }
      const sound = this.$store.state.sounds.find(s => s.name === soundName);
      if (sound && sound.url) {
        this.audioInstance = new Audio(sound.url);
        this.audioInstance.play()
          .then(() => {
            this.activeSound = soundName;
          })
          .catch(error => {
            console.error("Error playing the sound:", error);
          });
      } else {
        console.error("Sound not found:", soundName);
      }
    },
    update_sound(soundName) {
      if (this.audioInstance) {
        this.audioInstance.pause();
        this.audioInstance.currentTime = 0;
        this.audioInstance = null;
      }
      this.$store.dispatch("update_briefing", { ...this.$store.state.briefing, sound: soundName });
      this.activeSound = null;
    },
    image_layer_config(asset) {
      return new LogoLayerFactory().config({ config: { media_url: asset, object_fit: "contain" } })
    }
  }
}
</script>
