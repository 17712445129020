<template>
  <div @click.self="unselectLayer" @mouseover="on_canvas = true" @mouseleave="on_canvas = false"
    :class="{ 'canvas__body': !disabled }" ref="canvas">
    <div :class="`canvas__body-workspace canvas__body-workspace--${disabled} canvas__body-workspace--${editor_uid}`"
      ref="canvasWorkspace" :id="`canvas_workspace_${id}`" v-if="show" @click.self="unselectLayer" v-bind:style="{
        width: `${size.w}px`,
        height: `${size.h}px`,
        backgroundColor: `${background_color}`
      }">

      <img v-if="background && !background.includes('sample') && !background.includes('null')" :src="background"
        ref="canvas_image" :style="{ width: `${size.w}px`, height: `${size.h}px` }" @click.self="unselectLayer">

      <Layer v-bind:key="`${animation_uid}_${index}_${layer.id}`" v-for="(layer, index) in filtered_layers"
        v-bind:layer="layer" :editor_uid="editor_uid" v-bind:zIndex="z_index(layer)" v-bind:disabled="disabled"
        v-bind:active="layer.id == (activeLayer ? activeLayer.id : null)" v-bind:activeVariant="activeVariant"
        v-bind:activeLayer="activeLayer" v-bind:onLayerSelect="onLayerSelect" v-bind:onLayerUpdate="onLayerUpdate"
        v-bind:onLayerDelete="onLayerDelete" v-bind:layerIndex="index" v-bind:samples="l_samples"
        v-bind:segments="segments" v-bind:render_only="render_only" v-bind:constraint="constraints" v-bind:zoom="zoom"
        v-bind:canvas_size="size" v-bind:brandkit="brandkit" v-bind:layers="layers" v-bind:id="`layer_${layer.id}`"
        v-bind:animation_uid="animation_uid" v-bind:on_error="e => errors.push(e)" />
    </div>
    <div v-else class="canvas__body-workspace" v-bind:style="{
      width: `${size.w}px`,
      height: `${size.h}px`,
      backgroundColor: `${background_color}`
    }"></div>
  </div>
</template>


<script>
import Layer from './layer.vue'
import LayerModel from './store/modules/layers/model'
import AnimationController from './lib/animation_controller'
import { allResourcesLoaded } from './helpers'

export default {
  data() {
    return {
      constraints: { width: 0, height: 0, top: 0, left: 0, bottom: 0, right: 0 },
      ready: false,
      errors: [],
      l_samples: { data: [], indice: 0 },
      background_image_urls: {},
      video_scene_duration: 1000,
      animation_controller: null,
      on_canvas: false,
      video: {
        added_listener: false,
        n_videos_loaded: 0,
      },
      loaded: {
        images: false,
        layers: false,
        videos: false
      },
      show: true,
      resetting: false
    }
  },
  props: {
    id: { type: Number, required: false },
    samples: { type: Object, required: false, default: () => { return { data: [], indice: 0 } } },
    disabled: { type: Boolean, required: false, default: false },
    render_only: { type: Boolean, required: false, default: false },
    activeLayer: { type: Object, required: false },
    zoom: { type: Number, required: false, default: 1 },
    layers: { type: Array, required: true },
    size: { type: Object, required: true },
    background: { type: String, required: false },
    background_color: { type: String, required: false, default: "#FFFFFF" },
    activeVariant: { type: Object, required: false },
    show_settings: { type: Boolean, required: false, default: false },
    segments: { type: Array, required: false },
    onLayerDelete: { required: false, type: Function },
    onLayerSelect: { required: false, type: Function },
    onLayerUpdate: { required: false, type: Function },
    animation_uid: { required: false, type: String, default: "adflw" },
    is_animated: { required: false, type: Boolean, default: false },
    version: { required: false, type: Number, default: 1 },
    animation_complete: { required: false, type: Function },
    animation_progress: { required: false, type: Function },
    min_scene_duration: { required: false, type: Number, default: 2000 },
    on_hover_play: { required: false, type: Boolean, default: true },
    on_animation_controller_init: { required: false, type: Function, default: () => { } },
    force_default_brandkit: { required: false, type: Boolean, default: false },
    editor_uid: { required: false, type: String, default: "adflw" },
    brandkit: {
      required: false, type: Object, default: () => {
        return {}
      }
    },
    scaleHeight: {
      type: Boolean,
      required: false,
      default() { return this.size.h === 0 }
    },
  },
  components: { Layer },
  computed: {
    filtered_layers() {
      let layers = JSON.parse(JSON.stringify(this.layers)).sort((a, b) => a.position - b.position);
      layers = layers.filter(l => !l.config.group_id).map((l, i) => this.factory({ ...l, position: (i + 1) * 10 }));
      return layers;
    }
  },
  created() {
    if (!this.disabled)
      window.addEventListener("resize", this.setLayerConstraints);
  },
  destroyed() {
    if (!this.disabled)
      window.removeEventListener("resize", this.setLayerConstraints);
  },
  watch: {
    on_canvas: {
      handler(hover) {
        if (this.on_hover_play && hover && this.disabled) {
          this.play()
        } else {
          if (this.disabled && this.on_hover_play) {
            this.animation_controller?.pause()
            this.reset()
          }
        }
      }
    },
    samples: {
      immediate: true,
      deep: true,
      handler(newSamples) {
        if (this.$props.force_default_brandkit === false) {
          let o = { indice: newSamples.indice, data: [...newSamples.data] }
          o.data = o.data.map(s => {
            delete s.brandkit
            return s
          })
          this.l_samples = o
        } else {
          this.l_samples = { ...newSamples }
        }
      }
    },
    size: {
      deep: true,
      handler() {
        this.setLayerConstraints()
      }
    },
    errors(errors) {
      if (errors.filter(e => e.type == 'load').length > 0) {
        this.loaded.images = false
      }
    },
    video: {
      deep: true,
      handler() {
        if (this.video.n_videos_loaded == $('.layer_video').length) {
          this.loaded.videos = true
        }
      }
    },
    loaded: {
      deep: true,
      handler(loaded) {
        if (loaded.images && loaded.layers && this.loaded.videos && this.render_only) {
          document.title = `ready`
        }
      }
    }
  },
  mounted() {
    this.setLayerConstraints()
    this.$nextTick(() => {
      this.loaded.layers = true
      if (this.render_only && this.disabled) {
        this.check_if_ready()
      }

      const canvasParent = $(this.$el).closest('.canvas_parent')
      canvasParent.width(this.size.w)
      canvasParent.height(this.size.h)

      if (!this.animation_controller && this.is_animated) {
        this.animation_controller = this.init_animation_controller(this.min_scene_duration, this.min_scene_duration, this.samples.data)
        setTimeout(() => {
          if (this.render_only && this.disabled) {
            this.animation_controller.prepare()
          }
        }, 50)
      }
    })
  },
  methods: {
    layers_for_group(group_id) {
      if (!group_id) return []
      return this.layers.filter(l => l.config.group_id == group_id && l.layer_type !== 'group') //eslint-disable-line
    },
    reset() {
      if (this.animation_controller) {
        this.animation_controller.reset()
      }
    },
    duration() {
      if (!this.animation_controller) return 0
      return this.animation_controller.get_duration()
    },
    play(on_frame, start, end) {
      this.animation_controller?.play(on_frame, start, end)
    },
    pause() {
      if (this.animation_controller) {
        this.animation_controller?.pause()
      }
    },
    sample(sample) {
      this.l_samples = { ...sample }
    },
    next_sample() {
      let current_indice = this.l_samples.indice
      if (current_indice < this.l_samples.data.length - 1) {
        this.l_samples = { ...this.l_samples, indice: current_indice + 1 }
      }
    },
    init_animation_controller(total_duration,
      scene_duration,
      samples = [], {
        update_layer = () => { },
        complete = (anim) => { },
        begin = (anim) => { },
        update = (anim) => { }
      } = {}) {
      samples = samples.map(s => ({ ...s, brandkit: this.brandkit }))

      this.animation_controller = new AnimationController({
        video_duration: total_duration,
        complete: () => {
          if (this.animation_complete)
            this.animation_complete()
          complete()
        },
        begin,
        update: (anim) => {
          if (this.animation_progress)
            this.animation_progress(anim)
          update(anim)
        },
        update_layer,
        render_only: this.render_only,
        loop: false,
        uid: this.animation_uid,
        scenes: [{
          sample_indice: 0,
          samples: samples,
          layers: this.layers.map(l => this.factory(l)),
          duration: scene_duration
        }],
        callback: ({ index, sample, scene }) => {
          if (this.$props.version === 1) {
            this.sample({ data: this.samples.data, indice: index })
          }
        }
      })
      if (this.on_animation_controller_init) {
        this.on_animation_controller_init(this.animation_controller)
      }
      return this.animation_controller
    },
    check_if_ready() {
      allResourcesLoaded().then(() => {
        this.loaded.images = true
        this.loaded.videos = true
        this.loaded.layers = true
      })
    },
    factory(layer) {
      return new LayerModel(layer)
    },
    setLayerConstraints() {
      let boundingRect = this.$refs.canvasWorkspace.getBoundingClientRect()
      this.constraints = {
        width: boundingRect.width,
        height: boundingRect.height,
        top: boundingRect.top,
        left: boundingRect.left,
        bottom: boundingRect.bottom,
        right: boundingRect.right
      }
    },
    unselectLayer() {
      if (this.onLayerSelect) {
        this.onLayerSelect(null)
      }
    },
    z_index(layer) {
      let z_index_max = 100 * this.filtered_layers.length
      return z_index_max - layer.position
    },
    height() {
      if (!this.scaleHeight) return `${this.size.h}px`
      if (this.$refs.canvas_image) {
        return `${this.$refs.canvas_image.clientHeight}px`
      }
      return 'auto'
    },
    get_animation_controller() {
      return this.animation_controller
    },
    width() {
      if (this.$refs.canvas_image) {
        return this.$refs.canvas_image.clientWidth
      }
      return 0
    },
    store: () => window.vuex_store,
  }
}


</script>
