<template>
	<div>
		<DropShadowField :activeLayer="activeLayer"
										:update_layer_config="updateLayerConfig"
										:dropshadow="dropshadow"
										:palette="palette"
										:index="index"
										:key="index"
										:open="index == activeLayer.config.dropshadows.length - 1"
										v-for="(dropshadow, index) in (activeLayer.config.dropshadows || [])"/>
	</div>
</template>

<script>
import DropShadowField from '../fields/drop_shadow.vue'

export default {
    components: {DropShadowField},
    props: ['activeLayer', 'updateLayerConfig', 'palette'],
    computed: {
        show_dropshadows() {
            return this.activeLayer.config.show_dropshadows
        }
    },
    methods: {
			
    }
}
</script>