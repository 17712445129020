import anime from 'animejs/lib/anime'
import FocusPointAnimation from './animations/focuspoint'
import { mustache } from '../../lib/parsers'
import TexterAnimation from './animations/texter'
import VideoPlayer from './animations/videoplayer'
import ZoomAnimation from './animations/zoom'
import SlideInAnimation from './animations/slide_in'
import RevealerAnimation from './animations/revealer'
import ZoomSliderAnimation from './animations/zoomslider'
import PopInAnimation from './animations/pop_in'
import DropAnimation from './animations/drop_in'
import Layer from '../store/modules/layers/model'
import TextPopAnimation from './animations/text_pop'
import EntranceAnimation from './animations/entrance'
import MarqueeAnimation from './animations/marquee'
import TypeWriterAnimation from './animations/typewriter'
import FadeAnimation from './animations/fade'
import InWhileOut from './animations/inwhileout'

export default class Scene {
  constructor({
    controller, scene, uid, render_only, callback, video_duration, update_layer
  }) {
    this.controller = controller
    this.layers = this.fill_layers(scene.layers, scene.samples[scene.sample_indice])
    this.duration = scene.duration
    this.scene = scene
    this.callback = callback
    this.video_duration = video_duration
    this.update_layer = update_layer
    this.uid = uid
    this.render_only = render_only
    this.animations = []
    this.timelines = []
    this.offset = 5

    this.current_progress = 0

    this.init_animations()
  }

  animateable_layers() {
    return this.layers.filter((l) => l.show_animations())
  }

  init_animations() {
    this.animations = [
      ...this.get_focus_point_animations(),
      ...this.get_swiper_animations(),
      ...this.get_texter_animations(),
      ...this.get_videoplayers(),
      ...this.get_zoom_animations(),
      ...this.get_slide_in_animations(),
      ...this.get_revealer_animations(),
      ...this.get_zoom_slider_animations(),
      ...this.get_pop_in_animations(),
      ...this.get_drop_animations(),
      ...this.get_text_pop_animations(),
      ...this.get_entrance_animations(),
      ...this.get_marquee_animations(),
      ...this.get_typewriter_animations(),
      ...this.get_fade_animations(),
      ...this.get_in_while_out_animations(),
      ...this.get_review_animations()
    ]
  }

  prepare() {
    // this.init_animations()
    this.animations.forEach((a) => a.prepare())
  }

  reload(layers = this.layers, timeline_duration = this.duration) {
    this.duration = timeline_duration
    this.layers = this.fill_layers(layers, this.scene.samples[this.scene.sample_indice])
    this.init_animations()
    this.l_timeline = this.timeline()
    this.timelines.forEach((obj) => {
      obj.animation.cleanup()
    })
    this.current_progress = 0
  }

  get_duration() {
    return this.duration
  }

  cleanup() {
    if (this.timelines) {
      this.timelines.forEach((t) => {
        t.animation.cleanup()
      })
    }
  }

  restart() {
    this.current_progress = 0
    this.l_timeline.restart()
    this.timelines.forEach((t) => {
      t.timeline.restart()
      t.animation.restart()
    })
  }

  play() {
    this.l_timeline.play()
    this.timelines.forEach((t) => {
      if (t.timeline.paused && t.timeline.began && !t.animation.finished) {
        t.timeline.play()
        t.animation.play()
      }
    })
  }

  pause() {
    this.timelines.forEach((t) => {
      t.timeline.pause()
      t.animation.pause()
    })
    this.l_timeline.pause()
  }

  fill_layers(layers, sample) {
    if (sample === undefined) return layers
    if (sample.data) {
      sample = { ...sample, ...sample.data }
    }
    return layers.map((l) => new Layer({
      ...l,
      text: mustache.fill_in(l.text, sample),
      config: {
        ...l.config,
        image_url: mustache.fill_in(l.config.image_url, sample)
      }
    }))
  }

  between(x, min, max) {
    return x >= min && x <= max;
  }

  async seek(time) {
    // Convert `time` to a percentage of the total duration.
    const seekPercentage = (time / this.duration) * 100;

    for (const t of this.timelines) { // eslint-disable-line
      // Calculate the start time of the child timeline as a percentage of the total duration.
      const childStartTimePercentage = t.animation.delay_percentage;
      // Calculate the end time of the child timeline as a percentage of the total duration.
      const childEndTimePercentage = t.animation.delay_percentage
        + t.animation.duration_percentage;

      // Check if the seek time is within the duration of the child timeline.
      if (seekPercentage >= childStartTimePercentage && seekPercentage <= childEndTimePercentage) {
        // Calculate the relative seek time for the child timeline.
        const childDuration = this.duration
          * ((t.animation.duration_percentage / 100));
        const relativeSeekTime = ((seekPercentage - childStartTimePercentage)
          / (childEndTimePercentage - childStartTimePercentage)) * childDuration;

        // Seek the child timeline to the calculated time.
        t.timeline.seek(relativeSeekTime);
        if (t.animation.tick) {
          await t.animation.tick(time) // eslint-disable-line
        } else {
          // t.animation.seek(relativeSeekTime);
        }
      } else if (seekPercentage < childStartTimePercentage) {
        // If the seek time is before the start of the child timeline, seek to the start.
        t.timeline.seek(0);
        if (t.animation.tick) {
          await t.animation.tick(time) // eslint-disable-line
        }
        // t.animation.seek(0);
      } else {
        // If the seek time is after the end of the child timeline, seek to the end.
        const childDuration = this.duration * (t.animation.duration_percentage / 100);
        t.timeline.seek(childDuration);
        if (t.animation.tick) {
          await t.animation.tick(time) // eslint-disable-line
        }
        // t.animation.seek(childDuration);
      }
    }

    this.l_timeline.seek(time);
  }

  async tick(timestamp, render_mode = false) {
    const promises = [];

    let progressPercentage = this.current_progress
    if (render_mode) {
      progressPercentage = (timestamp / this.duration) * 100
    }

    for (const t of this.timelines) { // eslint-disable-line
      if (this.between(
        Math.floor(progressPercentage),
        t.animation.delay_percentage,
        t.animation.delay_percentage + t.animation.duration_percentage
      )) {
        t.timeline.tick(timestamp);
        if (render_mode === false) {
          t.animation.play();
        } else if (t.animation.tick) {
          promises.push(t.animation.tick(timestamp));
        }
      }
      if (!t.animation.finished && Math.floor(progressPercentage) === (t.animation.delay_percentage + t.animation.duration_percentage) - 1) { // eslint-disable-line
        t.animation.pause();
        t.animation.finish();
      }
      if (this.between(Math.floor(progressPercentage), t.animation.delay_percentage, t.animation.delay_percentage + this.offset)) { // eslint-disable-line
        if (!t.timeline.began && !t.animation.began && !t.animation.finished) {
          t.timeline.began = true;
          t.animation.began = true;
        }
      }
    }

    await Promise.all(promises);
    this.l_timeline.tick(timestamp);
  }

  timeline(loop) {
    this.timelines = this.animations.map((animation) => ({
      timeline: animation.animation(this.callback),
      animation
    }));

    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
      loop,
      begin: (anim) => { // eslint-disable-line
        this.callback({
          scene: this.scene,
          sample: this.scene.samples[this.scene.sample_indice],
          index: 0
        })
      },
      update: (anim) => {
        this.current_progress = anim.progress;
      },
      complete: (anim) => { // eslint-disable-line
        if (!this.render_only) {
          this.restart();
          this.pause();
        }
      }
    })

    this.l_timeline = timeline;
    this.l_timeline.add({
      targets: '.pixel',
      opacity: [0, 1],
      duration: this.get_duration(),
      loop: true,
      easing: 'linear',
      direction: 'alternate'
    }, 0);

    return timeline;
  }

  // TODO REMOVE THIS UNHOLY MESS OF DUPLICATION
  get_fade_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'fade' : false)).map((layer) => new FadeAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }

  get_typewriter_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'typewriter' : false)).map((layer) => new TypeWriterAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
      loop: false,
    }))]
  }

  get_marquee_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'marquee' : false)).map((layer) => new MarqueeAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
      loop: false,
    }))]
  }

  get_entrance_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'entrance' : false)).map((layer) => new EntranceAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
      loop: false,
    }))]
  }

  get_text_pop_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'text_pop' : false)).map((layer) => new TextPopAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }

  get_drop_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'drop' : false)).map((layer) => new DropAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }

  get_pop_in_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'pop_in' : false)).map((layer) => new PopInAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }

  get_zoom_slider_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'zoom_slider' : false)).map((layer) => new ZoomSliderAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }

  get_revealer_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'revealer' : false)).map((layer) => new RevealerAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }

  get_slide_in_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'slide_in' : false)).map((layer) => new SlideInAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }

  get_zoom_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'zoom' : false)).map((layer) => new ZoomAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }

  get_focus_point_animations() {
    return [...this.animateable_layers().filter((l) => (l.config.image_url
      && l.config.image_url.includes('focus_x')
      && l.animation() ? l.animation().int_name() === 'focuspoint' : false)).map((layer) => new FocusPointAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }

  get_review_animations() {
    return [...this.animateable_layers().flatMap((layer) => layer.animations()
      .filter(Boolean)
      .filter((A) => A.int_name() === 'review_stars_fly_in').map((A) => new A({
        samples: this.scene.samples,
        uid: this.uid,
        layer,
        update_layer: this.update_layer,
        duration: this.duration,
      })))]
  }

  get_swiper_animations() {
    const swipers = [...this.animateable_layers().flatMap((layer) => layer.animations()
      .filter(Boolean)
      .filter((A) => A.int_name() === 'swiper' || A.int_name() === 'slider').map((A) => new A({
        samples: this.scene.samples,
        uid: this.uid,
        layer,
        update_layer: this.update_layer,
        duration: this.duration,
      })))]
    return swipers
  }

  get_texter_animations() {
    return [...this.animateable_layers().filter((l) => l.layer_type === 'texter').map((layer) => new TexterAnimation({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }

  get_in_while_out_animations() {
    return [...this.animateable_layers().filter((l) => (l.animation() ? l.animation().int_name() === 'inwhileout' : false)).map((layer) => new InWhileOut({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
      video_duration: this.video_duration,
    }))]
  }

  get_videoplayers() {
    return [...this.animateable_layers().filter((l) => l.layer_type === 'background').map((layer) => new VideoPlayer({
      samples: this.scene.samples,
      uid: this.uid,
      layer,
      update_layer: this.update_layer,
      duration: this.duration,
    }))]
  }
}
