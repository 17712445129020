export default {
  squashed_history() {

  },
  top_segment(state) {
    return state.options.top_segment
  },
  canvas_version(state) {
    return state.canvas.canvas_version || state.canvas.canvas.version
  }
}
