<template>
  <span v-if="source" :key="source">
    <video :style="styles" :class="`layer_video video_layer_${animation_uid}_${layer.id}`" preload="metadata" muted
      :id="`video_layer_${animation_uid}_${layer.id}`" ref="video" style="position: absolute;" @error="registerError">
      <source :src="source" type="video/mp4">
    </video>
  </span>
</template>

<script>
import { mustache } from '../../../lib/parsers';

export default {
  props: ['layer', 'samples', 'disabled', 'animation_uid'],
  data() {
    return {
      source: null,
    };
  },
  computed: {
    cleanSource() {
      if (this.source === null)
        return null
      return this.source.split('?').shift();
    },
    styles() {
      return {
        'object-fit': this.layer.config['object_fit'] || 'contain',
        'object-position': `${this.layer.config.object_position || 'center'}`,
        width: '100%',
        height: '100%'
      }
    },
    sample() {
      return this.samples.data[this.samples.indice];
    }
  },
  watch: {
    cleanSource(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.reloadVideo();
      }
    },
    layer: {
      deep: true,
      handler() {
        this.updateSource();
      },
    },
    samples: {
      immediate: true,
      deep: true,
      handler() {
        this.updateSource();
      },
    },
  },
  mounted() {
    this.updateSource()
  },
  methods: {
    updateSource() {
      try {
        const videoUrl = mustache.fill_in(this.layer.config.media_url, this.sample);
        if (this.isValidURL(videoUrl)) {
          if (this.source !== videoUrl) {
            this.source = videoUrl;
          }
        } else {
          this.source = null;
        }
      } catch (e) {
        console.error(e);
        this.source = null;
      }
    },
    reloadVideo() {
      this.$nextTick(() => {
        this.$refs.video.load();
      });
    },
    registerError(e) {
      console.error(e);
      this.source = null;
      // Additional error handling here if needed
    },
    isValidURL(string) {
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?' + // fragment locator
        '(\\#t=[0-9]+(\\.[0-9]+)?,[0-9]+(\\.[0-9]+)?)?$', 'i'); // media fragment identifier
      return !!pattern.test(string);
    },
  },
};
</script>
