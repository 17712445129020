import axios from 'axios';

const api = {
  async createVariant(imageCanvasId, variant){
    const vari = { ...variant }
    delete vari.id
    const result = await axios.post(`/image_canvasses/${imageCanvasId}/variants.json`, vari)
    return result.data
  },

  async deleteVariant(imageCanvasId, variant){
    const result = await axios.delete(`/image_canvasses/${imageCanvasId}/variants/${variant.id}.json`)
    return result.data
  },

  async updateVariant(imageCanvasId, variant){
    const result = await axios.patch(`/image_canvasses/${imageCanvasId}/variants/${variant.id}.json`, { variant })
    return result.data
  },
}

export default api
