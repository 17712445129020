<template>
  <div class="layer-config__essentials">
  </div>
</template>

<script>
export default  {
  data(){
    return {

    }
  }
}
</script>
