var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ 
    width: _vm.layer.width + 'px', 
    height: _vm.layer.height + 'px', 
    position: 'relative', 
    background: _vm.layer.config.bg_visual_flow.color_1
  })},[_c('div',{style:(_vm.imageContainerStyle)},[_c('div',{style:({
        backgroundImage: 'url(' + _vm.image + ')',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: _vm.focus ? _vm.background_position : 'center'
      })})]),_vm._v(" "),_c('div',{style:(_vm.blurDivStyle)})])}
var staticRenderFns = []
export { render, staticRenderFns }