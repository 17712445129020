import api from '../../api/expression_api'
import FacebookCarousselCard from '../../../models/facebook_caroussel_card'
import {
  SET_MEDIA_RESOURCE,
  SET_MEDIA_RESOURCE_POST_PROCESSING
} from '../../mutation-types/facebook_mutations'

export default {
  firstOrCreate({ commit }, card){
    commit('CONSTRUCT', card)
  },

  async setMediaResource({ commit, state }, media){
    commit(SET_MEDIA_RESOURCE, media)
    const updatedCard = await api.uploadAsset(media.file, state)
    const mappedCard = new FacebookCarousselCard(updatedCard).toState();
    commit(SET_MEDIA_RESOURCE_POST_PROCESSING, mappedCard);
  },

  async destroy({ state, commit }){
    try {
      await api.deleteCard(state)
      commit('DESTRUCT', state)
    } catch (e){
      console.error('Unable to remove carousel item', e)
    }
  }
}
