export default {
  keys(state){
    return [...new Set(state.sample.map((data) => Object.keys(data)).flat())]
  },
  // Legacy object
  samples(state, getters, rootState){
    return {
      indice: state.indice,
      data: state.sample.map((sample, index) => {
        if (rootState.options.studio === false){
          return sample
        }
        let data = {}
        if (rootState.canvas.feed && rootState.canvas.feed.data[index]){
          data = rootState.canvas.feed.data[index].data
        }
        return { ...sample, ...data }
      })
    }
  },
  active_feed(state){
    const feed = state.list.find((f) => f.complex_identifier === state.active_feed)
    if (!feed) return { data: [] }
    return feed
  },
  segments(state, getters){
    if (getters.active_feed) return getters.active_feed.segments
    return []
  },
  public_segments(state){
    return state.segments.filter((s) => s.public)
  },
}
