<template>
  <span style="cursor: pointer">
    <i v-if="is_locked(attrib)" v-on:click="unlock(attrib)" class="fa-solid fa-lock-keyhole"></i>
    <i v-else v-on:click="lock(attrib)" class="fa-solid fa-lock-keyhole-open"></i>
  </span>
</template>

<script>
export default {
  props: ['attrib', 'activeLayer', 'updateLayer'],
  data(){
    return {}
  },
  methods: {
    lock(attrib){
      let locks = [...this.activeLayer.locked_attributes]
      if(!locks.includes(attrib))
        this.updateLayer('locked_attributes', [...locks, attrib])
    },
    unlock(attrib){
      let locks = [...this.activeLayer.locked_attributes]
      if(locks.includes(attrib))
        this.updateLayer('locked_attributes', [...locks.filter(l => l!==attrib)])
    },
    is_locked(attrib){
      return this.activeLayer.locked_attributes.includes(attrib)
    },
  }
}
</script>
