import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class DoNothing extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer, video_duration
  }) {
    super({
      samples, layer, uid, duration, update_layer, video_duration
    })

    this.total_video_duration = video_duration
  }

  name() {
    return ''
  }

  cleanup() {
    $(`${this.target()}`).css('opacity', this.opacity());
  }

  prepare() {
    $(`${this.target()}`).css('opacity', 0);
  }

  static int_name() {
    return 'do_nothing'
  }

  static exposed_to() {
    return ['background', 'image', 'logo', 'text', 'text_area', 'video', 'price', 'rectangle', 'circle']
  }

  static pretty_name() {
    return ''
  }

  animation_config(duration = this.duration, in_out = 'in', is_total_duration = false) {
    let initial_opacity = 0;
    if (this.layer.config.animation.delay_percentage === 0 && in_out === 'in') {
      initial_opacity = 1
    }
    let opacity = [initial_opacity, this.opacity()]
    if (in_out === 'out') {
      opacity = [this.opacity(), is_total_duration ? 1 : 0]
    }
    if (in_out === 'while') {
      opacity = [this.opacity(), this.opacity()]
    }

    const anim_conf = {
      targets: `${this.target()}`,
      duration,
      opacity
    }
    return anim_conf;
  }

  animation() {
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
      complete: () => {
        this.finish()
      }
    })

    timeline.add(this.animation_config())

    return timeline
  }
}
