<template>
  <div class="studio__preview"
      v-if="variant"
      :class="{[`studio__preview--${variant.size.name}`]: true,
               'studio__preview--active': is_selected()}"
      @click="e => toggle()">
    <CanvasPreview 
      :canvas="template_picker.canvas"
      :variant="variant"
      :selected="false"
      :brandkit="brandkit"
      :fonts="[]"
      :samples="{data: feed.map(e=>(
        {
          ...template_picker.canvas.feed.data[0].data, 
          ...e.data, 
          ...briefing_meta
        })), indice: 0}"/>
  </div>
  <div v-else>
    <p>Size missing</p>
  </div>
</template>
<script>
import CanvasPreview from '../../catalogs/canvas_preview.vue'
import {mapState} from 'vuex'
export default {
  components: {CanvasPreview},
  props: {
    feed: {
      type: Array,
      required: true
    },
    indice: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState({
      template_picker: state => state.template_picker,
      brandkit: state => state.brandkit,
      active_size: state => state.size,
    }),
    briefing_meta(){
      return this.$store.getters.briefing_meta
    },
    variant(){
      return this.template_picker.canvas.variants.find(v => v.size.name == this.active_size)
    }
  },
  data(){
    return {
      show: false
    }
  },
  methods: {
    is_selected(){
      return this.template_picker.variants.find(v => v.indice == this.indice)
    },
    toggle(){
      this.$store.commit('TOGGLE_VARIANT', {indice: this.indice, feed: this.feed})
      this.$store.dispatch('CREATE_SCENE')
    }
  }
}
</script>
