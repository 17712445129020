import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class PopIn extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })

    this.initial_styles = {
      transform: '',
      opacity: ''
    }
  }

  name() {
    return 'Pop in'
  }

  cleanup() {
    const transform = this.initial_transform
    $(`${this.target()}`).css('transform', `${transform} scale(1)`)
    $(`${this.target()}`).css('opacity', this.opacity())
  }

  prepare() {
    const transform = $(`${this.target()}`).css('transform')
    $(`${this.target()}`).css('transform', `${transform} scale(${this.conf().direction ? 0 : 1})`)
    $(`${this.target()}`).css('opacity', `${this.conf().direction ? 0 : this.opacity()}`)
  }

  static int_name() {
    return 'pop_in'
  }

  static exposed_to() {
    return ['background', 'image', 'logo', 'slider', 'review',
      'text', 'text_area', 'video', 'price', 'rectangle', 'circle']
  }

  static properties() {
    const props = {
      ...super.properties(),
      direction: {
        type: 'Boolean',
        default: true,
        display: {
          true: 'in',
          false: 'out'
        }
      }
    }
    delete props.easing
    return props
  }

  animation_config(duration = this.duration, in_out = 'in') {
    if (this.loop()) {
      duration /= 2
    }

    let scale = [0, 1]
    let opacity = [0, this.opacity()]
    let easing = 'easeOutElastic(1, .8)'
    if (!this.conf().direction || in_out === 'out') {
      scale = [1, 0]
      opacity = [this.opacity(), 0]
      easing = 'easeInElastic(.8, 1)'
    }

    const anim_conf = {
      targets: `${this.target()}`,
      scale,
      opacity,
      easing,
      duration,
      loop: false,
    }
    return anim_conf
  }

  animation() {
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
      complete: () => {
        this.finish()
      }
    })

    timeline.add(this.animation_config())

    return timeline
  }
}
