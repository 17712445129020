import Vuex from 'vuex'
import LayerModule from './modules/layers'
import VariantModule from './modules/variants'
import EditorModule from './modules/editor'
import FeedModule from './modules/feeds'
import History from './plugins/history'

class EditorStoreFactory {
  static create(){
    return new Vuex.Store({
      plugins: [History.factory()],
      strict: true,
      modules: {
        feeds: FeedModule(),
        variants: VariantModule(),
        layers: LayerModule()
      },
      ...EditorModule()
    })
  }
}

export default EditorStoreFactory
