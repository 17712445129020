export const INIT = 'INIT'
export const ADD_ASSET = 'ADD_ASSET'
export const DELETE_ASSET = 'DELETE_ASSET'
export const ADD_DESIGN = 'ADD_DESIGN'
export const DELETE_DESIGN = 'DELETE_DESIGN'
export const PROCESSING = 'PROCESSING'
export const UPDATE_ASSET = 'UPDATE_ASSET'
export const INIT_TEMPLATES = 'INIT_TEMPLATES'
export const SET_BATCH = 'SET_BATCH'
export const ADD_BRIEFING_FOLDER = 'ADD_BRIEFING_FOLDER'
export const ADD_BATCH_MESSAGE = 'ADD_BATCH_MESSAGE'
export const UPDATE_BRIEFING = 'UPDATE_BRIEFING'
export const REFRESH_PREVIEW_DATA = 'REFRESH_PREVIEW_DATA'
export const REFRESH_BRIEFING_CANVASSES = 'REFRESH_BRIEFING_CANVASSES'
export const INIT_FOLDERS = 'INIT_FOLDERS'
export const RECLASSIFY_ASSET = 'RECLASSIFY_ASSET'
export const SET_TAB = 'SET_TAB'
export const REMBG = 'REMBG'
export const SET_SHOW_TEMPLATE_PICKER = 'SET_SHOW_TEMPLATE_PICKER'
export const SET_TEMPLATE_TEMPLATE_PICKER = 'SET_TEMPLATE_TEMPLATE_PICKER'
export const SET_PREVIEW_TEMPLATE_PICKER = 'SET_PREVIEW_TEMPLATE_PICKER'
export const TOGGLE_VARIANT = 'TOGGLE_VARIANT'
export const SET_VARIANTS_TEMPLATE_PICKER = 'SET_VARIANTS_TEMPLATE_PICKER'
export const CREATE_SCENE = 'CREATE_SCENE'
export const ACTIVATE_SCENE = 'ACTIVATE_SCENE'
export const UPDATE_SCENE_FEED_ROW = 'UPDATE_SCENE_FEED_ROW'
export const DELETE_FEED_ROW = 'DELETE_FEED_ROW'
export const ADD_FEED_ROW = 'ADD_FEED_ROW'
export const UPDATE_BRIEFING_CANVAS = 'UPDATE_BRIEFING_CANVAS'
export const UPDATE_SCENE_FEED = 'UPDATE_SCENE_FEED'
export const SCENE_PLAYER_PROGRESS = 'SCENE_PLAYER_PROGRESS'
export const SCENE_PLAYER_PAUSED = 'SCENE_PLAYER_PAUSED'
export const GET_BRIEFING_CANVAS = 'GET_BRIEFING_CANVAS'
export const SCENE_REORDER = 'SCENE_REORDER'
export const SCENE_PLAYER_DURATION = 'SCENE_PLAYER_DURATION'
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
export const SET_SIZE = 'SET_SIZE'
export const SET_SIZE_HOVER = 'SET_SIZE_HOVER'
export const SET_UI_STATE = 'SET_UI_STATE'
export const SET_SHOW_SIZE_SELECTOR = 'SET_SHOW_SIZE_SELECTOR'
export const UPDATE_SCENE_DURATION = 'UPDATE_SCENE_DURATION'
export const SET_CREDITS = 'SET_CREDITS'
export const FINISH_INITIAL_LOAD = 'FINISH_INITIAL_LOAD'
export const SET_SCENE_CONTEXT = 'SET_SCENE_CONTEXT'
export const UPDATE_BRIEFING_CANVAS_LAYER = 'UPDATE_BRIEFING_CANVAS_LAYER'
export const ADD_BRIEFING_CANVAS_LAYER = 'ADD_BRIEFING_CANVAS_LAYER'
export const DELETE_BRIEFING_CANVAS_LAYER = 'DELETE_BRIEFING_CANVAS_LAYER'
export const SET_GLOBAL_ZOOM = 'SET_GLOBAL_ZOOM'
export const SET_EDITOR_REF = 'SET_EDITOR_REF'
export const SET_ANIMATION_CONTROLLER_MAP = 'SET_ANIMATION_CONTROLLER_MAP'
export const SET_UI_EDIT_STATE = 'SET_UI_EDIT_STATE'
export const SET_ACTIVE_LAYER = 'SET_ACTIVE_LAYER'
