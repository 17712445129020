<template>
  <div :style="{ color: layer.color, 'font-size': layer.fontSize + 'px', display: 'flex', gap: '10px' }">
    <div v-for="(i, index) in range" :key="i" style="position: relative;" :class="`review-star-${animation_uid}`"
      :style="{ width: layer.fontSize + 'px', height: layer.fontSize + 'px' }">
      <div style="position: absolute; filter: grayscale(100%); display: flex">
        <i v-if="!score_symbol" class="fa-sharp fa-solid fa-star" aria-hidden="true"></i>
        <div v-else :style="{
    width: size + 'px',
    height: size + 'px',
    'filter': 'grayscale(100%)',
    'background-size': 'contain',
    'background-repeat': 'no-repeat',
    'background-image': `url(${score_symbol})`
  }"></div>
      </div>
      <div v-if="ends_with_half && score && (index == parseInt(score))" style="position: absolute; display: flex;">
        <i v-if="!score_symbol" class="fa-sharp fa-solid fa-star-half" aria-hidden="true"></i>
        <div v-else :style="{
    width: size / 2 + 'px',
    height: size + 'px',
    'background-size': 'contain',
    'background-repeat': 'no-repeat',
    'background-size': `${layer.fontSize}px ${layer.fontSize}px`,
    'background-image': `url(${score_symbol})`
  }"></div>
      </div>

      <div v-else-if="i < score" style="position: absolute; display: flex;">
        <i v-if="!score_symbol" class="fa-sharp fa-solid fa-star" aria-hidden="true"></i>
        <div v-else :style="{
    width: size + 'px',
    height: size + 'px',
    'background-size': 'contain',
    'background-repeat': 'no-repeat',
    'background-image': `url(${score_symbol})`
  }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['score', 'score_symbol', 'range', 'layer', 'animation_uid'],
  data() {
    return {

    }
  },
  computed: {
    ends_with_half() {
      return !Number.isInteger(this.$props.score)
    },
    size() {
      return this.$props.layer.fontSize * 0.6
    }
  }
}
</script>
