import { registerStoreModule } from './register_store_module'

export function loadComponent({
  rootNamespace, renderComponent,
  storeModule, element, execBeforeInit
}){
  const { vue } = window
  const store = window.vuex_store
  const expressionId           = $(element).data('expression_id')           || ''
  const expressionType         = $(element).data('expression_type')         || 'channels'
  const numberOfCardsToShow    = $(element).data('number_of_cards')         || 10
  const namespace              = `${rootNamespace}_${expressionType}_${expressionId}`

  const options = {
    namespace,
    numberOfCardsToShow,
  }

  registerStoreModule(store, namespace, storeModule)
  execBeforeInit({
    store, namespace, expressionId, expressionType, options
  })

  const app = new vue({ // eslint-disable-line
    el: element,
    store,
    data: options,
    components: { [renderComponent.tag]: renderComponent.component }
  })
}
