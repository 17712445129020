import axios from 'axios';

const api = {
  async update_canvassable({
    id, height, width, background_color, size, palette, template
  }){
    const result = await axios.patch(`/image_canvasses/${id}.json`, {
      image_canvas: {
        height,
        width,
        background_color,
        size,
        palette,
        template
      }
    })
    return result.data
  },

  async get_stencils(page = 1, channel = null){
    const result = await axios.get(`/stencils.json?page=${page}&channel=${channel}`)
    return result.data
  },

  async get_templates({ video = false }){
    let result = null
    if (video){
      result = await axios.get('/designs/templates.json')
    } else {
      result = await axios.get(`/templates.json?t=${Date.now()}`)
    }
    return result.data
  },

  async reset_canvas({ id }){
    const result = await axios.get(`/image_canvasses/${id}/reset.json`)
    return result
  }
}

export default api
