<template>
  <transition name="image-editor__slide-">
    <div class="image-editor__sidebar-layer-config" v-if="showSettings" :class="{
      'image-editor__sidebar-layer-config--no-sizebar': version < 2 || !is_studio
    }">
      <div class="layer-config__collabse">
        <div class="layer-config__collabse-label">
          <label>Settings</label>
        </div>
      </div>
      <div class="layer-config__essentials">
        <div class="form-group" v-if="show_feed_selector">
          <label>Feed</label>
          <select v-on:change="e => this.$props.onFeedSelect(e)">
            <option>Select feed...</option>
            <option v-for="feed in feeds" :selected="feed.complex_identifier == $root.$store.state.feeds.active_feed"
              :key="feed.complex_identifier" :value="feed.complex_identifier">
              {{ feed.name }}</option>
          </select>
        </div>
        <div v-if="show_sizes">
          <div class="form-group">
            <label>Width</label>
            <input type="number" :value="canvas.width" v-on:keyup="update_width" />
          </div>
          <div class="form-group">
            <label>Height</label>
            <input type="number" :value="canvas.height" v-on:keyup="update_height" />
          </div>
          <hr />
        </div>
        <div class="form-group">
          <label>Background color</label>
          <div class="tweener">
            <ColorField :element="element" :element_key="'color'" :palette="canvas.palette" />
            <button class="button--light button--dark-text" @click="update_bg_color('transparent')">
              <i class="fa-regular fa-eraser" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="form-group">
          <label>Name</label>
          <input type="text" v-model="name" />
        </div>
        <div class="form-group">
          <label>Labels</label>
          <p>Labels seperated by comma (,)</p>
          <textarea v-model="labels" rows="2"></textarea>
        </div>
        <div class="form-group" v-if="canvas.background_image && !canvas.background_image.includes('sample')">
          <label>Background image</label>
          <FileInput :element="{ 'background_image': background_image }" :element_key="'background_image'"
            :name="'image_canvas[image]'" :callback="(file) => { store(file) }" />
        </div>
        <button v-if="!busy" v-on:click="update" class="button">Save</button>
        <div v-if="busy" class="loader"></div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from './api'
import FileInput from '../feeds/fields/file-input.vue'
import ColorField from '../feeds/fields/color.vue'
export default {
  data() {
    return {
      name: "",
      background_image: "",
      labels: "",
      file: null,
      busy: false,
      element: { color: { hex: this.$props.canvas.background_color } }
    }
  },
  components: { FileInput, ColorField },
  props: ['showSettings', 'show_sizes', 'is_studio', 'onFeedSelect', 'feeds', 'canvas', 'show_feed_selector', 'onCanvasUpdate', 'version'],
  mounted() {
    this.name = this.canvas.name
    this.labels = this.canvas.labels.join(', ')
  },
  methods: {
    store(file) {
      this.file = file
    },
    async update() {
      this.busy = true
      const result = await api.updateCanvassable(this.canvas, {
        background: this.file,
        name: this.name,
        labels: this.labels.split(',').map(l => l.trim())
      })
      this.busy = false
      this.background_image = result.background_image
    },
    update_height(e) {
      this.$root.$store.dispatch('update_canvas', { height: e.target.value })
    },
    update_width(e) {
      this.$root.$store.dispatch('update_canvas', { width: e.target.value })
    },
    update_bg_color(background_color) {
      this.$root.$store.dispatch('update_canvas', { background_color })
    }
  },
  watch: {
    element: {
      deep: true,
      handler(newVal) {
        this.update_bg_color(newVal.color.hex8)
      }
    },
    canvas: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.name = newVal.name
        this.background_image = newVal.background_image
        this.element.color.hex8 = newVal.background_color
      }
    }
  }
}
</script>
