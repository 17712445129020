<template>
  <div class="feed-builder__file-container">
    <div class="feed-builder__file-image"
         v-if="type() == 'image'"
         :style="{ backgroundImage: 'url(' + asset_url + ')' }">
      <div class="loader" v-if="busy"></div>
    </div>
    <div v-if="type() == 'video'"
         class="feed-builder__file-video">
      <div class="loader" v-if="busy"></div>
      <video :src="element[element_key]"/>
    </div>
    <div class="feed-builder__file-url">
      <input type="text" :name="name"
                         :disabled="disabled"
                         placeholder="Paste asset SID or upload file..."
                         v-on:change="forceUpdate()"
                         v-model="element[element_key]">
      <input type="file"
            :disabled="disabled"
            ref="file_input"
            v-on:change="store($event, element, element_key)">
    </div>
    <div v-on:click="$refs.file_input.click()" class="feed-builder__file-btn"></div>
  </div>
</template>


<script>

export default {
  data(){
    return {
      busy: false,
      asset_url: null
    }
  },

  props: {
    name: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    element: {
      type: Object,
      required: true,
    },
    element_key: {
      type: String,
      required: true,
    },
    submit_on_change: {
      type: Boolean,
      required: false,
      default: false,
    },
    callback: {
      type: Function,
      required: false,
      default: ()=>{}
    }
  },
  mounted(){
    this.asset_url = this.element[this.element_key]
  },
  methods: {
    forceUpdate(){
      this.get_remote_asset()
      this.$forceUpdate()
      this.submit_closest_form()
    },
    type(){
      const videos = ['mp4', 'avi', 'mov']
      if (this.element[this.element_key] &&
            videos.some( v => this.element[this.element_key].includes(v)))
        return 'video'
      return 'image'
    },

    store(event, element, element_key){
      let file = event.target.files[0]
      element[element_key] = file.name
      this.asset_url = URL.createObjectURL(file)
      this.callback(file)
    },

    submit_closest_form(){
      if(this.$props.submit_on_change === true){
        setTimeout(()=>{
          this.$el.closest('form').submit()
        }, 1)
      }
    }
  }
}

</script>
