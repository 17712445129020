import anime from 'animejs/lib/anime'
import Animation from '../../animation'

export default class Fade extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    });

    this.initial_styles = {
      opacity: ''
    };
  }

  name() {
    return 'Fade';
  }

  static pretty_name() {
    return 'Fade';
  }

  cleanup() {
    $(`${this.target()}`).css('opacity', this.opacity());
  }

  prepare(in_out = 'in') {
    $(`${this.target()}`).css('opacity', `${in_out === 'in' ? 0 : this.opacity()}`);
  }

  static int_name() {
    return 'composeable_fade';
  }

  static exposed_to() {
    return ['background', 'image', 'logo', 'slider', 'review', 'cube_spinner',
      'text', 'text_area', 'video', 'price', 'rectangle', 'circle'];
  }

  static properties() {
    const props = {
      ...super.properties(),
      direction: {
        type: 'Boolean',
        default: true,
        display: {
          true: 'in',
          false: 'out'
        }
      }
    };
    delete props.easing;
    return props;
  }

  animation_config(duration = this.duration, in_out = 'in') {
    let opacity = [0, this.opacity()];
    let easing = 'easeOutQuad';
    if (in_out === 'out') {
      opacity = [this.opacity(), 0];
      easing = 'easeInQuad';
    }

    const anim_conf = {
      targets: `${this.target()}`,
      opacity,
      easing,
      duration,
      loop: false,
    };
    return anim_conf;
  }

  animation() {
    const timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
      complete: () => {
        this.finish();
      }
    });

    timeline.add(this.animation_config());

    return timeline;
  }
}
