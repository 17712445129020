import {
  UPDATE,
  INIT,
  HISTORY,
  IMPORT,
  UNDO,
  START_SAVE,
  FINISH_SAVE,
  REDO,
  RESIZING
} from '../mutation_types'
import { historyStore } from '../../plugins/history/history';

import {
  TOGGLE_SETTINGS, UPDATE_CANVAS, OPTIONS, DIRTY, SET_TOP_SEGMENT, PALETTE, SET_STENCILS, SET_STATE,
  UPDATE_CANVAS_FEED
} from './mutation_types'

export default {
  [INIT](state, {
    canvas, autosave, options, fonts, brandkit, addons, project_id
  }) {
    state.project_id = project_id
    state.canvas = { ...canvas }
    state.fonts = [...fonts]
    state.brandkit = { ...brandkit }
    state.options = { ...state.options, autosave, ...options }
    state.addons = [...(addons || [])]
    state.layers = {
      ...state.layers,
      list: [],
      active: null
    }
    state.variants = {
      ...state.variants,
      list: [],
      active: null
    }
  },
  [RESIZING](state, resizing) {
    state.resizing = resizing
  },
  [UPDATE_CANVAS_FEED](state, feed) {
    state.canvas.feed.data = feed
  },

  [SET_STENCILS](state, stencils) {
    state.stencils = [...stencils]
  },

  [SET_STATE](state, ui_state) {
    state.ui_state = ui_state
  },

  [PALETTE](state, palette) {
    state.canvas.palette = palette
    // state.canvas.background_color = palette[Math.floor(Math.random() * palette.length)]
  },

  [UPDATE]() {

  },

  [DIRTY](state, dirty) {
    state.dirty = dirty
  },

  [UNDO]() {
    // const h = [...state.history]
    // h.pop()
    // state.history = h
    historyStore.undo()
  },

  [REDO]() {
    historyStore.redo()
  },

  [OPTIONS](state, opts) {
    state.options = { ...opts }
  },

  [TOGGLE_SETTINGS](state, show_settings) {
    state.options = { ...state.options, show_settings }
  },

  [UPDATE_CANVAS](state, canvas) {
    state.canvas = { ...state.canvas, ...canvas }
  },

  [HISTORY](state, mutation) {
    state.history = [...state.history, mutation]
  },

  [IMPORT](state) { // eslint-disable-line
    state = { // eslint-disable-line
      ...state,
      layers: {
        ...state.layers,
        list: [],
        active: null
      },
      variants: {
        ...state.variants,
        list: [],
        active: null
      },
    }
  },

  [START_SAVE]() {

  },

  [SET_TOP_SEGMENT](state, segment) {
    state.options = { ...state.options, top_segment: segment }
  },

  [FINISH_SAVE](state, { variant_id_map, layer_id_map }) {
    state.maps = {
      variant_id_map: { ...state.maps.variant_id_map, ...variant_id_map },
      layer_id_map: { ...state.maps.layer_id_map, ...layer_id_map },
    }
    state.history = []
  }
}
