<template>
  <div style="width: 100%;" :style="{height: layer.height + 'px'}">
    <div style="width: 100%;"
         v-for="(text, i) in texters"
         :class="`texter_element_${animation_uid}_${layer.id}`"
         :key="`texter_${layer.id}_${i}`">
      <div style="width: 100%;"
           :style="{'margin-bottom': layer.config.line_height * 10 + 'px'}"
           :ref="`texter_${i}`">
        <h2 :style="{ fontFamily: font,
                      fontSize: `${layer.fontSize}px`,
                      color: 'transparent',
                      '-webkit-text-stroke-width': '1px',
                      '-webkit-text-stroke-color': layer.color}">
          {{text}}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import {mustache} from '../../../lib/parsers'
import fitty from 'fitty'
export default  {
  props: ['layer', 'samples', 'disabled', 'on_error', 'brandkit', 'animation_uid'],
  data(){
    return {
      fits: [],
      fit: null,
      texters: []
    }
  },
  mounted(){
    this.texters = Array(this.layer.config.texter ? this.layer.config.texter.amount : 3).fill()
                    .map(e => mustache.fill_in(this.layer.text, this.samples.data[this.samples.indice]))
    // this.fits = this.texters.map((t,i)=>{
    //   let ref = this.$refs[`texter_${i}`]
    //   return fitty(ref[0], {
    //     minSize: this.layer.fontSize * .7,
    //     maxSize: this.layer.fontSize,
    //     multiLine: true,
    //   })
    // })
  },
  computed: {
    font(){
      if(this.layer.dynamic_font_id){
        let font_id = mustache.fill_in(this.layer.dynamic_font_id, {brandkit: this.brand})
        let font = this.$root.$store.state.fonts.find(f => f.id == font_id)
        if(font)
          return font.face
        return this.layer.font
      } else {
        return this.layer.font
      }
    },
    styles(){
      return {
        width: '100%',
        height: '100%'
      }
    },
    container_styles(){
      return {width: `${this.layer.width}px`, height: `${this.layer.height}px`}
    },
    sample: function(){
      return {...this.samples.data[this.samples.indice], brandkit: {...this.brand}} || {}
    },
    brand: function(){
      return Object.keys(this.$props.brandkit).length > 0 ? this.$props.brandkit : (this.$root.$store ? this.$root.$store.state.brandkit : {})
    },
  },
  watch: {
    layer: {
      deep: true,
      handler(newval, oldval){
        if(oldval && (oldval.fontSize !== newval.fontSize)){
          this.fits.forEach(fit => fit.unsubscribe())
          this.fits = []
          this.fits = this.texters.map((t,i)=>{
            let ref = this.$refs[`texter_${i}`]
            return fitty(ref[0], {
              minSize: newval.fontSize * .7,
              maxSize: newval.fontSize,
              multiLine: true,
            })
          })
        } else if(this.fits.length>0) {
          this.fits.forEach(fit => fit.fit())
        }
      }
    },
  },
  methods: {
    hex_to_rgba(hex, opacity=1){
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
        }
        throw new Error('Bad Hex');
    },
  }
}
</script>
