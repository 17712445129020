import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Text extends Component {
  name() {
    return this.display_name || 'Rectangle'
  }

  labels() {
    return ['shapes']
  }

  icon() {
    return 'fa-regular fa-rectangle'
  }

  config() {
    return Layer.factory({
      layer_type: 'rectangle',
      width: 400,
      height: 300,
      unlinked_properties: [Layer.LINK_ASPECT_ACROSS_SIZES],
      background: 'red',
      config: {
        show_background: true,
      }
    })
  }
}
