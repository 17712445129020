import Layer from '../../store/modules/layers/model'
import Background from './types/background'

export default class BgImageRepeater extends Background {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Image repeater'
  }

  labels() {
    return ['background']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'textArea',
          name: 'Callout',
          hide_label: true,
          key: 'root.text',
        },
        [
          {
            type: 'color',
            name: 'Text',
            key: 'root.color',
          },
          {
            type: 'color',
            name: 'Bg',
            key: 'root.background',
          },
        ],
        {
          type: 'slider',
          name: 'Text size',
          key: 'root.fontSize',
          min: 100,
          max: 600,
          step: 1,
        },
        {
          type: 'slider',
          name: 'Distance',
          key: 'gap',
          min: 0,
          max: 1000,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          color: '#F0C1E2',
          background: '#311733',
          config: {
            ...baseConfig.config,
            bg_image_repeater: {
              ...baseConfig.config.bg_image_repeater,
              align: 'left',
              gap: 0,
            }
          },
        }]
      },
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          color: '#808080',
          background: '#FFFFFF',
          config: {
            ...baseConfig.config,
            bg_image_repeater: {
              ...baseConfig.config.bg_image_repeater,
              align: 'left',
              gap: 0,
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: '#F5DFBE',
      align: 'center',
      text: 'Sale 25% OFF',
      width: 1080,
      height: 1080,
      fontSize: 300,
      background: '#000000',
      ...this.opts,
      config: {
        v_align: 'center',
        show_background: true,
        component: {
          type: 'bg_image_repeater',
          name: 'Sticker repeater',
        },
        bg_image_repeater: {
          align: 'left',
          gap: 0,
        }
      }
    }
  }

  config() {
    return {
      background: 'white',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
