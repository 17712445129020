import axios from 'axios';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

const api = {
  async evalSegment(segment, feedElement){
    const result = await axios.post(`/data/segments/${segment.id}/evaluate.json`, { data: feedElement })
    return result.data
  },

  async updateCanvas(canvas, { id }){
    const result = await axios.patch(`/canvasses/${canvas.id}.json`, {
      canvas: {
        ...canvas,
        facebook_caroussel_card_id: id
      }
    })
    return result.data
  },

  async getCanvas({ id }){
    const result = await axios.get(`/canvasses/${id}.json`)
    return result.data
  },

  async updateCanvassable(canvassable, { background, name, labels }){
    const formData = new FormData();
    if (background) formData.append('image_canvas[image]', background)
    formData.append('image_canvas[canvas][name]', name)
    formData.append('image_canvas[canvas][labels]', labels)
    const result = await axios.patch(`/image_canvasses/${canvassable.id}.json`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return result.data
  }
}

export default api
