<template>
  <div :id="`layer_textarea_${animation_uid}_${layer.id}`">
    <div v-if="type_writer" ref="toFit" :style="{
    fontFamily: font,
    margin: 0,
    overflow: 'hidden',
    color: layer.color,
  }">
      <span :class="`typewriter-char-${animation_uid}`" v-for="(char, index) in type_writer_characters" :key="index">
        <span v-if="char === '\n'">&nbsp;</span>
        <span v-else>{{ char }}</span>
      </span>
    </div>
    <pre ref="toFit" :style="{
    fontFamily: font,
    margin: 0,
    overflow: 'hidden',
    color: layer.color,
  }" v-else>
      <span :id="`layer_text_${animation_uid}_${layer.id}`" v-html="text_value"></span>
    </pre>
  </div>
</template>

<script>
import { mustache, highlight } from '../../lib/parsers'
import { apply } from './tags'
import fitty from 'fitty'

export default {
  props: ['layer', 'samples', 'disabled', 'brandkit', 'animation_uid'],
  data() {
    return {
      text: '',
      config: {},
      fit: null
    }
  },
  watch: {
    layer: {
      deep: true,
      immediate: true,
      handler(newval, oldval) {
        this.text = newval.text
        this.config = newval.config
        this.reinitializeFitty()
      }
    },
    text_value() {
      this.$nextTick(() => {
        if (this.fit) {
          this.fit.fit()
        }
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.reinitializeFitty()
    })
  },
  beforeDestroy() {
    if (this.fit) {
      this.fit.unsubscribe()
      this.fit = null
    }
  },
  computed: {
    type_writer() {
      if (!this.layer.config.animations) return false
      let anim = !!this.layer.config.animations.find(a => a.type === 'typewriter')
      let while_anim = this.layer.config.animation?.while?.type === "typewriter"
      return anim || while_anim
    },
    type_writer_characters() {
      return this.text.split(/(\n|.)/).filter(c => c !== '')
    },
    font() {
      if (this.layer.dynamic_font_id) {
        let font_id = mustache.fill_in(this.layer.dynamic_font_id, { brandkit: this.brand })
        if (this.$root.$store && this.$root.$store.state.fonts) {
          let font = this.$root.$store.state.fonts.find(f => f.id == font_id)
          if (font)
            return font.face
        }
        return this.layer.font
      } else {
        return this.layer.font
      }
    },
    sample() {
      return { ...this.samples.data[this.samples.indice], brandkit: { ...this.brand } } || {}
    },
    brand() {
      let brand = Object.keys(this.$props.brandkit).length > 0 ? this.$props.brandkit : (this.$root.$store ? this.$root.$store.state.brandkit : {})
      let placeholder_brand = {}
      if (this.samples.data[this.samples.indice] && this.samples.data[this.samples.indice].brandkit) {
        placeholder_brand = this.samples.data[this.samples.indice].brandkit
      }

      let result = { ...brand }
      for (let key in brand) {
        if (!brand[key] && placeholder_brand[key]) {
          result[key] = placeholder_brand[key]
        }
      }

      return result;
    },
    text_value() {
      let string = this.text
      let result = null
      if (!string) return null
      try {
        if (this.sample) {
          result = mustache.fill_in(string, this.sample)
        } else {
          result = string
        }
        if (result === 'undefined')
          return (this.text.replace('{{', '').replace('}}', ''))
        // Remove all HTML tags
        result = result.replace(/<[^>]*>?/gm, '')
        result = result.replace(/\n\r?/g, '<br />')
        if (this.layer.config.highlights) {
          return highlight.highlight(result,
            this.layer.config.highlights.background_color,
            this.layer.config.highlights.text_color)
        } else {
          return highlight.highlight(result)
        }
      } catch (e) {
        return ' '
      }
    }
  },
  methods: {
    reinitializeFitty() {
      this.$nextTick(() => {
        if (this.$refs.toFit) {
          try {
            if (this.fit) {
              this.fit.unsubscribe()
            }
            this.fit = fitty(this.$refs.toFit, {
              minSize: this.layer.fontSize * 0.7,
              maxSize: this.layer.fontSize,
              multiLine: true,
            })
          } catch (e) {
            console.error('Fitty initialization failed', e)
          }
        }
      })
    },
    capfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    parse(str) {
      return apply(str || "", this.sample, this.config) || ""
    },
  }
}
</script>
