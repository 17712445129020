<template>
    <div>
        <span v-if="l_show_spreadsheet === false">
            <div v-if="l_feed"
                :class="{ 'feed-builder--column-view': spreadsheet_mode, 'feed-builder--row-view': !spreadsheet_mode }">
                <div v-for="(element, index) in (spreadsheet_mode ? l_feed : [l_feed[0]])">
                    <Element :indice="index" :field_id="`row_${index}`" :schema="schema" :brandkit="brandkit"
                        :spreadsheet_mode="spreadsheet_mode"
                        :hidden_fields="[...(!spreadsheet_mode && slurp ? product_keys.map(k => k.key) : []), ...hidden_fields]"
                        :key="`feed_schema_row_${index}`" :element="element"
                        :on_delete="(e, indice) => delete_element(e, indice)" :show_labels="index === 0"
                        :on_change="update_feed" />
                    <!-- <button v-if="spreadsheet_mode" @click="e => open_lib(true, index)">update</button> -->
                </div>
            </div>

            <div class="feed-builder__feed-row" v-if="!spreadsheet_mode && slurp">
                <!-- kun je het volgende dynamische maken? Antal items in de swiper feed, de images (min 1, max 4 in de preview) Ik heb ook een class toegevoegd voor als er niets is geseleceerd-->
                <span>
                    <label>Products ({{ l_feed.length }})</label>
                </span>
                <div class="product_selector__button" @click="show_feed_editor">
                    <div class="product_selector__button-assets">
                        <div class="product_selector__button-asset" v-for="element in l_feed.slice(0, 5)">
                            <img :src="element.data['1_item_image_link']">
                        </div>
                        <!-- deze alleen tonen als de product feed leeg is.
                        <i class="fa-solid fa-plus"></i><span>&nbsp; Add items</span>
                        -->
                    </div>
                    <i class="fa-regular fa-pen-to-square"></i>
                </div>
            </div>

        </span>
        <span v-else>
            <div class="product_selector" v-on-clickaway="e => on_override(false)">
                <div class="product_selector__feed">
                    <div class="studio__nav">
                        <div class="studio__nav-wrapper">
                            <h3 class="big">Products</h3>
                        </div>
                        <div class="studio__nav-wrapper">
                            <a class="studio__nav-tab" @click="delete_all">
                                <i class="fa-regular fa-trash-can"></i>
                                Delete all
                            </a>
                        </div>
                    </div>
                    <FeedEditor :spreadsheet_mode="true" :multiple="true" :slurp="slurp"
                        :on_row_update="feed => subfeed_updated(feed)" :feed_id="feed_id" :brandkit="brandkit"
                        :feed="sub_feed" :hidden_fields="hidden_fields" :schema="{
            rows: [...product_keys]
        }" />
                </div>
                <div class="product_selector__medialib">
                    <Medialib :fixed="false" :show="true" :on_asset="e => add_element(e)" />
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2';
import Element from './element.vue'
import axios from 'axios';
import { debounce } from "debounce"
import { EventBus } from '../../../lib/media/eventbus.js'
import Medialib from '../../../studio/components/medialib.vue'
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

export default {
    name: "FeedEditor",
    components: {
        Element,
        Medialib
    },
    mixins: [clickaway],
    props: {
        brandkit: {
            type: Object,
            required: false,
            default: () => { }
        },
        show_spreadsheet: {
            type: Boolean,
            required: false,
            default: false
        },
        on_override: {
            type: Function,
            required: false,
            default: () => { }
        },
        hidden_fields: {
            type: Array,
            required: false,
            default: () => []
        },
        slurp: {
            type: Boolean,
            required: false,
            default: false
        },
        spreadsheet_mode: {
            type: Boolean,
            required: false,
            default: false
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        feed_id: {
            type: String,
            required: true
        },
        schema: {
            type: Object,
            required: true
        },
        feed: {
            type: Object,
            required: false,
            default: () => { }
        },
        on_row_update: {
            type: Function,
            required: false,
            default: () => { }
        },
    },
    data() {
        return {
            l_show_spreadsheet: false,
            sub_feed: { data: [] },
            l_feed: null,
            product_keys: [
                {
                    key: '1_item_title',
                    key_type: 'text',
                }, {
                    key: '1_item_price',
                    key_type: 'text',
                }, {
                    key: '1_item_image_link',
                    key_type: 'audio-video',
                }, {
                    key: '1_item_condition',
                    key_type: 'text',
                }, {
                    key: '1_item_description',
                    key_type: 'text_area',
                }, {
                    key: '1_item_inventory',
                    key_type: 'text',
                }, {
                    key: '1_item_sale_price',
                    key_type: 'text',
                }]
        }
    },
    mounted() {

    },
    computed: {
        columns() {
            return this.schema.rows.map(r => r.key)
        },
        contains_product_keys() {
            return this.columns.some(c => this.product_keys.map(k => k.key).includes(c))
        },
        product_feed() {
            let products = [
                ...new Set(this.schema.rows.filter(row => row.key.includes('_item_'))
                    .map(row => row.key.split('_item_')[0]))]
            return products.map(p => {
                return {
                    data: {
                        ...this.product_keys.reduce((acc, key) => {
                            acc[`${p}_${key.key.split('1_')[1]}`] = ''
                            return acc
                        }, {})
                    }
                }
            })
        },
    },
    watch: {
        schema: {
            immediate: true,
            deep: true,
            handler(newval, oldval) {
                this.product_keys = newval.rows.filter(r => r.key.includes('_item_'))
                    .map(r => {
                        return {
                            key: r.key,
                            key_type: r.key_type
                        }
                    })
            }
        },
        show_spreadsheet: {
            immediate: true,
            handler(newval, oldval) {
                this.l_show_spreadsheet = newval
            }
        },
        feed: {
            immediate: true,
            deep: true,
            handler(newval, oldval) {
                this.l_feed = JSON.parse(JSON.stringify(newval.data))
                this.sub_feed = { data: this.l_feed }
            }
        },
        // feed_id: {
        //     immediate: true,
        //     async handler(newval, oldval){
        //         const feed = await this.get_feed(newval)
        //         this.l_feed = JSON.parse(JSON.stringify(feed.data))
        //     }
        // }
    },
    methods: {
        subfeed_updated(feed) {
            this.l_feed = feed
            this.on_row_update(feed)
        },
        delete_all() {
            let keys = this.product_keys.map(k => k.key)
            this.l_feed.forEach(async row => {
                keys.forEach(async key => {
                    row.data[key] = null
                    await this.update_feed_row(this.feed_id, row)
                })
            })
            this.l_feed.splice(1, this.l_feed.length - 1).forEach(async row => {
                await this.delete_feed_row(this.feed_id, row)
            })
            this.$forceUpdate()
        },
        show_feed_editor() {
            this.l_show_spreadsheet = true
            this.on_override(true)
        },
        async delete_element(row, indice) {
            if (indice === 0) {
                let copy = JSON.parse(JSON.stringify(this.l_feed))
                let keys = this.product_keys.map(k => k.key)
                keys.forEach(async key => {
                    copy[0].data[key] = null
                    row.data[key] = null
                    await this.update_feed_row(this.feed_id, row)
                })
                this.on_row_update([...copy], copy[0])
            } else {
                await this.delete_feed_row(this.feed_id, row)
                let fe = this.l_feed.filter(f => f.data.adflow_row_id != row.data.adflow_row_id)
                this.l_feed = fe
                this.on_row_update([...fe])
            }
            this.$forceUpdate()
        },
        async add_element(e) {
            let row = {
                data: Object.keys(e).reduce((acc, key) => {
                    if (!key.includes('_item')) {
                        acc[`1_item_${key}`] = e[key]
                    }
                    return { ...this.l_feed[0].data, ...acc }
                }, {})
            }
            if (this.l_feed[0].data['1_item_image_link'] == null) {
                this.update_feed_row(this.feed_id, row)
                this.l_feed[0] = row
                this.on_row_update([...this.l_feed])
            } else {
                const result = await this.create_feed_row(this.feed_id, row)
                let r = result.data.data
                this.l_feed.push({ data: r })
                this.on_row_update([...this.l_feed])
            }
        },
        update_feed(data, d_key, update_remote = true) {
            let copy = JSON.parse(JSON.stringify(this.l_feed))
            let newfeed = copy.map(e => {
                Object.keys(e.data).forEach(key => {
                    if (e.data[key] && e.data[key].hex8) {
                        e.data[key] = e.data[key].hex8
                    }
                    // bit hacky but convert single string to array
                    if (key === "brandkit" && e.data[key]['logos']) {
                        if (typeof e.data[key]['logos'] === 'string')
                            e.data[key]['logos'] = [e.data[key]['logos']]
                    }
                    if (d_key === "brandkit.logos") {
                        e.data['brandkit'] = { ...e.data['brandkit'], logos: data }
                    }
                })
                return e
            })
            if (data && data.hex8) {
                data = data.hex8
            }
            newfeed = newfeed.map(e => {
                if (!d_key.includes('_item') && d_key !== 'brandkit.logos') {
                    e.data[d_key] = data
                }
                return e
            })

            if (update_remote) {
                console.log('updating feed', newfeed)
                this.update_feed_rows(this.feed_id, newfeed)
                this.on_row_update(newfeed, newfeed[0])
            }
        },
        async get_feed(id) {
            const result = await axios.get(`/data/feeds/${id}.json?limit=10`)
            return result
        },
        async delete_feed_row(complex_id, row) {
            const result = await axios.delete(`/data/feeds/${complex_id}/rows/${row.data.adflow_row_id}.json`)
            return result
        },
        async create_feed_row(complex_id, row) {
            const result = await axios.post(`/data/feeds/${complex_id}/rows.json`, {
                data_row: {
                    data: row.data
                }
            })
            return result
        },
        update_feed_rows: debounce(async function (complex_id, rows) {
            const result = await axios.patch(`/data/feeds/${complex_id}/update_all_rows.json`, {
                rows
            })
            return result
        }, 500),
        update_feed_row: debounce(async function (complex_id, row) {
            const result = await axios.patch(`/data/feeds/${complex_id}/rows/${row.data.adflow_row_id}.json`, {
                data_row: {
                    data: row.data
                }
            })
            return result
        }, 500),
    }
}
</script>