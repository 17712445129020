<template>
  <div v-on-clickaway="away" id="drpdncontainer">
    <div @click="type == 'toggle' ? toggle() : () => { }" @mouseover="type == 'hover' ? show() : () => { }"
      @mouseleave="type == 'hover' ? hide() : () => { }" ref="initiator" :style="{ color: disabled ? '#CCC' : null }">
      <slot name="btn" :is_active="is_active">
        {{ name }}
      </slot>
    </div>
    <Transition name="slide">
      <div v-show="is_active" ref="content" class="v-dropdown header__project-selector__dropdown">
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2';
export default {
  props: {
    active: { Type: Boolean, required: false, default: false },
    name: { Type: String, required: false, default: "none" },
    root: { type: Boolean, required: false, default: true },
    on_toggle: { Type: Function, required: false, default: () => { } },
    type: { type: String, required: false, default: 'toggle' },
    disabled: { type: Boolean, required: false, default: false },
    y_offset: { type: Number, required: false, default: 0 },
    x_offset: { type: Number, required: false, default: 0 },
    right: { type: Number, required: false, default: 0 },
    top: { type: Number, required: false, default: 0 },
    pos: { type: String, required: false, default: 'bottom_right' }
  },
  data() {
    return {
      is_active: false
    }
  },
  mixins: [clickaway],
  watch: {
    is_active: {
      immediate: true,
      handler(newVal) {
        this.is_active = newVal
        this.on_toggle(newVal)
      }
    },
    active: {
      immediate: true,
      handler(newVal) {
        this.is_active = newVal
      }
    }
  },
  methods: {
    away() {
      this.is_active = false
    },
    toggle() {
      if (!this.disabled) {
        this.is_active = !this.is_active
        this.set_content_pos()
      }
    },
    set_content_pos() {
      setTimeout(() => {
        if (this.root) {
          $(this.$refs.content).css(this.calc_pos())
        } else {
          $(this.$refs.content).css({ right: '0px' })
        }
      }, 2)
    },
    show() {
      if (!this.disabled) {
        this.is_active = true
        this.set_content_pos()
      }
    },
    hide() {
      if (!this.disabled) {
        this.is_active = false
        this.set_content_pos()
      }
    },
    calc_pos() {
      if (this.right && this.top) {
        return {
          top: `${this.top}px`,
          right: `${this.right}px`
        }
      }
      let rect = this.$refs.initiator.getBoundingClientRect()
      let content = this.$refs.content
      switch (this.pos) {
        case 'bottom_right':
          return {
            top: (rect.y + rect.height + 5) + this.y_offset + 'px',
            left: ((rect.x + this.x_offset)) + 'px'
          }
        case 'bottom_left':
          return {
            top: `${this.y_offset + rect.height + 10}px`,
            left: `${this.x_offset}px`
          }
        case 'top_right':
          return {
            top: (rect.y - content.offsetHeight) + this.y_offset + 'px',
            left: ((rect.x + this.x_offset)) + 'px'
          }
        case 'top_right_abs':
          return {
            right: `${this.x_offset}px`,
            top: `${this.y_offset}px`,
          };
      }
    }
  },
  mounted() {

  }
}
</script>
