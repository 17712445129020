import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class AdditionalImages extends Component {
  name(){
    return 'Additional images'
  }

  labels(){
    return ['product']
  }

  icon(){
    return 'fa-regular fa-images'
  }

  config(){
    return Layer.factory({
      layer_type: 'additional_images',
      text: null,
      width: 100,
      height: 100,
      config: {
        additional_images_field: 'additional_image_link',
        additional_images_index: 0
      }
    })
  }
}
