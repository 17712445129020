<template>
  <div class="layer-config__essentials">
    <div class="form-group">
      <label>Scale</label>
      <button type="button"
              :class="{'button__toggle--active': layer.config['object_fit'] == 'contain'}"
              class="button--light button--dark-text"
              v-on:click="updateLayerConfig({object_fit: 'contain'})">
        <i class="fa-regular fa-compress" aria-hidden="true"></i>
      </button>
      <button type="button"
              :class="{'button__toggle--active': layer.config['object_fit'] == 'cover'}"
              class="button--light button--dark-text"
              v-on:click="updateLayerConfig({object_fit: 'cover'})">
        <i class="fa-regular fa-expand" aria-hidden="true"></i>
      </button>
    </div>

    <div class="form-group">
      <label>Video</label>
      <UrlUpload
        name="video_url"
        :media_folder="mediaFolder"
        element_key="data"
        :element="{data: layer.config.video_url}"
        :on_change="(url)=>setLayerVideo(url)"
      />
    </div>
  </div>
</template>

<script>

import UrlUpload from '../../../feeds/fields/audio-video.vue'

export default  {
  props: ['mediaFolder', 'layer', 'onUpdate'],
  components: {
    UrlUpload
  },
  data(){
    return {

    }
  },
  methods: {
    setLayerVideo(url){
      this.updateLayerConfig({'video_url': url})
    },
    updateLayerConfig(conf){
      this.updateLayer('config', {...this.layer.config, ...conf})
    },
    updateLayer(key, value){
      const layer = {...this.layer, [key]: value}
      this.$props.onUpdate(layer)
    },
  }
}

</script>
