<template>
    <div :style="{ color: layer.color, width: layer.width + 'px', position: 'relative' }">
    
        <div
            style="
                display: flex;
                flex-direction: row;
                z-index: 0;
                border-radius: 20px;
                border-bottom-left-radius: 0px;
                padding: 20px 20px 20px 30px;
                background: white;
                align-items: center;
                overflow: hidden;
                gap: 10px;"
            > 

            
            <div v-if="isImage(image) && layer.config.native_tiktok_reply.show_image" 
                :style="{
                    backgroundImage: 'url(' + image + ')',
                    width: `${layer.fontSize * 0.7}px`, // Increased width
                    height: `${layer.fontSize * 0.7}px`, // Increased height
                    minWidth: `${layer.fontSize * 0.7}px`, // Increased width
                    minHeight: `${layer.fontSize * 0.7}px`, // Increased height
                    borderRadius: '50%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
            }"></div>
            

            <div
                style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px 40px 20px 10px;
                    gap: 10px"
            >

                <span
                    style="
                        width: 100%;
                        text-wrap: wrap;
                        color: #959595"
                    :style="{ 
                        fontSize: layer.fontSize * 0.3 + 'px',
                        fontFamily: font,
                    }">
                    {{ top_text }}
                </span>
                <span
                    class="sticker__repeater__text"
                    style="
                        width: 100%;"
                    :style="`
                        color: ${layer.config.native_tiktok_reply.bg_color}; 
                        font-size: ${layer.fontSize * 0.5}px;
                        font-family: ${font};
                        text-wrap: wrap;`"
                >
                    {{ layer.text }}
                </span>
            </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="22" viewBox="0 0 45 22" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V16.8135C0 20.6396 4.12305 23.0479 7.45508 21.168L44.9941 0H0Z" fill="white"/>
        </svg>

    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    components: {},
    mixins: [FontMixin],
    computed: {
        component_name() {
            return this.layer.config.component.type
        },
        bgColor() {
            return this.layer.config[this.component_name].bg_color || 'white'
        },
        top_text() {
            return this.layer.config.native_tiktok_reply.top_text
        },
        image() {
            return this.layer.config.native_tiktok_reply.image
        },
    },
    methods: {
        parse(text) {
            return highlight.highlight(text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color)
        },
        isImage(icon) {
            return /\.(jpeg|jpg|gif|png|svg|webp)$/.test(icon);
        },
    }
}
</script>