import Vuex from 'vuex'
import axios from 'axios';
import Vue from './Vue.js' // eslint-disable-line
import TurbolinksAdapter from './lib/turbolinks_adapter'
import loadFacebookPageSelector from    './facebook_page_selector'
import loadFacebookAudienceSelector from './facebook_audience_selector'
import loadCanvas from                   './canvas'
import loadConsumeStatus from            './consume'
import loadImageEditor from './image_editor'
import { BriefingWizard } from './briefing-wizard'

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

// Polyfills for IE
require('es6-promise').polyfill();
require('es6-object-assign').polyfill();

Vue.use(TurbolinksAdapter)
Vue.use(Vuex)

function init(){
  window.vue = Vue
  window.vue_bus = new Vue({})
  BriefingWizard.load()
  loadFacebookPageSelector()
  loadFacebookAudienceSelector()
  loadImageEditor()
  loadCanvas()
  loadConsumeStatus()
}

document.addEventListener('turbolinks:load', init);
