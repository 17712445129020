<template>
    <div>
        <ImageConf 
            :mediaFolder="mediaFolder"
            :layer="layer"
            :hide_input="true"
            :onUpdate="onUpdate"/>

        <!-- <div style="padding: 12px;">
            <b>My logo's</b>
            <hr>
            <div style="display: flex; overflow-x: scroll; ">
                <div :key="key"
                    style="cursor: pointer;"
                    v-on:click="set_logo(key)"
                    v-for="(asset, key) in brandkit.logos">
                <Thumb class="thumb" style="margin-right: 8px;" :url="asset"/>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import ImageConf from './image.vue'
import Thumb from '../../assets/thumb.vue'

export default {
    props: ['mediaFolder', 'layer', 'onUpdate', 'brandkit'],
    data(){
        return {

        }
    },
    components: {ImageConf, Thumb},
    methods: {
        set_logo(key){
            let ref = `{{brandkit.logos|index:${key}}}`
            this.setLayerImage({'image_url': ref})
        },  
        setLayerImage(url){
            this.updateLayerConfig({'image_url': url})
        },
        updateLayerConfig(conf){
            this.updateLayer('config', {...this.layer.config, ...conf})
        },
        updateLayer(key, value){
            const layer = {...this.layer, [key]: value}
            this.$props.onUpdate(layer)
        },
  }
}
</script>