export default {
  template_picker_briefing_canvas(state) {
    if (state.template_picker.canvas === null) return null
    return state.briefing_canvasses.find((bc) => bc.canvas.id === state.template_picker.canvas.id)
  },
  root_scenes(state) {
    return state.briefing_canvasses.filter((bc) => bc.root)
  },
  active_size_object(state) {
    return state.available_sizes.find((s) => s.name === state.size)
  },
  scenes_missing_sizes(state, getters) {
    const { sizes } = state.briefing
    const missing = {}
    getters.root_scenes.forEach((bc) => {
      const missing_sizes = sizes.filter((s) => !bc.canvas.variants.map((v) => v.size.name).includes(s)) // eslint-disable-line
      missing_sizes.forEach((s) => {
        missing[s] = [...(missing[s] || []), bc.id]
      })
    })
    return missing
  },
  active_scene_index(state) {
    return state.briefing_canvasses.findIndex((bc) => bc.id === state.active_scene.id)
  },
  has_next_scene(state, getters) {
    return getters.active_scene_index < state.briefing_canvasses.length - 1
  },
  has_previous_scene(state, getters) {
    return getters.active_scene_index > 0
  },
  all_active_scene_layers(state) {
    if (!state.active_scene) return []
    return state.active_scene.canvas.variants.flatMap((variant) => variant.layers)
  },
  all_active_scene_size_layers(state) {
    if (!state.active_scene) return []
    if (!state.active_scene.canvas) return []
    if (!state.active_scene.canvas.variants) return []
    return state.active_scene.canvas.variants
      .find((v) => v.size.name === state.size)
      .layers
  },
  all_active_scene_variable_layers(state, getters) {
    return getters.all_active_scene_layers.filter((v) => v.parent_id === null && v.variable)
      .sort((a, b) => b.layer_type.localeCompare(a.layer_type))
  },
  variant_scenes(state) {
    return state.briefing_canvasses
      .filter((bc) => !bc.root)
      .map((bc) => {
        const parent = state.briefing_canvasses
          .find((b) => b.id === bc.parent_id)
        if (parent) {
          return {
            ...bc,
            canvas: parent.canvas
          }
        }
        return bc
      })
  },
  selected_sound(state) {
    return state.sounds.find((s) => s.name === state.briefing.sound)
  },
  footage_assets(state) {
    if (state.active_feed === null) return []
    return state.active_feed
      .data
      .filter((row) => row.data.adflow_image_classification === 'lifestyle')
      .sort((a, b) => (a.data.adflow_order < b.data.adflow_order ? -1 : 1))
  },
  product_assets(state) {
    if (state.active_feed === null) return []
    return state.active_feed
      .data
      .filter((row) => row.data.adflow_image_classification === 'product')
      .sort((a, b) => (a.data.adflow_order < b.data.adflow_order ? -1 : 1))
  },
  unclassified_assets(state) {
    if (state.active_feed === null) return []
    return state.active_feed
      .data
      .filter((row) => row.data.adflow_image_classification !== 'product'
        && row.data.adflow_image_classification !== 'lifestyle')
      .sort((a, b) => (a.data.adflow_order < b.data.adflow_order ? -1 : 1))
  },
  assets(state) {
    if (state.active_feed === null) return []
    return state.active_feed.data
  },
  briefing_meta(state) {
    return {
      briefing: {
        title: state.briefing.title,
        subtitle: state.briefing.subtitle,
        cta: state.briefing.cta
      }
    }
  }
}
