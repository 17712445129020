<template>
  <div ref="parentContainer" class="canvas_scale_container">
    <div :style="{ zoom: computedScale, width: `${size.w}px`, height: `${size.h}px` }">
      <Canvas :size="size" :layers="layer_list" :fonts="fonts" :background="canvas['background_image']"
        :background_color="canvas.background_color" :brandkit="brandkit || {}"
        :disabled="disabled === undefined ? true : disabled" :animation_uid="animation_uid()" :samples="data()"
        :animation_complete="animation_complete" :animation_progress="animation_progress"
        :min_scene_duration="min_scene_duration" :on_hover_play="on_hover_play" :force_default_brandkit="true"
        :is_animated="is_animated" ref="canvas" />
    </div>
  </div>
</template>

<script>
import Canvas from '../image-editor/canvas.vue'
export default {
  props: [
    'canvas', 'fonts', 'animation_progress', 'on_hover_play', 'variant',
    'animation_complete', 'selected', 'element', 'anim_uid', 'disabled',
    'autoscale', 'brandkit', 'samples', 'min_scene_duration'
  ],
  components: { Canvas },
  data() {
    return {
      parentWidth: 0,
      parentHeight: 0
    };
  },
  computed: {
    is_animated() {
      return this.canvas.video;
    },
    layer_list() {
      let layers = [];
      if (this.$props.variant) {
        layers = this.$props.variant.layers;
      } else {
        layers = this.$props.canvas.layers;
      }
      layers = JSON.parse(JSON.stringify(layers)).sort((a, b) => a.position - b.position);
      layers = layers.map((l, i) => ({ ...l, position: (i + 1) * 10 }));
      return layers;
    },
    size() {
      if (this.$props.variant && this.$props.variant.size) {
        return {
          w: this.$props.variant.size.display_dimensions[0],
          h: this.$props.variant.size.display_dimensions[1]
        };
      } else {
        return {
          w: this.$props.canvas.width,
          h: this.$props.canvas.height
        };
      }
    },
    computedScale() {
      const scaleX = this.parentWidth / this.size.w;
      const scaleY = this.parentHeight / this.size.h;
      return Math.min(scaleX, scaleY);
    }
  },
  methods: {
    updateParentDimensions() {
      const parent = this.$refs.parentContainer;
      this.parentWidth = parent.clientWidth;
      this.parentHeight = parent.clientHeight;
    },
    animation_uid() {
      if (this.anim_uid) return this.anim_uid;
      return (Math.random() + 1).toString(36).substring(7);
    },
    duration() {
      return this.$refs.canvas.duration();
    },
    play() {
      this.$refs.canvas.play();
    },
    pause() {
      this.$refs.canvas.pause();
    },
    reset() {
      this.$refs.canvas.reset();
    },
    data() {
      if (this.element) {
        return { data: [this.element], indice: 0 };
      } else if (this.samples) {
        return this.samples;
      }
    }
  },
  mounted() {
    this.updateParentDimensions();
    window.addEventListener('resize', this.updateParentDimensions);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateParentDimensions);
  }
}
</script>
