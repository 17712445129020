import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ReviewScore extends Component {
  name(){
    return 'Random customer quote'
  }

  labels(){
    return ['brandkit']
  }

  icon(){
    return 'fa-solid fa-message-quote'
  }

  config(){
    return Layer.factory({
      layer_type: 'text',
      align: 'center',
      width: 180,
      height: 180,
      fontSize: 24,
      config: {
        word_wrap: 'initial',
        v_align: 'center'
      },
      text: '"{{brandkit.customer_quotes|random}}"'
    })
  }
}
