<template>
  <div>
    <h4 class="studio__asset__label">Backgrounds</h4>
    <div v-on:click="e => set_tab('focuspicker')"
         style="padding-bottom: 25px;">
      <draggable class="studio__asset"
                :emptyInsertThreshold="100"
                :swapThreshold="0.5"
                @start="drag = true"
                @end="drag = false"
                v-model="backgrounds"
                group="assets"
                @change="e => reclassify('backgrounds', e)">
        <transition-group name="image-editor__layers">
          <div
            class="studio__asset__row"
            v-for="(element, index) in backgrounds"
            :key="`footage_${index}`">
            <div class="studio__asset__row-dragger">
              <i class="fa-regular fa-grip-dots-vertical"></i>
            </div>
            <div class="studio__asset__row-body">
              <div class="studio__asset__row-thumb" style="overflow: hidden;">
                <Video
                  :url="`${element.data.image_link}#t=0.5`"
                  v-if="element.data.image_link.indexOf('mp4') > -1"/>
                <img :src="element.data.image_link" v-if="element.data.image_link">
                <div v-else class="loader"></div>
              </div>
              <div class="studio__asset__row-buttons">
                <button @click.stop="to_clipboard(element.data.image_link)" class="button--small button--light"><i class="fa-regular fa-link"></i></button>
                <button @click.stop="remove_footage(element.data)" class="button--small button--light"><i class="fa-regular fa-trash"></i></button>
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>

    <h4 class="studio__asset__label">Products</h4>
    <div style="padding-bottom: 25px;" v-on:click="e => set_tab('productimages')">
      <draggable class="studio__asset"
                :emptyInsertThreshold="100"
                :swapThreshold="0.5"
                @start="drag = true"
                @end="drag = false"
                v-model="products"
                group="assets"
                @change="e => reclassify('products', e)">
        <transition-group name="image-editor__layers">
          <div
            class="studio__asset__row"
            v-for="(element, index) in products"
            :key="`product_${index}`">
            <div class="studio__asset__row-dragger">
              <i class="fa-regular fa-grip-dots-vertical"></i>
            </div>
            <div class="studio__asset__row-body">
              <div class="studio__asset__row-thumb">
                <img :src="image_link(element)" v-if="image_link(element)">
                <div v-else class="loader"></div>
              </div>
              <div class="studio__asset__row-buttons">
                <button @click.stop="to_clipboard(image_link(element))" class="button--small button--light"><i class="fa-regular fa-link"></i></button>
                <button @click.stop="remove_footage(element.data)" class="button--small button--light"><i class="fa-regular fa-trash"></i></button>
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>

    <h4 class="studio__asset__label">Other</h4>
    <draggable class="studio__asset"
               :emptyInsertThreshold="50"
               :swapThreshold="0.5"
               @start="drag = true"
               @end="drag = false"
               v-model="other"
               group="assets"
               @change="e => reclassify('other', e)">
      <transition-group name="image-editor__layers">
        <div
          class="studio__asset__row"
          v-for="(element, index) in other"
          :key="`unclassified_${index}`">
          <div class="studio__asset__row-dragger">
            <i class="fa-regular fa-grip-dots-vertical"></i>
          </div>
          <div class="studio__asset__row-body">
            <div class="studio__asset__row-thumb">
              <img :src="element.data.image_link" v-if="element.data.image_link">
              <div v-else class="loader"></div>
            </div>
            <div class="studio__asset__row-buttons">
              <button @click.stop="to_clipboard(element.data.image_link)" class="button--small button--light"><i class="fa-regular fa-link"></i></button>
              <button @click.stop="remove_footage(element.data)" class="button--small button--light"><i class="fa-regular fa-trash"></i></button>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Video from  './components/video.vue'
export default {
  components: {
    draggable,
    Video
  },
  props: {
    rembg: {type: Object, default: () => {}},
    on_delete: {type: Function, default: () => {}},
  },
  data(){
    return {
      backgrounds: [],
      products: [],
      other: [],
      drag: false
    }
  },
  mounted(){
    this.backgrounds = this.footage_assets
    this.products = this.product_assets
    this.other = this.unclassified_assets
  },
  watch: {
    footage_assets:{
      deep: true,
      handler(footage){
        this.backgrounds = footage
      }
    },
    product_assets:{
      deep: true,
      handler(products){
        this.products = products
      }
    },
    unclassified_assets:{
      deep: true,
      handler(unclassified){
        this.other = unclassified
      }
    },
  },
  computed: {
    footage_assets() {
      return this.$store.getters.footage_assets
    },
    product_assets(){
      return this.$store.getters.product_assets
    },
    unclassified_assets(){
      return this.$store.getters.unclassified_assets
    }
  },
  methods:{
    image_link(element){
      if(element.data.adflow_image_no_background && this.rembg.enabled){
        return element.data.adflow_image_no_background
      } else {
        return element.data.image_link
      }
    },
    set_tab(tab){
      this.$store.commit('SET_TAB', tab)
    },
    remove_footage(row){
      this.$store.dispatch('delete_asset', row)
    },
    to_clipboard(content){
      navigator.clipboard.writeText(content)
      create_notification('Link copied to clipboard', '', 'success')
    },
    reclassify(group, e){
      if(e.added)
        this.$store.dispatch('reclassify_asset', {group, row: e.added.element})
      if(e.moved){
        this.$store.dispatch('reorder_assets', {group, list: this[group]})
      }
    }
  }
}
</script>
