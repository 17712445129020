import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ContentSlider extends Component {
  name() {
    return 'Slider'
  }

  labels() {
    return ['video']
  }

  icon() {
    return 'fa-regular fa-arrows-h'
  }

  config(opts = {}) {
    return Layer.factory({
      layer_type: 'slider',
      text: '',
      width: 300,
      height: 300,
      config: {
        show_animations: true,
        animation: {
          delay_percentage: 0,
          duration_percentage: 100,
          in: null,
          while: null,
          out: null,
        },
        animations: [
          {
            type: 'inwhileout'
          },
          {
            type: 'swiper',
            in_place: false,
            easing: 'easeInOutExpo'
          }],
        object_fit: 'contain',
        swiper: {
          assets: [],
          use_items: false,
          version: 2
        }
      },
      ...opts
    })
  }
}
