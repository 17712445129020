export default {
  highlight(text, background = 'red', color = 'white'){
    text = text.replace(/\n\r?/g, '<br />')
    let padding = 'padding-left: 3px; padding-right: 3px;'
    if (background === 'transparent') padding = ''
    if (background.length === 9) background = `${background.slice(0, -2)}`
    if (color.length === 9) color = `${color.slice(0, -2)}`
    return text.replace(/==(.*?)==/g, `<span style="${padding} background: ${background}; color: ${color};">$1</span>`);
  }
}
