import Vue from './Vue.js' // eslint-disable-line
import ImageEditor from './image-editor/editor.vue'

function loadImageEditor(){
  const imageEditor = document.getElementsByTagName('image-editor')[0]
  if (imageEditor != null) {
    const app = new Vue({ // eslint-disable-line
      el: 'image-editor',
      components: { 'image-editor': ImageEditor },
    });
  }
}

export default loadImageEditor
