<template>
  <div class="feed-builder__color-container">

    <Panel :position="panelProps.position" :margin="panelProps.margin" :panelTitle="'Color'">
      <template v-slot:button>
        <div class="tweener">

          <div class="color-picker__color-field" v-if="element && element_key"
            :style="{ background: color(), cursor: 'pointer' }">
          </div>

          <div class="color-picker__value-field" v-if="c && name">
            <input type="hidden" :name="name" v-model="c.hex8">
            <input type="text" v-if="name && (typeof (c.hex8) == 'string' || typeof (c) == 'string')"
              :value="c.hex8 || c" @input="update_color">
          </div>
        </div>


      </template>
      <template v-slot>
        <ColorPicker v-model="c" :preset-colors="palette" :disableAlpha="true" />
        <div v-if="l_show_dynamic">
          <input type="text" v-model="c.hex8">
        </div>
      </template>
    </Panel>



    <div v-if="show_picker && element && element_key" v-on-clickaway="() => show_picker = false"
      class="color-picker__container">

    </div>

  </div>
</template>


<script>
import { mixin as clickaway } from 'vue-clickaway2';
import { Sketch } from 'vue-color'
import Panel from '../../studio/components/panel.vue'

export default {
  data() {
    return {
      show_picker: false,
      c: (this.element[this.element_key]?.hex8 ?
        this.element[this.element_key]?.hex8 :
        this.element[this.element_key]) || { h: "" },
      skip_watcher: false,
      l_show_dynamic: false,
    };
  },
  components: {
    ColorPicker: Sketch, Panel
  },
  mixins: [clickaway],
  props: {
    name: {
      type: String,
      required: false,
      default: ""
    },
    show_dynamic: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    element: {
      type: Object,
      required: true
    },
    element_key: {
      type: String,
      required: true
    },
    palette: {
      type: Array,
      required: false,
      default: () => []
    },
    on_change: {
      type: Function,
      required: false,
      default: () => { }
    },
    submit_on_change: {
      type: Boolean,
      required: false,
      default: false,
    },
    panelProps: {
      type: Object,
      required: false,
      default: () => ({ position: 'left', margin: 36 })
    }
  },
  watch: {
    show_dynamic: {
      immediate: true,
      handler(newVal) {
        if (this.$store && this.$store.state.options && this.$store.state.options.nightswatch) {
          this.l_show_dynamic = true
        } else if (this.$store && this.$store.state.options && this.$store.state.options.show_data) {
          this.l_show_dynamic = true
        } else {
          this.l_show_dynamic = newVal
        }
      }
    },
    element: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal[this.element_key]) {
          this.c = newVal[this.element_key];
        } else {
          this.skipWatcher = true
          this.c = { h: "" }
        }
      }
    },
    c: {
      deep: true,
      handler(newVal) {
        if (this.skipWatcher) {
          this.skipWatcher = false;
          return;
        }
        this.element[this.element_key] = newVal;
        this.on_change(this.element[this.element_key], this.element_key);
      }
    }
  },
  methods: {
    update_color(e) {
      if (this.c) {
        this.c = e.target.value
      }
    },
    color() {
      if (this.c && this.c.hex8) {
        return this.c.hex8
      } else if (this.c && this.c.hex) {
        return this.c.hex
      } else if (this.c && this.c.rgba) {
        let color = this.c.rgba
        return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
      } else {
        return this.c
      }
    },
    forceUpdate() {
      if (this.$props.submit_on_change === true) {
        setTimeout(() => {
          this.$el.closest('form').submit()
        }, 1)
      }
    }
  }
}

</script>