import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class SalePrice extends Component {
  name(){
    return 'Sale price'
  }

  labels(){
    return ['product']
  }

  icon(){
    return 'fa-regular fa-eur'
  }

  config(){
    return Layer.factory({
      layer_type: 'price',
      text: '{{sale_price}}'
    })
  }
}
