export default {
  active(state){
    return state.list.find((v) => v.id === state.active)
  },
  default(state){
    return state.list.find((v) => v.is_default || v.default)
  },
  list(state){
    return state.list.filter((v) => !v.deleted)
  }
}
