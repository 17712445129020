import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Text extends Component {
  name() {
    return this.display_name || 'Image'
  }

  labels() {
    return ['layer']
  }

  icon() {
    return 'fa-regular fa-image'
  }

  config(opts = {}) {
    return Layer.factory({
      layer_type: 'image',
      variable_tag: this.variable_tag,
      text: '',
      width: 200,
      height: 200,
      variable: !!this.variable_tag,
      config: {
        image_url: ''
      },
      ...opts
    })
  }
}
