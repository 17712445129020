import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class Swiper extends Animation {
  constructor({
    samples, uid, layer, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })
    this.use_items = false

    if (this.layer.config.swiper) this.use_items = this.layer.config.swiper.use_items
  }

  name() {
    return 'Swiper'
  }

  cleanup() {
    // Reset the swiper to its start position
    const swiperElement = document.querySelector(`#swiper_${this.uid}_${this.layer.id}`);
    if (swiperElement) {
      swiperElement.style.transform = 'translateX(0px)';
    }

    // Reset any other properties if needed
    // For example, resetting opacity of the first image if it was changed
    const firstImage = document.querySelector(`.swiper__slide__image_${this.uid}_${this.layer.id}`);
    if (firstImage) {
      firstImage.style.opacity = '1';
    }
  }

  static int_name() {
    return 'swiper'
  }

  static stand_alone() {
    return true
  }

  static exposed_to() {
    return ['swiper']
  }

  static properties() {
    return {
      ...super.properties(),
      in_place: {
        pretty_name: 'Static or Animated',
        type: 'List',
        default: 'animated',
        values: [{
          value: 'animated',
          display: 'Animated',
        }, {
          value: 'static',
          display: 'Static',
        }],
      },
      easing: {
        pretty_name: 'Animation type',
        type: 'List',
        default: 'animated',
        values: [{
          value: 'easeInQuad',
          display: 'easeInQuad',
        }, {
          value: 'linear',
          display: 'linear',
        }, {
          value: 'easeOutQuad',
          display: 'easeOutQuad',
        }],
      }
    }
  }

  composeable() {
    return false
  }

  in_place() {
    return (this.conf().in_place === 'static' || this.conf().in_place === true)
  }

  easing() {
    return this.conf().easing || 'easeInQuad'
  }

  has_focus_points(src) {
    try {
      const link = new URL(src)
      const focus_x = link.searchParams.get('focus_x')
      const focus_y = link.searchParams.get('focus_y')
      return !!(focus_x && focus_y)
    } catch (e) {
      return false
    }
  }

  background_position(src) {
    let y = (Math.abs(this.focus_points(src).y) / 2) * 100
    let x = (Math.abs(this.focus_points(src).x) / 2) * 100
    if (this.focus_points(src).y < 0) {
      y += 50
    } else {
      y = 50 - (Math.abs(this.focus_points(src).y) * 50)
    }
    if (this.focus_points(src).x > 0) {
      x += 50
    } else {
      x = 50 - (Math.abs(this.focus_points(src).x) * 50)
    }
    return `${x}% ${y}%`
  }

  focus_points(src) {
    try {
      const link = new URL(src)
      const focus_x = link.searchParams.get('focus_x')
      const focus_y = link.searchParams.get('focus_y')
      return { x: focus_x, y: focus_y }
    } catch (e) {
      return { x: 0, y: 0 }
    }
  }

  version() {
    if (this.layer && this.layer.config && this.layer.config.swiper) {
      return this.layer.config.swiper.version
    }
    return 1
  }

  conf() {
    return this.layer.config.animations.find((a) => a.type === 'swiper')
  }

  animation(callback) {
    if (this.version() > 1) this.duration *= 0.6

    let timeline = anime.timeline({
      duration: this.duration,
      autoplay: false,
    })

    let assets = []
    if (this.layer.config.swiper
      && this.layer.config.swiper.assets
      && this.layer.config.swiper.assets.filter(Boolean).length > 0) {
      assets = this.layer.config.swiper.assets.map((a) => a.asset_url)
    } else {
      assets = this.samples
        .map((sample) => sample['1_item_image_link'])
    }

    if (this.in_place()) {
      assets.forEach((asset, index) => {
        timeline.add({
          targets: `.swiper__slide__image_${this.uid}_${this.layer.id}`,
          opacity: [1, 1],
          duration: (this.duration / (assets.length - 1)),
          easing: this.easing(),
          begin: () => {
            if (this.has_focus_points(asset)) {
              $(`.swiper__slide__image_${this.uid}_${this.layer.id}`).hide()
              $(`.swiper__slide_${this.uid}_${this.layer.id}`).css({
                'background-position': this.background_position(asset),
                'background-image': `url(${asset})`,
              })
            } else {
              $(`.swiper__slide__image_${this.uid}_${this.layer.id}`).show()
              $(`.swiper__slide__image_${this.uid}_${this.layer.id}`).attr('src', asset)
            }
            if (callback) callback({ index })
          },
        })
      })
    } else {
      timeline = this.swipe(timeline, assets, callback)
    }

    return timeline
  }

  swipe(timeline, assets, callback) {
    const duration = this.duration * 0.9;
    // Assuming we want animation to take 40% of the total duration
    const totalAnimationTime = duration * 0.5;
    const animationDurationPerAsset = totalAnimationTime / assets.length;

    // Calculate total time per asset including both animation and display time
    const totalTimePerAsset = (duration / assets.length) - animationDurationPerAsset;

    assets.forEach((asset, index) => {
      let delay = index * totalTimePerAsset;
      if (index === 0) delay = this.duration * 0.1;
      const offset = -Math.abs(index * this.layer.width);
      timeline.add({
        targets: `#swiper_${this.uid}_${this.layer.id}`,
        translateX: offset,
        duration: animationDurationPerAsset,
        delay,
        easing: this.easing(),
        update(anim) {
          if (anim.progress > 50 && anim.progress < 55) {
            if (callback) callback({ index });
          }
        },
      });
    });

    return timeline;
  }
}
