<template>
  <div>
    <span :class="{'editor__strikethrough-diagonal': layer.config.strikethrough == true || layer.config.strikethrough == 'true'}"
          :style="{'--price-strikethrough-deg': `${layer.config.strikethrough_angle}deg`,
                   '--price-strikethrough-width': `${layer.fontSize * 0.12 || 1}px`,
                   '--price-strikethrough-color': `${layer.config.strikethrough_color || 'black'}`,
                   fontFamily: font, fontSize: `${layer.fontSize}px`, color: layer.color}">
      <span v-if="(layer.config.superscript == 'true' || layer.config.superscript === true) && !layer.config.truncate_trailing_zeros">
        {{getValue(layer.text).split(layer.config.decimal)[0]}}{{layer.config.decimal}}<sup>{{getValue(layer.text).split(layer.config.decimal)[1]}}</sup>
      </span>
      <span v-else>
        {{getValue(layer.text)}}
      </span>
    </span>
  </div>
</template>

<script>
import {mustache} from '../../lib/parsers'
import {apply} from './tags'

export default  {
  props: ['layer', 'samples', 'disabled', 'brandkit'],
  computed: {
    sample: function(){
      return this.samples.data[this.samples.indice] || {}
    },
    font(){
      if(this.layer.dynamic_font_id){
        let font_id = mustache.fill_in(this.layer.dynamic_font_id, {brandkit: this.brand})
        if(this.$root.$store && this.$root.$store.state.fonts){
          let font = this.$root.$store.state.fonts.find(f => f.id == font_id)
          if(font)
            return font.face
        }
        return this.layer.font
      } else {
        return this.layer.font
      }
    },
    brand: function(){
      let brand = Object.keys(this.$props.brandkit).length > 0 ? this.$props.brandkit : (this.$root.$store ? this.$root.$store.state.brandkit : {})
      let placeholder_brand = {}
      if(this.samples.data[this.samples.indice] && this.samples.data[this.samples.indice].brandkit){
        placeholder_brand = this.samples.data[this.samples.indice].brandkit
      }
   
      let result = { ...brand }
      for (let key in brand) {
        if (!brand[key] && placeholder_brand[key]) {
          result[key] = placeholder_brand[key]
        }
      }

      return result;
    },
  },
  methods: {
    getValue(string){
      if(!string) return null
      try {
        if(this.samples && this.samples.data.length > 0){
          return this.parse(mustache.fill_in(string, this.samples.data[this.samples.indice]))
        } else {
          return this.parse(string)
        }
      } catch(e) {
        return this.disabled ? `Layer ${this.layer.id}` : ' '
      }
    },
    parse(str){
      return apply(str, this.sample, {...this.layer.config})
    },
  }
}
</script>
