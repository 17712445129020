<template>
  <div class="layer-config__essentials">

    <div class="form-group">
      <label>{{ layer.layer_type }}</label>
      <SwiperPicker v-if="version > 1" :layer="layer" :onUpdate="onUpdate" />
    </div>

    <div class="form-group">
      <label>Scale</label>
      <div class="button__tab_group">
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_fit'] == 'contain' }"
          class="button__toggle__light" v-on:click="updateLayerConfig({ object_fit: 'contain' })">
          <i class="fa-regular fa-compress" aria-hidden="true"></i>
          <span class="tooltip tooltip--top">Fit</span>
        </button>
        <button type="button" :class="{ 'button__toggle--active': layer.config['object_fit'] == 'cover' }"
          class="button__toggle__light" v-on:click="updateLayerConfig({ object_fit: 'cover' })">
          <i class="fa-regular fa-expand" aria-hidden="true"></i>
          <span class="tooltip tooltip--top">Cover</span>
        </button>
      </div>
    </div>

  </div>
</template>

<script>

import UrlUpload from '../../../feeds/fields/audio-video.vue'
import SwiperPicker from '../../../feeds/fields/swiper-picker.vue'
import { EventBus } from '../../../lib/media/eventbus'

export default {
  props: ['mediaFolder', 'layer', 'onUpdate', 'version'],
  components: {
    UrlUpload,
    SwiperPicker
  },
  data() {
    return {
      field_id: Math.random().toString(36).substring(7)
    }
  },
  computed: {
    assets() {
      return this.layer.config.swiper.assets
    },
    isSwiperEmpty() {
      return this.assets.length === 0;
    }
  },
  methods: {
    updateLayerConfig(conf) {
      this.updateLayer('config', { ...this.layer.config, ...conf })
    },
    updateLayer(key, value) {
      const layer = { ...this.layer, [key]: value }
      this.$props.onUpdate(layer)
    },
  }
}

</script>
