<template>
  <div class="layer-config__spacer">
    <div class="form-group">
      <label> Background </label>
      <div style="display: flex; gap: 6px;">
        <ColorField :element="highlights.background" :element_key="'color'" :palette="palette" :show_dynamic="false" />
        <button class="button--light button--dark-text" @click="updateLayerConfig({
          highlights: {
            ...layer.config.highlights,
            background_color: 'transparent'
          }
        })">
          <i class="fa-regular fa-eraser" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="form-group">
      <label> Text </label>
      <div style="display: flex; gap: 6px;">
        <ColorField :element="highlights.text" :element_key="'color'" :palette="palette" :show_dynamic="false" />
      </div>
    </div>
  </div>
</template>

<script>
import ColorField from '../../feeds/fields/color.vue'
import UrlUpload from '../../feeds/fields/audio-video.vue'
export default {
  props: ['layer', 'onUpdate', 'palette'],
  components: {
    UrlUpload,
    ColorField
  },
  data() {
    return {
      show_highlights: false,
      highlights: {
        text: {
          color: {
            hex8: '#FFFFFFFF'
          }
        },
        background: {
          color: {
            hex8: '#FF0000FF'
          }
        }
      }
    }
  },
  watch: {
    highlights: {
      deep: true,
      handler(newVal) {
        this.updateLayerConfig({
          highlights: {
            text_color: newVal.text.color.hex8,
            background_color: newVal.background.color.hex8
          }
        })
      }
    },
  },
  mounted() {
    this.highlights = {
      text: {
        color: {
          hex8: this.layer.config?.highlights?.text_color ?? '#FFFFFFFF'
        }
      },
      background: {
        color: {
          hex8: this.layer.config?.highlights?.background_color ?? '#FF0000FF'
        }
      }
    }
  },
  methods: {
    updateLayerConfig(conf) {
      this.updateLayer('config', { ...this.layer.config, ...conf })
    },
    updateLayer(key, value) {
      const layer = { ...this.layer, [key]: value }
      this.$props.onUpdate(layer)
    },
  }
}
</script>