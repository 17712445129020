import axios from 'axios';
import facebookPageSelector from './expression/components/facebook/pageselector.vue'
import { loadExpressionComponent } from  './lib/nimrod/loaders/load_expression_component'

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

function  loadFacebookPageSelector(){
  $('facebook_page_selector').each((index, el) => {
    const renderComponent = { tag: 'facebook_page_selector', component: facebookPageSelector }
    const element = el
    loadExpressionComponent({ ...renderComponent, element })
  })
}

export default loadFacebookPageSelector
