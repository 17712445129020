import Layer from '../../store/modules/layers/model'
import TextArea from './text_area'

export default class Title extends TextArea {
  constructor({ variable_tag, display_name, default_value } =
  { variable_tag: null, display_name: null, default_value: null }) {
    super({ variable_tag, display_name, default_value })
  }

  name() {
    return this.display_name || 'Title'
  }

  labels() {
    return ['layer']
  }

  icon() {
    return 'fa-regular fa-text'
  }

  config({ canvas }) {
    return Layer.factory({
      layer_type: 'text_area',
      text: this.default_value || '',
      width: 800,
      height: 150,
      color: '#000000',
      align: 'center',
      config: {
        v_align: 'center',
      },
      fontSize: 120,
      x: canvas.width / 2 - 200,
      y: canvas.height / 3 - 75,
      variable: true,
      variable_tag: 'title',
    })
  }
}
