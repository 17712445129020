<template>
    <div>
        <label>Enabled</label>
        <input type="checkbox" v-model="layer.config.constraints_enabled" @change="updateLayerConfig({enabled: layer.config.constraints_enabled})">
        
        <br><br>
        <label> min width </label>
        <input type="number" v-model="layer.config.constraints_min_width" @change="updateLayerConfig({min_width: layer.config.constraints_min_width})">

        <label> max width </label>
        <input type="number" v-model="layer.config.constraints_max_width" @change="updateLayerConfig({max_width: layer.config.constraints_max_width})">

        <label> min height </label>
        <input type="number" v-model="layer.config.constraints_min_height" @change="updateLayerConfig({min_height: layer.config.constraints_min_height})">

        <label> max height </label>
        <input type="number" v-model="layer.config.constraints_max_height" @change="updateLayerConfig({max_height: layer.config.constraints_max_height})">
    </div>
  </template>
  
  <script>
  export default  {
    props: ['layer', 'onUpdate', 'palette'],
    components: {

    },
    data(){
      return {
        
      }
    },
    watch: {
     
      
    },
    mounted(){
     
    },
    methods: {
      updateLayerConfig(conf){
        this.updateLayer('config', {...this.layer.config, ...conf})
      },
      updateLayer(key, value){
        const layer = {...this.layer, [key]: value}
        this.$props.onUpdate(layer)
      },
    }
  }
  </script>
  