<template>
  <div>

  </div>
</template>

<script>
export default  {
  props: ['layer', 'samples', 'disabled', 'active'],
  mounted(){

  },
  watch:{
    active(newVal, oldVal){

    }
  },
  methods: {

  }
}
</script>
