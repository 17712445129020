<template>
  <div style="display: flex; flex-direction: column; gap: 8px;">
    <h3>Edit</h3>
    <div v-for="(layer, index) in sortedLayers" :key="layer.id">
      <div v-if="layer.layer_type === 'image'" class="form-group">
        <label>{{ layer.layer_type }} / {{ layer.variable_tag }}</label>
        <UrlUpload 
          name="image_url" 
          element_key="data" 
          :element="{ data: layer.config.image_url }"
          :on_change="(url) => setLayerImage(layer, url)"
          :panelProps="{ position: 'right', margin: 36 }"
        />
      </div>
      <div v-if="layer.layer_type === 'background'" class="form-group">
        <label>{{ layer.layer_type }} / {{ layer.variable_tag }}</label>
        <UrlUpload 
          name="media_url" 
          element_key="data" 
          :element="{ data: layer.config.media_url }"
          :on_change="(url) => setMediaUrl(layer, url)"
          :panelProps="{ position: 'right', margin: 36 }"
        />
      </div>
      <div v-if="layer.layer_type === 'text_area'" class="form-group">
        <label>{{ layer.layer_type }} / {{ layer.variable_tag }}</label>
        <textarea ref="layerText" rows="3" type="text" :value="layer.text"
          @input="(e) => updateLayer(layer, 'text', e.target.value)">
        </textarea>
      </div>
      <div class="form-group" v-if="layer.layer_type === 'text'">
        <label>{{ layer.layer_type }} / {{ layer.variable_tag }}</label>
        <input ref="layerText" type="text" :value="layer.text"
          @input="(e) => updateLayer(layer, 'text', e.target.value)">
      </div>
      <div class="form-group" v-if="layer.layer_type === 'price'">
        <label>{{ layer.layer_type }} / {{ layer.variable_tag }}</label>
        <input type="text" :value="layer.text" @input="(e) => updateLayer(layer, 'text', e.target.value)">
      </div>

      <div class="form-group" v-if="layer.layer_type === 'swiper'">
        <label>{{ layer.layer_type }}</label>
        <SwiperPicker :layer="layer" :onUpdate="updateLayer" />
      </div>
    </div>
  </div>
</template>

<script>
import UrlUpload from '../feeds/fields/audio-video.vue'
import { EventBus } from '../lib/media/eventbus'
import SwiperPicker from '../feeds/fields/swiper-picker.vue'

export default {
  components: {
    UrlUpload,
    SwiperPicker
  },
  props: {
    layers: {
      type: Array,
      required: true
    },
    onUpdate: {
      type: Function,
      required: true
    },
  },
  computed: {
    sortedLayers() {
      const order = {
        text: 40,
        text_area: 30,
        price: 60,
        image: 50,
        swiper: 10,
        background: 20
      };
      return this.layers.slice().sort((a, b) => order[a.layer_type] - order[b.layer_type]);
    }
  },
  methods: {
    setLayerImage(layer, url) {
      this.updateLayerConfig(layer, { 'image_url': url });
    },
    setMediaUrl(layer, url) {
      this.updateLayerConfig(layer, { 'media_url': url });
    },
    updateLayerConfig(layer, conf) {
      this.updateLayer(layer, 'config', { ...layer.config, ...conf });
    },
    updateLayer(layer, key, value) {
      const l = { ...layer, [key]: value };
      this.$props.onUpdate(l);
    }
  }
}
</script>
