import { sale_diff } from './sale_diff'
import { sale_pct } from './sale_pct'
import lib from './lib'

const REGISTERED_TAGS = {
  sale_diff,
  sale_pct
}

export const apply = (string, sample, config) => {
  Object.keys(REGISTERED_TAGS).forEach((tag) => {
    if (REGISTERED_TAGS[tag].GLOB_MATCHER.test(string)) {
      string = REGISTERED_TAGS[tag].parse(string, sample, config)
    }
    if (lib.is_currency(string)) {
      const cur = lib.get_currency(string, sample, config)
      const cur_options = {
        ...lib.default_currency_format,
        ...config,
        pattern: config.show_symbol === 'true' || config.show_symbol === true ? '!#' : '#',
      }
      string = string.replace(cur.matched_string, cur.amount.format(cur_options))
      if (config.truncate_trailing_zeros === 'true' || config.truncate_trailing_zeros === undefined || config.truncate_trailing_zeros === true) {
        string = string.replace(`${cur_options.decimal}00`, `${cur_options.decimal}-`)
      } else if (config.truncate_trailing_zeros === 'hide_if_zero') {
        const decimal = parseInt(string.split(cur_options.decimal)[1], 10)
        if (decimal === 0) {
          string = string.split(cur_options.decimal)[0]
        }
      }
    }
  })

  return string
}

export const parseCurrency = (string, sample, config) => {
  if (lib.is_currency(string)) {
    const cur = lib.get_currency(string, sample, config)
    const cur_options = {
      ...lib.default_currency_format,
      ...config,
      pattern: config.show_symbol === 'true' || config.show_symbol === true ? '!#' : '#',
    }
    string = string.replace(cur.matched_string, cur.amount.format(cur_options))
    if (config.truncate_trailing_zeros === 'true' || config.truncate_trailing_zeros === undefined || config.truncate_trailing_zeros === true) {
      string = string.replace(`${cur_options.decimal}00`, `${cur_options.decimal}-`)
    } else if (config.truncate_trailing_zeros === 'hide_if_zero') {
      const decimal = parseInt(string.split(cur_options.decimal)[1], 10)
      if (decimal === 0) {
        string = string.split(cur_options.decimal)[0]
      }
    }
  }
  return string
}
