import Layer from './model'
import { getLinkedLayers } from './util'

export default {
  active_variant(state, getters, rootState) {
    return rootState.variants.list.find((v) => v.id === rootState.variants.active)
  },
  active(state) {
    const l = state.list.find((v) => v.id === state.active)
    if (l) return new Layer(l)
    return l
  },
  active_with_related(state, getters, rootState) {
    return getLinkedLayers(state.list, getters.active).map((l) => {
      const v = rootState.variants.list.find((v) => l.image_canvas_variant_id === v.id)
      l.origin_variant_size = v.size
      return l
    })
  },
  variants(state, getters, rootState) {
    return rootState.variants.list
  },
  all(state) {
    return state.list.filter((l) => l.deleted === false)
  },
  all_variable(state) {
    const vars = state.list.filter((l) => l.variable === true && l.deleted === false)
    return vars.filter((v) => v.parent_id === null && v.variable)
      .sort((a, b) => b.layer_type.localeCompare(a.layer_type))
  },
  list(state, getters) {
    const list = state.list.filter((l) => {
      if (getters.active_variant && l) {
        return (l.image_canvas_variant_id === getters.active_variant.id)
          && (l.deleted === false || l.deleted === undefined)
      }
      return true
    }).sort((a, b) => a.position - b.position)
    return list
  }
}
