import FocusPoint from './focuspoint';
import Swiper from './swiper';
// import Texter from './texter';
import Zoom from './zoom';
import PopIn from './pop_in'
import SlideIn from './slide_in'
import Drop from './drop_in'
// import Revealer from './revealer';
import ZoomSlider from './zoomslider'
// import TextPop from './text_pop'
import Entrance from './entrance'
import Marquee from './marquee';
import TypeWriter from './typewriter';
import Fade from './fade'
import InWhileOut from './inwhileout'
import ReviewStarsFlyIn from './review_stars_fly_in'

export default class Animations {
  static animations() {
    return [
      FocusPoint,
      Swiper,
      // Texter,
      Zoom,
      SlideIn,
      Fade,
      ZoomSlider,
      PopIn,
      Drop,
      Entrance,
      Marquee,
      TypeWriter,
      InWhileOut,
      ReviewStarsFlyIn
      // TextPop
    ]
  }

  static by_type(type) {
    return Animations.animations().find((a) => a.int_name() === type)
  }

  static available_animations_for(layer) {
    return Animations.animations().filter((a) => a.exposed_to().includes(layer.layer_type))
  }
}
