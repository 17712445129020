import {
  CREATE,
  UPDATE,
  DELETE,
  INIT,
  ACTIVATE,
  CREATE_REMOTE,
  UPDATE_REMOTE,
  DELETE_REMOTE,
  UNDO
} from '../mutation_types'
import Variant from './model'

import { SET_LAYERS } from './mutation_types'

export default {
  [SET_LAYERS](state, layers) {
    state.list = state.list.map((variant) => {
      if (variant.id === state.active) {
        variant.layers = layers
      }
      return variant
    })
  },

  [UNDO](state, history) {
    switch (history.type.split('/')[1]) {
      case CREATE:
        state.list = state.list.filter((v) => v.id !== history.new_value.id)
        break;
      case UPDATE:
        state.list = state.list.map((v) => ((v.id === history.new_value.id) ? history.old_value : v)) // eslint-disable-line
        break;
      case DELETE:
        state.list = state.list.map((v) => {
          if (v.id === history.old_value.id) {
            v.deleted = false
          }
          return v
        })
        break;
      default:
        break;
    }
    return state
  },

  [CREATE_REMOTE](state, { old_val, new_val }) {
    state.list = state.list.map((variant) => {
      if (variant.id === old_val.id) {
        variant = Variant.factory({ ...variant, ...new_val, dirty: false })
      }
      return variant
    })
  },

  [UPDATE_REMOTE](state, { old_val, new_val }) {
    state.list = state.list.map((variant) => {
      if (variant.id === old_val.id) {
        variant = Variant.factory({ ...variant, ...new_val, dirty: false })
      }
      return variant
    })
  },

  [DELETE_REMOTE](state, { old_val }) {
    state.list = state.list.filter((v) => v.id !== old_val.id)
  },

  [CREATE](state, variant) {
    state.list = [...state.list, variant]
  },

  [UPDATE](state, variant) {
    state.list = state.list.map((v) => {
      if (variant.id === v.id) {
        v = { ...v, ...variant }
      }
      return v
    })
  },

  [DELETE](state, variant) {
    state.list = state.list.map((v) => {
      if (v.id === variant.id) {
        v.deleted = true
      }
      return v
    })
  },

  [INIT](state, variants) {
    state.list = variants
  },

  [ACTIVATE](state, variant) {
    state.active = variant.id
  }
}
