import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ListComponent extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'List'
  }

  labels() {
    return ['lists']
  }

  icon() {
    return 'fa-solid fa-list'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'draggableListWithTextAndIcon',
          name: 'Items',
          key: 'items',
        },
        [
          {
            type: 'color',
            name: 'Text',
            key: 'root.color',
          },
          {
            type: 'color',
            name: 'Icon',
            key: 'icon_color',
          },
          {
            type: 'color',
            name: 'Bg',
            key: 'bg_color',
          },
          {
            type: 'toggle',
            name: 'Show bg',
            key: 'show_bg',
          },
        ],
        {
          type: 'buttonGroup',
          name: 'Icon type',
          key: 'icon_type',
          options: [
            {
              type: 'icon',
              name: 'Check',
              icon: 'fa-regular fa-check',
              value: 'fa-regular fa-check',
            },
            {
              type: 'icon',
              name: 'Check circle',
              icon: 'fa-regular fa-circle-check',
              value: 'fa-regular fa-circle-check',
            },
            {
              type: 'icon',
              name: 'Check solid circle',
              icon: 'fa-solid fa-circle-check',
              value: 'fa-solid fa-circle-check',
            },
            {
              type: 'icon',
              name: 'Check solid circle',
              icon: 'fa-regular fa-shield-check',
              value: 'fa-regular fa-shield-check',
            },
            {
              type: 'icon',
              name: 'Check solid circle',
              icon: 'fa-solid fa-badge-check',
              value: 'fa-solid fa-badge-check',
            },
            {
              type: 'icon',
              name: 'Check solid circle',
              icon: 'fa-solid fa-xmark',
              value: 'fa-solid fa-xmark',
            },
            {
              type: 'icon',
              name: 'Check solid circle',
              icon: 'fa-solid fa-octagon-xmark',
              value: 'fa-solid fa-octagon-xmark',
            },
            {
              type: 'icon',
              name: 'Check solid circle',
              icon: 'fa-solid fa-ban',
              value: 'fa-solid fa-ban',
            },
            {
              type: 'text',
              name: 'None',
              value: 'none',
            },
          ]
        },
        {
          type: 'slider',
          name: 'Icon size',
          key: 'icon_size',
          min: -100,
          max: 200,
          step: 10,
        },
        {
          type: 'slider',
          name: 'Icon spacing',
          key: 'icon_spacing',
          min: 0,
          max: 100,
          step: 1,
        },
        {
          type: 'slider',
          name: 'List spacing',
          key: 'list_spacing',
          min: 0,
          max: 250,
          step: 1,
        },
        {
          type: 'buttonGroup',
          name: 'Align',
          key: 'align_list',
          options: [
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-left',
              value: 'flex-start',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-left-right',
              value: 'center',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-right',
              value: 'flex-end',
            },
          ]
        },
        {
          type: 'buttonGroup',
          name: 'Icon position',
          key: 'icon_position',
          options: [
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-solid fa-list',
              value: 'l',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-solid fa-list fa-flip-horizontal',
              value: 'r',
            },
          ]
        },
        {
          type: 'assetPicker',
          name: 'Icon',
          key: 'icon',
          display: 'simple'
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        layers: [{
          ...baseConfig,
          color: 'white',
          fontSize: 30,
          config: {
            ...baseConfig.config,
            list: {
              ...baseConfig.config.list,
              icon_position: 'l',
              icon: 'fa-solid fa-check',
              orientation: 'horizontal',
              vertical_gap: 10,
              horizontal_gap: 50,
              icon_color: 'white',
              bg_color: '#3F3F3F',
              show_bg: true,
              icon_size: -40,
              icon_spacing: 13,
              list_spacing: 4,

            }
          },
        }]
      },
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          color: 'black',
          fontSize: 30,
          config: {
            ...baseConfig.config,
            list: {
              ...baseConfig.config.list,
              icon_position: 'l',
              icon_type: 'fa-regular fa-circle-check',
              orientation: 'horizontal',
              vertical_gap: 10,
              horizontal_gap: 50,
              icon_color: 'black',
              bg_color: 'white',
              show_bg: true,
              icon_size: -40,
              icon_spacing: 13,
              list_spacing: 4,

            }
          },
        }]
      },
      {
        layers: [{
          ...baseConfig,
          color: 'white',
          fontSize: 30,
          config: {
            ...baseConfig.config,
            list: {
              ...baseConfig.config.list,
              icon_position: 'l',
              icon: 'fa-solid fa-circle-check',
              orientation: 'horizontal',
              vertical_gap: 10,
              horizontal_gap: 50,
              icon_color: '#62FF62',
              bg_color: '#949453',
              show_bg: true,
              icon_size: -40,
              icon_spacing: 13,
              list_spacing: 4,

            }
          },
        }]
      },
      {
        layers: [{
          ...baseConfig,
          color: 'white',
          fontSize: 30,
          config: {
            ...baseConfig.config,
            list: {
              ...baseConfig.config.list,
              icon_position: 'l',
              icon: 'fa-solid fa-xmark',
              orientation: 'horizontal',
              vertical_gap: 10,
              horizontal_gap: 50,
              icon_color: '#FF3030',
              bg_color: '#944949',
              show_bg: true,
              icon_size: -40,
              icon_spacing: 13,
              list_spacing: 4,

            }
          },
        }]
      },
    ]
  }

  baseConfig(opts = {}) {
    return {
      layer_type: 'component',
      color: 'black',
      align: 'left',
      width: 300,
      height: 200,
      fontSize: 46,
      ...opts,
      ...this.opts,
      config: {
        v_align: 'center',
        component: {
          type: 'list',
          name: 'List',
        },
        list: {
          icon_position: 'l',
          align_list: 'flex-start',
          // icon: 'fa-regular fa-circle-check',
          icon_type: 'fa-regular fa-check',
          icon_color: '#808080',
          bg_color: '#5F5F5F',
          orientation: 'vertical',
          icon_spacing: 20,
          list_spacing: 12,
          items: [
            {
              text: 'Item 1',
            },
            {
              text: 'Item 2',
            },
            {
              text: 'Item 3',
            },
          ]
        }
      }
    }
  }

  config() {
    return {
      background: 'white',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
