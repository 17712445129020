import Background from './types/background'
import Layer from '../../store/modules/layers/model'

export default class BgGradient extends Background {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Gradient'
  }

  labels() {
    return ['background']
  }

  icon() {
    return 'fa-solid fa-heart'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        [
          {
            type: 'color',
            name: 'Color 1',
            key: 'color_1',
          },
          {
            type: 'color',
            name: 'Color 2',
            key: 'color_2',
          },
        ],
        {
          type: 'buttonGroup',
          name: 'Direction',
          hide_label: false,
          key: 'direction',
          options: [
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-left',
              value: 'left',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-right',
              value: 'right',
            },
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-up',
              value: 'top',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-down',
              value: 'bottom',
            }
          ]
        },
        {
          type: 'buttonGroup',
          name: 'Type',
          hide_label: false,
          key: 'type',
          options: [
            {
              type: 'text',
              name: 'lineair',
              value: 'lineair',
            },
            {
              type: 'text',
              name: 'radial',
              value: 'radial',
            }
          ]
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: 'white',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            bg_gradient: {
              ...baseConfig.config.bg_gradient,
              color_1: '#BEA681',
              color_2: '#FFF3E0',
              direction: 'top',
              type: 'radial'
            }
          },
        }]
      },
    ]
  }

  baseConfig({ canvas = { width: 560, height: 245 } } = {}) {
    return {
      layer_type: 'component',
      width: canvas.width,
      height: canvas.height,
      ...this.opts,
      config: {
        component: {
          type: 'bg_gradient',
          name: 'Gradient',
        },
        bg_gradient: {
          color_1: '#212A38',
          color_2: '#2B4774',
          direction: 'top',
          type: 'lineair',
        }
      }
    }
  }

  config({ canvas = { width: 560, height: 245 } } = {}) {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig({ canvas }))
      ]
    }
  }
}
