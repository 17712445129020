<template>
  <div class="studio__sidebar studio__sidebar__sizes">
    <div class="studio__sidebar__sizes__body">
      <h4 class="margin-left-2">sizes</h4>
      <div class="studio__size_preview"
        :class="{ 'studio__size_preview--active': active_size ? active_size.name === variant.size.name : false }"
        v-for="(variant, index) in sizes" :key="index" @click="on_size_select(variant)">
        <div class="studio__size_preview__header">
          <p>{{ variant.size.short_name }}</p>
        </div>
        <div class="studio__size_preview__body">
          <div ref="parentContainer" class="canvas_scale_container">
            <div
              :style="{ zoom: getComputedScale(variant, index), width: `${variant.size.display_dimensions[0]}px`, height: `${variant.size.display_dimensions[1]}px` }">
              <Canvas :ref="'canvas_' + index" v-bind:layers="get_layers_of_variant(variant)"
                v-bind:background="canvas.background_image" v-bind:background_color="canvas.background_color"
                v-bind:size="{ w: variant.size.display_dimensions[0], h: variant.size.display_dimensions[1] }"
                v-bind:disabled="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Canvas from './canvas.vue'

export default {
  components: { Canvas },
  props: {
    sizes: {
      type: Array,
      required: true
    },
    active_size: {
      type: Object,
      required: false
    },
    canvas: {
      type: Object,
      required: true
    },
    layers: {
      type: Array,
      required: true
    },
    on_size_select: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      parentDimensions: {}
    };
  },
  computed: {
    size() {
      if (this.$props.variant && this.$props.variant.size) {
        return {
          w: this.$props.variant.size.display_dimensions[0],
          h: this.$props.variant.size.display_dimensions[1]
        };
      } else {
        return {
          w: this.$props.canvas.width,
          h: this.$props.canvas.height
        };
      }
    }
  },
  methods: {
    updateParentDimensions(index) {
      this.$nextTick(() => {
        const parent = this.$refs.parentContainer[index];
        if (parent) {
          this.$set(this.parentDimensions, index, {
            width: parent.clientWidth,
            height: parent.clientHeight
          });
        }
      });
    },
    getComputedScale(variant, index) {
      const parentDimension = this.parentDimensions[index] || { width: 0, height: 0 };
      const scaleX = parentDimension.width / variant.size.display_dimensions[0];
      const scaleY = parentDimension.height / variant.size.display_dimensions[1];
      return Math.min(scaleX, scaleY);
    },
    get_zoom(variant) {
      let w = variant.size.display_dimensions[0];
      let h = variant.size.display_dimensions[1];
      if (w > h) {
        return 0.065;
      }
      return 0.1;
    },
    get_layers_of_variant(variant) {
      return this.layers.filter(layer => layer.image_canvas_variant_id === variant.id);
    }
  },
  mounted() {
    this.sizes.forEach((variant, index) => {
      this.updateParentDimensions(index);
      window.addEventListener('resize', () => this.updateParentDimensions(index));
    });
  },
  beforeDestroy() {
    this.sizes.forEach((variant, index) => {
      window.removeEventListener('resize', () => this.updateParentDimensions(index));
    });
  }
}
</script>