export default class Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    this.layer = layer
    this.uid = uid
    this.samples = samples
    this.scene_duration = duration
    this.duration_percentage = layer.config.animation ? layer.config.animation.duration_percentage : 100  // eslint-disable-line
    this.delay_percentage = layer.config.animation ? layer.config.animation.delay_percentage : 0
    this.duration = this.scene_duration * (this.duration_percentage / 100)
    this.update_layer = update_layer
    this.id = this.id()
    this.container = '_container';
    this.initial_transform = this.layer.config.transform || `translate(${0}px, ${0}px) scale(1)`
    this.finished = false
  }

  id() {
    return (Math.random() + 1).toString(36).substring(7)
  }

  prepare() {

  }

  finish() {
    this.finished = true
    this.began = false
  }

  opacity() {
    return this.layer.config.opacity ? this.layer.config.opacity / 100 : 1
  }

  while() {
    return this.name()
  }

  target() {
    if (!this.container) {
      return `#layer_content_${this.uid}_${this.layer.id}`;
    }
    return `.layer${this.container}_${this.uid}_${this.layer.id}`;
  }

  reload(layer) { // eslint-disable-line
    this.initial_transform = this.layer.config.transform
    $(this.target()).css('transform', this.initial_transform)
    this.finished = false
  }

  cleanup() {
    // override in child
    this.finished = false
  }

  easing() {
    return this.conf().easing ? this.conf().easing : 'linear'
  }

  loop() {
    return this.conf().loop ? this.conf().loop : false
  }

  conf() {
    if (this.layer.config.animations.length > 0) {
      return this.layer.config.animations[0]
    }
    return {}
  }

  static exposed_to() {
    // list of layertypes this animation can be applied to
    return []
  }

  static int_name() {
    // internal name of the animation
    return 'animation'
  }

  seek() {

  }

  restart() {

  }

  static properties() {
    // list of props that are exposed as settings to the user
    // to configure the animation
    return {
      easing: {
        type: 'Select',
        default: 'easeOutExpo',
        options: [
          'linear',
          'easeInQuad',
          'easeInCubic',
          'easeInQuart',
          'easeInQuint',
          'easeInSine',
          'easeInExpo',
          'easeInCirc',
          'easeInBack',
          'easeOutQuad',
          'easeOutCubic',
          'easeOutQuart',
          'easeOutQuint',
          'easeOutSine',
          'easeOutExpo',
          'easeOutCirc',
          'easeOutBack',
          'easeInOutQuad',
          'easeInOutCubic',
          'easeInOutQuart',
          'easeInOutQuint',
          'easeInOutSine',
          'easeInOutExpo',
          'easeInOutCirc',
          'easeInOutBack',
        ]
      },
      // loop: {
      //   type: 'Boolean',
      //   default: false
      // }
    }
  }

  in() {
    return null
  }

  composeable() {
    return false
  }

  static composeable() {
    return false
  }

  out() {
    return null
  }

  begin() {
    this.began = true
    this.finished = false
  }

  static stand_alone() {
    // if true this animation is tied to it's own layer type and not something that can be applied
    // to multiple layers.
    return false
  }

  animation_config(duration = this.duration) {
    // override in child
    return {
      duration
    }
  }

  set_attributes(e, timeline_element, layer) {
    this.layer = { ...layer }
    this.set_duration(e, timeline_element)
    this.set_delay(e, timeline_element)
    this.update_layer({
      ...this.layer,
      config: {
        ...this.layer.config,
        animation: {
          ...this.layer.config.animation,
          delay_percentage: this.delay_percentage,
          delay: this.delay,
          duration: this.duration,
          duration_percentage: this.duration_percentage,
        }
      }
    })
  }

  calculatePercentageAndDuration(dimension, totalWidth, videoSceneDuration) {
    let percentage = (dimension / totalWidth) * 100;
    if (percentage % 1 > 0.4) {
      percentage = Math.ceil(percentage);
    }
    if (percentage > 100) {
      percentage = 100;
    }
    const duration = (percentage / 100) * videoSceneDuration;
    return { percentage, duration };
  }

  get_scale() {
    const { transform } = this.layer.config;
    if (!transform) return 1;
    const match = transform.match(/scale\(([^)]+)\)/);
    if (match) {
      return parseFloat(match[1]);
    }
    return 1;
  }

  set_delay(e, timeline_element) {
    const { percentage, duration } = this.calculatePercentageAndDuration(e.left, timeline_element.clientWidth, this.video_scene_duration); // eslint-disable-line
    this.delay_percentage = percentage;
    this.delay = duration;
  }

  set_duration(e, timeline_element) {
    const { percentage, duration } = this.calculatePercentageAndDuration(e.width, timeline_element.clientWidth, this.video_scene_duration); // eslint-disable-line
    this.duration_percentage = percentage;
    this.duration = duration;
  }

  get_html_left(timeline_element) {
    // Ensure timeline_element is provided and has clientWidth property
    if (!timeline_element || typeof timeline_element.clientWidth !== 'number') {
      return 0;
    }
    // Calculate and return the left position based on delay_percentage
    return (this.delay_percentage / 100) * timeline_element.clientWidth;
  }

  get_html_width(timeline_element) {
    // Ensure timeline_element is provided and has clientWidth property
    if (!timeline_element || typeof timeline_element.clientWidth !== 'number') {
      return 0;
    }
    // Calculate and return the width based on duration_percentage
    return (this.duration_percentage / 100) * timeline_element.clientWidth;
  }

  static pretty_name() {
    // pretty name of the animation
    return 'Animation'
  }

  play() {

  }

  pause() {

  }
}
