<template>
    <div class="feed-builder__feed-row">
        <div class="feed-builder__feed-column" v-for="feed_schema_row in filtered_schema_rows" style="margin-bottom: 15px;">
            <span v-if="!feed_schema_row.key.includes('.')">
                <label v-if="show_labels"><b>{{ prettify_key(feed_schema_row.key) }}</b></label>
                <span v-if="feed_schema_row.key_type === 'color'">
                    <ColorField
                        :name="feed_schema_row.key"
                        :element="element.data"
                        :element_key="feed_schema_row.key"
                        :on_change="on_change"
                        :palette="brandkit.palette"
                        :show_dynamic="false"/>
                </span>
                <span v-if="feed_schema_row.key_type === 'text'">
                    <TextField
                        :name="feed_schema_row.key"
                        :element="element.data"
                        :element_key="feed_schema_row.key"
                        :on_change="on_change"/>
                </span>
                <span v-if="feed_schema_row.key_type === 'text_area'">
                    <TextArea
                        :name="feed_schema_row.key"
                        :element="element.data"
                        :element_key="feed_schema_row.key"
                        :on_change="on_change"/>
                </span>
                <span v-if="feed_schema_row.key_type === 'audio-video'">
                    <UrlUpload
                        :name="feed_schema_row.key"
                        :element="element.data"
                        :element_key="feed_schema_row.key"
                        :on_change="on_change"/>
                </span>
            </span>
            <span v-else>
                <span v-if="!hidden_fields.includes(feed_schema_row.key.split('.')[0])">
                    <div v-for="k in get_nested_attributes(feed_schema_row.key)">
                        <span v-if="feed_schema_row.key_type === 'text_area'">
                            <label v-if="show_labels">({{ feed_schema_row.key.split('.')[0] }}) {{ k }}</label>
                            <TextArea
                                :name="feed_schema_row.key"
                                :element="element.data[feed_schema_row.key.split('.')[0]]"
                                :element_key="k"
                                :on_change="on_change"/>
                        </span>
                        <span v-if="feed_schema_row.key_type === 'text'">
                            <label v-if="show_labels">({{ feed_schema_row.key.split('.')[0] }}) {{ k }}</label>
                            <TextField
                                :name="feed_schema_row.key"
                                :element="element.data[feed_schema_row.key.split('.')[0]]"
                                :element_key="k"
                                :on_change="on_change"/>
                        </span>
                        <span v-if="feed_schema_row.key_type === 'audio-video' && feed_schema_row.key.includes('|index') ">
                            <label v-if="show_labels">({{ feed_schema_row.key.split('.')[0] }}) logo</label>
                            <UrlUpload
                                :name="feed_schema_row.key"
                                :element="logo_element"
                                :element_key="'key'"
                                :on_change="logo_change"/>
                        </span>
                        <span v-if="feed_schema_row.key_type === 'audio-video' && !feed_schema_row.key.includes('|index') ">
                            <label v-if="show_labels">({{ feed_schema_row.key.split('.')[0] }}) {{ k }}</label>
                            <UrlUpload
                                :name="feed_schema_row.key"
                                :element="element.data[feed_schema_row.key.split('.')[0]]"
                                :element_key="k"
                                :on_change="on_change"/>
                        </span>
                    </div>
                </span>
            </span>
        </div>
        <span class="feed-builder__feed-column--sticky-right" v-if="spreadsheet_mode">
            <button class="button--light" v-on:click="e => on_delete(element, indice)"><i class="fa-regular fa-trash-can"></i></button>
        </span>
    </div>
</template>

<script>
import TextField from '../../../feeds/fields/text.vue'
import ColorField from '../../../feeds/fields/color.vue'
import UrlUpload from '../../../feeds/fields/audio-video.vue'
import TextArea from '../../../feeds/fields/textarea.vue'
export default {
    components: {
        TextField,
        ColorField,
        UrlUpload,
        TextArea,
    },
    props: {
        indice: {
            type: Number,
            required: true
        },
        hidden_fields: {
            type: Array,
            required: false,
            default: () => []
        },
        spreadsheet_mode: {
            type: Boolean,
            required: false,
            default: false
        },
        show_labels: {
            type: Boolean,
            required: false,
            default: true
        },
        schema: {
            type: Object,
            required: true
        },
        element: {
            type: Object,
            required: true
        },
        on_change: {
            type: Function,
            required: false,
            default: () => {}
        },
        on_delete: {
            type: Function,
            required: false,
            default: () => {}
        },
        brandkit: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data(){
        return {
            logo_element: {
                key: ""
            }
        }
    },
    mounted(){
        if(this.element.data && this.element.data.brandkit && this.element.data.brandkit.logos && this.element.data.brandkit.logos.length > 0){
            if(typeof this.element.data.brandkit.logos[0] === 'string')
                this.logo_element.key = this.element.data.brandkit.logos[0]
        } else {
            this.logo_element.key = ''
        }
    },
    watch:{
        element: {
            immediate: true,
            deep: true,
            handler(newval){
                if(newval.data && newval.data.brandkit && newval.data.brandkit.logos && newval.data.brandkit.logos.length > 0){
                    if(typeof newval.data.brandkit.logos[0] === 'string')
                        this.logo_element.key = newval.data.brandkit.logos[0]
                } else {
                    this.logo_element.key = ''
                }
            }
        }
    },
    computed: {
        filtered_schema_rows(){
            return this.schema.rows.filter(row => {
                return !this.hidden_fields.includes(row.key)
            }).sort((a, b) => {
                return b.key > a.key ? 1 : -1
            })
        }
    },
    methods: {
        prettify_key(key) {
            if(key.includes('_footage_') || key.includes('_item_')){
                let split_key = key.split('_')
                let nr = split_key[0]
                let type = split_key[1]
                if(type === 'item')
                    type = 'product'
                return `(${nr}) ${type.charAt(0).toUpperCase() + type.slice(1)}`
            } else {
                return key
                    .split('_') // split the string into an array of words
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
                    .join(' '); // join the words back together with spaces in between
            }
        },
        logo_change(v,k){
            this.on_change([v], 'brandkit.logos')
        },
        get_nested_attributes(key){
            key = key.split('|')[0]
            let l = key.split('.')
            l.shift()
            return l
        }, 
    }
}
</script>