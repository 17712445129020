import consumer from '../../channels/consumer'

class MediaFolderConsumer {
  constructor() {
    this.consumer = consumer
    this.channel = 'UserMediaFolderChannel'
    this.callbacks = []
    try {
      this.subscription = this.consumer.subscriptions.create({ channel: this.channel }, {
        received: (data) => {
          this.callbacks.forEach((callback) => callback(data))
        }
      })
    } catch (e) {
      console.error('error creating websocket connection', e)
    }
  }

  onReceive(callback) {
    this.callbacks.push(callback)
  }

  send(data) {
    this.subscription.send(data)
  }
}

export const MediaFolderChannel = new MediaFolderConsumer()
