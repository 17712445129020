export const Keys = {
  load(){
    $('.feed-builder__label').unbind('click')
    $('.editor__variable-item').unbind('click')
    $('.feed-builder__label').click((e) => {
      this.action(e)
    })
    $('.editor__variable-item').click((e) => {
      this.action(e)
    })
  },
  action(e){
    const tempEl = $('<input>');
    $('body').append(tempEl)
    tempEl.val($(e.target).text().trimStart().trimEnd()).select()
    document.execCommand('copy')
    tempEl.remove()
      create_notification('Copied to clipboard', `${$(e.target).text()} copied to clipboard`, 'success') // eslint-disable-line
  }

}
