export const Contextable = {
  name: 'contextable',
  props: [],
  events: [],
  render(moveable, React) {
    // const rect = moveable.getRect();
    // const { pos2 } = moveable.state;
    const EditableViewer = moveable.useCSS('div', `
        {
            position: absolute;
            left: 50%;
            top: 0px;
            transform: translateX(-50%);
            will-change: transform;
            transform-origin: 0px 0px;
            display: flex;
            justify-content: center;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added drop shadow */
            background: white;
            border-radius: 12px;
        }
        .button__toggle__light {
            width: 50px;
            height: 50px;
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }
        .button__tab_group {
            padding: 12px;
            background: white;
            border-radius: 10px;
            display: flex;
            justify-content: center;
        }
        .tooltip {
            visibility: hidden;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            bottom: 125%; /* Position the tooltip above the button */
            left: 50%;
            margin-left: -60px;
            opacity: 0;
            transition: opacity 0.3s;
        }
        .tooltip--top::after {
            content: "";
            position: absolute;
            top: 100%; /* At the bottom of the tooltip */
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }
        .button__toggle__light:hover .tooltip {
            visibility: visible;
            opacity: 1;
        }
        `);
    return React.createElement(EditableViewer, {
      key: 'editable-viewer',
      className: 'moveable-editable',
      style: {
        transform: `translate(${0}px, -90px)`
      }
    }, [React.createElement('LayerContextMenu')]);
  }
};
