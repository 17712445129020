import anime from 'animejs/lib/anime'
import Animation from '../animation'

export default class Revealer extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }){
    super({
      samples, layer, uid, duration, update_layer
    })
  }

  name(){
    return 'Revealer'
  }

  cleanup(){
    setTimeout(() => {
      $(`#layer_revealer_${this.uid}_${this.layer.id}`).remove()
      const el = $(`.layer_${this.uid}_${this.layer.id}`)
      $(el).html(this.contents)
    }, 15)
  }

  static int_name(){
    return 'revealer'
  }

  static exposed_to(){
    return ['image', 'text', 'text_area', 'video', 'price']
  }

  static properties(){
    return {
      ...super.properties(),
    }
  }

  animation(){
    const el = $(`.layer_${this.uid}_${this.layer.id}`)
    if (!this.contents) this.contents = $(el).html()
    $(el).empty()
    const el_width = el.width()
    const el_height = el.height()

    $(el).append(`<div id="layer_revealer_${this.uid}_${this.layer.id}"
                       style="width: ${el_width}px;
                              height:${el_height}px;
                              position: absolute;
                              background: ${this.layer.background};">
                                <div style="width: 100%;">
                                  ${this.contents}
                                </div>
                              </div>`)

    return anime.timeline({
      duration: this.duration,
      autoplay: false,
    }).add({
      targets: `#layer_revealer_${this.uid}_${this.layer.id}`,
      translateX: [0, el_width + 25],
      easing: this.easing(),
      duration: this.duration * 0.5,
    }).add({
      targets: `.layer_${this.uid}_${this.layer.id}`,
      width: [el_width, 0],
      easing: this.easing(),
    })
  }
}
