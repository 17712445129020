<template>
    <div :style="{ color: layer.color, width: layer.width + 'px', height: layer.height + 'px' }">

        <div style="width: 100%; height: 100%; position: relative">
            <div
                :style="`width: 100%; height: 100%; left: 0px; top: 0px; position: absolute; background: rgba(${hexToRgb(layer.background)}, 0.80)`">
            </div>
            <img style="width: 100%; height: 100%; left: 0px; top: 0px; position: absolute"
                src="https://cdn.adflow.io/public/794fee1d-02b2-4919-bf16-e8f18b1b250b.png" />
            <div
                :style="`width: 100%; height: 100%; left: 0px; top: 0px; position: absolute; background: rgba(${hexToRgb(layer.background)}, 0.50)`">
            </div>
            <div
                :style="`width: 100%; height: 100%; left: 0px; top: 0px; position: absolute; background: radial-gradient(99.91% 99.91% at 99.91% 49.96%, rgba(${hexToRgb(layer.background)}, 0) 0%, rgba(${hexToRgb(layer.background)}, 0.60) 100%)`">
            </div>
            <div style="width: 100%; height: 100%; left: 0px; top: 0px; position: absolute; text-align: center">
                <div class="sticker__repeater"
                    :style="`gap: ${layer.config.bg_image_repeater.gap || 5}px; display: flex; flex-direction: column; align-items: center; overflow: hidden; padding-top: 10%`">
                    <template v-for="i in 50">
                        <span class="sticker__repeater__text" :style="`
                                color: ${layer.color}; 
                                font-size: ${layer.fontSize * 0.45}px;
                                font-family: ${font}; 
                                padding-left: ${layer.config.bg_image_repeater.gap}px; 
                                padding-right: ${layer.config.bg_image_repeater.gap}px;
                                ${i === 1 ? '' : `color: transparent; -webkit-text-stroke: 2px ${layer.color};`}
                            `" :key="'text' + i">
                            {{ layer.text }}
                        </span>
                    </template>
                </div>
            </div>
            <div
                :style="`width: 100%; height: 100%; left: 0px; top: 0px; position: absolute; background: linear-gradient(180deg, rgba(${hexToRgb(layer.background)}, 0) 0%, rgba(${hexToRgb(layer.background)}, 1) 100%)`">
            </div>
        </div>


    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    components: {},
    mixins: [FontMixin],
    data() {
        return {}
    },
    computed: {
        component_name() {
            return this.layer.config.component.type
        },
        bgColor() {
            // Dynamically fetch bg_bolor, default to 'white' if not set
            return this.layer.config[this.component_name].bg_color || 'white'
        },
        iconType() {
            return this.layer.config.bg_image_repeater.icon_type || ''
        },
    },
    methods: {
        parse(text) {
            return highlight.highlight(text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color)
        },
        hexToRgb(hex) {
            // Remove the '#' if it's there
            hex = hex.replace('#', '');

            // Parse the hex color
            const bigint = parseInt(hex, 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;

            return `${r}, ${g}, ${b}`; // Return as a string for easy use in rgba
        },
    }
}
</script>