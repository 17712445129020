<template>
    <div ref="cubeContainer" style="overflow: hidden;" :style="`width: ${layer.width}px; height: ${layer.height}px`">
    </div>
</template>

<script>
import * as THREE from 'three';

export default {
    name: 'CubeSpinner',
    data() {
        return {
            camera: null,
            renderer: null,
            scene: null,
            cube: null,
            animationId: null
        }
    },
    props: {
        layer: {
            type: Object,
            required: true
        },
        samples: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        on_error: {
            type: Function,
            required: false,
            default: () => { }
        },
        brandkit: {
            type: Object,
            required: false,
            default: () => { }
        },
        animation_uid: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.initThreeJS();
    },
    beforeDestroy() {
        this.cleanupThreeJS();
    },
    computed: {
        imagePath() {
            return this.layer.config.image_url || 'https://placehold.co/600x400';
        }
    },
    watch: {
        layer: {
            handler() {
                const width = this.layer.width;
                const height = this.layer.height;
                this.camera.aspect = width / height;
                this.camera.updateProjectionMatrix();
                this.renderer.setSize(width, height);
            },
            deep: true
        }
    },
    methods: {
        initThreeJS() {
            // Set up the scene
            const scene = new THREE.Scene();
            this.scene = scene;

            // Set up the camera
            const camera = new THREE.PerspectiveCamera(75, this.$refs.cubeContainer.clientWidth / this.$refs.cubeContainer.clientHeight, 0.1, 1000);
            camera.position.z = 1.5;
            this.camera = camera;

            // Set up the renderer and add it to the DOM
            const renderer = new THREE.WebGLRenderer({ alpha: true });
            renderer.setSize(this.$refs.cubeContainer.clientWidth, this.$refs.cubeContainer.clientHeight);
            renderer.setClearColor(0x000000, 0);
            this.$refs.cubeContainer.appendChild(renderer.domElement);
            this.renderer = renderer;

            // Handle context loss
            renderer.domElement.addEventListener('webglcontextlost', this.onContextLost, false);
            renderer.domElement.addEventListener('webglcontextrestored', this.onContextRestored, false);

            // Load the texture
            const textureLoader = new THREE.TextureLoader();
            const texture = textureLoader.load(this.imagePath);

            // Create a material with the texture
            const material = new THREE.MeshBasicMaterial({ map: texture });

            // Create the cube geometry and mesh
            const geometry = new THREE.BoxGeometry();
            const cube = new THREE.Mesh(geometry, material);
            scene.add(cube);
            this.cube = cube;

            // Animation loop
            const animate = () => {
                this.animationId = requestAnimationFrame(animate);

                // Rotate the cube
                cube.rotation.x += 0.01;
                cube.rotation.y += 0.01;

                // Render the scene
                renderer.render(scene, camera);
            };

            // Start the animation
            animate();
        },
        cleanupThreeJS() {
            try {
                if (this.animationId) {
                    cancelAnimationFrame(this.animationId);
                }
                if (this.renderer) {
                    this.renderer.dispose();
                }
                if (this.cube) {
                    this.cube.geometry.dispose();
                    this.cube.material.dispose();
                }
            } catch (e) {
                console.error('Error cleaning up ThreeJS', e);
            }
        },
        onContextLost(event) {
            event.preventDefault();
            console.warn('WebGL context lost');
            this.cleanupThreeJS();
        },
        onContextRestored() {
            console.log('WebGL context restored');
            this.initThreeJS();
        }
    }
};
</script>