<template>
  <transition name="list">
    <img :src="source"
         @load="img_loaded"
         :key="src"
         v-show="loaded"
         v-if="type==='image'"/>
    <video controls="controls" preload="metadata" v-if="type==='video'">
      <source :src="`${source}#t=0.3`" type="video/mp4">
    </video>
  </transition>
</template>

<script>
export default {
    props: {
      src: {type: String, required: true},
    },
    data(){
      return {
        loaded: false,
        source: null,
        type: 'image'
      }
    },
    mounted(){
      if(this.src.split('.').pop() === 'mp4'){
        this.type = 'video'
      }
      this.source = this.src
    },
    methods:{
      img_loaded(){
        this.loaded = true
      }
    }
}
</script>
