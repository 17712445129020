<template>
  <div style="width: 100%; display: flex; flex-direction: column; align-items: center;">
    <div style="text-align: center;">
      <h3>Choose size</h3>
    </div>
    <br>
    <div class="editor__size-selector-options editor__size-selector-options--darken" style="width: 400px">
      <div v-on:click="is_excluded(size) ? null : set_size(size)" 
          class="editor__size-selector-option" :class="{'editor__size-selector-option--disabled': is_excluded(size)}" :key="size.name" v-for="size in sizes.filter(e=>e.enabled)">
        <!--<div class="icon-ar" :class="`icon-ar--${size.aspect_icon}`" aria-hidden="true"></div>-->
        <span class="editor__size-selector-option__name">{{size.short_name}}</span>
        <span>{{size.aspect_ratio}}</span>
        <i :class="size.icon" class="fab"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '../../components/dropdown.vue'
export default {
  components: {Dropdown},
  props: ['on_change', 'canvas', 'disabled', 'excluded', 'filter'],
  data(){
    return {
      show_dropdown: false,
      sizes: [
        ...this.$props.canvas.available_sizes.filter(s => s.name !== 'sizeless' && !this.$props.filter.includes(s.name)).map(size => {
          return {
            name: size.name.split('_').join(' '),
            key: size.name,
            dimensions: size.render_dimensions,
            icon: `fab ${size.icon}`,
            enabled: size.enabled,
            short_name: size.short_name,
            aspect_ratio: size.aspect_ratio,
            order: size.order,
          }
      })].sort((a, b) => a.order - b.order)
    }
  },
  computed: {
    active_size(){
      for(const size of this.sizes){
        if(size.key == this.canvas.size){
          return size
        }
      }
      return {icon: 'fad fa-angle-down', name: 'choose size', dimensions: ['n/a', 'n/a']}
    }
  },
  methods: {
    is_excluded(size){
      return this.$props.excluded.includes(size.key)
    },
    set_size(size){
      this.on_change({size: size.key})
      this.show_dropdown = false
    }
  }
}
</script>
