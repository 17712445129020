import actions   from './actions'
import getters   from './getters'
import mutations from './mutations'

const state = () => ({
  sample: [],
  sampling: false,
  indice: 0,
  list: [],
  active_feed: null,
  segments: [],
})

export default () => ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})
