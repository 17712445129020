<template>
  <div class="studio__scene-card-mini"
       ref="scene"
       @click="activate"
       @mouseover="hover = true"
       @mouseleave="hover = false"
       :class="{'studio__scene-card-mini--active': active_scene ? active_scene.id == scene.id : false}"
       :style="{top: (indice * 30) + 'px', 'z-index': zindex}">
    <div class="studio__scene-card-del" :style="{'display': hover ? 'flex' : 'none'}" v-on:click="destroy">
      <i class="fa-sharp fa-solid fa-trash"></i>
    </div>
    <div class="studio__scene-card-edit" :style="{'display': hover ? 'flex' : 'none'}" v-on:click="edit">
      <i class="fa-sharp fa-solid fa-pen"></i>
    </div>
    <div class="studio__scene-mini-preview" style="margin: 0"
        :class="{[`studio__scene-mini-preview--${scene.canvas.size}`]: true}">
      <CanvasPreview :canvas="scene.canvas"
                     v-if="show"
                     :selected="false"
                     :brandkit="brandkit"
                     :fonts="[]"
                     :animation_complete="()=>anim_complete()"
                     :samples="{data: [{...feed[0][0], ...briefing_meta}], indice: 0}"/>
    </div>
  </div>
</template>

<script>
import CanvasPreview from '../../catalogs/canvas_preview.vue'
import {mapState} from 'vuex'
export default {
  components: {CanvasPreview},
  props: {
    indice: {
      type: Number,
      required: true
    },
    scene: {
      type: Object,
      required: true
    },
    feed: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      brandkit: state => state.brandkit,
      briefing: state => state.briefing,
      active_scene: state => state.active_scene,
      size: state => state.size,
    }),
    briefing_meta(){
      return this.$store.getters.briefing_meta
    },
  },
  data(){
    return {
      hover: false,
      zindex: 0,
      show: true
    }
  },
  mounted(){
    this.zindex = this.indice + 1 * 100
  },
  watch: {
    hover(){
      if(this.active_scene && this.active_scene.id == this.scene.id) {
        this.zindex = 1000
      } else {
        this.zindex = this.hover ? 1000 : this.indice + 1 * 100
      }
    },
    active_scene(){
      if(this.active_scene && this.active_scene.id == this.scene.id) {
        this.zindex = 1000
      } else {
        this.zindex = this.indice + 1 * 100
      }
    }
  },
  methods: {
    activate(){
      if(this.active_scene && this.active_scene.id == this.scene.id) {
        this.$store.commit('ACTIVATE_SCENE', null)
      } else {
        this.$store.commit('ACTIVATE_SCENE', {...this.scene, ref: this.$refs.scene})
      }
    },
    anim_complete(){
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 1)
    },
    edit(){
      window.location.href= `/designs/${this.scene.canvas.id}/edit?source=${this.scene.feed.complex_identifier}&redirect=/briefings/${this.briefing.id}&size=${this.size}`
    },
    destroy(){
      this.$store.dispatch('delete_design', this.scene)
    }
  }
}
</script>
