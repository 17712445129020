import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class SalePercentage extends Component {
  name(){
    return 'Sale percentage'
  }

  labels(){
    return ['discounts']
  }

  icon(){
    return 'fa-regular fa-percent'
  }

  config(){
    return Layer.factory({
      layer_type: 'text',
      width: 100,
      align: 'center',
      text: '[[sale_pct]]%'
    })
  }
}
