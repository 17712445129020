export default class Component {
  constructor({ variable_tag, display_name, default_value } =
  { variable_tag: null, display_name: null, default_value: null }) {
    this.variable_tag = variable_tag
    this.display_name = display_name
    this.default_value = default_value
  }

  icon() {
    return 'fa-regular fa-font'
  }

  render(variant, { canvas } = { canvas: {} }) {
    if (variant) {
      return variant
    }
    return this.config({ canvas })
  }

  static enabled() {
    return true
  }

  sizeConfig(size) { // eslint-disable-line no-unused-vars
    return {

    }
  }

  configFields() {
    return {
      options: []
    }
  }

  variants() {
    return []
  }

  required_addon() {
    return null
  }

  config(opts = {}) {
    return {
      ...opts,
    }
  }
}
