<template>
	<select @change="on_select">
		<option v-for="feed in feeds" :value="feed.complex_identifier">{{ feed.name }}</option>
	</select>
</template>

<script>
import api from '../api'
const SELECTED_FEED_KEY = 'FEED_SELECTOR_FEED'
export default {
	props: {
		on_feed_select: {
			type: Function,
			required: true
		}
	},
	data(){
		return {
			feeds: []
		}
	},
	async mounted() {
    await this.fetch_feeds()
    const selectedFeed = localStorage.getItem(SELECTED_FEED_KEY)
    if (selectedFeed) {
      const feedExists = this.feeds.some(feed => feed.complex_identifier === selectedFeed)
      if (feedExists) {
        this.on_feed_select({
          target: {
            value: selectedFeed
          }
        })
      } else {
        const f = this.feeds[0]
        if (f) {
          this.on_feed_select({
            target: {
              value: f.complex_identifier
            }
          })
        }
      }
    } else {
      const f = this.feeds[0]
      if (f) {
        this.on_feed_select({
          target: {
            value: f.complex_identifier
          }
        })
      }
    }
  },
  methods: {
    async fetch_feeds() {
      const feeds = await api.feeds()
      this.feeds = feeds
    },
    on_select(event) {
      const selectedValue = event.target.value
      localStorage.setItem(SELECTED_FEED_KEY, selectedValue)
      this.on_feed_select(event)
    }
  }
}
</script>