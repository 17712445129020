var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"canvas",class:{ 'canvas__body': !_vm.disabled },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.unselectLayer.apply(null, arguments)},"mouseover":function($event){_vm.on_canvas = true},"mouseleave":function($event){_vm.on_canvas = false}}},[(_vm.show)?_c('div',{ref:"canvasWorkspace",class:("canvas__body-workspace canvas__body-workspace--" + _vm.disabled + " canvas__body-workspace--" + _vm.editor_uid),style:({
      width: ((_vm.size.w) + "px"),
      height: ((_vm.size.h) + "px"),
      backgroundColor: ("" + _vm.background_color)
    }),attrs:{"id":("canvas_workspace_" + _vm.id)},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.unselectLayer.apply(null, arguments)}}},[(_vm.background && !_vm.background.includes('sample') && !_vm.background.includes('null'))?_c('img',{ref:"canvas_image",style:({ width: ((_vm.size.w) + "px"), height: ((_vm.size.h) + "px") }),attrs:{"src":_vm.background},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.unselectLayer.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),_vm._l((_vm.filtered_layers),function(layer,index){return _c('Layer',{key:(_vm.animation_uid + "_" + index + "_" + (layer.id)),attrs:{"layer":layer,"editor_uid":_vm.editor_uid,"zIndex":_vm.z_index(layer),"disabled":_vm.disabled,"active":layer.id == (_vm.activeLayer ? _vm.activeLayer.id : null),"activeVariant":_vm.activeVariant,"activeLayer":_vm.activeLayer,"onLayerSelect":_vm.onLayerSelect,"onLayerUpdate":_vm.onLayerUpdate,"onLayerDelete":_vm.onLayerDelete,"layerIndex":index,"samples":_vm.l_samples,"segments":_vm.segments,"render_only":_vm.render_only,"constraint":_vm.constraints,"zoom":_vm.zoom,"canvas_size":_vm.size,"brandkit":_vm.brandkit,"layers":_vm.layers,"id":("layer_" + (layer.id)),"animation_uid":_vm.animation_uid,"on_error":function (e) { return _vm.errors.push(e); }}})})],2):_c('div',{staticClass:"canvas__body-workspace",style:({
    width: ((_vm.size.w) + "px"),
    height: ((_vm.size.h) + "px"),
    backgroundColor: ("" + _vm.background_color)
  })})])}
var staticRenderFns = []
export { render, staticRenderFns }