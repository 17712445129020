import actions   from './actions'
import mutations from './mutations'
import getters   from './getters'

const state = () => ({
  channelId: null,
  advertiseableId: null,
  type: null,
  activeCard: {},
  facebookCarouselCards: [],
  advert: null,
  fb_connection: {
    pages: [],
    selected: null
  },
  advertiseablePath: null,
  advertiseableType: null,
  api_context: 'channels',
  constructing: false
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
