import {
  SET_SUGGESTIONS,
  ADD_INTEREST,
  REMOVE_INTEREST,
  SET_SEARCH_STRING,
  SET_SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS,
  CLEAR_SUGGESTIONS,
  SET_INTERESTS,
  SET_VALIDATION_RESULTS,
  SET_AUDIENCE_REACH,
  SET_AUDIENCE_ID,
  SET_AUDIENCE,
  SET_AUDIENCE_REACH_STATUS
} from '../../mutation-types/facebook_mutations'

export default {

  [SET_AUDIENCE_ID](state, payload){
    state.id = payload
  },

  [SET_AUDIENCE_REACH_STATUS](state, payload){
    state.reach_status = payload
  },

  [SET_AUDIENCE_REACH](state, payload){
    state.reach = payload
  },

  [SET_AUDIENCE](state, payload){
    state.audience = payload
  },

  [SET_VALIDATION_RESULTS](state, payload){
    state.validation = payload
  },

  [SET_INTERESTS](state, payload){
    state.audience = payload
  },

  [CLEAR_SEARCH_RESULTS](state){
    state.searchResults = []
  },

  [CLEAR_SUGGESTIONS](state){
    state.suggestions = []
  },

  [SET_SEARCH_RESULTS](state, payload){
    state.searchResults = payload
  },

  [SET_SEARCH_STRING](state, payload){
    state.search = payload
  },

  [SET_SUGGESTIONS](state, payload){
    state.suggestions = payload
  },

  [ADD_INTEREST](state, payload){
    state.audience = [...state.audience, payload]
  },

  [REMOVE_INTEREST](state, payload){
    state.audience = state.audience.filter((target) => target.id !== payload.id)
  }

}
