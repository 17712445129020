import axios from 'axios';
import AudienceSelector from './expression/components/facebook/audience.vue'
import facebookAudienceStore from './expression/store/modules/fb_audience'
import { registerStoreModule } from './lib/nimrod/loaders/register_store_module'

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

function  loadFacebookAudienceSelector(){
  $('facebook_audience_selector').each((index, el) => {
    const renderComponent = { tag: 'facebook_audience_selector', component: AudienceSelector }
    const element = el
    const audienceId = $(element).data('audience_id')
    const store = window.vuex_store
    registerStoreModule(store, 'facebookAudience', facebookAudienceStore)
    store.dispatch('facebookAudience/construct', { audienceId })
    const app = new vue({ // eslint-disable-line
      el: element,
      store,
      data: {
        namespace: 'facebookAudience'
      },
      components: { [renderComponent.tag]: renderComponent.component }
    })
  })
}

export default loadFacebookAudienceSelector
