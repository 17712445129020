var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"briefing_wizard__prompt_output"},[_c('span',{staticClass:"profile_initial"},[_vm._v("A")]),_vm._v(" "),_c('div',{staticClass:"briefing_wizard__prompt"},[_c('div',{staticClass:"briefing_wizard__prompt_header"},[_c('span',{staticClass:"strong"},[_vm._v("Adflow")]),_vm._v(" "),(_vm.created_at)?_c('span',{},[_vm._v(_vm._s(_vm.created_at))]):_vm._e()]),_vm._v(" "),_c('transition-group',{staticStyle:{"display":"flex","align-items":"flex-start","align-content":"flex-start","gap":"12px","flex-shrink":"0","flex-wrap":"wrap"},attrs:{"tag":"div","name":"fade"}},_vm._l((_vm.l_ads),function(ad,ad_index){return _c('div',{key:("sug_it_" + ad_index + "_" + (ad.render_key)),staticStyle:{"position":"relative"}},[_c('div',{staticClass:"template_adopt adflow__briefing_small adflow__briefing_small--story",on:{"click":function (e) { return ad.id ? _vm.set_active_ad(ad) : null; }}},[(_vm.active_size)?_c('CompactPlayer',{key:("sug_" + (ad.id)),style:(("text-size-adjust: none; zoom: 0.225; margin-right: 40px; width: " + (_vm.active_size.display_dimensions[0] + 20) + "px; height: " + (_vm.active_size.display_dimensions[0] + 20) + "px;")),attrs:{"briefing_id":ad.id,"brandkit":_vm.brandkit,"show":_vm.show_previews,"size":_vm.active_size}}):_c('div',{staticClass:"loader"})],1)])}),0),_vm._v(" "),_c('div',{staticClass:"briefing_wizard__prompt_actions"},[_c('div',{staticClass:"briefing_wizard__prompt_actions__row"},_vm._l((_vm.iteration.ads),function(ad,index){return _c('button',{staticClass:"briefing_wizard__prompt_button",class:{
                'briefing_wizard__prompt_button--active': _vm.find_version({
                    type: 'version',
                    mode: null,
                    id: ad.id,
                    iteration_id: _vm.iteration.uuid
                })
            },on:{"click":function (e) { return _vm.set_version({
                type: 'version',
                pretty_name: ("V" + (index + 1)),
                id: ad.id,
                mode: null,
                iteration_id: _vm.iteration.uuid
            }); }}},[_vm._v("\n                    V"+_vm._s(index + 1)+"\n                ")])}),0),_vm._v(" "),(_vm.selected_version)?_c('div',{staticClass:"briefing_wizard__prompt_actions__row"},[_c('button',{staticClass:"briefing_wizard__prompt_button",class:{
                'briefing_wizard__prompt_button--active': _vm.selected_command ? _vm.selected_command.type === 'assets' : false
            },on:{"click":function (e) { return _vm.set_command({
                type: 'assets',
                mode: 'shuffle',
                pretty_name: 'Shuffle assets',
                iteration_id: _vm.iteration.uuid
            }); }}},[_vm._v("Shuffle assets")]),_vm._v(" "),_c('Dropdown',{attrs:{"active":_vm.show_rephrase_dropdown,"root":true,"right":-75,"top":_vm.last ? -170 : 50,"on_toggle":function (state) { return _vm.show_rephrase_dropdown = state; }},scopedSlots:_vm._u([{key:"btn",fn:function(ref){
            var is_active = ref.is_active;
return [_c('button',{staticClass:"briefing_wizard__prompt_button",class:{
                'briefing_wizard__prompt_button--active': _vm.selected_command ? _vm.selected_command.type === 'script' : false
            }},[_vm._v("Rephrase text\n                            "),_c('i',{staticClass:"fa-regular fa-chevron-down"})])]}}],null,false,2216751168)},[_vm._v(" "),_c('div',{staticClass:"plan__select_plan__options",staticStyle:{"height":"150px","overflow-y":"scroll"}},_vm._l((_vm.rephrase_options),function(option,index){return _c('div',{key:("rephrase_opt_" + index),staticClass:"plan__select_plan__option",on:{"click":function (e) { return _vm.set_command({
                type: 'script',
                mode: option.mode,
                pretty_name: option.name,
                iteration_id: _vm.iteration.uuid
            }); }}},[_c('div',{staticClass:"plan__select_plan__option_group"},[_vm._v("\n                                "+_vm._s(option.name)+"\n                            ")])])}),0)]),_vm._v(" "),_c('LanguagePicker',{attrs:{"right":-75,"top":_vm.last ? -220 : 50,"default_language":_vm.campaign_briefing.language,"active":_vm.selected_command ? _vm.selected_command.type === 'language' : false,"on_change":function (language) { return _vm.set_command({
                type: 'language',
                mode: language,
                pretty_name: 'translate to ' + language,
                iteration_id: _vm.iteration.uuid
            }); }}})],1):_vm._e()])],1)])}
var staticRenderFns = []
export { render, staticRenderFns }