import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class NativeReply extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Native reply'
  }

  labels() {
    return ['native']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'textArea',
          name: 'Question',
          key: 'top_text',
        },
        {
          type: 'textArea',
          name: 'Answere',
          hide_label: false,
          key: 'root.text',
        },
        {
          type: 'color',
          name: 'Bg',
          key: 'bg_color',
        },
        {
          type: 'slider',
          name: 'Text size',
          key: 'root.fontSize',
          min: 0,
          max: 300,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            native_reply: {
              ...baseConfig.config.native_reply,
              bg_color: '#4193F0', // Achtergrondkleur van de shape
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'white',
      align: 'center',
      text: 'Answere here',
      width: 350,
      height: 250,
      fontSize: 106,
      ...this.opts,
      config: {
        v_align: 'center',
        line_height: 1,
        component: {
          type: 'native_reply',
          name: 'Sticker pricetag circle',
        },
        native_reply: {
          top_text: 'Question here?',
          top_text_color: '#000000', // Standaardwaarde voor 'from' kleur
          bg_color: '#262626', // Achtergrondkleur van de shape
          h_align: 'row', // Horizontale uitlijning
          v_align: 'flex-start', // Verticale uitlijning
          shape: 'circle', // Standaardvorm van de shape
          gap: 0 // Afstand/gap tussen elementen
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
