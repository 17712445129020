import anime from 'animejs/lib/anime'
import Animation from '../../animation'

export default class Drop extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer
  }) {
    super({
      samples, layer, uid, duration, update_layer
    })

    this.initial_transform = layer.config.transform || `translate(${0}px, ${0}px) scale(1)`;
  }

  name() {
    return 'Drop'
  }

  cleanup() {
    const targets = $(`${this.target()}`)
    $(targets).css('transform', `${this.initial_transform} scale(1)`);
    $(targets).css('opacity', '1');
  }

  prepare() {
    const targets = $(`${this.target()}`)
    $(targets).css('transform', 'translateY(-1200px) scale(0.2)');
    $(targets).css('opacity', '0');
  }

  static pretty_name() {
    return 'Drop'
  }

  static int_name() {
    return 'composeable_drop'
  }

  static exposed_to() {
    return ['background', 'slider', 'image', 'text', 'text_area', 'cube_spinner',
      'video', 'price', 'rectangle', 'circle', 'logo', 'review']
  }

  static properties() {
    const props = {
      ...super.properties(),
    }
    delete props.easing
    return props
  }

  animation_config(duration = this.duration, inOut = 'in') {
    this.initial_transform = $(`${this.target()}`).css('transform')
    if (inOut === 'in') {
      return {
        targets: this.target(),
        translateY: [
          { value: -1200, duration: 0 },
          { value: 0, duration: duration * 0.66, easing: 'easeOutBounce' }
        ],
        opacity: {
          value: [0, this.opacity()],
          duration: duration * 0.33,
          easing: 'easeOutQuad'
        },
        scale: {
          value: [0.2, this.get_scale()],
          duration: duration * 0.66,
          easing: 'easeOutBack'
        },
      }
    }  // Assuming 'out' is the only other option
    return {
      targets: this.target(),
      translateY: [
        { value: 0, duration: duration * 0.66, easing: 'easeInBounce' },
        { value: -1200, duration: 0 }
      ],
      opacity: {
        value: [this.opacity(), 0],
        duration: duration * 0.33,
        easing: 'easeInQuad'
      },
      scale: {
        value: [this.get_scale(), 0.2],
        duration: duration * 0.66,
        easing: 'easeInBack'
      },
    }
  }

  animation() {
    return anime.timeline({
      duration: this.duration,
      autoplay: false,
    }).add(this.animation_config())
  }
}
