<template>
    <Transition name="fade">
        <div class="video__timeline-bar" @mouseleave="dragging = false" v-cloak
            v-if="video.controller && show_variants && video.enabled == true" ref="variant-bar">
            <div class="video__timeline-container">
                <div class="video__timeline" ref="timeline" style="position: relative; width: 100%"
                    @mousedown="startDrag" @mousemove="dragCursor" @mouseup="stopDrag" @click.stop="e => { }">
                    <div class="video__timeline-duration">{{ (video.controller.cur_time / 1000).toFixed(2) }} / {{
                (duration / 1000).toFixed(2)
            }}s</div>
                    <div class="video__timeline-cursor" :style="{ 'left': `${progress}%` }"></div>
                    <div class="video__timeline-grid"></div>
                    <div class="video_timeline-animations">
                        <div v-for="([groupId, animations], key) in grouped_animations" :key="`anim_group_${key}`"
                            v-on:click="on_animation_select(animations[0])">
                            <Animation v-for="(animation, index) in animations" :key="index" :layers="layers"
                                :active_layer="active_layer" :animation="animation"
                                :on_animation_select="on_animation_select" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import Animation from './animation.vue'
export default {
    components: { Animation },
    props: {
        video: {
            type: Object,
            required: true
        },
        show_variants: {
            type: Boolean,
            required: true
        },
        layers: {
            type: Array,
            required: true
        },
        on_animation_select: {
            type: Function,
            required: true
        },
        on_layer_update: {
            type: Function,
            required: false,
            default: () => { }
        },
        active_layer: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            render_key: 0,
            active: null,
            progress: 0,
            _self: this,
            loaded: false,
            dragging: false,
            resizing: false,
            duration: 0
        }
    },
    mounted() {
        if (this.video.controller) {
            this.video.controller.on('update', (event) => {
                this.progress = event.detail.progress
            })
        }
        setTimeout(() => {
            this.duration = this.video.controller?.get_duration()
            this.video.controller?.reset()
        }, 1)
    },
    computed: {
        grouped_animations() {
            if (!this.video.controller) return []
            return Object.entries(this.video.controller.grouped_animations()).reverse()
        },
    },
    watch: {
        video: {
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal?.controller) {
                    newVal.controller.on('update', this.progress_update)
                } else if (oldVal?.controller) {
                    oldVal.controller.off('update', this.progress_update)
                }
            }
        }
    },
    beforeDestroy() {
        if (this.video.controller) {
            this.video.controller.off('update')
        }
    },
    methods: {
        startDrag(event) {
            if (!this.video.controller) return;
            if (this.resizing) return;

            if (event.target.className.includes('video__timeline-animation') ||
                event.target.className.includes('custom-select') ||
                event.target.className.includes('video__timeline-animation__dragger') ||
                event.target.className.includes('chip__filter__data custom-select-wrapper') ||
                event.target.className.includes('chip__filter'))
                return
            // Disable text selection
            document.body.style.userSelect = 'none';
            document.body.style.webkitUserSelect = 'none'; // For Safari
            document.body.style.msUserSelect = 'none'; // For IE and Edge
            document.body.style.mozUserSelect = 'none'; // For Firefox

            this.dragging = true;
            this.updateCursorPosition(event);
            event.stopPropagation()
        },
        dragCursor(event) {
            if (!this.video.controller) return;
            if (this.resizing) return;

            if (this.dragging) {
                this.updateCursorPosition(event);
            }
            event.stopPropagation()
        },
        stopDrag() {
            if (!this.video.controller) return;
            if (this.resizing) return;
            // Re-enable text selection
            document.body.style.userSelect = '';
            document.body.style.webkitUserSelect = '';
            document.body.style.msUserSelect = '';
            document.body.style.mozUserSelect = '';
            this.dragging = false;
            event.stopPropagation()
        },
        updateCursorPosition(event) {
            const timelineRect = this.$refs.timeline.getBoundingClientRect();
            const position = ((event.clientX - timelineRect.left) / timelineRect.width) * 100;
            this.progress = Math.max(0, Math.min(100, position)); // Clamp between 0% and 100%
            this.video.controller.seek(this.progress / 100 * this.duration);
        },
        progress_update(event) {
            this.progress = event.detail.progress
            if (this.progress >= 100) {
                setTimeout(() => {
                    this.video.controller.reset()
                    this.progress = 0
                    this.$forceUpdate()
                }, 25)
            }
        },
    }
}
</script>