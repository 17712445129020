<template>
  <div ref="parentContainer" class="canvas_scale_container">
    <div v-if="l_show && size && size.display_dimensions && briefing_id && size && briefing_canvasses.length > 0"
      :style="{ position: 'relative', zoom: computedScale, width: `${size.w}px`, height: `${size.h}px` }">
      <Scene :scene="scene" :key="scene.id" :indice="index" :playing="playing" :render_mode="true" :brandkit="brandkit"
        :anim_uid="anim_uid" :active="false" :size="{ w: l_size_w, h: l_size_h, key: size.name }"
        :ref="'scene_' + scene.id" :complete="e => complete(scene, index, e)"
        :on_progress="(scene, anim) => on_progress(scene, index, anim)" v-for="(scene, index) in briefing_canvasses" />
      <div class="progress-bar-holder" style="position: absolute; top: -5px;"
        :style="`${width_override ? `min-width: ${width_override}%;` : ''} top: ${l_size_h - 26}px;`">
        <div class="progress-bar" :style="`width: ${progress}%; background: red; height: 20px;`"></div>
      </div>
    </div>
    <div v-else
      :style="`width: ${l_size_w}px; height: ${l_size_h}px; background: white; display: flex; justify-content: center; align-items: center;`">
      <div class="loader loader--big"></div>
    </div>
  </div>
</template>

<script>
import studioApi from '../store/api.js'
import Scene from './scene_player_scene.vue'
export default {
  components: { Scene },
  props: {
    anim_uid: { type: String, required: false, default: '' },
    brandkit: { type: Object, required: false, default: () => { return {} } },
    size: {
      type: Object, required: false, default: () => {
        return { "display_dimensions": [1080, 1080], "render_dimensions": [1024, 1024], "name": "facebook_catalog", "icon": "fa-facebook-square", "enabled": true, "short_name": "catalog", "aspect_icon": "1-1" }
      }
    },
    briefing_id: { type: Number, required: false, default: null },
    show: { type: Boolean, required: false, default: true },
    public: { type: Boolean, required: false, default: false },
    on_complete: { type: Function, required: false, default: () => { } },
  },
  data() {
    return {
      zindices: {},
      completion_counter: 0,
      audio: false,
      total_progress: 0,
      current_scene_indice: 0,
      playing: false,
      progress: 0,
      wrapping_up: false,
      briefing_canvasses: [],
      l_show: this.show,
      width_override: null,
      parentWidth: 0,
      parentHeight: 0
    }
  },
  async mounted() {
    let briefing_canvasses = []
    if (this.briefing_id) {
      if (this.public) {
        briefing_canvasses = await studioApi.template_scenes({ id: this.briefing_id }, { from_cache: true })
      } else {
        briefing_canvasses = await studioApi.briefing_canvasses({ id: this.briefing_id }, { from_cache: true })
      }
      this.briefing_canvasses = briefing_canvasses
    }

    if (this.size.name == 'instagram_story') {
      this.width_override = 90
    }
    this.$nextTick(() => {
      this.updateParentDimensions()
    })
  },
  watch: {
    show: {
      handler: function (show) {
        this.l_show = show
      }
    },
  },
  computed: {
    computedScale() {
      const scaleX = this.parentWidth / this.l_size_w;
      const scaleY = this.parentHeight / this.l_size_h;
      return Math.min(scaleX, scaleY);
    },
    l_size_w() {
      try {
        return this.size.display_dimensions[0]
      } catch (e) {
        return 1080
      }
    },
    l_size_h() {
      try {
        return this.size.display_dimensions[1]
      } catch (e) {
        return 1080
      }
    }
  },
  methods: {
    updateParentDimensions() {
      if (this.$refs.parentContainer) {
        const parent = this.$refs.parentContainer;
        this.parentWidth = parent.clientWidth;
        this.parentHeight = parent.clientHeight;
      }
    },
    play() {
      this.playing = true
      let indice = this.current_scene_indice
      try {
        this.$refs['scene_' + this.briefing_canvasses[indice].id][0].play()
      } catch (e) {
        console.log(e)
      }
    },
    calculate_scene_progress_percentage(index, anim) {
      let progress = 100 / this.briefing_canvasses.length * (index) + 100 / this.briefing_canvasses.length * (anim.progress / 100)
      return progress
    },
    on_progress(scene, index, anim) {
      this.progress = this.calculate_scene_progress_percentage(index, anim)
      if (this.progress >= 100) {
        this.progress = 0
        this.on_complete()
        setTimeout(() => {
          this.l_show = false
          setTimeout(() => {
            this.l_show = true
          }, 2)
        }, 10)
      }
    },
    complete(scene, index, anim) {
      if (index == this.briefing_canvasses.length - 1) {
      } else {
        this.current_scene_indice = index + 1
        try {
          this.$refs['scene_' + this.briefing_canvasses[index + 1].id][0].play()
        } catch (e) {
          console.log(e)
        }
      }
    },
  }
}
</script>
