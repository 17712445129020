import anime from 'animejs/lib/anime';
import Animation from '../animation';

export default class Entrance extends Animation {
  constructor({
    samples, layer, uid, duration, update_layer,
  }) {
    super({
      samples, layer, uid, duration, update_layer,
    });
  }

  name() {
    return 'Entrance';
  }

  cleanup() {
    const transform = this.initial_transform
    $(`${this.target()}`).css('transform', `${transform} scale(1)`)
    $(`${this.target()}`).css('opacity', this.opacity())
  }

  prepare() {
    const targetElement = $(`${this.target()}`);
    targetElement.css('transform', `${this.initial_transform} scale(0)`);
    targetElement.css('opacity', '0');
  }

  static int_name() {
    return 'entrance';
  }

  static exposed_to() {
    return ['image', 'logo', 'text', 'text_area', 'video', 'price', 'rectangle', 'circle', 'background'];
  }

  animation() {
    return anime.timeline({
      duration: this.duration,
      autoplay: false,
    }).add({
      targets: `${this.target()}`,
      scale: [0, 1.2],
      opacity: [0, this.opacity()],
      easing: 'easeOutQuart',
      duration: this.duration * 0.6,
    }).add({
      targets: `${this.target()}`,
      scale: [1.2, 1],
      easing: 'easeInOutQuart',
      duration: this.duration * 0.4,
    });
  }
}
