import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ReviewScore extends Component {
  name(){
    return 'Company review score'
  }

  labels(){
    return ['brandkit']
  }

  icon(){
    return 'fa-solid fa-hundred-points'
  }

  config(){
    return Layer.factory({
      layer_type: 'text',
      width: 100,
      align: 'center',
      text: '{{brandkit.average_review_score}}'
    })
  }
}
