<template>
  <div>
    <h3>Reachable audience {{reach.toLocaleString()}}</h3>
    <multiselect
      v-model="audience"
      :custom-label="customLabel"
      track-by="id"
      placeholder="Type to search"
      open-direction="bottom"
      :options="suggestionResults"
      :multiple="true"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      :clear-on-select="false"
      :close-on-select="false"
      :options-limit="300"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="true"
      @search-change="debounceInput">
      <template slot="tag" slot-scope="{ option, remove }">
        <span class="multiselect__tag">
          <span>{{ option.name }} - {{option.type}}</span>
          <i aria-hidden="true" tabindex="1" @click="remove(option)" class="multiselect__tag-icon"></i>
        </span>
      </template>
      <template slot="clear" slot-scope="props">
        <div class="multiselect__clear" v-if="audience.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
      </template>
      <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
    </multiselect>
  </div>
</template>

<script>
import {SET_SEARCH_STRING,
        CLEAR_SUGGESTIONS,
        CLEAR_SEARCH_RESULTS,
        SET_SEARCH_RESULTS,
        SET_SUGGESTIONS,
        SET_INTERESTS } from '../../store/mutation-types/facebook_mutations'
import Multiselect from 'vue-multiselect'
import { debounce } from "debounce";

export default {
  components:{
    Multiselect
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed:{
    audience:{
      get () {
        return this.$store.state[this.namespace()].audience
      },
      set(value){
        this.$store.commit(`${this.namespace()}/${SET_INTERESTS}`, value)
      }
    },
    reachStatus(){
      return this.$store.state[this.namespace()].reach_status
    },
    reach(){
      return this.$store.state[this.namespace()].reach
    },
    searchResults(){
      return this.$store.state[this.namespace()].searchResults
    },
    suggestionResults(){
      return this.$store.getters[`${this.namespace()}/suggestionResults`]
    }
  },
  created(){
    setInterval(()=>{
      this.$store.dispatch(`${this.namespace()}/getReach`)
    }, 500)
    this.$store.subscribe((mutation, state) => {
      const mutation_type = mutation.type.split('/')[1]
      if(mutation_type === SET_SUGGESTIONS || mutation_type === SET_SEARCH_RESULTS)
        this.isLoading = false
      if(mutation_type === SET_INTERESTS)
        this.updateAudience()
    })
  },
  methods:{
    limitText (count) {
      return `and ${count} other targets`
    },
    customLabel({name, type}){
      return `${name} - ${type}`
    },
    updateAudience(){
      this.debounceInput()
    },
    namespace(){
      return this.$root.$data.namespace
    },
    debounceInput: debounce(function(value){
      this.$store.dispatch(`${this.namespace()}/update`)
      this.isLoading = true
      if(typeof value  === 'string' && value){
        this.$store.commit(`${this.namespace()}/${CLEAR_SUGGESTIONS}`)
        this.$store.dispatch(`${this.namespace()}/search`, value)
      } else {
        this.$store.commit(`${this.namespace()}/${CLEAR_SEARCH_RESULTS}`)
        this.$store.dispatch(`${this.namespace()}/getSuggestions`, this.audience)
      }
    }, 500)
  }
}

</script>
