<template>
    <div :style="{ color: layer.color, width: layer.width + 'px' }">
        <div class="sticker__repeater" :style="`gap: ${layer.gap || 5}px; display: flex; align-items: center;`">
            <template v-for="i in 50">
                <span class="sticker__repeater__text" :style="`
                        color: ${layer.color}; 
                        font-size: ${layer.fontSize * 0.45}px;
                        font-family: ${font}; 
                        padding-left: ${layer.config.sticker_repeater.gap}px; 
                        padding-right: ${layer.config.sticker_repeater.gap}px`" :key="'text' + i">
                    {{ layer.text }}
                </span>
                <i :class="iconType"
                    :style="{ color: layer.config.sticker_repeater.icon_color, fontSize: layer.fontSize * 0.2 + 'px' }">
                </i>
            </template>
        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    components: {},
    mixins: [FontMixin],
    data() {
        return {}
    },
    computed: {
        component_name() {
            return this.layer.config.component.type
        },
        bgColor() {
            // Dynamically fetch bg_bolor, default to 'white' if not set
            return this.layer.config[this.component_name].bg_color || 'white'
        },
        iconType() {
            return this.layer.config.sticker_repeater.icon_type || ''
        },
    },
    methods: {
        parse(text) {
            return highlight.highlight(text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color)
        },
    }
}
</script>