<template>
  <div v-if="!element[element_key]" class="feed-builder__file-container">
    <!-- Empty state -->
    <button class="button button--light" @click="open_media_lib">
      <i aria-hidden="true" class="fa-regular fa-plus"></i>
      Choose file
    </button>
  </div>

  <div v-else>

    <div class="feed-builder__file-container">
      <div class="feed-builder__file-image" @click="open_media_lib"
        :style="{ backgroundImage: 'url(' + element[element_key] + ')' }">
        <div class="loader" v-if="busy"></div>
      </div>

      <div style="margin-left: 6px;" v-if="!defaultDisplay">
        <i class="icon-delete image-editor__sidebar-layer-config-delete" @click="e => on_change(null)"></i>
      </div>

      <button class="button button__toggle" @click="open_media_lib" v-if="defaultDisplay">
        <i class="fa-regular fa-arrows-rotate"></i>
        Replace
        <span class="tooltip tooltip--top">Swap file</span>
      </button>

      <button class="button button__toggle__backplated" v-if="type() == 'image' && defaultDisplay"
        v-on:click="open_img_focus">
        <i class="fa-regular fa-arrows-to-circle"></i>
        <span class="tooltip tooltip--top">Set focuspoint</span>
      </button>

      <Panel :position="panelProps.position" :margin="panelProps.margin" :panelTitle="'Media'" v-if="defaultDisplay">
        <template v-slot:button>
          <div class="button button button__toggle__backplated">
            <i class="fa-regular fa-ellipsis-vertical"></i>
          </div>
        </template>
        <template v-slot>

          <div style="display: flex; flex-direction: column; gap: 4px;">

            <button class="button__toggle__light button__toggle--active" @click="open_media_lib">
              <i class="fa-regular fa-arrows-rotate"></i>
              Replace
            </button>

            <button class="button__toggle__light button__toggle--active" v-if="type() == 'image'"
              v-on:click="open_img_focus">
              <i class="fa-regular fa-arrows-to-circle"></i>
              Set focuspoint
            </button>

            <button class="button__toggle__light button__toggle--active" v-if="type() == 'image'" @click="remove_bg">
              <i class="fa-regular fa-wand-sparkles"></i>
              Copy url for remove.bg
            </button>


            <div class="feed-builder__file" :style="{ backgroundImage: 'url(' + element[element_key] + ')' }">
              <div class="loader" v-if="busy"></div>
            </div>

            <div v-if="type() == 'video'" class="feed-builder__file-video">
              <div class="loader" v-if="busy"></div>
              <Video :url="element[element_key]" />
            </div>

            <div class="feed-builder__file-url" style="width: 100%">
              <input type="text" :name="name" :disabled="disabled" placeholder="Paste url or upload file..."
                v-on:keyup="forceUpdate()" v-model="element[element_key]">
              <input type="file" :disabled="disabled" ref="file_input"
                v-on:change="create_asset($event, element, element_key)">
            </div>

          </div>

        </template>
      </Panel>

      <Teleport to="#teleportTo" v-if="defaultDisplay">
        <modal :name="`img_focus_${uid}${field_id}_${element_key}`" height="auto" width="70%" style="z-index: 40000">
          <div style="display: flex; padding: 12px;">
            <div style="width: 20%; padding: 20px;">
              <b>Focus</b><br><br>
              <div>
                <img :id="`img_focus_picker_${field_id}_${element_key}`" :src="element[element_key]"
                  style="width: 100%;" />
              </div>
            </div>
            <div style="width: 20%; border-radius: 10px; margin-left: 12px; height: 400px; background-size: cover;"
              :style="focus_picker_styles">
            </div>
            <div style="width: 20%; border-radius: 10px; margin-left: 12px;height: 250px; background-size: cover;"
              :style="focus_picker_styles">
            </div>
            <div style="width: 30%; border-radius: 10px;  margin-left: 12px; height: 150px; background-size: cover;"
              :style="focus_picker_styles">
            </div>
          </div>
        </modal>
      </Teleport>

    </div>
  </div>
</template>

<script>
import { FocusPicker } from "image-focus"
import { debounce } from "debounce";
import { EventBus } from '../../lib/media/eventbus.js'
import Video from '../../studio/components/video.vue'
import Panel from '../../studio/components/panel.vue'
import Teleport from 'vue2-teleport';

export default {
  components: { Video, Teleport, Panel },
  data() {
    return {
      busy: false,
      field_id: null,
    }
  },
  props: {
    display: {
      type: String,
      required: false,
      default: "block",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    uid: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    element: {
      type: Object,
      required: true,
    },
    element_key: {
      type: String,
      required: true,
    },
    submit_on_change: {
      type: Boolean,
      required: false,
      default: false,
    },
    on_change: {
      type: Function,
      required: false,
      default: () => { }
    },
    on_btn_click: {
      type: Function,
      required: false,
      default: () => { }
    },
    panelProps: {
      type: Object,
      required: false,
      default: () => ({ position: 'left', margin: 236 })
    }
  },
  computed: {
    focus_picker_styles() {
      return {
        'background-image': `url(${this.element[this.element_key]})`,
        'background-position': this.background_position
      }
    },
    defaultDisplay() {
      return this.display === 'block'
    },
    background_position() {
      let y = Math.abs(this.focus_points.y) / 2 * 100
      let x = Math.abs(this.focus_points.x) / 2 * 100
      if (this.focus_points.y < 0) {
        y += 50
      } else {
        y = 50 - Math.abs(this.focus_points.y) * 50
      }
      if (this.focus_points.x > 0) {
        x += 50
      } else {
        x = 50 - Math.abs(this.focus_points.x) * 50
      }
      return `${x}% ${y}%`
    },
    focus_points() {
      try {
        let link = new URL(this.element[this.element_key])
        let focus_x = link.searchParams.get('focus_x')
        let focus_y = link.searchParams.get('focus_y')
        return { x: focus_x, y: focus_y }
      } catch (e) {
        return {
          x: 0,
          y: 0
        }
      }
    }
  },
  mounted() {
    // EventBus.$on('asset_uploaded', (data)=>{
    //   let element = {...this.element}
    //   element[this.element_key] = data.asset.file_url
    //   this.update(element[this.element_key])
    //   this.busy = false
    // })
    this.field_id = Math.random().toString(36).slice(2, 7)
  },
  methods: {
    open_media_lib() {
      EventBus.$emit('show', { field_id: this.field_id })
      EventBus.$on('on_asset_' + this.field_id, this.handle_asset_pick)
      this.on_btn_click()
    },
    handle_asset_pick(e) {
      EventBus.$off('on_asset_' + this.field_id, this.handle_asset_pick)
      EventBus.$emit('hide')
      let element = { ...this.element }
      element[this.element_key] = e.image_link ? e.image_link : e.asset_url
      this.update(element[this.element_key])
    },
    open_img_focus() {
      if (this.element[this.element_key]) {
        let url = new URL(this.element[this.element_key])
        this.$modal.show(`img_focus_${this.uid}${this.field_id}_${this.element_key}`)
        setTimeout(() => {
          const focusPickerEl = document.getElementById(`img_focus_picker_${this.field_id}_${this.element_key}`)
          const focusPicker = new FocusPicker(focusPickerEl, {
            onChange: focus => {
              url.searchParams.set('focus_y', focus.y)
              url.searchParams.set('focus_x', focus.x)
              this.update(url.toString())
            },
            focus: this.get_focus_points(),
          })
        }, 50)
      }
    },
    update: debounce(function (data) {
      this.element[this.element_key] = data
      this.on_change(data, this.element_key)
    }, 500),
    get_focus_points() {
      let url = new URL(this.element[this.element_key])
      let obj = { x: url.searchParams.get('focus_x') || 0, y: url.searchParams.get('focus_y') || 0 }
      return obj
    },
    forceUpdate(e) {
      this.$forceUpdate()
      this.submit_closest_form()
      this.update(this.element[this.element_key])
    },
    type() {
      const videos = ['mp4', 'avi', 'mov']
      if (this.element[this.element_key] &&
        videos.some(v => this.element[this.element_key] && this.element[this.element_key].toLowerCase().includes(v)))
        return 'video'
      return 'image'
    },
    create_asset(event, element, key) {
      const form_data = new FormData()
      const file = event.target.files[0]
      EventBus.$emit('upload', { file: file, element: element, key: key })
      this.busy = true
    },
    submit_closest_form() {
      if (this.$props.submit_on_change === true) {
        setTimeout(() => {
          this.$el.closest('form').submit()
        }, 1)
      }
    },
    remove_bg() {
      const url = this.element[this.element_key];
      if (url) {
        this.copyToClipboard(url);
        window.open(`https://www.remove.bg/upload`, '_blank');
      }
    },
    copyToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  }

}
</script>
