var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"multine-ellipsis",style:({
    'white-space': _vm.layer.config['word_wrap'] == 'initial' ? 'initial' : '',
    fontFamily: _vm.font,
    fontSize: ((_vm.layer.fontSize) + "px"),
    color: _vm.color,
    'word-break': 'break-word',
    'hyphens': 'auto',
    WebkitLineClamp: _vm.layer.config['word_wrap_line_clamp'] || 4
  }),attrs:{"id":("multine-ellipsis_" + _vm.animation_uid + "_" + (_vm.layer.id))}},[(_vm.disabled)?_c('span',{attrs:{"id":("layer_text_" + _vm.animation_uid + "_" + (_vm.layer.id))},domProps:{"innerHTML":_vm._s(_vm.parse(_vm.text_value))}}):_c('span',{staticStyle:{"display":"block"},attrs:{"id":("layer_text_" + _vm.animation_uid + "_" + (_vm.layer.id))},domProps:{"innerHTML":_vm._s(_vm.parse(_vm.text_value) || ("Layer " + (_vm.layer.id)))}})])])}
var staticRenderFns = []
export { render, staticRenderFns }