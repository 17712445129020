<template>
  <Canvas :key="`stencil_it_${stencil.id}`"
          :disabled="true"
          :size="{w: stencil.width, h: stencil.height}"
          :layers="stencil.layers"
          :background_color="stencil.background_color"
          :background="stencil.background_image"
          :samples="samples"/>
</template>

<script>

import LayerModel from '../store/modules/layers/model'
export default  {
  name: 'Stencil',
  components: {
    Canvas: () => import('../canvas.vue')
  },
  props: ['layer', 'samples', 'disabled', 'brandkit'],
  data(){
    return {
      stencil: null,
      counter: 0
    }
  },
  mounted(){

  },
  watch: {
    layer: {
      deep: true,
      immediate: true,
      handler(layer){
        if(layer.stencil){
          this.stencil = {...layer.stencil}
        }
      }
    }
  },
  computed: {
  },
  methods: {
    z_index(layer){
      if(this.stencil.layers.length - layer.position >= 0)
        return this.stencil.layers.length - layer.position
      return 0
    },
    factory(layer){
      return new LayerModel(layer)
    },
  }
}
</script>
