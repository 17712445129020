import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class SaleDifference extends Component {
  name(){
    return 'Sale difference'
  }

  labels(){
    return ['discounts']
  }

  icon(){
    return 'fa-regular fa-eur'
  }

  config(){
    return Layer.factory({
      layer_type: 'price',
      text: '[[sale_diff]]'
    })
  }
}
