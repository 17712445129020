import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Image extends Component {
  name() {
    return 'Logo'
  }

  labels() {
    return ['brandkit']
  }

  icon() {
    return 'fa-regular fa-picture-o'
  }

  config(opts = {}) {
    return Layer.factory({
      layer_type: 'logo',
      text: '',
      width: 200,
      height: 200,
      config: {
        image_url: ''
      },
      ...opts
    })
  }
}
